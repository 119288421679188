import React        from "react";
import styled       from 'styled-components';
import { pageType } from "../pages";
import Body         from "../component/body";
import { Input }    from "../component/form";
import Textarea     from "../component/form/textarea";
import * as utils   from '../utils';
import ReCAPTCHA    from '../component/recaptcha';
import { ws }       from "../utils";

const StyledContactContainer = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    min-width: 30rem;
    opacity: 1;
    transition: opacity .3s ease-in-out;
    
    & > * {
        margin: .25rem 0;
    }
    
    &.sent {
        opacity: .3;
        pointer-events: none;
        cursor: progress;
    }
`;

const StyledMessageFormSent = styled.div`
    opacity: 0;
    transition: opacity .2s ease-in-out;

    &.sent {
        opacity: 1;
    }
`;

interface Props {
}

interface State {
    dateTimeFormat: string;
    name: React.RefObject<Input>;
    email: React.RefObject<Input>;
    description: React.RefObject<Textarea>;
    nameCharactersCurrent: number;
    emailCharactersCurrent: number;
    descriptionCharactersCurrent: number;
    maxCharacters: {
        name: number;
        email: number;
        description: number;
    };
    valid: {
        name: boolean;
        email: boolean;
        description: boolean;
    };
    currentDateTime: () => Date;
    blockedUntil?: Date;
    error?: string;
    success?: string;
    sentEnabled: { [name: string]: boolean };
    recaptcha: boolean;
    submitted: boolean;
}

class PageBody extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        const dateTimeFormat  = utils.storage.account.preferences.get(`dateTimeFormat`);
        const currentDateTime = (): Date => new Date();
        const contactForm     = utils.storage.contact.get(`form`);
        const blockedUntil    = new Date(contactForm);
        this.state            = {
            dateTimeFormat               : dateTimeFormat || `Y-m-d H:i:s`,
            name                         : React.createRef<Input>(),
            email                        : React.createRef<Input>(),
            description                  : React.createRef<Textarea>(),
            nameCharactersCurrent        : 0,
            emailCharactersCurrent       : 0,
            descriptionCharactersCurrent : 0,
            maxCharacters                : {
                name        : 64,
                email       : 100,
                description : 512
            },
            valid                        : {
                name        : false,
                email       : false,
                description : false,
            },
            currentDateTime              : currentDateTime,
            blockedUntil                 : blockedUntil.toLocaleString() === `Invalid Date` ? undefined : blockedUntil,
            error                        : currentDateTime() <= blockedUntil ? `Sorry, this form is locked until ${utils.formattedDate(blockedUntil, dateTimeFormat)} is passed.` : undefined,
            success                      : undefined,
            sentEnabled                  : {
                name        : false,
                email       : false,
                description : false,
                threshold   : this.getThreshold(blockedUntil, currentDateTime),
            },
            recaptcha: false,
            submitted: false,
        };
    }

    componentDidMount() {
        ws.listener.message(
            [`verify.recaptcha`],
            data => this.setState({recaptcha: data.code === 200})
        );

        setInterval(
            () => {
                if (
                    this.state.blockedUntil
                    && this.state.error !== undefined
                    && this.state.currentDateTime() > this.state.blockedUntil
                ) {
                    const inputData = this.inputData();
                    this.setState(
                        {
                            error       : undefined,
                            sentEnabled : {
                                name        : inputData.name.valid,
                                email       : inputData.email.valid,
                                description : inputData.description.valid,
                                threshold   : this.getThreshold(),
                            }
                        },
                    );
                }
            },
            1000
        );
    }

    inputData() {
        const nameCurrent        = this.state.name.current?.state.inputRef.current?.value || ``;
        const emailCurrent       = this.state.email.current?.state.inputRef.current?.value || ``;
        const descriptionCurrent = this.state.description.current?.state.inputRef.current?.value || ``;

        return {
            name        : {
                value          : nameCurrent,
                length         : nameCurrent.length,
                valid          : nameCurrent.length > 3,
                greaterThanMax : nameCurrent.length > this.state.maxCharacters.name,
            },
            email       : {
                value          : emailCurrent,
                length         : emailCurrent.length,
                valid          : utils.validation.isValidEmail(emailCurrent),
                greaterThanMax : emailCurrent.length > this.state.maxCharacters.email,
            },
            description : {
                value          : descriptionCurrent,
                length         : descriptionCurrent.length,
                valid          : descriptionCurrent.length > 20,
                greaterThanMax : descriptionCurrent.length > this.state.maxCharacters.description,
            },
        };
    }

    getThreshold(blockedUntil?: Date, currentDateTime?: () => Date): boolean {
        if (blockedUntil === undefined) {
            blockedUntil = this.state.blockedUntil;
        }

        if (currentDateTime === undefined) {
            currentDateTime = this.state.currentDateTime;
        }

        return blockedUntil === undefined
            || blockedUntil.toString() === `Invalid Date`
            || currentDateTime() > blockedUntil;
    }

    inputChangedCheckMax(e: KeyboardEvent) {
        const inputData = this.inputData();
        if (
            (
                inputData.name.greaterThanMax
                || inputData.email.greaterThanMax
                || inputData.description.greaterThanMax
            )
            && ![
                `shift`,
                `backspace`,
                `home`,
                `end`,
                `delete`,
            ].includes(e.key.toLowerCase())
        ) {
            e.preventDefault();
        }

        this.setState(
            {
                nameCharactersCurrent        : inputData.name.length,
                emailCharactersCurrent       : inputData.email.length,
                descriptionCharactersCurrent : inputData.description.length,
            }
        );
    }

    inputChanged() {
        const inputData = this.inputData();
        if (
            inputData.name.length > this.state.maxCharacters.name
            || inputData.email.length > this.state.maxCharacters.email
            || inputData.description.length > this.state.maxCharacters.description
        ) {
            return;
        }

        const currentState = {
            name        : inputData.name.valid,
            email       : inputData.email.valid,
            description : inputData.description.valid,
        };

        this.setState(
            {
                sentEnabled                  : {
                    ...currentState,
                    threshold   : this.getThreshold(),
                },
                nameCharactersCurrent        : inputData.name.length,
                emailCharactersCurrent       : inputData.email.length,
                descriptionCharactersCurrent : inputData.description.length,
                valid                        : currentState,
            }
        );
    }

    sentContactForm() {
        if (
            !this.state.recaptcha
            || Object.values(this.state.sentEnabled).includes(false)
        ) {
            return;
        }

        const inputData = this.inputData();
        const contactObject = {
            name        : inputData.name.value,
            email       : inputData.email.value,
            description : inputData.description.value,
        };

        const messageSeconds  = 7;
        const blockedSeconds  = 1;
        const blockedDateTime = new Date();
        blockedDateTime.setTime((this.state.currentDateTime().getTime() || 0) + (blockedSeconds * 1000));

        utils.storage.contact.set.key(
            `form`,
            utils.formattedDate(blockedDateTime, `Y-m-d H:i:s.u`)
        );

        this.setState(
            {
                success      : `Form sent successful!`,
                blockedUntil : blockedDateTime,
                sentEnabled  : {
                    ...this.state.sentEnabled,
                    threshold : this.getThreshold(blockedDateTime),
                },
                valid        : {
                    ...this.state.valid,
                    name        : false,
                    email       : false,
                    description : false,
                },
                recaptcha: false,
                submitted: true
            },
            () => {
                // @ts-ignore
                window.grecaptcha.reset();
                (this.state.name.current?.state.inputRef.current as HTMLInputElement).value           = ``;
                (this.state.email.current?.state.inputRef.current as HTMLInputElement).value          = ``;
                (this.state.description.current?.state.inputRef.current as HTMLTextAreaElement).value = ``;

                utils.ws.sent(
                    `contact`,
                    `form`,
                    {
                        ...contactObject,
                        accountId: utils.storage.account.get(`accountId`) || `anonymous`,
                    },
                );

                setTimeout(
                    () => this.setState(
                        {
                            success : undefined,
                            error   : this.state.currentDateTime && this.state.blockedUntil && this.state.currentDateTime() <= this.state.blockedUntil
                                ? `This form is locked until ${utils.formattedDate(this.state.blockedUntil, this.state.dateTimeFormat)} is passed.`
                                : undefined,
                            submitted: false,
                        },
                    ),
                    messageSeconds * 1000
                );
            }
        );
    }

    render() {
        return <Body
            background={false}
        >
            <h1>Contact</h1>
            <StyledMessageFormSent
                className={this.state.submitted ? `sent` : ``}
            >
                The contact information has been sent successful!
            </StyledMessageFormSent>
            <StyledContactContainer
                className={this.state.submitted ? `sent` : ``}
            >
                <Input
                    ref={this.state.name}
                    type={`text`}
                    placeholder={`Name`}
                    iconName={`username`}
                    onBlur={() => this.inputChanged()}
                    onKeyUp={() => this.inputChanged()}
                    onKeyPress={(e: KeyboardEvent) => this.inputChangedCheckMax(e)}
                    errorEnabled={false}
                    success={this.state.valid.name}
                    currentLength={this.state.nameCharactersCurrent}
                    maxLength={this.state.maxCharacters.name}
                    showLength={true}
                />
                <Input
                    ref={this.state.email}
                    type={`text`}
                    placeholder={`Email`}
                    onBlur={() => this.inputChanged()}
                    onKeyUp={() => this.inputChanged()}
                    onKeyPress={(e: KeyboardEvent) => this.inputChangedCheckMax(e)}
                    errorEnabled={false}
                    success={this.state.valid.email}
                    currentLength={this.state.emailCharactersCurrent}
                    maxLength={this.state.maxCharacters.email}
                    showLength={true}
                />
                <Textarea
                    ref={this.state.description}
                    placeholder={
                        [
                            `What do you want to let the website holders to know?`,
                        ].join(`\r\n`)
                    }
                    onBlur={() => this.inputChanged()}
                    onKeyUp={() => this.inputChanged()}
                    onKeyPress={(e: KeyboardEvent) => this.inputChangedCheckMax(e)}
                    minHeight={`15rem`}
                    resizable={false}
                    success={this.state.valid.description}
                    currentLength={this.state.descriptionCharactersCurrent}
                    maxLength={this.state.maxCharacters.description}
                    showLength={true}
                />
                <ReCAPTCHA/>
                <Input
                    type={`submit`}
                    placeholder={`Sent`}
                    title={this.state.error}
                    error={this.state.error}
                    success={!Object.values(this.state.sentEnabled).includes(false) && this.state.recaptcha}
                    onClick={() => this.sentContactForm()}
                />
            </StyledContactContainer>
        </Body>;
    }
}

const page: pageType = {
    name  : `contact`,
    link  : [`/contact`],
    page  : <PageBody />,
    props : {
        menu : false,
    }
};

export default page;