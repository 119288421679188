import React from 'react';
import styled from 'styled-components';
import * as utils from '../utils';
import {ws} from "../utils";

const StyledInsContainer = styled.div`
    position: relative;
    height: 100%;
    min-width: calc(100% - 5rem);
    margin: auto;
    padding: 2.5rem;
`;
const StyledIns = styled.ins`
    position: relative;
    height: 100%;
    width: 100%;
    margin: auto;
`;
const StyledDiv = styled.div`
    text-align: center;
`;

interface Props {
    adType: `page` | `guide`
}

interface State {
    maxClicks: number;
    adUid: string;
    client: string;
    slots: { [adType: string]: string };
    format: { [adType: string]: string };
    layoutKey: { [adType: string]: string | undefined };
}

class Ads extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        const client = `ca-pub-7156885353482298`;
        this.state = {
            maxClicks: 15,
            adUid    : utils.uid(),
            client   : client,
            slots    : {
                'page' : `4423468696`,
                'guide': `9084093695`,
            },
            format   : {
                'page' : `auto`,
                'guide': `fluid`,
            },
            layoutKey: {
                'page' : undefined,
                'guide': `-ef+6k-30-ac+ty`,
            }
        };
    }

    componentDidMount() {
        const adIns = document.getElementById(this.state.adUid);
        !utils.isDevelopment()
        && setTimeout(
            () => (
                    adIns !== null
                    && adIns.offsetWidth > 64
                    && (adIns.getAttribute(`class`) || ``).includes(`adsbygoogle`)
                    && !(adIns.getAttribute(`class`) || ``).includes(`adsbygoogle-noablate`)
                    && ![`unfilled`, `filled`].includes(adIns.getAttribute(`data-ad-status`) || ``)
                )
                // @ts-ignore
                && (window.adsbygoogle = window.adsbygoogle || []).push({}),
            500
        );

        if (!utils.storage.ads.isClickBlocked()) {
            utils.storage.ads.removeClickBlock();
        }
    }

    onClick(e: React.MouseEvent) {
        const element = e.currentTarget as HTMLElement;

        let amount = Number(utils.storage.ads.get(`clicked`) || 0);
        amount = isNaN(amount) ? 1 : amount + 1;
        utils.storage.ads.set.key(`clicked`, amount);

        if (amount > this.state.maxClicks) {
            e.preventDefault();
            !utils.storage.ads.isClickBlocked()
            && utils.storage.ads.setClickBlock(false);

            const exceeded = amount - this.state.maxClicks;
            ws.sent(
                `log`,
                `ads.clicks`,
                {
                    accountId   : utils.storage.account.get(`accountId`) || `anonymous`,
                    url         : window.location.pathname,
                    clicks      : {
                        current  : amount,
                        max      : this.state.maxClicks,
                        exceeded : amount >= this.state.maxClicks && exceeded > 0 ? exceeded : 0,
                    },
                    blockedUntil: amount >= this.state.maxClicks ? utils.storage.ads.get(`clickBlockedUntil`) : null,
                    additional  : {
                        ...element.dataset || {},
                    },
                },
            );
        }

    }

    render() {
        return <StyledInsContainer
            key={this.state.adUid}
        >
            {
                utils.isDevelopment()
                    ? (
                        <StyledDiv
                            key={this.state.adUid}
                            id={this.state.adUid}
                            data-ad-client={this.state.client}
                            data-ad-slot={this.state.slots[this.props.adType]}
                            data-ad-format={this.state.format[this.props.adType] || `auto`}
                            data-ad-layout-key={this.state.layoutKey[this.props.adType]}
                            onClick={(e) => this.onClick(e)}
                        >
                            ad here!
                        </StyledDiv>
                    )
                    : (
                        <StyledIns
                            key={this.state.adUid}
                            id={this.state.adUid}
                            className={`adsbygoogle`}
                            style={{display: `block`}}
                            data-ad-client={this.state.client}
                            data-ad-slot={this.state.slots[this.props.adType]}
                            data-ad-format={this.state.format[this.props.adType] || `auto`}
                            data-ad-layout-key={this.state.layoutKey[this.props.adType]}
                            onClick={(e) => this.onClick(e)}
                        />
                    )
            }
        </StyledInsContainer>;
    }
}

export default Ads;