import React        from "react";
import styled       from 'styled-components';
import { pageType } from "../pages";
import Body         from "../component/body";
import AvailableGames from './games/index';
import * as utils   from '../utils';

const StyledContactContainer = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    min-width: 30rem;
    
    & > * {
        margin: .25rem 0;
    }
`;


interface Props {
}

interface State {
}

class PageBody extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
        };
    }

    componentDidMount() {
    }

    render() {
        return <Body
            background={false}
        >
            <h1>Games</h1>
            <ul>
                {
                    AvailableGames.AgersCustom.map(
                        (page, index) => page.props?.enabled === true
                            && <li
                            key={index}
                        >
                            <a href={page.link[0]}>{page.name}</a>
                        </li>
                    )
                }
            </ul>
            <StyledContactContainer>
            </StyledContactContainer>
        </Body>;
    }
}

const page: pageType = {
    name  : `games`,
    link  : [`/games`],
    page  : <PageBody />,
    props : {
        menu    : true,
        enabled : utils.storage.get(`test`) === `2957aeb927300d2cf9d5f0bdf39e7448`
    }
};

export default page;