import React                               from 'react';
import Book, {maxPageChunks} from "../../../../../../../../component/bookshelf/book";
import {pagesType, pageType} from "../../../../../../../../pages";
import * as Images from "../../../../../../../../asset/guide/windows/server/2012";

const link  = `/guides/windows/server/2012/raf/dhcp/configure`;
let   pages: pagesType = [
    {
        title: `Youtube video`,
        pages: [
            {
                text: <p>In this roles and features guide we are going to configure the role "DHCP".</p>,
                youtubeIdFull: `kG0IOfhK8XA`
            },
        ]
    },
    {
        title: `Add/install role`,
        pages: [
            {
                text: <p>Click on "Manage" and on "Add Roles and Features".</p>,
                imageSrc: Images.RolesAndFeatures.AddRolesAndFeatures
            },
            {
                text: <p>Click on "Next &gt;" to proceed.</p>,
                imageSrc: Images.RolesAndFeatures.dhcp.AddRolesAndFeaturesWizard1
            },
            {
                text: <p>Click on "Next &gt;" to proceed.</p>,
                imageSrc: Images.RolesAndFeatures.dhcp.AddRolesAndFeaturesWizard2
            },
            {
                text: <p>Check "DHCP Server".</p>,
                imageSrc: Images.RolesAndFeatures.dhcp.AddRolesAndFeaturesWizard3
            },
            {
                text: <p>Leave the checkbox "Include management tools (if applicable)" checked and click on "Add Features".</p>,
                imageSrc: Images.RolesAndFeatures.dhcp.AddRolesAndFeaturesWizard4
            },
            {
                text: <p>Click on "Next &gt;".</p>,
                imageSrc: Images.RolesAndFeatures.dhcp.AddRolesAndFeaturesWizard5
            },
            {
                text: <p>Click on "Next &gt;" again.</p>,
                imageSrc: Images.RolesAndFeatures.dhcp.AddRolesAndFeaturesWizard6
            },
            {
                text: <p>Click on "Next &gt;".</p>,
                imageSrc: Images.RolesAndFeatures.dhcp.AddRolesAndFeaturesWizard7
            },
            {
                text: <p>Click on "Install".</p>,
                imageSrc: Images.RolesAndFeatures.dhcp.AddRolesAndFeaturesWizard8
            },
            {
                text: <p>Click on "Close".</p>,
                imageSrc: Images.RolesAndFeatures.dhcp.AddRolesAndFeaturesWizard9
            }
        ]
    },
    {
        title: `DHCP Post-Install configuration`,
        pages: [
            {
                text: <p>Click on the flag (there should be a yellow warning sign) in the Server Manager. Click on "Complete DHCP configuration".</p>,
                imageSrc: Images.RolesAndFeatures.dhcp.DHCPConfigure
            },
            {
                text: <p>Click on "Next &gt;".</p>,
                imageSrc: Images.RolesAndFeatures.dhcp.DHCPConfigure1
            },
            {
                text: <p>Leave the selection on "Use the following user's credentials" (with the user "Administrator" of the domain) and click on "Commit".</p>,
                imageSrc: Images.RolesAndFeatures.dhcp.DHCPConfigure2
            },
            {
                text: <p>Click on "Close".</p>,
                imageSrc: Images.RolesAndFeatures.dhcp.DHCPConfigure3
            }

        ]
    },
    {
        title: `Configure new scope`,
        pages: [
            {
                text: <p>Click on "Tools" and on "DHCP".</p>,
                imageSrc: Images.RolesAndFeatures.dhcp.DHCPManager
            },
            {
                text: <p>Right click on "IPv4" and click on "New Scope...".</p>,
                imageSrc: Images.RolesAndFeatures.dhcp.IPV4NewScope
            },
            {
                text: <p>Click on "Next &gt;".</p>,
                imageSrc: Images.RolesAndFeatures.dhcp.NewScope1
            },

            {
                text: <div>
                    <p>Fill in a scope name. In this guide i'll use "AgersNLScope" since i've used "AgersNL" as the site of this domain.</p>
                    <p>Click on "Next &gt;".</p>
                </div>,
                imageSrc: Images.RolesAndFeatures.dhcp.NewScope2
            },
            {
                text: <div>
                    <p>Fill in an IP range.</p>
                    <p>This can be from 192.168.81.1 till 192.169.81.254, this depends on the subnet mask. If the subnet mask is 255.255.255.0 it means that it has 254 IP addresses (192.168.81.1 till 192.169.81.254).</p>
                    <p>255.255.255.0 is a C subnet mask.</p>
                    <p>For this guide i'll use the IP range 192.168.81.150 till 192.169.81.254.</p>
                    <p>Click on "Next &gt;".</p>
                </div>,
                imageSrc: Images.RolesAndFeatures.dhcp.NewScope3
            },
            {
                text: <p>For this guide i'll use no exclusions, so click on "Next &gt;".</p>,
                imageSrc: Images.RolesAndFeatures.dhcp.NewScope4
            },
            {
                text: <p>Change the lease duration to 6 hours and click on "Next &gt;".</p>,
                imageSrc: Images.RolesAndFeatures.dhcp.NewScope5
            },
            {
                text: <p>Leave the option "Yes, I want to configure these options now" selected and click on "Next &gt;".</p>,
                imageSrc: Images.RolesAndFeatures.dhcp.NewScope6
            },
            {
                text: <div>
                    <p>Add the Router IP (Default Gateway). In this case it's the TutorialServer1 IP address.</p>
                    <p>That is 192.168.81.1 so fil that IP address in and click on "Add".</p>
                </div>,
                imageSrc: Images.RolesAndFeatures.dhcp.NewScope7
            },
            {
                text: <p>Click on "Next &gt;" to proceed.</p>,
                imageSrc: Images.RolesAndFeatures.dhcp.NewScope8
            },
            {
                text: <p>Remove every IP address except for the IP address of the first server. In this case that TutorialServer1.</p>,
                imageSrc: Images.RolesAndFeatures.dhcp.NewScope9
            },
            {
                text: <p>Click on "Next &gt;" to proceed.</p>,
                imageSrc: Images.RolesAndFeatures.dhcp.NewScope10
            },
            {
                text: <p>In this guide we don't use this. Therefore click on "Next &gt;".</p>,
                imageSrc: Images.RolesAndFeatures.dhcp.NewScope11
            },
            {
                text: <p>Leave the option "Yes, I want to activate this scope now" and click on "Next &gt;".</p>,
                imageSrc: Images.RolesAndFeatures.dhcp.NewScope12
            },
            {
                text: <p>Click on "Finish"</p>,
                imageSrc: Images.RolesAndFeatures.dhcp.NewScope13
            },
            {
                text: <p>Click on "Address Pool" to check the configured scope.</p>,
                imageSrc: Images.RolesAndFeatures.dhcp.Scope
            },
            {
                text: <p>Congratulations, you've just installed DHCP and configured a DHCP scope! &#127881;</p>,
            }
        ]
    },
];

const links: string[] = [];
const lengthPages: number = (pages.map(
    (p) => {
        const modulo = p.pages.length % maxPageChunks;
        return p.pages.length + (modulo !== 0 ? maxPageChunks - (p.pages.length % maxPageChunks) : 0);
    }
).reduce((a,b)=>a+b) / (maxPageChunks * 2)) + 1;
for (let i=0;i<=lengthPages;i++) {
    links.push(`${link}/${i}`);
}

const page: pageType = {
    name: `Windows Server 2012`,
    link: [
        link,
        ...links,
    ],
    page: <Book
        logo={Images.Logo}
        preTitle={`Windows Server 2012`}
        title={`Roles and Features`}
        subTitle={`DHCP: configure`}
        link={link}
        links={links}
        pages={pages}
    />,
    props: {
        menu: true
    }
};

export default page;