import React from 'react';
import styled from 'styled-components';
import Body from '../../component/body';

const StyledMessage = styled.p`
    display: flex;
    flex-direction: column;
    margin: -.5rem 0;

    & > span {
        line-height: 2rem;
    }
`;

const NotFound = () => <Body
    background={false}
>
    <h1>Error 404: Not found</h1>
    <StyledMessage>
        <span>This page doesn't exist! But nice try.</span>
        <span>So shoo now! Begone!</span>
    </StyledMessage>
</Body>;

export default NotFound;