import React from 'react';
import styled from 'styled-components';

const StyledLinks = styled.div`
    text-transform: capitalize;
`;

const StyledDivider = styled.div`
    cursor: default;
`;

const StyledLink = styled.a`
    color: var(--color-text);
    
    &:hover {
        color: var(--color-text-hover);
    }
`;

const Links = () => {
    const allLinks: {[key: string]: string} = {
        'cookies'              : `/cookies`,
        'privacy policy'       : `/privacy-policy`,
        'terms and conditions' : `/terms-and-conditions`,
        'contact'              : `/contact`
    };

    const maxPerLine: number = 3;
    const links: {[key: string]: string}[][] = [];
    const allLinksKeys: string[] = Object.keys(allLinks);
    const loopLength = Math.ceil(allLinksKeys.length / maxPerLine);
    if (loopLength > 0) {
        for(let i=0; i<loopLength; i++) {
            let index = i*maxPerLine;
            const maxKeys = allLinksKeys.slice(index, index+maxPerLine);
            links.push(
                maxKeys.map(
                    (k)=> {
                        return {
                            name : k,
                            link : allLinks[k]
                        };
                    }
                )
            );
        }
    }

    return <StyledLinks>
        {
            links.map(
                (link, index: number) => <div
                    key={index}
                    className={`agers-link`}
                >
                    {
                        link.map(
                            (linkObject, index: number) => linkObject !== undefined
                                ? [
                                    index === 0 ? undefined : <StyledDivider key={index + 1.5}>-</StyledDivider>,
                                    <StyledLink
                                        key={index}
                                        href={linkObject.link}
                                    >
                                        {linkObject.name}
                                    </StyledLink>
                                ]
                                : undefined
                        )
                    }
                </div>
            )
        }
    </StyledLinks>;
};

export default Links;