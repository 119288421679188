const number = {
    format: (value: number|string, decimals: number = 0) => {
        if (isNaN(Number(value))) {
            return value;
        }

        value = decimals > 0 ? value.toString().split(`.`).map((v,index) => index === 1 ? v.substring(0,decimals) : v).join(`.`) : value;
        value = Number(value);

        return isNaN(Number(value))
            ? value
            : Number(value).toLocaleString(`nl`, {minimumFractionDigits: decimals, maximumFractionDigits: decimals})
    },

};
export default number;