import storage from "./index";

const featureKey = `features`;
const features = {
    set: {
        storage: (newStorage: object): boolean => storage.set.key(featureKey, newStorage),
        key: function(key: string, value?: any): boolean {
            if (`${key}`.length === 0) {
                return false;
            }

            const storageAgers = features.get() || {};
            if (
                typeof storageAgers === `object`
                && storageAgers !== null
            ) {
                storageAgers[key] = value;

                return this.storage(storageAgers);
            }

            return false;
        },
        keys: function(object: object): void {
            const storageAgers = features.get() || {};
            if (
                typeof storageAgers !== `object`
                || storageAgers === null
            ) {
                return;
            }

            this.storage(
                {
                    ...storageAgers,
                    ...object
                }
            );
        },
    },
    remove: {
        key : (key: string): void => {
            if (`${key}`.length === 0) {
                return;
            }

            const storageAgers = features.get();
            if (
                typeof storageAgers === `object`
                && storageAgers !== null
                && storageAgers.hasOwnProperty(key)
            ) {
                storageAgers[key] = undefined;
                delete storageAgers[key];
                features.set.storage(storageAgers);
            }
        },
        keys: (...keys: string[]): void => {
            const storageAgers = features.get();
            if (
                typeof storageAgers !== `object`
                || storageAgers === null
            ) {
                return;
            }

            keys.forEach(
                key => {
                    if (storageAgers.hasOwnProperty(key)) {
                        storageAgers[key] = undefined;
                        delete storageAgers[key];
                    }
                }
            );

            features.set.storage(storageAgers);
        }
    },
    clear : (): void => storage.remove.key(featureKey),
    get: (key?: string) => {
        const storageAgers = storage.get(featureKey);
        if (!key) {
            return storageAgers;
        }

        return typeof storageAgers === `object` && storageAgers !== null && storageAgers.hasOwnProperty(key)
            ? storageAgers[key]
            : undefined;
    },
};

export default features;