import React                               from 'react';
import Book, {maxPageChunks} from "../../../../../../component/bookshelf/book";
import {pagesType, pageType} from "../../../../../../pages";
import * as Images from '../../../../../../asset/guide/windows/server/2012';

const link  = `/guides/windows/server/2012/installation`;
let   pages: pagesType = [
    {
        title: `Requirements`,
        pages: [
            {
                text: <div>
                    <p>The Windows server 2012 requires at least the following specification:</p>
                    <table>
                        <tbody>
                            <tr>
                                <td></td>
                                <td></td>
                                <td>Minimum</td>
                                <td></td>
                                <td>Recommended</td>
                                <td></td>
                                <td>Optional</td>
                            </tr>
                            <tr>
                                <td>CPU Core(s)</td>
                                <td>:</td>
                                <td>1</td>
                                <td></td>
                                <td>2 or more</td>
                                <td></td>
                                <td>No</td>
                            </tr>
                            <tr>
                                <td>CPU 32/64-bits</td>
                                <td>:</td>
                                <td>64-bit</td>
                                <td></td>
                                <td>64-bit</td>
                                <td></td>
                                <td>No</td>
                            </tr>
                            <tr>
                                <td>CPU speed</td>
                                <td>:</td>
                                <td>1.4 GHz</td>
                                <td></td>
                                <td>2.1 GHz or more</td>
                                <td></td>
                                <td>No</td>
                            </tr>
                            <tr>
                                <td>RAM</td>
                                <td>:</td>
                                <td>512 MB</td>
                                <td></td>
                                <td>2 GB or more</td>
                                <td></td>
                                <td>No</td>
                            </tr>
                            <tr>
                                <td>Disk</td>
                                <td>:</td>
                                <td>32 GB</td>
                                <td></td>
                                <td>160 GB or more</td>
                                <td></td>
                                <td>No</td>
                            </tr>
                            <tr>
                                <td>Monitor/Display</td>
                                <td>:</td>
                                <td>1024x768</td>
                                <td></td>
                                <td>1920x1080 or higher</td>
                                <td></td>
                                <td>No</td>
                            </tr>
                            <tr>
                                <td>Network</td>
                                <td>:</td>
                                <td>10 MB</td>
                                <td></td>
                                <td>100 MB or more</td>
                                <td></td>
                                <td>Yes</td>
                            </tr>
                        </tbody>
                    </table>
                </div>,
            },
        ],
    },
    {
        title: `Youtube video`,
        pages: [
            {
                text: <p>In this installation guide the Windows server 2012 will be installed from scratch. Like a new server, either virtual or physical.</p>,
                youtubeIdFull: `5C0vcN4z0e8`
            },
        ]
    },
    {
        title: `Installation guide (with GUI)`,
        pages: [
            {
                text: <div>
                    <p>Select the desired language, time and currency format and Keyboard or input method.</p>
                    <p>For this guide i'll use the default English and US international keyboard.</p>
                </div>,
                imageSrc: Images.Language
            },
            {
                text: <p>Click on "Install now".</p>,
                imageSrc: Images.InstallNow
            },
            {
                text: <p>Fill in the product key.</p>,
                imageSrc: Images.ProductKey
            },
            {
                text: <p>Select "Server with a GUI". The "Server Core" is only commandline and no visual interface to interact with.</p>,
                imageSrc: Images.ServerType
            },
            {
                text: <p>Read the license terms, check the "I accept the license terms" and click "Next" to proceed.</p>,
                imageSrc: Images.LicenseTerms
            },
            {
                text: <p>Choose the "Custom: Install Windows only (advanced)" option.</p>,
                imageSrc: Images.CustomInstallWindows
            },
            {
                text: <p>Now we need to allocate space to install the server on. Click on "Drive options (advanced)".</p>,
                imageSrc: Images.DriveOptionsAdvanced
            },
            {
                text: <p>Click on "New".</p>,
                imageSrc: Images.DriveOptionsNew
            },
            {
                text: <div>
                    <p>Leave the size as it is and click "Apply".</p>
                    <p>The disk size may vary on your computer/virtual machine.</p>
                </div>,
                imageSrc: Images.DriveOptionsNewApply
            },
            {
                text: <p>Click "Ok" to proceed.</p>,
                imageSrc: Images.DriveOptionsNewApplyMessage
            },
            {
                text: <p>Click "Next".</p>,
                imageSrc: Images.DriveOptions
            },
            {
                text: <p>Wait until Windows Server 2012 is installed.</p>,
                imageSrc: Images.InstallingWindowsServer
            },
            {
                text: <>
                    <p>Fill in a strong administrator password.</p>
                    <p>Recommended to use at least:</p>
                    <ul>
                        <li>1 uppercase</li>
                        <li>1 lowercase</li>
                        <li>1 number</li>
                        <li>1 special character</li>
                        <li>length of 8 characters</li>
                    </ul>
                </>,
                imageSrc: Images.AdministratorSetPassword
            },
            {
                text: <p>Sign in with the administrator account, which you've just set the password for.</p>,
                imageSrc: Images.SignIn
            },
        ]
    },
    {
        title: `VMware Tools`,
        pages: [
            {
                text: <p>Right click on the virtual machine and click on "Install VMware Tools...".</p>,
                imageSrc: Images.VMWareToolsInstall
            },
            {
                text: <div>
                    <p>Open a explorer and click on "VMware Tools" on the DVD/CD drive.</p>
                    <p>Tip: use the shortcut "windows key" + "E".</p>
                </div>,
                imageSrc: Images.VMWareTools
            },
            {
                text: <p>{`Click on "Next >".`}</p>,
                imageSrc: Images.VMWareToolsWizard1
            },
            {
                text: <p>{`Leave the setup type on "Typical" and click on "Next >" to proceed.`}</p>,
                imageSrc: Images.VMWareToolsWizard2
            },
            {
                text: <p>Click on "Install" and wait until the setup is done.</p>,
                imageSrc: Images.VMWareToolsWizard3
            },
            {
                text: <div>
                    <p>Click on "Finish" to close the setup.</p>
                    <p></p>
                    <p>
                        VMware Tools is installed now.<br/>
                        This should made have some changes, like adjusting the screen size to the VMware client.
                    </p>
                </div>,
                imageSrc: Images.VMWareToolsWizard4
            },
            {
                text: <div>
                    <p>Click on "Yes" if the screen didn't adjust to the VMware client.</p>
                    <p>If the screen did adjust, click "No".</p>
                </div>,
                imageSrc: Images.VMWareToolsWizard5
            },
        ]
    },
    {
        title: `Network IP address`,
        pages: [
            {
                text: <p>Press the keys "windows key" + "X" to open up the quick menu and click on "Control Panel".</p>,
                imageSrc: Images.QuickMenu
            },
            {
                text: <p>Click on "Network and Internet".</p>,
                imageSrc: Images.ControlPanel
            },
            {
                text: <p>Click on "Network and Sharing Center" to proceed.</p>,
                imageSrc: Images.ControlPanelNetwork
            },
            {
                text: <p>Click on "Change adapter settings".</p>,
                imageSrc: Images.ControlPanelNetworkAndSharingCenter
            },
            {
                text: <div>
                    <p>Right click on the network and click on "Rename".</p>
                    <p>Rename the NIC (Network Interface) to "LAN" (Local Area Network).</p>
                </div>,
                imageSrc: Images.ControlPanelNetworkNICRename
            },
            {
                text: <p>Right click again on the NIC (Network Interface) and click on "Status".</p>,
                imageSrc: Images.NICStatusMenu
            },
            {
                text: <p>Click on "Details...".</p>,
                imageSrc: Images.NICStatusDialog
            },
            {
                text: <div>
                    <p>It's possible that it doesn't have a IPv4 address.</p>
                    <p>If you use VMware it's possible that it is like this.</p>
                    <p>Click on "Close" to proceed.</p>
                </div>,
                imageSrc: Images.NICDetailsDialog
            },
            {
                text: <p>Click on "Properties" to setup a own IP address for the server.</p>,
                imageSrc: Images.NICProperties
            },
            {
                text: <div>
                    <p>Uncheck "Internet Protocol Version 6 (TCP/IPv6)" (only if you don't use IPv6).</p>
                    <p>Select "Internet Protocol Version 4 (TCP/IPv4)" and click on "Properties".</p>
                </div>,
                imageSrc: Images.NICPropertiesIPV4
            },
            {
                text: <div>
                    <p>Fill in a IP address. It's mostly common to use a IP address on the begin of the IP address range. The range may very on the subnet.</p>
                    <p>For this case we use a C-subnet type which is 255.255.255.0, this has a range from 192.168.81.1 till 192.168.81.255 (254 addresses).</p>
                    <p>The 192.168.81.x could be anything, but has at least 4 octets (x.x.x.x).</p>
                    {/*<p>For example, 10.0.1.x or 172.99.1.x is valid too. So fill in the IP address, subnet (255.255.255.0), default gateway (the ip address of the server) and preferred DNS server (also the ip address of the server).</p>*/}
                    <p>Click on "OK" and "Close".</p>
                </div>,
                imageSrc: Images.NICPropertiesIPV4Properties
            },
        ]
    },
    {
        title: `Computer/Server name`,
        pages: [
            {
                text: <p>Open up the explorer again. Press "windows key" + "E", right click on "Computer" and click on "Properties".</p>,
                imageSrc: Images.ExplorerComputerProperties
            },
            {
                text: <p>Click on "Change settings" (on the right of the system dialog).</p>,
                imageSrc: Images.SystemChangeSettings
            },
            {
                text: <p>Click on "Change..." to proceed.</p>,
                imageSrc: Images.SystemProperties
            },
            {
                text     : <p>Change the computer name to a desired name and click on "OK". For this guide i'll use "TutorialServer1".</p>,
                imageSrc : Images.ComputerNameChanged
            },
            {
                text     : <p>After changing the computer name Windows Server informs you that the system needs to reboot to apply the changes.</p>,
                imageSrc : Images.ChangedComputerNameMessage
            },
            {
                text     : <p>Open up the explorer again. Press "windows key" + "E", right click on "Computer" and click on "Properties".</p>,
                imageSrc : Images.ChangedComputerNameMessage
            },
            {
                text     : <p>Click on "Restart now" to proceed.</p>,
                imageSrc : Images.RestartNow
            },
        ]
    },
    {
        title: `Server services`,
        pages: [
            {
                text: <div>
                    <p>Now windows server is rebooted, login the server with the administrator account.</p>
                    <p>It's possible that some services didn't start, if you want to start these services click on "Services" (in the "All Servers").</p>
                </div>,
                imageSrc: Images.ServerServiceNotStarted
            },
            {
                text: <p>Select all services which are stopped. Right click and click on "Start Services", this might take a few seconds.</p>,
                imageSrc: Images.ForceStartService
            },
            {
                text: <p>Congratulations, you've just installed Windows Server 2012! &#127881;</p>,
            },
        ]
    },
];

const links: string[] = [];
const lengthPages: number = (pages.map(
    (p) => {
        const modulo = p.pages.length % maxPageChunks;
        return p.pages.length + (modulo !== 0 ? maxPageChunks - (p.pages.length % maxPageChunks) : 0);
    }
).reduce((a,b)=>a+b) / (maxPageChunks * 2)) + 1;
for (let i=0;i<lengthPages;i++) {
    links.push(`${link}/${i}`);
}

const page: pageType = {
    name: `Windows Server 2012`,
    link: [
        link,
        ...links,
    ],
    page: <Book
        logo={Images.Logo}
        preTitle={`Windows Server 2012`}
        title={`Installation`}
        link={link}
        links={links}
        pages={pages}
    />,
    props: {
        menu: true
    }
};

export default page;