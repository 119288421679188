import React        from "react";
import { Chart as ReactChart } from "react-charts";
import * as utils from '../../utils';

export type chartType = {
    label: string;
    data: {
        primary: string | number | Date;
        secondary: string | number | Date;
    }[]
};

const Chart = (
    {
        type,
        data,
    } : {
        type: `bar` | `line`,
        data: chartType[];
    }
) => <div
    style={
        {
            width: `100%`,
            height: `20rem`,
        }
    }
>
    <ReactChart
        options={
            {
                data: data,
                dark: utils.storage.features.get(`theme`) === `dark`,
                padding: {
                    top   : 0,
                    left  : 5,
                    right : 5,
                    bottom: 30,
                },
                primaryAxis: {
                    elementType: type,
                    getValue: (obj) => obj.primary
                },
                secondaryAxes: [
                    {
                        elementType: type,
                        getValue: (obj) => obj.secondary
                    }
                ],
            }
        }
    />
</div>;

export default Chart;