import React from 'react';
import Book, {maxPageChunks} from "../../../../../../../component/bookshelf/book";
import {pagesType, pageType} from "../../../../../../../pages";

const link  = `/guides/windows/client/11/domain/configure`;
let   pages: pagesType = [
    {
        title: `Youtube video`,
        pages: [
            {
                text: <p>In this guide we are going to add this client to the domain.</p>,
                youtubeIdFull: `hD2lxzDTJJ8`
            },
        ]
    },
    {
        title: `Join Domain`,
        pages: [
        ]
    },
    {
        title: `Verify joined domain`,
        pages: [
        ]
    },
];

const links: string[] = [];
const lengthPages: number = (pages.map(
    (p) => {
        const modulo = p.pages.length % maxPageChunks;
        return p.pages.length + (modulo !== 0 ? maxPageChunks - (p.pages.length % maxPageChunks) : 0);
    }
).reduce((a,b)=>a+b) / (maxPageChunks * 2)) +1;
for (let i=0;i<=lengthPages;i++) {
    links.push(`${link}/${i}`);
}

const page: pageType = {
    name: `Windows 11`,
    link: [
        link,
        ...links,
    ],
    page: <Book
        preTitle={`Windows 11`}
        title={`Domain`}
        subTitle={`Configure`}
        link={link}
        links={links}
        pages={pages}
    />,
    props: {
        enabled: false,
        menu: true
    }
};

export default page;