import React          from 'react'
import styled         from 'styled-components';
import MuiImageLogin  from '@mui/icons-material/Login';
import MuiImageLogout from '@mui/icons-material/Logout';
import { Navigate }   from "react-router-dom";
import { ws }         from "../../utils";
import {Login as LoginPage} from "../../page/account";

const StyledAccountContainer = styled.div`
    position: fixed;
    top: 3rem;
    right: 0;
    display: flex;
    flex-direction: column;
    margin: 0;
    padding: .5rem;
    border-top-left-radius: .2rem;
    border-bottom-left-radius: .2rem;
    background-color: var(--color-background-box-overlay);

    & >*:not(:first-child) {
        margin: .5rem 0 0 0;
    }
    & > * {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        align-items: center;
        justify-items: center;
    }
`;

const StyledAccountOption = styled.div`
    overflow: hidden;
    cursor: pointer;
`;

const StyledAccountAvatar = styled.img`
    border-radius: 50%;
    min-width: 1.5rem;
    max-width: 1.5rem;
    min-height: 1.5rem;
    max-height: 1.5rem;
`;

interface Props {
    login: boolean;
    functions: { [name: string]: (...parameter: any) => void };
    [key:string]: any
}
interface State {
    account?: boolean;
    redirect?: string
}

class Account extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            account: false,
            redirect: undefined
        };

        this.setRedirect = this.setRedirect.bind(this);
    }

    componentDidMount() {
        ws.listener.message(
            [`account.logout`],
            data => data.code === 200 && this.setRedirect(`/login`)
        );
    }

    setRedirect(page: string) {
        // CAREFUL! Can perform a maximum stack if the state isn't cleared right after it has been set.! Aka infinite loop!
        this.setState({redirect: page}, () => this.setState({redirect: undefined}));
    }

    render() {
        if (LoginPage.props?.enabled === false) {
            return undefined;
        }

        return <StyledAccountContainer>
            {this.state.redirect !== undefined && <Navigate replace to={this.state.redirect}/>}
            {
                this.props.login
                && <StyledAccountOption
                    onClick={() => this.setRedirect(`/account`)}
                >
                    <StyledAccountAvatar alt={``} src={`https://cdnb.artstation.com/p/assets/images/images/009/836/467/large/maria-bo-schatzis-stream-profilpicture.jpg?1521139318`}/>
                </StyledAccountOption>
            }
            {
                this.props.login
                && <StyledAccountOption
                    title={`Logout`}
                    onClick={() => ws.sent(`account`, `logout`, {})}
                >
                    <MuiImageLogout/>
                </StyledAccountOption>
            }
            {
                !this.props.login
                && <StyledAccountOption
                    title={`Login`}
                    onClick={() => this.setRedirect(`/login`)}
                >
                    <MuiImageLogin/>
                </StyledAccountOption>
            }
        </StyledAccountContainer>;
    }
}

export default Account;