import React from 'react';
import styled from 'styled-components';
import Input from './input';

const StyledForm = styled.div`
    display: grid;
    gap: .25rem;
`;

const Form = (
    {
        children
    }: {
        children: React.ReactNode
    }
) => <StyledForm>{children}</StyledForm>;

export {
    Form,
    Input,
};