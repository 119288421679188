import React from "react";
import styled from 'styled-components';
import {pageType} from "../../pages";
import * as utils from "../../utils";
import Body from "../../component/body";
import {
    StyledChalkBoard,
    StyledContainer,
    StyledFormula,
    StyledSelect,
    StyledTable,
    StyledTableTd
} from "./base";

const StyledBox = styled.div`
    position: relative;
    display: flex;
    border: .2rem solid rgba(255, 255, 255, .7);
    width: 10rem;
    height: 10rem;
    margin: 0 0 0 2rem;
`;

const StyledBoxValue = styled.div`
    position: absolute;
    display: flex;
    flex-direction: column;
`;
const StyledBoxValueWidth  = styled(StyledBoxValue)`
    top: -2rem;
    left: -.2rem;
    right: -.2rem;
    align-items: center;
    border-left: .1rem solid rgba(255, 255, 255, .7);
    border-right: .1rem solid rgba(255, 255, 255, .7);
`;
const StyledBoxValueHeight = styled(StyledBoxValue)`
    top: -.2rem;
    left: -1.8rem;
    bottom: -.2rem;
    width: 1.25rem;
    align-items: center;
    flex-grow: 1;
    justify-content: center;
    align-items: center;
    border-top: .1rem solid rgba(255, 255, 255, .7);
    border-bottom: .1rem solid rgba(255, 255, 255, .7);
    
    & > div {
        transform: rotate(-90deg);
        white-space: nowrap;
    }
`;

interface Props {}
interface State {
    parameters    : string[];
    widthValue    : number;
    widthUnit     : string;
    widthResult?  : string;
    heightValue   : number;
    heightUnit    : string;
    heightResult? : string;
    to            : string;
    result        : string | undefined;
    units         : {[unitName: string]: string};
}

const url = `/converter/length-to-content`;
class Page extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        const allowableUnits = {
            'mm' : `millimeter`,
            'cm' : `centimeter`,
            'dm' : `decimeter`,
            'm'  : `meter`,
            'dam': `decameter`,
            'hm' : `hectometer`,
            'km' : `kilometer`,
            'Mm' : `Megameter`,
            'Gm' : `Gigameter`,
            'Tm' : `Terameter`,
        };

        const allowableUnitKeys   = Object.keys(allowableUnits);
        const allowableUnitValues = Object.values(allowableUnits).map((v)=>v.toLowerCase());

        let   urlParameters = window.location.pathname.replace(`${url}`, ``).split(`/`).filter((v)=>v.length > 0);
        const searches      = window.location.search.substring(1);
        const searchParameters: {
            [name: string]: string | number;
            widthUnit: string;
            widthValue: number;
            heightUnit: string;
            heightValue: number;
            to: string;
            value: number;
        } = {
            widthUnit   : `mm`,
            widthValue  : 0,
            heightUnit  : `mm`,
            heightValue : 0,
            to          : `mm`,
            value       : 1,
        };

        searches.split(`&`).forEach(
            (search) => {
                const param = search.split(`=`);
                if (param.length > 0) {
                    const name  = param[0] || ``;
                    if (searchParameters.hasOwnProperty(name)) {
                        let value: string | number = param[1] || ``;
                        if (
                            name === `value`
                            || (
                                [`width`, `height`, `to`].includes(name)
                                && (
                                    allowableUnitKeys.includes(value)
                                    || allowableUnitValues.includes(value.toLowerCase())
                                )
                            )
                        ) {
                            if (name === `value`) {
                                value = !isNaN(Number(value))
                                    ? Number(value)
                                    : 1;
                            }

                            if (value !== ``) {
                                searchParameters[name] = value;
                            }
                        }
                    }
                }
            }
        );

        if (urlParameters.length > 0) {
            const searchParameterKeys = Object.keys(searchParameters);
            urlParameters
                .slice(0, searchParameterKeys.length)
                .forEach(
                    (urlParameter, index) => {
                        const position = allowableUnitValues.indexOf(urlParameter.toLowerCase());

                        searchParameters[searchParameterKeys[index]] = position !== -1
                            ? allowableUnitKeys[position]
                            : urlParameter;
                    }
                );
        }

        const calculate = this.calculate(
            {
                widthValue : searchParameters.widthValue,
                widthUnit  : searchParameters.widthUnit,
                heightValue: searchParameters.heightValue,
                heightUnit : searchParameters.heightUnit,
                to         : searchParameters.to,
                units      : allowableUnitKeys,
            }
        );

        this.state = {
            parameters  : urlParameters,
            widthValue  : calculate.widthValue,
            widthUnit   : calculate.widthUnit,
            heightValue : calculate.heightValue,
            heightUnit  : calculate.heightUnit,
            to          : searchParameters.to,
            result      : calculate.result.toString(),
            units       : allowableUnits,
        };
    }

    componentDidMount() {
        utils.setUrl(`${url}/${this.state.widthUnit}/${this.state.widthValue}/${this.state.heightUnit}/${this.state.heightValue}/${this.state.to}`);
    }

    calculate(
        {
            widthValue,
            widthUnit,
            heightValue,
            heightUnit,
            to,
            units = []
        } : {
            widthValue?: number;
            widthUnit?: string;
            heightValue?: number;
            heightUnit?: string;
            to?: string;
            value?: number;
            units?: string[]
        }
    ) {
        if (widthValue === undefined) {
            widthValue = this.state.widthValue;
        }
        if (widthUnit === undefined) {
            widthUnit = this.state.widthUnit;
        }
        if (heightValue === undefined) {
            heightValue = this.state.heightValue;
        }
        if (heightUnit === undefined) {
            heightUnit = this.state.heightUnit;
        }
        if (to === undefined) {
            to = this.state.to;
        }

        const widthResult = utils.calculation.calculate(units, 10, widthUnit, to, widthValue).result.toString();
        const heightResult = utils.calculation.calculate(units, 10, heightUnit, to, heightValue).result.toString();

        console.log(`widthResult`, widthResult, `heightResult`, heightResult);

        return {
            widthValue : Number(widthResult),
            widthUnit  : widthUnit,
            heightValue: Number(heightResult),
            heightUnit : heightUnit,
            to         : to,
            result     : ``,
        };
    }

    changeResult(
        {
            widthValue,
            widthUnit,
            heightValue,
            heightUnit,
            to,
            units = []
        } : {
            widthValue?: number;
            widthUnit?: string;
            heightValue?: number;
            heightUnit?: string;
            to?: string;
            value?: number;
            units?: string[]
        }
    ) {
        const calculate = this.calculate(
            {
                widthValue : widthValue,
                widthUnit  : widthUnit,
                heightValue: heightValue,
                heightUnit : heightUnit,
                to         : to,
                units      : units
            }
        );

        if (
            calculate.widthValue === this.state.widthValue
            && calculate.heightValue === this.state.heightValue
            && calculate.to === this.state.to
        ) {
            return; // why recalculate?
        }

        this.setState(
            {
                widthValue : calculate.widthValue,
                widthUnit  : calculate.widthUnit,
                heightValue: calculate.heightValue,
                heightUnit : calculate.heightUnit,
                to         : calculate.to,
                result     : calculate.result,
            },
            () => utils.setUrl(`${url}/${this.state.widthUnit}/${this.state.widthValue}/${this.state.heightUnit}/${this.state.heightValue}/${this.state.to}`)
        );
        // ToDo: need to tweak this
    }

    render() {
        return <Body>
            <h1>Length to content</h1>
            <StyledContainer>
                <StyledTable>
                    <tbody>
                    <tr>
                        <StyledTableTd><strong>Width</strong></StyledTableTd>
                        <td>
                            <input
                                type={`number`}
                                placeholder={`value`}
                                defaultValue={this.state.widthValue}
                                onChange={(e) => this.changeResult({units: Object.keys(this.state.units), widthValue: (e.currentTarget as HTMLInputElement).valueAsNumber})}
                                min={-999999999999999}
                                max={999999999999999}
                            />
                        </td>
                        <td>
                            <StyledSelect
                                value={this.state.widthUnit}
                                onChange={(e) => this.changeResult({units: Object.keys(this.state.units), widthUnit: (e.currentTarget as HTMLSelectElement).value})}
                            >
                                {
                                    this.state.units
                                    && Object.keys(this.state.units).filter((v)=>v!==`--empty--`).map(
                                        (unit, index) => <option
                                            key={index}
                                            value={unit}
                                        >
                                            {this.state.units[unit]} ({unit})
                                        </option>
                                    )
                                }
                            </StyledSelect>
                        </td>
                    </tr>
                    <tr>
                        <td colSpan={3}><hr/></td>
                    </tr>
                    <tr>
                        <StyledTableTd><strong>Height</strong></StyledTableTd>
                        <td>
                            <input
                                type={`number`}
                                placeholder={`value`}
                                defaultValue={this.state.heightValue}
                                onChange={(e) => this.changeResult({units: Object.keys(this.state.units), heightValue: (e.currentTarget as HTMLInputElement).valueAsNumber})}
                                min={-999999999999999}
                                max={999999999999999}
                            />
                        </td>
                        <td>
                            <StyledSelect
                                value={this.state.heightUnit}
                                onChange={(e) => this.changeResult({units: Object.keys(this.state.units), heightUnit: (e.currentTarget as HTMLSelectElement).value})}
                            >
                                {
                                    this.state.units
                                    && Object.keys(this.state.units).filter((v)=>v!==`--empty--`).map(
                                        (unit, index) => <option
                                            key={index}
                                            value={unit}
                                        >
                                            {this.state.units[unit]} ({unit})
                                        </option>
                                    )
                                }
                            </StyledSelect>
                        </td>
                    </tr>
                    <tr>
                        <td colSpan={4}><hr/></td>
                    </tr>
                    <tr>
                        <td>to</td>
                        <td colSpan={2}>
                            <StyledSelect
                                value={this.state.to}
                                onChange={(e) => this.changeResult({units: Object.keys(this.state.units), to: (e.currentTarget as HTMLSelectElement).value})}
                            >
                                {
                                    this.state.units
                                    && Object.keys(this.state.units).filter((v)=>v!==`--empty--`).map(
                                        (unit, index) => <option
                                            key={index}
                                            value={unit}
                                        >
                                            {this.state.units[unit]} ({unit})
                                        </option>
                                    )
                                }
                            </StyledSelect>
                        </td>
                    </tr>
                    <tr>
                        <td colSpan={3}><hr/></td>
                    </tr>
                    <tr>
                        <td>Result</td>
                        <td>{this.state.result !== undefined ? `${this.state.result} ${this.state.to}` : `???`}</td>
                    </tr>
                    </tbody>
                </StyledTable>
                <StyledChalkBoard>
                    <pre>
                        <div>Short formula:</div>
                        <StyledFormula>
                        </StyledFormula>
                        <br/>
                        <br/>
                        <br/>
                        <div>Formula:</div>
                        <StyledFormula>
                        </StyledFormula>
                        <br/>
                        <br/>
                        <br/>
                        <StyledBox>
                            <StyledBoxValueWidth>width: {this.state.widthValue} {this.state.to}</StyledBoxValueWidth>
                            <StyledBoxValueHeight><div>height: {this.state.heightValue}  {this.state.to}</div></StyledBoxValueHeight>
                        </StyledBox>
                    </pre>
                </StyledChalkBoard>
            </StyledContainer>
            <div>
                Need another converter? Click <a href={`/converter`}>here</a> to check the other converters.
            </div>
        </Body>;
    }
}

const page: pageType = {
    name: `Length to content`,
    link: [url, `${url}/*`],
    page: <Page/>,
    props: {
        menu: false,
        enabled: false
    },
};

export default page;