import React  from 'react';
import styled from 'styled-components';

const StyledIframe = styled.iframe`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    outline: none;
    border: none;
    width: calc(100% - 1.5rem);
    height: 100%;
`;

interface Props {
    [key: string]: any;
}

class Iframe extends React.Component<Props> {
    render() {
        return <StyledIframe {...this.props} />;
    }
}

export default Iframe;