const Binary = {
    binaryToString: (binary: string): string => binary.replace(/.{1,8}/g, (char: string) => String.fromCharCode(Number(`0b${char}`))),
    stringToBinary: (string: string): string => {
        return string.split(``).map(
            char => {
                char = char.charCodeAt(0).toString(2);
                const diff = 8 - char.length;
                if (diff > 0) {
                    for(let i = 0; i < diff; i++) {
                        char = `0${char}`;
                    }
                }

                return char;
            }
        ).join(``);
    },
};

export default Binary;