import React from "react";
import {pageType} from "../../pages";
import Base from "./base";

const url = `/converter/distance-length`;
const page: pageType = {
    name: `Distance / Length`,
    link: [url, `${url}/*`],
    page: <Base
        title={`Distance / Length`}
        url={url}
        steps={10}
        defaultValues={
            {
                from  : `mm`,
                to    : `mm`,
                value : 1,
            }
        }
        allowableUnits={
            {
                'mm' : `millimeter`,
                'cm' : `centimeter`,
                'dm' : `decimeter`,
                'm'  : `meter`,
                'dam': `decameter`,
                'hm' : `hectometer`,
                'km' : `kilometer`,
                'Mm' : `Megameter`,
                'Gm' : `Gigameter`,
                'Tm' : `Terameter`,
            }
        }
    />,
    props: {
        menu: false
    },
};

export default page;