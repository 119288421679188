import React                               from 'react';
import Book, {maxPageChunks} from "../../../../../../../../component/bookshelf/book";
import {pagesType, pageType} from "../../../../../../../../pages";
import * as Images from "../../../../../../../../asset/guide/windows/server/2012";

const link  = `/guides/windows/server/2012/raf/dns/reverse-lookup-zone`;
let   pages: pagesType = [
    {
        title: `Youtube video`,
        pages: [
            {
                text: <p>In this roles and features guide we are going to configure the role "Domain Name System" (DNS) Reverse lookup zone.</p>,
                youtubeIdFull: `-ufrtVpFNrc`
            },
        ]
    },
    {
        title: `Reverse lookup zone: New Zone`,
        pages: [
            {
                text: <p>Click on "Tools" and on "DNS".</p>,
                imageSrc: Images.RolesAndFeatures.dns.ServerManagerDNS
            },
            {
                text: <p>Right click on "Reverse Lookup Zone" and on "New Zone...".</p>,
                imageSrc: Images.RolesAndFeatures.dns.DNSReverseLookupZoneNewZone
            },
            {
                text: <p>Click on "Next &gt;".</p>,
                imageSrc: Images.RolesAndFeatures.dns.NewZoneWizard1
            },
            {
                text: <div>
                    <p>Leave this to the default selection.</p>
                    <p>Default: Select "Primary zone" and check the checkbox "Store the zone in Active Directory (available only if DNS server is a writeable domain controller)".</p>
                    <p>Click on "Next &gt;".</p>
                </div>,
                imageSrc: Images.RolesAndFeatures.dns.NewZoneWizard2
            },
            {
                text: <p>Select "To all domain controllers in this domain (for Windows 2000 compatibility): agers.nl" (the domain at the end could be different for your domain). Click on "Next &gt;" to proceed.</p>,
                imageSrc: Images.RolesAndFeatures.dns.NewZoneWizard3
            },
            {
                text: <p>Select "IPv4 Reverse Lookup zone" and click on "Next &gt;".</p>,
                imageSrc: Images.RolesAndFeatures.dns.NewZoneWizard4
            },
            {
                text: <div>
                    <p>Select "Network ID:" and fill in the IP address scope. In this guide i've used the IP address 192.168.81.1 for the first server.</p>
                    <p>Therefore the IP address scope i need to use is the first 3 octets "192.168.81".</p>
                    <p>Click on "Next &gt;".</p>
                </div>,
                imageSrc: Images.RolesAndFeatures.dns.NewZoneWizard5
            },
            {
                text: <p>Select "Allow only secure dynamic updates (recommended for Active Directory)". Click on "Next &gt;".</p>,
                imageSrc: Images.RolesAndFeatures.dns.NewZoneWizard6
            },
            {
                text: <p>Click on "Finish" to close the wizard.</p>,
                imageSrc: Images.RolesAndFeatures.dns.NewZoneWizard7
            }
        ]
    },
    {
        title: `Associated pointer (PTR)`,
        pages: [
            {
                text: <div>
                    <p>Click on "Forward Lookup Zones" and on "agers.nl" (the domain).</p>
                    <p>Right click on the first server "TutorialServer1" and click on "Properties".</p>
                </div>,
                imageSrc: Images.RolesAndFeatures.dns.ForwardLookupServerProperties
            },
            {
                text: <p>Check "Update associated pointer (PTR) record" and click on "Ok".</p>,
                imageSrc: Images.RolesAndFeatures.dns.PTR
            },
            {
                text: <p>Right click on the second server "TutorialServer2" and click on "Properties".</p>,
                imageSrc: Images.RolesAndFeatures.dns.ForwardLookupServer2
            },
            {
                text: <p>Check "Update associated pointer (PTR) record" and click on "Ok".</p>,
                imageSrc: Images.RolesAndFeatures.dns.Server2PTR
            },
            {
                text: <div>
                    <p>Click on "Reverse Lookup Zones" and on "81.168.192.in-addr-arp" (or a different IP address scope in your case).</p>
                    <p>Right click on "81.168.192.in-addr-arp" and click on "Refresh".</p>
                </div>,
                imageSrc: Images.RolesAndFeatures.dns.ReverseLookupZoneRefresh
            },
            {
                text: <p>See that both servers are visible in the Reverse lookup zone.</p>,
                imageSrc: Images.RolesAndFeatures.dns.ReverseLookupZoneServers
            },
            {
                text: <p>Congratulations, you've just configured a new reverse lookup zone! &#127881;</p>,
            }
        ]
    },
];

const links: string[] = [];
const lengthPages: number = (pages.map(
    (p) => {
        const modulo = p.pages.length % maxPageChunks;
        return p.pages.length + (modulo !== 0 ? maxPageChunks - (p.pages.length % maxPageChunks) : 0);
    }
).reduce((a,b)=>a+b) / (maxPageChunks * 2)) + 1;
for (let i=0;i<=lengthPages;i++) {
    links.push(`${link}/${i}`);
}

const page: pageType = {
    name: `Windows Server 2012`,
    link: [
        link,
        ...links,
    ],
    page: <Book
        logo={Images.Logo}
        preTitle={`Windows Server 2012`}
        title={`Roles and Features`}
        subTitle={`DNS: Reverse lookup zone`}
        link={link}
        links={links}
        pages={pages}
    />,
    props: {
        menu: true
    }
};

export default page;