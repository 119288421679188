import React from 'react';
import Book, {maxPageChunks} from "../../../../../../../component/bookshelf/book";
import {pagesType, pageType} from "../../../../../../../pages";
import * as Images from '../../../../../../../asset/guide/windows/client/xp';

const link  = `/guides/windows/client/xp/installation`;
let   pages: pagesType = [
    {
        title: `Youtube video`,
        pages: [
            {
                text: <p>In this guide we are going to install a windows XP (client).</p>,
                youtubeIdFull: `a6gP9EBKgE0`
            },
        ]
    },
    {
        title: `Installation Windows XP`,
        pages: [
            {
                text: <p>Press "Enter" to continue.</p>,
                imageSrc: Images.PreSetup
            },
            {
                text: <p>Press "F8" to agree with the license.</p>,
                imageSrc: Images.License
            },
            {
                text: <p>Press "Enter" to install on the "Unpartitioned space".</p>,
                imageSrc: Images.Partition
            },
            {
                text: <p>Leave the selection on "Format the partition using the NTFS file system" and press on "Enter".</p>,
                imageSrc: Images.DiskFormat
            },
            {
                text: <p>Click on "Next &gt;".</p>,
                imageSrc: Images.RegionalLanguage
            },
            {
                text: <p>Fill in "Admin" and click on "Next &gt;".</p>,
                imageSrc: Images.AccountName
            },
            {
                text: <p>Fill in the product key and click on "Next &gt;".</p>,
                imageSrc: Images.ProductKey
            },
            {
                text: <div>
                    <p>Fill in a computer name, in this guide i'll use "TutorialClient1".</p>
                    <p>Also fill in a good administrator password.</p>
                </div>,
                imageSrc: Images.ComputerName
            },
            {
                text: <p>Select your TimeZone and click on "Next &gt;".</p>,
                imageSrc: Images.DateTime
            }
        ]
    },
    {
        title: `VMware tools`,
        pages: [
            {
                text: <p>Log in.</p>,
                imageSrc: Images.SignIn
            },
            {
                text: <p>Right click on the virtual machine and click on "Install VMware Tools...".</p>,
                imageSrc: Images.VMWareToolsInstall
            },
            {
                text: <p>Click on "Next &gt;".</p>,
                imageSrc: Images.VMWareToolsInstall1
            },
            {
                text: <p>Leave the selection on "Typical" and click on "Next &gt;".</p>,
                imageSrc: Images.VMWareToolsInstall2
            },
            {
                text: <p>Click on "Install".</p>,
                imageSrc: Images.VMWareToolsInstall3
            },
            {
                text: <div>
                    <p>Click on "Ok".</p>
                    <p>It's possible that you may or may not get this message. If you do, it's not bad.</p>
                </div>,
                imageSrc: Images.VMWareToolsInstall4
            },
            {
                text: <p>Click on "Finish" to close the setup.</p>,
                imageSrc: Images.VMWareToolsInstall5
            },
            {
                text: <p>Click on "Yes" to reboot the virtual machine.</p>,
                imageSrc: Images.VMWareToolsInstall6
            }
        ]
    },
    {
        title: `Change resolution`,
        pages: [
            {
                text: <p>Log in.</p>,
                imageSrc: Images.SignIn
            },
            {
                text: <p>Right click on the background and click on "Properties".</p>,
                imageSrc: Images.Properties
            },
            {
                text: <p>Click on the tab "Settings" and change the resolution to the desired resolution and click on "Ok".</p>,
                imageSrc: Images.ScreenResolution
            },
            {
                text: <p>Click on "Yes" to leave the applied settings and click on "Ok".</p>,
                imageSrc: Images.ScreenResolutionApply
            }
        ]
    },
    {
        title: `Verify computer name`,
        pages: [
            {
                text: <p>Click on the start menu, right click on "My Computer" and click on "Properties".</p>,
                imageSrc: Images.ComputerProperties
            },
            {
                text: <p>Click on the tab "Computer Name" and see that this computer has the computer name which we give in the setup/install earlier.</p>,
                imageSrc: Images.ComputerNameCheck
            },
            {
                text: <p>Congratulations, you've just installed Windows XP! &#127881;</p>,
            }
        ]
    },
];

const links: string[] = [];
const lengthPages: number = (pages.map(
    (p) => {
        const modulo = p.pages.length % maxPageChunks;
        return p.pages.length + (modulo !== 0 ? maxPageChunks - (p.pages.length % maxPageChunks) : 0);
    }
).reduce((a,b)=>a+b) / (maxPageChunks * 2)) +1;
for (let i=0;i<=lengthPages;i++) {
    links.push(`${link}/${i}`);
}

const page: pageType = {
    name: `Windows XP`,
    link: [
        link,
        ...links,
    ],
    page: <Book
        logo={Images.Logo}
        preTitle={`Windows XP`}
        title={`Installation`}
        link={link}
        links={links}
        pages={pages}
    />,
    props: {
        menu: true
    }
};

export default page;