const calculation = {
    calculate: (units: string[], steps: number = 1, from: string, to: string, value: number) => {
        const positionFrom = units.indexOf(from);
        const positionTo   = units.indexOf(to);
        const calculationResults: {result: string | number, operator: string, steps: number, formula: number[]} = {
            result   : value,
            operator : ``,
            steps    : steps,
            formula  : []
        };
        if (positionFrom !== positionTo) {
            const isMultiply = positionFrom > positionTo;
            const diff       = isMultiply
                ? positionFrom - positionTo
                : positionTo - positionFrom;
            let newValue: string | number = value;

            if (diff > 0) {
                calculationResults.formula = [...new Array(diff)].map(() => steps);
                const amount = calculationResults.formula.reduce((a,b)=>a*b);
                newValue = isMultiply
                    ? value * amount
                    : value / amount;
            }

            if (newValue.toString().includes(`e-`)) {
                newValue = newValue.toFixed(diff);
            }

            calculationResults.operator = isMultiply ? `x` : `/`;
            calculationResults.result = newValue;
        }

        return calculationResults;
    },
};

export default calculation;