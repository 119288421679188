import React from 'react';
import styled from 'styled-components';
import MuiImageNote from '@mui/icons-material/Notes';

const StyledContainer = styled.div`
    position: relative;
    display: flex;
    flex-grow: 1;
`;
const StyledContainerColumn = styled(StyledContainer)`
    flex-direction: column;
`;
const StyledContainerRow = styled(StyledContainer)`
    flex-direction: row;
`;
const StyledLabel = styled.label`
    display: flex;
    padding: 1rem 1.25rem;
    border-radius: .25rem;
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    background-color: var(--color-background-label);
    
    &.success {
        color: var(--color-text-success-darker);
    }
`;
const StyledImageNote = styled(MuiImageNote)`
    display: flex;
    flex-direction: column;
    align-self: baseline;
    max-width: 1.5rem;
    max-height: 1.5rem;
    color: var(--color-text-invert);
`;

const StyledTextarea = styled.textarea`
    position: relative;
    display: flex;
    flex-grow: 1;
    border-radius: .25rem;
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
    background-image: none;
    border: 0;
    color: inherit;
    font: inherit;
    margin: 0;
    outline: 0;
    padding: 1rem 1.25rem;
    transition: background-color .3s;
    color: var(--color-text);
    background-color: var(--color-background-input);
    
    &.success {
        color: var(--color-text-success);
        background-color: var(--color-background-input-success);
    }
    &.no-resize {
        resize: none;
    }
    &:is(:hover, :focus) {
        background-color: var(--color-background-input-hover);
    }
    &.success:is(:hover, :focus) {
        background-color: var(--color-background-input-success-hover);
    }
    &:disabled {
        cursor: not-allowed;
        color: var(--color-text-error);
        background-color: var(--color-background-input-danger);
    }
`;

const StyledLengthCharacters = styled.div`
    position: absolute;
    right: 0;
    bottom: 0;
    padding: .25rem .5rem;
    transition: all .2s ease-in-out;
    
    &.scrollbar {
        right: 1.1rem;
    }
    
    &.success {
        color: var(--color-text-success);
        background-color: var(--color-background-input-success);
    }
`;


interface Props {
    placeholder: string;
    resizable?: boolean;
    minWidth?: string;
    minHeight?: string;
    maxWidth?: string;
    maxHeight?: string;
    width?: string;
    height?: string;
    showLength?: boolean;
    currentLength?: number;
    maxLength?: number;
    success?: boolean;
    [key: string]: any;
}

interface State {
    inputRef: React.RefObject<HTMLTextAreaElement>;
}

class Textarea extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            inputRef: React.createRef<HTMLTextAreaElement>(),
        };
    }

    getFocus() {
        this.state.inputRef.current?.focus();
    }

    render() {
        return <StyledContainerColumn>
            <StyledContainerColumn>
                <StyledContainerRow>
                    <StyledLabel
                        title={this.props.placeholder}
                        className={this.props.success ? `success` : ``}
                    >
                        <StyledImageNote/>
                    </StyledLabel>
                    <StyledTextarea
                        ref={this.state.inputRef}
                        className={
                            [
                                this.props.resizable ? `` : `no-resize`,
                                this.props.success ? `success` : ``
                            ].join(` `)
                        }
                        style={
                            {
                                width     : this.props.width,
                                minWidth  : this.props.minWidth,
                                maxWidth  : this.props.maxWidth,
                                height    : this.props.height,
                                minHeight : this.props.minHeight,
                                maxHeight : this.props.maxHeight,
                            }
                        }
                        {...this.props}
                    />
                    {
                        this.props.showLength
                        && this.props.currentLength !== undefined
                        && this.props.maxLength
                        && <StyledLengthCharacters
                            className={
                                [
                                    this.state.inputRef.current !== null && this.state.inputRef.current.scrollHeight > this.state.inputRef.current.clientHeight ? `scrollbar` : ``,
                                    this.props.success ? `success` : ``
                                ].join(` `)
                            }
                        >
                            {this.props.currentLength}/{this.props.maxLength}
                        </StyledLengthCharacters>
                    }
                </StyledContainerRow>
            </StyledContainerColumn>
        </StyledContainerColumn>;
    }
}

export default Textarea;