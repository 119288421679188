import React            from 'react';
import styled           from 'styled-components';
import * as Utils       from '../../utils';

const StyledContainer = styled.div`
    position: relative;
    display: flex;
    flex-grow: 1;
`;
const StyledContainerColumn = styled(StyledContainer)`
    flex-direction: column;
`;
const StyledContainerRow = styled(StyledContainer)`
    flex-direction: row;
`;
const StyledContainerColMax = styled(StyledContainerColumn)`
    max-height: 3.5rem;
`;

const StyledInput = styled.input`
    display: flex;
    flex-grow: 1;
    border-radius: .25rem;
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
    background-image: none;
    border: 0;
    color: inherit;
    font: inherit;
    margin: 0 1rem 0 0;
    outline: 0;
    padding: 1rem 4rem 1rem 1.25rem;
    transition: background-color .3s;
    color: var(--color-text);
    background-color: var(--color-background-input);
    
    &.success {
        color: var(--color-text-success);
        background-color: var(--color-background-input-success);
    }
    &.error {
        color: var(--color-text-error);
        background-color: var(--color-background-input-danger);
    }
    &:hover {
        color: var(--color-text-invert-hover);
    }
    &:is(:hover, :focus) {
        background-color: var(--color-background-input-hover);
    }
    &.success:is(:hover, :focus) {
        background-color: var(--color-background-input-success-hover);
    }
    &.error:is(:hover, :focus) {
        background-color: var(--color-background-input-danger-hover);
    }
    &:disabled {
        cursor: not-allowed;
        color: var(--color-text-error);
        background-color: var(--color-background-input-danger);
    }
`;

interface Props {
    context: string;
    title?: string;
    [key: string]: any;
}

interface State {
    checkBoxRef: React.RefObject<HTMLInputElement>;
    uniqueId: string;
}

class Checkbox extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            checkBoxRef: React.createRef<HTMLInputElement>(),
            uniqueId: Utils.uid(),
        };
    }

    click() {
        this.state.checkBoxRef.current?.click();
    }

    render() {
        return <StyledContainerColMax>
            <StyledContainerColumn>
                <StyledContainerRow>
                    <StyledInput
                        ref={this.state.checkBoxRef}
                        type={`checkbox`}
                        id={this.state.uniqueId}
                        {...this.props}
                    />
                    <label htmlFor={this.state.uniqueId}>{this.props.context}</label>
                </StyledContainerRow>
            </StyledContainerColumn>
        </StyledContainerColMax>;
    }
}

export default Checkbox;