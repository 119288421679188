import React   from 'react';
import styled from 'styled-components';
import {
    BrowserRouter as Router,
    Routes,
    Route,
    Navigate,
}                 from 'react-router-dom';
import Account    from './component/menu/account';
import Menu       from './menu';
import pages      from "./pages";
import storage    from "./utils/storage";
import { ws }     from "./utils";
import NotFound   from "./page/error/not_found";
import * as utils from './utils';
import preval     from 'preval.macro';
import Features   from "./component/menu/feature";

const StyledExtraInfo = styled.div`
    position: fixed;
    right: 1rem;
    bottom: 1rem;
    display: flex;
    flex-direction: column;
    opacity: .3;
`;

const StyledRelease = styled.div`
    font-size: .6rem;
`;

const StyledClock = styled.div`
    display: flex;
    flex-direction: column;
    align-items: end;
`;

interface Props {}
interface State {
    login: boolean;
    clock: string;
    functions: { [name: string]: (...parameter: any) => void };
}

const lastReleaseDate: Date = preval`module.exports = new Date();`;
class Body extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            login: false,
            clock: utils.formattedDate(),
            functions: {
                setLogin: (state: boolean): void => this.state.login !== state
                    ? this.setState(
                        {login: state},
                        () => storage.account.set.key(`login`, state ? `true` : `false`)
                    )
                    : undefined,
            }
        };
    }

    componentDidMount() {
        setInterval(
            () => this.setState(
                {
                    clock: utils.formattedDate()
                }
            ),
            1000
        );
        // ws.connect();

        ws.listener.message(
            [`account.verify`],
            data => {
                if (
                    data.code === 200
                     && typeof data.results === `object`
                    && data.results.verified
                ) {
                    this.setState({login : true});
                } else {
                    storage.account.remove.keys(
                        `username`,
                        `avatar`,
                        `token`,
                        `validTo`,
                    );
                }
            }
        );

        ws.listener.message(
            [`account.logout`],
            data => data.code === 200 && this.setState({login : false}, () => storage.account.remove.keys(`username`, `avatar`, `token`, `validTo`))
        );

        const token = storage.account.get(`token`);
        if (typeof token === `string`) {
            ws.sent(
                `account`,
                `verify`,
                {
                    token: token
                },
            );
        }
    }

    render() {
        return <Router>
            <Menu
                pages={pages}
                login={this.state.login}
            />
            <Account
                {...this.state}
            />
            <Features.Feedback />
            <Routes>
                {
                    [true, false].includes(this.state.login)
                    && pages.map(
                        pageInfo => pageInfo.link.map(
                            (link, index: number) => {
                                if (
                                    pageInfo.hasOwnProperty(`props`)
                                    && pageInfo.props !== undefined
                                ) {
                                    switch (true) {
                                        case pageInfo.props.enabled === false:
                                            return undefined;
                                        case pageInfo.props.login !== undefined && pageInfo.props.login !== this.state.login:
                                            return pageInfo.props.redirect !== undefined
                                                ? <Route
                                                    key={index}
                                                    path={link}
                                                    element={<Navigate replace to={pageInfo.props.redirect} />}
                                                />
                                                : undefined;
                                        default:
                                            break;
                                    }
                                }

                                return <Route
                                    key={index}
                                    path={link}
                                    element={pageInfo.page}
                                />
                            }
                        )
                    )
                }
                <Route path={`*`} element={<NotFound/>}/>
            </Routes>
            <StyledExtraInfo>
                <StyledClock>{this.state.clock}</StyledClock>
                {
                    utils.isDevelopment()
                    && <StyledRelease>Last release: {utils.formattedDate(new Date(lastReleaseDate))}</StyledRelease>
                }
            </StyledExtraInfo>
        </Router>;
    }
}

export default Body;