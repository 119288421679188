import React                               from 'react';
import Book, {maxPageChunks} from "../../../../../../../component/bookshelf/book";
import {pagesType, pageType} from "../../../../../../../pages";
import * as Images from "../../../../../../../asset/guide/windows/server/2012";

const link  = `/guides/windows/server/2012/disk/quotas`;
let   pages: pagesType = [
    {
        title: `Youtube video`,
        pages: [
            {
                text: <p>In this guide we are going to configure a disk quota.</p>,
                youtubeIdFull: `hw0o1PSZBzg`
            },
        ]
    },
    {
        title: `Configure quota's`,
        pages: [
            {
                text: <p>Open up the explorer. Press "windows key" + "E".</p>,
                imageSrc: Images.Explorer
            },
            {
                text: <p>Right click on a volume and click on "Properties".</p>,
                imageSrc: Images.Disk.Properties
            },

            {
                text: <div>
                    <p>Click on tab "Quota".</p>
                    <ul>
                        <li>Check "Enable quota" management</li>
                        <li>Select "Limit disk space to" and fill in 100 MB</li>
                        <li>Set warning level to: 90 MB</li>
                    </ul>
                    <p>Click on "Quota Entries...".</p>
                </div>,
                imageSrc: Images.Disk.Quotas
            },
            {
                text: <p>at this point there should be only 1 entry, cause we didn't apply the change. Close this window.</p>,
                imageSrc: Images.Disk.QuotaEntries
            },
            {
                text: <p>Apply the changes</p>,
                imageSrc: Images.Disk.QuotasApply
            },
            {
                text: <p>Click on "Ok".</p>,
                imageSrc: Images.Disk.RescanDisk
            },
            {
                text: <p>See that the traffic lights went to green. Click on "Quota entries...".</p>,
                imageSrc: Images.Disk.QuotaEntriesOption
            },
            {
                text: <p>See that there are 2 entries.</p>,
                imageSrc: Images.Disk.QuotaEntriesForDiskE
            },
            {
                text: <p>Congratulations, you've just configured disk quota's! &#127881;</p>,
            }
        ]
    },
];

const links: string[] = [];
const lengthPages: number = (pages.map(
    (p) => {
        const modulo = p.pages.length % maxPageChunks;
        return p.pages.length + (modulo !== 0 ? maxPageChunks - (p.pages.length % maxPageChunks) : 0);
    }
).reduce((a,b)=>a+b) / (maxPageChunks * 2)) +1;
for (let i=0;i<=lengthPages;i++) {
    links.push(`${link}/${i}`);
}

const page: pageType = {
    name: `Windows Server 2012`,
    link: [
        link,
        ...links,
    ],
    page: <Book
        logo={Images.Logo}
        preTitle={`Windows Server 2012`}
        title={`Disk`}
        subTitle={`Quota's`}
        link={link}
        links={links}
        pages={pages}
    />,
    props: {
        menu: true
    }
};

export default page;