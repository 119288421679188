import XP from './xp';
import Y7 from './7';
import Y8 from './8';
import Y10 from './10';
import Y11 from './11';

const Client = [
    ...XP,
    ...Y7,
    ...Y8,
    ...Y10,
    ...Y11,
];

export default Client;