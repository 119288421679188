import React from 'react';
import Book, {maxPageChunks} from "../../../../../../../component/bookshelf/book";
import {pagesType, pageType} from "../../../../../../../pages";
import * as Images from '../../../../../../../asset/guide/windows/client/8';

const link  = `/guides/windows/client/8/installation`;
let   pages: pagesType = [
    {
        title: `Youtube video`,
        pages: [
            {
                text: <p>In this guide we are going to install a windows 8 (client).</p>,
                youtubeIdFull: `H3xzFUvnGhk`
            },
        ]
    },
    {
        title: `Installation Windows 8`,
        pages: [
            {
                text: <div>
                    <p>Select the desired language, time and currency format and Keyboard or input method.</p>
                    <p>For this guide i'll use the default English and US international keyboard.</p>
                </div>,
                imageSrc: Images.Language
            },
            {
                text: <p>Click on "Install now".</p>,
                imageSrc: Images.InstallNow
            },
            {
                text: <p>Fill in the product key and click on "Next".</p>,
                imageSrc: Images.ProductKey
            },
            {
                text: <p>Read the license terms, check the "I accept the license terms" and click "Next" to proceed.</p>,
                imageSrc: Images.License
            },
            {
                text: <p>Choose the "Custom (advanced)" option.</p>,
                imageSrc: Images.CustomAdvanced
            },
            {
                text: <p>Now we need to allocate space to install the server on. Click on "Drive options (advanced)".</p>,
                imageSrc: Images.DriverOptions
            },
            {
                text: <p>Click on "New".</p>,
                imageSrc: Images.NewPartition
            },
            {
                text: <div>
                    <p>Leave the size as it is and click "Apply".</p>
                    <p>The disk size may vary on your computer/virtual machine.</p>
                </div>,
                imageSrc: Images.Apply
            },
            {
                text: <p>Click "Ok" to proceed.</p>,
                imageSrc: Images.WindowsAdditionalPartition
            },
            {
                text: <p>Click "Next".</p>,
                imageSrc: Images.DiskPartitions
            },
            {
                text: <p>Fill in "TutorialClient3" as the computer name, pick a color you like and click on "Next".</p>,
                imageSrc: Images.Personalize
            },
            {
                text: <p>Click on "Customize".</p>,
                imageSrc: Images.SettingsCustomize
            },
            {
                text: <p>Click on "Yes, turn on sharing and connect to devices" (this might can take a few minutes if there is no internet connection).</p>,
                imageSrc: Images.SettingsNetworkSharing
            },
            {
                text: <div>
                    <p>Toggle the following off:</p>
                    <ul>
                        <li>Turn on Windows SmartScreen Filter to check files and apps with Microsoft</li>
                        <li>Turn on Internet Explorer SmartScreen Filter to check URLs and downloads with Microsoft</li>
                        <li>Send a Do Not Track request to websites you visit in Internet Explorer</li>
                    </ul>
                    <p>And click on "Next &gt;".</p>
                </div>,
                imageSrc: Images.SettingsUpdates
            },
            {
                text: <div>
                    <p>Toggle the following off:</p>
                    <ul>
                        <li>Help improve Windows Store by sending URLs for web content that apps use</li>
                        <li>Help Microsoft respond to malicious apps and malware by joining Microsoft Active Protection Service</li>
                        <li>Help improvement Microsoft services by sending some location data when location-aware apps are used</li>
                        <li>Participate in the Customer Experience Improvement Program to help improve Microsoft software and services, (A file will periodically download to collect better info.)</li>
                        <li>Help improve Windows Help content by sending info to the Help Experience Improvement Program</li>
                    </ul>
                    <p>And click on "Next &gt;".</p>
                </div>,
                imageSrc: Images.SettingsSendInfo
            },
            {
                text: <div>
                    <p>Toggle the following off:</p>
                    <ul>
                        <li>Use Windows Error Reporting to check for solutions to problems</li>
                        <li>Use Internet Explorer Compatibility lists to help improvement my experience on some sites</li>
                        <li>Let apps use my name and account picture</li>
                        <li>Turn on Windows Location Platform so apps can ask users for their location</li>
                    </ul>
                    <p>And click on "Next &gt;".</p>
                </div>,
                imageSrc: Images.SettingsShareInfo
            },
            {
                text: <p>Click on "Sign in without a Microsoft account" to create a local account.</p>,
                imageSrc: Images.CreateLocalAccount
            },
            {
                text: <p>Click on "Local account".</p>,
                imageSrc: Images.LocalAccount
            },
            {
                text: <div>
                    <p>Fill in:</p>
                    <ul>
                        <li>User name</li>
                        <li>Password</li>
                        <li>Reenter password</li>
                        <li>Password hint</li>
                    </ul>
                    <p>And click on "Finish".</p>
                </div>,
                imageSrc: Images.AccountCredentialsRegister
            }
        ]
    },
    {
        title: `VMware tools`,
        pages: [
            {
                text: <p>Click on "Desktop".</p>,
                imageSrc: Images.Desktop
            },
            {
                text: <p>Right click on the virtual machine and click on "Install VMware Tools...".</p>,
                imageSrc: Images.VMWareToolsInstall
            },
            {
                text: <div>
                    <p>Open a explorer.</p>
                    <p>Tip: use the shortcut "windows key" + "E".</p>
                </div>,
                imageSrc: Images.Explorer
            },
            {
                text: <p>Click on "VMware Tools" on the DVD/CD drive.</p>,
                imageSrc: Images.DVD
            },
            {
                text: <p>Click on "Yes".</p>,
                imageSrc: Images.UAC
            },
            {
                text: <p>Click on "Next &gt;".</p>,
                imageSrc: Images.VMWareSetup1
            },
            {
                text: <p>Leave the setup type on "Typical" and click on "Next &gt;" to proceed.</p>,
                imageSrc: Images.VMWareSetup2
            },
            {
                text: <p>Click on "Install" and wait until the setup is done.</p>,
                imageSrc: Images.VMWareSetup2
            },
            {
                text: <p>Click on "Finish" to close the setup.</p>,
                imageSrc: Images.VMWareSetup4
            },
            {
                text: <p>Click on "Yes" if the screen didn't adjust to the VMware client.</p>,
                imageSrc: Images.Reboot
            }
        ]
    },
    {
        title: `Verify computer name`,
        pages: [
            {
                text: <p>Log in.</p>,
                imageSrc: Images.SignIn
            },
            {
                text: <p>Click on "Desktop".</p>,
                imageSrc: Images.Desktop
            },
            {
                text: <div>
                    <p>Open a explorer.</p>
                    <p>Tip: use the shortcut "windows key" + "E".</p>
                </div>,
                imageSrc: Images.Explorer
            },
            {
                text: <p>Right click on "Computer" and click on "Properties".</p>,
                imageSrc: Images.ComputerProperties
            },
            {
                text: <p>Click on "Change settings".</p>,
                imageSrc: Images.SystemChangeSettings
            },
            {
                text: <p>Click on "Change...".</p>,
                imageSrc: Images.SystemProperties
            },
            {
                text: <p>See that this computer has the computer name which we give in the setup/install earlier.</p>,
                imageSrc: Images.ComputerName
            },
            {
                text: <p>Congratulations, you've just installed Windows 8! &#127881;</p>,
            }
        ]
    },
];

const links: string[] = [];
const lengthPages: number = (pages.map(
    (p) => {
        const modulo = p.pages.length % maxPageChunks;
        return p.pages.length + (modulo !== 0 ? maxPageChunks - (p.pages.length % maxPageChunks) : 0);
    }
).reduce((a,b)=>a+b) / (maxPageChunks * 2)) +1;
for (let i=0;i<=lengthPages;i++) {
    links.push(`${link}/${i}`);
}

const page: pageType = {
    name: `Windows 8`,
    link: [
        link,
        ...links,
    ],
    page: <Book
        logo={Images.Logo}
        preTitle={`Windows 8`}
        title={`Installation`}
        link={link}
        links={links}
        pages={pages}
    />,
    props: {
        menu: true
    }
};

export default page;