import { Binary } from "../crypto";

type listenerType = (
    actions  : string[],
    callback : callbackType
) => void;
export type sessionDataType = {
    type: string;
    action: string;
    code: number;
    messages: string[];
    results: { [key:string]: any };
};
type callbackType = (data: sessionDataType) => void;
export type onType = {
    [actionType: string]: callbackType[]
};
export type wsInfoType = {
    url: string;
    socket: undefined | WebSocket,
    retries: object,
    onopen: onType,
    onmessage: onType,
    onclose: onType,
    onerror: onType,
    onFunction: (
        infoKey: `onopen` | `onmessage` | `onclose` | `onerror`,
        sessionData: { [key: string]: any }
    ) => void,
    encrypt: (data: string) => string;
};
export type wsType = {
    status: {
        isConnected  : () => boolean;
        isClosed     : () => boolean;
        isConnecting : () => boolean;
        isClosing    : () => boolean;
    },
    listener: {
        open    : listenerType,
        message : listenerType,
        close   : listenerType,
        error   : listenerType,
        set     : () => void,
    },
    connect: (url?: string) => boolean;
    sent: (
        type   : string,
        action : string,
        data   : object,
    ) => boolean;
};

const encrypted = false;
const fixUrl = (url: string): string => {
    if (url !== undefined) {
        if (
            !url.includes(`ws://`)
            || !url.includes(`wss://`)
        ) {
            url = `${window.location.protocol === `http:` ? `ws` : `wss`}://${url}`;
        }
    }

    return url;
};
const hasKeys  = (...keys: string[]): boolean => wsInfo.socket !== undefined && Object.keys(Object.getPrototypeOf(wsInfo.socket)).filter(k => keys.includes(k)).length > 0;
const hasState = (state: `OPEN` | `CONNECTING` | `CLOSING` | `CLOSED`): boolean => wsInfo.socket !== undefined && hasKeys(`readyState`, state) && wsInfo.socket.readyState === wsInfo.socket[state];
const listenerFunction = (
    onType: `onopen` | `onmessage` | `onclose` | `onerror`,
    actions: string[],
    callback: callbackType
): void => {
    actions.forEach(
        action => {
            if (!wsInfo[onType].hasOwnProperty(action)) {
                wsInfo[onType][action] = [];
            }

            wsInfo[onType][action].push(callback);
        }
    );
};
const setListenerFunctions = (
    ...onTypes: (`onopen` | `onmessage` | `onclose` | `onerror`)[]
): void => onTypes.forEach(
    onType => {
        if (wsInfo.socket !== undefined) {
            wsInfo.socket[onType] = (sessionData: object) => wsInfo.onFunction(onType, sessionData);
        }
    }
);

const wsUrl = (url: string): void => {
    url = fixUrl(url);

    if (url !== wsInfo.url) {
        wsInfo.url = url;
    }
};

const wsInfo: wsInfoType = {
    url        : `wss://websocket.agers.nl`,
    socket     : undefined,
    retries    : {},
    onopen     : {},
    onmessage  : {},
    onclose    : {},
    onerror    : {},
    onFunction : (
        infoKey: `onopen` | `onmessage` | `onclose` | `onerror`,
        sessionData: {[key: string]: any},
    ): void => {
        if (
            Object.getPrototypeOf(sessionData) === null
            || !Object.keys(Object.getPrototypeOf(sessionData)).includes(`data`)
            || ![`onopen`, `onmessage`, `onclose`, `onerror`].includes(infoKey)
        ) {
            return;
        }

        let data = sessionData.data;
        try {
            if (encrypted) {
                data = Binary.binaryToString(data);
            }
            data = JSON.parse(data);
        } catch (exception) {
            data = {};
        }

        if (
            data.type !== undefined
            && data.action
        ) {
            data.action = `${data.type}.${data.action}`;
        }

        const infoObject = wsInfo[infoKey];
        if (infoObject === undefined) {
            return;
        }

        const infoKeys = Object.keys(infoObject);
        infoKeys.forEach(
            actionType => {
                const socketFunctions = infoObject[actionType];

                data.hasOwnProperty(`action`)
                && data.action === actionType
                && Array.isArray(socketFunctions)
                && socketFunctions.forEach(
                    socketFunction => typeof socketFunction === `function` && socketFunction(data)
                );
            }
        );
    },
    encrypt: (data: string): string => encrypted ? Binary.stringToBinary(data) : data,
};

export {
    wsInfo,
    hasState,
    listenerFunction,
    setListenerFunctions,
    wsUrl,
};