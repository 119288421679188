import React      from "react";
import * as utils from "../utils";

interface Props {
}

interface State {
    ref: React.RefObject<HTMLDivElement>;
}

class ReCAPTCHA extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            ref : React.createRef<HTMLDivElement>(),
        };
    }

    componentDidMount() {
        this.renderCaptcha();
    }

    renderCaptcha() {
        // @ts-ignore
        if (window.grecaptcha !== undefined) {
            // @ts-ignore
            window.grecaptcha.ready(
                // @ts-ignore
                () => window.grecaptcha.render(
                    this.state.ref.current as HTMLDivElement,
                    {
                        sitekey  : `6LetvfUiAAAAAD7wNKu88HFrWsMMYmI4VbXhALMx`,
                        callback : (token: string | null) => token !== null
                            && utils.ws.sent(
                                `verify`,
                                `recaptcha`,
                                {
                                    response : token
                                },
                            ),
                        theme    : utils.storage.features.get(`theme`) === `dark` ? `dark` : `light`
                    }
                )
            );
        } else {
            setTimeout(
                () => this.renderCaptcha(),
                1000
            );
        }
    }

    render() {
        return <div ref={this.state.ref} />;
    }
}

export default ReCAPTCHA;