import Add from './add';
import Volumes from './volumes';
import Quotas from './quotas';
import PreviousVersions from './previous_versions';

const Disk = [
    Add,
    Volumes,
    Quotas,
    PreviousVersions,
];

export default Disk;