import Binary from './binary';
import DateTime from '../datetime';
import {random} from "../index";

const Crypto = {
    createHash: async (string?: string) => {
        if (string === undefined) {
            string = `undefined`;
        }

        const datetime = DateTime.formattedDate(undefined, `Y-m-d H:i:s`);
        string = `${datetime}-${string}`;

        const hash = window.location.protocol === `http:`
            ? (Array.from(Array(32))).map(()=>parseInt(`${random(-127,127)}`))
            : await crypto
                .subtle
                .digest(
                    `SHA-256`,
                    (new TextEncoder()).encode(string)
                );

        return Array
            .from(new Uint8Array(hash))
            .map(b=>b.toString(16).padStart(2, `0`))
            .join(``);
    },
};

export default Crypto;
export {
    Crypto,
    Binary,
};