import Installation from "./installation";
import RolesAndFeatures from "./roles_and_features";
import Disk from "./disk";

const Server2012 = [
    Installation,
    ...RolesAndFeatures,
    ...Disk
];

export default Server2012;