import React from 'react';
import Book, {maxPageChunks} from "../../../../../../../component/bookshelf/book";
import {pagesType, pageType} from "../../../../../../../pages";
import * as Images from '../../../../../../../asset/guide/windows/client/10';

const link  = `/guides/windows/client/10/installation`;
let   pages: pagesType = [
    {
        title: `Youtube video`,
        pages: [
            {
                text: <p>In this guide we are going to install a windows 10 (client).</p>,
                youtubeIdFull: `shLlUkWkybQ`
            },
        ]
    },
    {
        title: `Installation Windows 10`,
        pages: [
            {
                text: <div>
                    <p>Select the desired language, time and currency format and Keyboard or input method.</p>
                    <p>For this guide i'll use the default English and US international keyboard.</p>
                </div>,
                imageSrc: Images.Language
            },
            {
                text: <p>Click on "Install now".</p>,
                imageSrc: Images.InstallNow
            },
            {
                text: <p>Fill in the product key and click on "Next".</p>,
                imageSrc: Images.ProductKey
            },
            {
                text: <p>Read the license terms, check the "I accept the license terms" and click "Next" to proceed.</p>,
                imageSrc: Images.License
            },
            {
                text: <p>Choose the "Custom (advanced)" option.</p>,
                imageSrc: Images.CustomAdvanced
            },
            {
                text: <p>Now we need to allocate space to install the server on. Click on "Drive options (advanced)".</p>,
                imageSrc: Images.DriverOptions
            },
            {
                text: <p>Click on "New".</p>,
                imageSrc: Images.New
            },
            {
                text: <div>
                    <p>Leave the size as it is and click "Apply".</p>
                    <p>The disk size may vary on your computer/virtual machine.</p>
                </div>,
                imageSrc: Images.Apply
            },
            {
                text: <p>Click "Ok" to proceed.</p>,
                imageSrc: Images.WindowsAdditionalPartitions
            },
            {
                text: <p>Click "Next".</p>,
                imageSrc: Images.DiskPartitions
            },
            {
                text: <p>Wait for cortana to finish.</p>,
                imageSrc: Images.CortanaIntro
            },
            {
                text: <p>Select your region.</p>,
                imageSrc: Images.Region
            },
            {
                text: <p>Select your keyboard layout</p>,
                imageSrc: Images.Keyboard
            },
            {
                text: <p>Click on "Skip".</p>,
                imageSrc: Images.SecondKeyboard
            },
            {
                text: <p>Click on "I don't have internet".</p>,
                imageSrc: Images.NetworkConnection
            },
            {
                text: <p>Click on "Continue with limited setup".</p>,
                imageSrc: Images.LimitedSetup
            },
            {
                text: <p>Fill in a username. In this guide i'll use "Admin". Click on "Next".</p>,
                imageSrc: Images.Username
            },
            {
                text: <p>Fill in a strong password and click "Next".</p>,
                imageSrc: Images.AccountPassword
            },
            {
                text: <p>Reenter the same password and click "Next".</p>,
                imageSrc: Images.AccountConfirmPassword
            },
            {
                text: <p>Select a security question 1 and fill in your answer. Click on "Next" to proceed.</p>,
                imageSrc: Images.SecurityQuestions1
            },
            {
                text: <p>Select a security question 2 and fill in your answer. Click on "Next" to proceed.</p>,
                imageSrc: Images.SecurityQuestions2
            },
            {
                text: <p>Select a security question 3 and fill in your answer. Click on "Next" to proceed.</p>,
                imageSrc: Images.SecurityQuestions3
            },
            {
                text: <p>Click on "No".</p>,
                imageSrc: Images.ActivityHistory
            },
            {
                text: <p>Click on "Decline".</p>,
                imageSrc: Images.DigitalAssistant
            },
            {
                text: <p>Toggle all options off.</p>,
                imageSrc: Images.PrivacySettings1
            },
            {
                text: <p>Toggle all options off.</p>,
                imageSrc: Images.PrivacySettings2
            },
            {
                text: <p>Wait until Cortana is done.</p>,
                imageSrc: Images.CortanaAlmostDone
            }
        ]
    },
    {
        title: `VMware tools`,
        pages: [
            {
                text: <p>Right click on the virtual machine and click on "Install VMware Tools...".</p>,
                imageSrc: Images.VMWareToolsInstall
            },
            {
                text: <div>
                    <p>Open a explorer.</p>
                    <p>Tip: use the shortcut "windows key" + "E".</p>
                </div>,
                imageSrc: Images.Explorer
            },
            {
                text: <p>Click on "VMware Tools" on the DVD/CD drive.</p>,
                imageSrc: Images.DVD
            },
            {
                text: <p>Click on "Yes".</p>,
                imageSrc: Images.UAC
            },
            {
                text: <p>Click on "Next &gt;".</p>,
                imageSrc: Images.VMWareSetup1
            },
            {
                text: <p>Leave the setup type on "Typical" and click on "Next &gt;" to proceed.</p>,
                imageSrc: Images.VMWareSetup2
            },
            {
                text: <p>Click on "Install" and wait until the setup is done.</p>,
                imageSrc: Images.VMWareSetup3
            },
            {
                text: <p>Click on "Finish" to close the setup.</p>,
                imageSrc: Images.VMWareSetup4
            },
            {
                text: <p>Click on "Yes" if the screen didn't adjust to the VMware client.</p>,
                imageSrc: Images.Reboot
            }
        ]
    },
    {
        title: `Change computer name`,
        pages: [
            {
                text: <div>
                    <p>Open a explorer.</p>
                    <p>Tip: use the shortcut "windows key" + "E".</p>
                </div>,
                imageSrc: Images.Explorer
            },
            {
                text: <p>Right click on "Computer" and click on "Properties".</p>,
                imageSrc: Images.ComputerProperties
            },
            {
                text: <p>Click on "Change settings".</p>,
                imageSrc: Images.SystemChangeSettingsComputerName
            },
            {
                text: <p>Click on "Change...".</p>,
                imageSrc: Images.SystemProperties
            },
            {
                text: <p>Give the OS (Operating System) another name. In this guide i'll use "TutorialClient4" and click on "Ok".</p>,
                imageSrc: Images.ComputerName
            },
            {
                text: <p>Click on "Ok".</p>,
                imageSrc: Images.RestartToTakeEffect
            },
            {
                text: <p>Click on "Close".</p>,
                imageSrc: Images.SystemPropertiesClose
            },
            {
                text: <p>Click on "Restart now".</p>,
                imageSrc: Images.RebootNow
            }
        ]
    },
    {
        title: `Verify computer name`,
        pages: [
            {
                text: <p>Sign in.</p>,
                imageSrc: Images.SignIn
            },
            {
                text: <div>
                    <p>Open a explorer.</p>
                    <p>Tip: use the shortcut "windows key" + "E".</p>
                </div>,
                imageSrc: Images.Explorer
            },
            {
                text: <p>Right click on "Computer" and click on "Properties".</p>,
                imageSrc: Images.ComputerProperties
            },
            {
                text: <p>Click on "Change settings".</p>,
                imageSrc: Images.SystemChangeSettings
            },
            {
                text: <p>Click on "Change...".</p>,
                imageSrc: Images.SystemProperties
            },
            {
                text: <p>See that this computer has the computer name which we give earlier in the guide.</p>,
                imageSrc: Images.ComputerName
            },
            {
                text: <p>Congratulations, you've just installed Windows 10! &#127881;</p>,
            }
        ]
    },
];

const links: string[] = [];
const lengthPages: number = (pages.map(
    (p) => {
        const modulo = p.pages.length % maxPageChunks;
        return p.pages.length + (modulo !== 0 ? maxPageChunks - (p.pages.length % maxPageChunks) : 0);
    }
).reduce((a,b)=>a+b) / (maxPageChunks * 2)) +1;
for (let i=0;i<=lengthPages;i++) {
    links.push(`${link}/${i}`);
}

const page: pageType = {
    name: `Windows 10`,
    link: [
        link,
        ...links,
    ],
    page: <Book
        logo={Images.Logo}
        preTitle={`Windows 10`}
        title={`Installation`}
        link={link}
        links={links}
        pages={pages}
    />,
    props: {
        menu: true
    }
};

export default page;