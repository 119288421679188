import React from 'react';
import styled from 'styled-components';
import {hourType, dayType, monthType, yearType, dataType} from '../overview';

const StyledHorizontalLine = styled.hr`
    margin: 0;
`;

const StyledTable = styled.table`
    width: 100%;
`;
export const StyledTableData = styled.td`
    &.green {
        color: lightgreen;
    }
    &.red {
        color: lightcoral;
    }
`;

const StyledTableDataSingleLine = styled(StyledTableData)`
    display: flex;
    flex-direction: row;
`;
const StyledTableDataBrackets = styled(StyledTableData)`
    &::before {
        content: '(';
        color: var(--color-text);
    }
    &::after {
        content: ')';
        color: var(--color-text);
    }
`;
const StyledTableDataClickable = styled(StyledTableData)`
    cursor: pointer;
`;
const StyledBranched = styled.div`
    &::before {
        content: 'L';
        display: block;
        font-size: x-small;
        margin: .125rem .25rem 0 .5rem;
        text-align: right;
    }
    &.hide::before {
        color: transparent;
    }
`;
const StyledTableDataTextAlignRight = styled(StyledTableData)`
    text-align: right;
`;

export const ValueColor = (value: number, reverse: boolean = false) => reverse
    ? value > 0 ? `green` : (value < 0 ? `red` : ``)
    : value < 0 ? `green` : (value > 0 ? `red` : ``);

const PriceTableData = (
    {
        price,
        decimal,
        reverse = false
    } : {
        price: number;
        decimal: number;
        reverse?: boolean;
    }
) => <>
    <td
        key={`euroSign`}
    >
        {PRICE}
    </td>
    <StyledTableDataTextAlignRight
        key={`priceValue`}
        title={`€ ${price}`}
        className={ValueColor(price, reverse)}
    >
        {price.toFixed(decimal)}
    </StyledTableDataTextAlignRight>
</>;

const ExpandedInfo = (
    {
        prefix,
        value,
        price,
        decimals,
        unitMeasure,
        isTotal = false
    } : {
        prefix: string;
        value?: number;
        price?: number;
        decimals?: {
            price: number;
            units: number;
        };
        unitMeasure?: string;
        isTotal?: boolean;
    }
) => {
    if (unitMeasure !== undefined && `${unitMeasure}`.length > 0) {
        unitMeasure = ` ${unitMeasure}`;
    }

    return <>
        {
            isTotal
            && <tr>
                <td colSpan={4}><StyledHorizontalLine/></td>
            </tr>
        }
        <tr>
            <StyledTableDataSingleLine>
                <StyledBranched className={isTotal ? `hide` : ``}/>
                <span>{prefix}</span>
            </StyledTableDataSingleLine>
            {
                value !== undefined
                    ? <StyledTableDataBrackets
                        title={`${value}${unitMeasure}`}
                        className={ValueColor(value || 0)}
                    >
                        {(value).toFixed(decimals?.units || 0)}{unitMeasure}
                    </StyledTableDataBrackets>
                    : <td/>
            }
            <PriceTableData
                price={price || 0}
                decimal={decimals?.price || 0}
            />
            <td/>
        </tr>
    </>;
};

interface Props {
    data: yearType | monthType | dayType | hourType | dataType,
    yearPayment?: number,
    decimals: {
        price: number;
        units: number;
    };
    subjectWithUnits?: boolean;
}
interface State {
    electricity: {
        usedExpand: boolean,
        returnExpand: boolean,
    },
    gasExpand: boolean,
    fixedExpand: boolean,
}

const PRICE = `€`;
const KWH   = `kWh`;
const M3    = `m³`;

class TablePrices extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            electricity: {
                usedExpand: false,
                returnExpand: false,
            },
            gasExpand: false,
            fixedExpand: false,
        };
    }

    render() {
        return <div>
            <StyledTable>
                <tbody>
                <tr>
                    <StyledTableDataClickable
                        onClick={
                            () => this.setState({electricity:{...this.state.electricity, usedExpand: !this.state.electricity.usedExpand}})
                        }
                    >
                        Usage electricity:
                    </StyledTableDataClickable>
                    {
                        !this.state.electricity.usedExpand && this.props.subjectWithUnits
                            ? <StyledTableDataBrackets
                                className={
                                    this.props.subjectWithUnits
                                        ? ValueColor(Number(this.props.data.totalPrices?.electricPrices.value.usedTotal || 0))
                                        : ``
                                }
                            >
                                {
                                    this.props.subjectWithUnits
                                        ? `${Number(this.props.data.totalPrices?.electricPrices.value.usedTotal || 0).toFixed(this.props.decimals.price)} ${KWH}`
                                        : ``
                                }
                            </StyledTableDataBrackets>
                            : <td/>
                    }
                    <td/>
                    <PriceTableData
                        price={this.props.data.totalPrices?.electricPrices.price.usedTotal.inclusive || 0}
                        decimal={this.props.decimals.price}
                    />
                </tr>
                {
                    this.state.electricity.usedExpand
                    && <>
                        <ExpandedInfo
                            prefix={`OffPeak`}
                            value={this.props.data.totalPrices?.electricPrices.value.offPeak || 0}
                            price={this.props.data.totalPrices?.electricPrices.price.offPeak.inclusive || 0}
                            decimals={this.props.decimals}
                            unitMeasure={KWH}
                        />
                        <ExpandedInfo
                            prefix={`Peak`}
                            value={this.props.data.totalPrices?.electricPrices.value.peak || 0}
                            price={this.props.data.totalPrices?.electricPrices.price.peak.inclusive || 0}
                            decimals={this.props.decimals}
                            unitMeasure={KWH}
                        />
                        <ExpandedInfo
                            prefix={`Total`}
                            value={this.props.data.totalPrices?.electricPrices.value.usedTotal || 0}
                            price={this.props.data.totalPrices?.electricPrices.price.usedTotal.inclusive || 0}
                            decimals={this.props.decimals}
                            unitMeasure={KWH}
                            isTotal={true}
                        />
                    </>
                }
                <tr>
                    <StyledTableDataClickable
                        onClick={
                            () => this.setState({gasExpand: !this.state.gasExpand})
                        }
                    >
                        Usage gas:
                    </StyledTableDataClickable>
                    {
                        !this.state.gasExpand && this.props.subjectWithUnits
                            ? <StyledTableDataBrackets
                                className={
                                    this.props.subjectWithUnits
                                        ? ValueColor(Number(this.props.data.totalPrices?.gasPrices.value.gas || 0))
                                        : ``
                                }
                            >
                                {
                                    this.props.subjectWithUnits
                                        ? `${Number(this.props.data.totalPrices?.gasPrices.value.gas || 0).toFixed(this.props.decimals.price)} ${M3}`
                                        : ``
                                }
                            </StyledTableDataBrackets>
                            : <td/>
                    }
                    <td/>
                    <PriceTableData
                        price={this.props.data.totalPrices?.gasPrices.price.gas.inclusive || 0}
                        decimal={this.props.decimals.price}
                    />
                </tr>
                {
                    this.state.gasExpand
                    && <ExpandedInfo
                        prefix={`Gas`}
                        value={this.props.data.totalPrices?.gasPrices.value.gas || 0}
                        price={this.props.data.totalPrices?.gasPrices.price.gas.inclusive || 0}
                        decimals={this.props.decimals}
                        unitMeasure={M3}
                    />
                }
                <tr>
                    <StyledTableDataClickable
                        onClick={
                            () => this.setState({fixedExpand: !this.state.fixedExpand})
                        }
                    >
                        Fixed:
                    </StyledTableDataClickable>
                    <td colSpan={2}/>
                    <PriceTableData
                        price={this.props.data.totalPrices?.totalFixed.inclusive || 0}
                        decimal={this.props.decimals.price}
                    />
                </tr>
                {
                    this.state.fixedExpand
                    && <>
                        <ExpandedInfo
                            prefix={`Delivery`}
                            price={this.props.data.totalPrices?.fixedPrices.delivery.exclusive || 0}
                            decimals={this.props.decimals}
                        />
                        <ExpandedInfo
                            prefix={`GridManagement`}
                            price={this.props.data.totalPrices?.fixedPrices.gridManagement.exclusive || 0}
                            decimals={this.props.decimals}
                        />
                        <ExpandedInfo
                            prefix={`Reduction`}
                            price={this.props.data.totalPrices?.fixedPrices.reduction.exclusive || 0}
                            decimals={this.props.decimals}
                        />
                        <ExpandedInfo
                            prefix={`Vat`}
                            price={this.props.data.totalPrices?.fixedPrices.vat.exclusive || 0}
                            decimals={this.props.decimals}
                        />
                        <ExpandedInfo
                            prefix={`Total`}
                            price={this.props.data.totalPrices?.fixedPrices.total.exclusive || 0}
                            decimals={this.props.decimals}
                            isTotal={true}
                        />
                    </>
                }
                <tr>
                    <StyledTableDataClickable
                        onClick={
                            () => this.setState({electricity:{...this.state.electricity, returnExpand: !this.state.electricity.returnExpand}})
                        }
                    >
                        Return electricity:
                    </StyledTableDataClickable>
                    {
                        !this.state.electricity.returnExpand && this.props.subjectWithUnits
                            ? <StyledTableDataBrackets
                                    className={
                                        this.props.subjectWithUnits
                                            ? ValueColor(Number(this.props.data.totalPrices?.electricPrices.value.returnTotal || 0))
                                            : ``
                                    }
                                >
                                    {
                                        this.props.subjectWithUnits
                                            ? `${Number(this.props.data.totalPrices?.electricPrices.value.returnTotal || 0).toFixed(this.props.decimals.price)} ${KWH}`
                                            : ``
                                    }
                                </StyledTableDataBrackets>
                            : <td/>
                    }
                    <td/>
                    <PriceTableData
                        price={this.props.data.totalPrices?.electricPrices.price.returnTotal.inclusive || 0}
                        decimal={this.props.decimals.price}
                    />
                </tr>
                {
                    this.state.electricity.returnExpand
                    && <>
                        <ExpandedInfo
                            prefix={`OffPeak`}
                            value={this.props.data.totalPrices?.electricPrices.value.returnOffPeak || 0}
                            price={this.props.data.totalPrices?.electricPrices.price.returnOffPeak.inclusive || 0}
                            decimals={this.props.decimals}
                            unitMeasure={KWH}
                        />
                        <ExpandedInfo
                            prefix={`Peak`}
                            value={this.props.data.totalPrices?.electricPrices.value.returnPeak || 0}
                            price={this.props.data.totalPrices?.electricPrices.price.returnPeak.inclusive || 0}
                            decimals={this.props.decimals}
                            unitMeasure={KWH}
                        />
                        <ExpandedInfo
                            prefix={`Total`}
                            value={this.props.data.totalPrices?.electricPrices.value.returnTotal || 0}
                            price={this.props.data.totalPrices?.electricPrices.price.returnTotal.inclusive || 0}
                            decimals={this.props.decimals}
                            unitMeasure={KWH}
                            isTotal={true}
                        />
                    </>
                }
                <tr>
                    <td colSpan={5}><StyledHorizontalLine/></td>
                </tr>
                <tr>
                    <td>Total:</td>
                    <td colSpan={2}/>
                    <PriceTableData
                        price={this.props.data.totalPrices?.totalPrice.inclusive || 0}
                        decimal={this.props.decimals.price}
                    />
                </tr>
                {
                    this.props.yearPayment
                    && <>
                        <tr>
                            <td>Payment year:</td>
                            <td colSpan={2}/>
                            <PriceTableData
                                price={this.props.yearPayment}
                                decimal={this.props.decimals.price}
                            />
                        </tr>
                        <tr>
                            <td colSpan={9}><StyledHorizontalLine/></td>
                        </tr>
                        <tr>
                            <td>Overpaid refund:</td>
                            <td colSpan={2}/>
                            <PriceTableData
                                price={(this.props.data.totalPrices?.totalPrice.inclusive || 0) - this.props.yearPayment}
                                decimal={this.props.decimals.price}
                            />
                        </tr>
                    </>
                }
                </tbody>
            </StyledTable>
        </div>;
    }
}

export default TablePrices;