import React from "react";
import styled from 'styled-components';
import Body from "../../component/body";
import * as utils from '../../utils';
import ChalkBoard from '../../asset/chalkboard_background.png';
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';

export const StyledContainer = styled.div`
    position: relative;
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    padding: 0 0 1rem 0;
`;

export const StyledTable = styled.table`
    position: relative;
    display: flex;
    flex-direction: column;
`;
export const StyledTableTd = styled.td`
    min-width: 5rem;
`;
export const StyledTableTdCenter = styled.td`
    display: flex;
    justify-content: center;
`;
export const StyledChalkBoard = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    background-color: rgba(0,0,0, .5);
    margin: 0 0 0 1rem;
    padding: 1.5rem 2rem;
    border-radius: .3rem;
    background-image: url('${ChalkBoard}');
    background-repeat: no-repeat;
    background-size: cover;
    color: rgba(189, 193, 199, 1);

    & > * {
        white-space: pre-wrap;
    }
`;
export const StyledFormula = styled.div`
    position: relative;
    display: flex;
    min-height: 1.25rem;
    align-items: end;
`;
export const StyledSup = styled.sup`
    display: flex;
    align-self: baseline;
`;

export const StyledSelect = styled.select`
    width: 100%;
`;

const StyledInput = styled.input`
    width: calc(100% - .5rem);
`;

const StyledConverterLink = styled.a`
    color: var(--color-text);

    &:hover {
        color: var(--color-text-hover);
    }
`;

interface Props {
    title: string;
    allowableUnits: {[unit: string]: string};
    defaultValues: {from: string; to: string, value: number}
    steps: number;
    url: string;
}
interface State {
    parameters : string[];
    value      : number;
    from       : string;
    to         : string;
    result     : string | undefined;
    operator   : string;
    formula    : number[];
    steps      : number;
    units      : {[unitName: string]: string};
}

class PageBody extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        const allowableUnitKeys   = Object.keys(this.props.allowableUnits);
        const allowableUnitValues = Object.values(this.props.allowableUnits).map((v)=>v.toLowerCase());

        let   urlParameters = window.location.pathname.replace(`${this.props.url}`, ``).split(`/`).filter((v)=>v.length > 0);
        const searches      = window.location.search.substring(1);
        const searchParameters: {[name: string]: string | number, from: string, to: string, value: number} = {
            from  : this.props.defaultValues.from,
            to    : this.props.defaultValues.to,
            value : this.props.defaultValues.value,
        };

        searches.split(`&`).forEach(
            (search) => {
                const param = search.split(`=`);
                if (param.length > 0) {
                    const name  = param[0] || ``;
                    if (searchParameters.hasOwnProperty(name)) {
                        let value: string | number = param[1] || ``;
                        if (
                            name === `value`
                            || (
                                [`from`, `to`].includes(name)
                                && (
                                    allowableUnitKeys.includes(value)
                                    || allowableUnitValues.includes(value.toLowerCase())
                                )
                            )
                        ) {
                            if (name === `value`) {
                                value = !isNaN(Number(value))
                                    ? Number(value)
                                    : this.props.defaultValues.value;
                            }

                            if (value !== ``) {
                                searchParameters[name] = value;
                            }
                        }
                    }
                }
            }
        );

        if (urlParameters.length > 0) {
            const searchParameterKeys = Object.keys(searchParameters);
            urlParameters
                .slice(0, searchParameterKeys.length)
                .forEach(
                    (urlParameter, index) => {
                        const position = allowableUnitValues.indexOf(urlParameter.toLowerCase());

                        searchParameters[searchParameterKeys[index]] = position !== -1
                            ? allowableUnitKeys[position]
                            : urlParameter;
                    }
                );
        }

        const calculate = this.calculate(
            {
                value: searchParameters.value,
                from: searchParameters.from,
                to: searchParameters.to,
                units: allowableUnitKeys,
            }
        );

        this.state = {
            parameters : urlParameters,
            value      : searchParameters.value,
            from       : searchParameters.from,
            to         : searchParameters.to,
            result     : calculate.result.toString(),
            operator   : calculate.operator,
            formula    : calculate.formula,
            steps      : calculate.steps,
            units      : this.props.allowableUnits,
        };
    }

    componentDidMount() {
        utils.setUrl(`${this.props.url}/${this.state.from}/${this.state.to}/${this.state.value}`);
    }

    calculate(
        {
            from,
            to,
            value,
            units = []
        } : {
            from?: string;
            to?: string;
            value?: number;
            units?: string[]
        }
    ) {
        if (from === undefined) {
            from = this.state.from;
        }
        if (to === undefined) {
            to = this.state.to;
        }
        if (value === undefined) {
            value = this.state.value;
        }

        const calculation = utils.calculation.calculate(
            units,
            this.props.steps,
            from,
            to,
            value
        );

        return {
            from    : from,
            to      : to,
            value   : value,
            result  : calculation.result.toString(),
            operator: calculation.operator,
            formula : calculation.formula,
            steps   : calculation.steps,
        };
    }

    changeResult(
        {
            from,
            to,
            value,
            units = []
        } : {
            from?: string;
            to?: string;
            value?: number;
            units: string[]
        }
    ) {
        const calculate = this.calculate({from: from, to: to, value: value, units: units});

        if (
            calculate.from === this.state.from
            && calculate.to === this.state.to
            && calculate.value === this.state.value
        ) {
            return; // why recalculate?
        }

        this.setState(
            {
                from    : calculate.from,
                to      : calculate.to,
                value   : calculate.value,
                result  : calculate.result,
                operator: calculate.operator,
                formula : calculate.formula,
                steps   : calculate.steps,
            },
            () => utils.setUrl(`${this.props.url}/${this.state.from}/${this.state.to}/${this.state.value}`)
        );
    }

    render() {
        return <Body>
            <h1>{this.props.title}</h1>
            <StyledContainer>
                <StyledTable>
                    <tbody>
                    <tr>
                        <StyledTableTd>Value</StyledTableTd>
                        <td>
                            <StyledInput
                                type={`number`}
                                placeholder={`value`}
                                defaultValue={this.state.value}
                                onChange={(e) => this.changeResult({units: Object.keys(this.state.units), value: (e.currentTarget as HTMLInputElement).valueAsNumber})}
                                min={-999999999999999}
                                max={999999999999999}
                            />
                        </td>
                    </tr>
                    <tr>
                        <td>From</td>
                        <td>
                            <StyledSelect
                                value={this.state.from}
                                onChange={(e) => this.changeResult({units: Object.keys(this.state.units), from: (e.currentTarget as HTMLSelectElement).value})}
                            >
                                {
                                    this.state.units
                                    && Object.keys(this.state.units).filter((v)=>v!==`--empty--`).map(
                                        (unit, index) => <option
                                            key={index}
                                            value={unit}
                                        >
                                            {this.state.units[unit]} ({unit})
                                        </option>
                                    )
                                }
                            </StyledSelect>
                        </td>
                    </tr>
                    <tr>
                        <td></td>
                        <StyledTableTdCenter
                            title={this.state.from !== this.state.to ? `Swap ${this.state.to} to ${this.state.from}` : `Can't swap the same unit.`}
                            style={
                                {
                                    cursor  : this.state.from !== this.state.to ? `pointer` : `not-allowed`,
                                    opacity : this.state.from !== this.state.to ? 1 : .6
                                }
                            }
                            onClick={
                                () => this.state.from !== this.state.to
                                    && this.changeResult(
                                        {
                                            units : Object.keys(this.state.units),
                                            from  : this.state.to,
                                            to    : this.state.from,
                                        }
                                    )
                            }
                        >
                            <SwapHorizIcon
                                style={{rotate:`90deg`, cursor: `pointer`}}
                            />
                        </StyledTableTdCenter>
                    </tr>
                    <tr>
                        <td>to</td>
                        <td>
                            <StyledSelect
                                value={this.state.to}
                                onChange={(e) => this.changeResult({units: Object.keys(this.state.units), to: (e.currentTarget as HTMLSelectElement).value})}
                            >
                                {
                                    this.state.units
                                    && Object.keys(this.state.units).filter((v)=>v!==`--empty--`).map(
                                        (unit, index) => <option
                                            key={index}
                                            value={unit}
                                        >
                                            {this.state.units[unit]} ({unit})
                                        </option>
                                    )
                                }
                            </StyledSelect>
                        </td>
                    </tr>
                    <tr>
                        <td colSpan={2}><hr/></td>
                    </tr>
                    <tr>
                        <td>Result</td>
                        <td>{this.state.result !== undefined ? `${this.state.result} ${this.state.to}` : `???`}</td>
                    </tr>
                    </tbody>
                </StyledTable>
                <StyledChalkBoard>
                    <pre>
                        <div>Short formula:</div>
                        <StyledFormula>
                            {
                                this.state.operator
                                && this.state.operator !== ``
                                    ? <>
                                        {this.state.value} {this.state.operator} {this.state.steps}{this.state.formula.length > 1 ? <StyledSup>{this.state.formula.length}</StyledSup> : ``}
                                    </>
                                    : `-`
                            }
                        </StyledFormula>
                        <br/>
                        <br/>
                        <br/>
                        <div>Formula:</div>
                        <StyledFormula>
                            {
                                this.state.operator
                                && this.state.operator !== ``
                                    ? <>
                                        {this.state.value} {this.state.formula.length >= 1 ? this.state.operator : ``} {this.state.formula.join(` ${this.state.operator} `)}
                                    </>
                                    : `-`
                            }
                        </StyledFormula>
                    </pre>
                </StyledChalkBoard>
            </StyledContainer>
            <div>
                Need another converter? Click <StyledConverterLink href={`/converter`}>here</StyledConverterLink> to check the other converters.
            </div>
        </Body>;
    }
}

export default PageBody;