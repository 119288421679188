import React      from "react";
import {pageType} from "../../../pages";
import styled     from 'styled-components';
import * as utils from "../../../utils";
import TablePrices, {StyledTableData, ValueColor} from "../home/usage/overview/tablePrices";
import {dataType, dayType, monthType, yearType} from "../home/usage/overview";
import Circle from "../../../component/circle";

const StyledMainBody = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  
  display: flex;
  flex-direction: row;
  background-color: var(--color-background);
  //background-color: green;
  z-index: 99;
  
  // width: 1920px;
  height: 480px;

  overflow: hidden;
  overflow-x: auto;
`;

const StyledComponentColumn = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  background-color: rgba(255, 255, 255, .1);
  border-radius: .4rem;
  min-width: 475px;
  max-width: 475px;
  margin: .5rem;
  padding: 1rem .5rem;
  font-size: 1rem;
  
  & > h1 {
    margin: 0;
    padding: 0;
  }
`;
const StyledComponentColumnSmall = styled(StyledComponentColumn)`
  min-width: 350px;
  max-width: 350px;
`;
const StyledComponentColumnHeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
`;
const StyledTableMax = styled.table`
  width: 100%;
`;
const StyledTableInformation = styled(StyledTableMax)`
  width: 100%;
  font-size: .64rem;
`;
const StyledComponentColumnHeader = styled.span`
  display: flex;
  flex-grow: 1;
  font-size: 1.75rem;
  font-weight: bold;
  text-align: left;
  justify-content: left;
`;
const StyledComponentColumnHeaderSub = styled(StyledComponentColumnHeader)`
  font-size: 1.5rem;
`;
const StyledComponentColumnHeaderSubRight = styled(StyledComponentColumnHeaderSub)`
  text-align: right;
  justify-content: right;
`;
const StyledComponentColumnHeaderSmall = styled(StyledComponentColumnHeader)`
  font-size: 1rem;
  font-weight: normal;
`;
const StyledComponentColumnHeaderSmallRight = styled(StyledComponentColumnHeaderSmall)`
  text-align: right;
  justify-content: right;
`;

const StyledComponentColumnDateTime = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  padding: 0.4rem .75rem;
`;

const StyledTableDataSmall = styled.td`
  min-width: 11rem;
  max-width: 11rem;
`;
const StyledTableDataMax = styled(StyledTableData)`
  width: 100%;
`;
const StyledTableDataAlignRight = styled(StyledTableData)`
  text-align: right;
`;
const StyledTableDataAlignRightSecond = styled(StyledTableDataAlignRight)`
  min-width: 3rem;
  max-width: 3rem;
`;
const StyledTableDataAlignRightHour = styled(StyledTableDataAlignRight)`
  min-width: 5rem;
  max-width: 5rem;
`;
const StyledTableDataAlignRightDay = styled(StyledTableDataAlignRight)`
  min-width: 5rem;
  max-width: 5rem;
`;

const StyledWindDirection = styled.div`
  display: flex;
  flex-direction: row;
  
  & > div:not(:first-child) {
    margin: 0;
  }
  & > div:first-child {
    margin: 0 .5rem 0 0;
  }
`;
const StyledWindDirectionArrow = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  align-items: center;
  width: 1rem;
  height: 1rem;
  padding: 0;
`;

const StyledDonutContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  min-width: 10rem;
  max-width: 10rem;
`;
const StyledDonut = styled.div`
  position: relative;
  min-width: 10rem;
  max-width: 10rem;
`;


type wattType = {
    watt: number;
    kiloWatt: number;
    Wh: number;
    kWh: number;
};

type phaseType = {
    power: {
        used: number;
        return: number;
        total: number;
    };
    sags: number;
    swells: number;
    voltage: number;
};

interface Props {}
interface State {
    date: Date,
    decimals: {
        price: number;
        units: number;
    };
    dateTime: {
        current: Date;
        year: {
            requesting: boolean,
            lastUpdate: Date | undefined;
            nextUpdate: Date | undefined;
            updateInSeconds: number | undefined;
        };
        month: {
            requesting: boolean,
            lastUpdate: Date | undefined;
            nextUpdate: Date | undefined;
            updateInSeconds: number | undefined;
        };
        day: {
            requesting: boolean,
            lastUpdate: Date | undefined;
            nextUpdate: Date | undefined;
            updateInSeconds: number | undefined;
        };
    };
    data: {
        year: {
            [year: string]: yearType | dataType
        };
        month: {
            [yearMonth: string]: monthType | dataType
        };
        day: {
            [yearMonthDay: string]: dayType | dataType
        };
        actual: {
            version?: string;
            accurateTotal: {
                electric: {
                    power: number;
                    phases: phaseType[];
                    difference: number;
                    raw: number;
                    inSeconds: wattType;
                    inMinutes: wattType;
                    inHours: wattType;
                    inDays: wattType;
                };
                gas: {
                    difference: number;
                    inSeconds: number;
                    inMinutes: number;
                    inHours: number;
                    inDays: number;
                };
            },
            electricTotal: number;
            gasTotal: number;
        };
        weather: {
            current: {
                isDay: boolean;
                temperature: number;
                time: string|Date;
                weatherCode: number;
                windDirection: number;
                windSpeed: number;
            };
            daily: {
                precipitationSum: number[];
                rainSum: number[];
                showerSum: number;
                snowfallSum: number;
                sunRise: (string|Date)[];
                sunSet: (string|Date)[];
                temperature2mMin: number[];
                temperature2mMax: number[];
                time: (string|Date)[];
                uvIndexClearSkyMax: number[];
                uvIndexMax: number[];
                weatherCode: number[];
            };
            dailyUnits: {
                time: `iso8601`,
                weatherCode: `wmo code`,
                temperature2mMin: `°C`,
                temperature2mMax: `°C`,
                sunRise: `iso8601`,
                sunSet: `iso8601`,
                uvIndexMax: ``,
                uvIndexClearSkyMax: ``,
                precipitationSum: `mm`,
                rainSum: `mm`,
                showersSum: `mm`,
                snowfallSum: `cm`
            };
            position: {
                latitude: number;
                longitude: number;
                timezone: string;
            };
        };
    };
}

const KWH   = `kWh`;
const M3    = `m³`;

const redirect  = `/home`;
class Page extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            date: utils.datetime.create(),
            decimals: {
                price: 2,
                units: 2
            },
            dateTime: {
                current: utils.datetime.create(),
                year: {
                    requesting: false,
                    lastUpdate: undefined,
                    nextUpdate: undefined,
                    updateInSeconds: undefined,
                },
                month: {
                    requesting: false,
                    lastUpdate: undefined,
                    nextUpdate: undefined,
                    updateInSeconds: undefined,
                },
                day: {
                    requesting: false,
                    lastUpdate: undefined,
                    nextUpdate: undefined,
                    updateInSeconds: undefined,
                }
            },
            data: {
                year: {},
                month: {},
                day  : {},
                actual: {
                    version: undefined,
                    accurateTotal: {
                        electric: {
                            power: 0,
                            phases: [],
                            difference: 0,
                            raw: 0,
                            inSeconds: {
                                watt: 0,
                                kiloWatt: 0,
                                Wh: 0,
                                kWh:0,
                            },
                            inMinutes: {
                                watt: 0,
                                kiloWatt: 0,
                                Wh: 0,
                                kWh:0,
                            },
                            inHours: {
                                watt: 0,
                                kiloWatt: 0,
                                Wh: 0,
                                kWh:0,
                            },
                            inDays: {
                                watt: 0,
                                kiloWatt: 0,
                                Wh: 0,
                                kWh:0,
                            },
                        },
                        gas: {
                            difference: 0,
                            inSeconds: 0,
                            inMinutes: 0,
                            inHours: 0,
                            inDays: 0,
                        },
                    },
                    electricTotal: 0,
                    gasTotal: 0,
                },
                weather: {
                    current: {
                        isDay: false,
                        temperature: 0,
                        time: new Date(),
                        weatherCode: 0,
                        windDirection: 0,
                        windSpeed: 0,
                    },
                    daily: {
                        precipitationSum: [],
                        rainSum: [],
                        showerSum: 0,
                        snowfallSum: 0,
                        sunRise: [],
                        sunSet: [],
                        temperature2mMin: [],
                        temperature2mMax: [],
                        time: [],
                        uvIndexClearSkyMax: [],
                        uvIndexMax: [],
                        weatherCode: [],
                    },
                    dailyUnits: {
                        time: `iso8601`,
                        weatherCode: `wmo code`,
                        temperature2mMin: `°C`,
                        temperature2mMax: `°C`,
                        sunRise: `iso8601`,
                        sunSet: `iso8601`,
                        uvIndexMax: ``,
                        uvIndexClearSkyMax: ``,
                        precipitationSum: `mm`,
                        rainSum: `mm`,
                        showersSum: `mm`,
                        snowfallSum: `cm`
                    },
                    position: {
                        latitude: 0,
                        longitude: 0,
                        timezone: ``,
                    },
                },
            },
        };
    }

    componentDidMount() {
        const requestInMS = {
            day: 30000,
            month: 30000,
            year: 30000,
        };
        setInterval(
            () => {
                const dateTimeLoad = utils.datetime.create();
                dateTimeLoad.setMilliseconds(0);

                if (this.state.date.getTime() !== dateTimeLoad.getTime()) {
                    let dateTimeDay = this.state.dateTime.day.nextUpdate
                        ? ((this.state.dateTime.day.nextUpdate.getTime() - dateTimeLoad.getTime()) / 1000)
                        : 0;
                    let dateTimeMonth = this.state.dateTime.month.nextUpdate
                        ? ((this.state.dateTime.month.nextUpdate.getTime() - dateTimeLoad.getTime()) / 1000)
                        : 0;

                    if (dateTimeDay < 0) {
                        dateTimeDay = 0;
                    }
                    if (dateTimeMonth < 0) {
                        dateTimeMonth = 0;
                    }

                    dateTimeDay = Number(`${dateTimeDay}`.split(`.`)[0] || 0);
                    dateTimeMonth = Number(`${dateTimeMonth}`.split(`.`)[0] || 0);

                    this.setState(
                        {
                            date: dateTimeLoad,
                            dateTime: {
                                ...this.state.dateTime,
                                day: {
                                    ...this.state.dateTime.day,
                                    updateInSeconds: dateTimeDay
                                },
                                month: {
                                    ...this.state.dateTime.month,
                                    updateInSeconds: dateTimeMonth
                                },
                            },
                        },
                    );
                }
            },
            1000
        );

        const requestDay = () => this.setState(
            {
                dateTime: {
                    ...this.state.dateTime,
                    day: {
                        ...this.state.dateTime.day,
                        requesting: true,
                    }
                }
            },
            () => utils.ws.sent(
                `account`,
                `home.usage.overview.day`,
                {
                    year  : utils.datetime.formattedDate(this.state.date, `Y`),
                    month : utils.datetime.formattedDate(this.state.date, `m`),
                    day   : utils.datetime.formattedDate(this.state.date, `d`),
                    loop  : false
                },
            )
        );
        const requestMonth = () => this.setState(
            {
                dateTime: {
                    ...this.state.dateTime,
                    month: {
                        ...this.state.dateTime.month,
                        requesting: true,
                    }
                }
            },
            () => utils.ws.sent(
                `account`,
                `home.usage.overview.month`,
                {
                    year  : utils.datetime.formattedDate(this.state.date, `Y`),
                    month : utils.datetime.formattedDate(this.state.date, `m`),
                    loop  : false
                },
            )
        );
        const requestYear = () => this.setState(
            {
                dateTime: {
                    ...this.state.dateTime,
                    year: {
                        ...this.state.dateTime.year,
                        requesting: true,
                    }
                }
            },
            () => utils.ws.sent(
                `account`,
                `home.usage.overview.year`,
                {
                    year  : utils.datetime.formattedDate(this.state.date, `Y`),
                    loop  : false
                },
            )
        );

        utils.ws.listener.message(
            [`account.home.usage.overview.year`],
            d => {
                if (d.code !== 200) {
                    return;
                }

                const data = d.results;
                const yearsData: yearType = {
                    creation    : data.hasOwnProperty(`creation`) ? data.creation : null,
                    datetime    : data.hasOwnProperty(`datetime`) ? data.datetime : null,
                    year        : data.hasOwnProperty(`year`) ? data.year : null,
                    months      : data.hasOwnProperty(`months`) ? data.months : null,
                    stats       : data.hasOwnProperty(`stats`) ? data.stats : {},
                    totalPrices : data.hasOwnProperty(`totalPrices`) ? data.totalPrices : {},
                };

                this.setState(
                    {
                        data: {
                            ...this.state.data,
                            year: {
                                ...this.state.data.year,
                                [yearsData.year]: yearsData
                            }
                        }
                    },
                    () => {
                        const dateTimeNow = utils.datetime.create();
                        const dateTimeNext = utils.datetime.create();
                        dateTimeNext.setMilliseconds(dateTimeNext.getMilliseconds() + requestInMS.month);

                        this.setState(
                            {
                                dateTime: {
                                    ...this.state.dateTime,
                                    year: {
                                        ...this.state.dateTime.year,
                                        requesting: false,
                                        lastUpdate: dateTimeNow,
                                        nextUpdate: dateTimeNext,
                                    }
                                }
                            },
                            () => setTimeout(requestYear, requestInMS.year)
                        );
                    }
                );
            }
        );

        utils.ws.listener.message(
            [`account.home.usage.overview.month`],
            d => {
                if (d.code !== 200) {
                    return;
                }

                const data = d.results;
                const monthsData: monthType = {
                    creation    : data.hasOwnProperty(`creation`) ? data.creation : null,
                    datetime    : data.hasOwnProperty(`datetime`) ? data.datetime : null,
                    year        : data.hasOwnProperty(`year`) ? data.year : null,
                    month       : data.hasOwnProperty(`month`) ? data.month : null,
                    days        : data.hasOwnProperty(`days`) ? data.days : {},
                    stats       : data.hasOwnProperty(`stats`) ? data.stats : {},
                    totalPrices : data.hasOwnProperty(`totalPrices`) ? data.totalPrices : {},
                };

                this.setState(
                    {
                        data: {
                            ...this.state.data,
                            month: {
                                ...this.state.data.month,
                                [`${monthsData.year}-${monthsData.month}`]: monthsData
                            }
                        }
                    },
                    () => {
                        const dateTimeNow = utils.datetime.create();
                        const dateTimeNext = utils.datetime.create();
                        dateTimeNext.setMilliseconds(dateTimeNext.getMilliseconds() + requestInMS.month);

                        this.setState(
                            {
                                dateTime: {
                                    ...this.state.dateTime,
                                    month: {
                                        ...this.state.dateTime.month,
                                        requesting: false,
                                        lastUpdate: dateTimeNow,
                                        nextUpdate: dateTimeNext,
                                    }
                                }
                            },
                            () => setTimeout(requestMonth, requestInMS.month)
                        );
                    }
                );
            }
        );

        utils.ws.listener.message(
            [`account.home.usage.overview.day`],
            d => {
                if (d.code !== 200) {
                    return;
                }

                const data = d.results;
                const daysData: dayType = {
                    creation    : data.hasOwnProperty(`creation`) ? data.creation : null,
                    datetime    : data.hasOwnProperty(`datetime`) ? data.datetime : null,
                    year        : data.hasOwnProperty(`year`) ? data.year : null,
                    month       : data.hasOwnProperty(`month`) ? data.month : null,
                    day         : data.hasOwnProperty(`day`) ? data.day : null,
                    hours       : data.hasOwnProperty(`hours`) ? data.hours : {},
                    stats       : data.hasOwnProperty(`stats`) ? data.stats : {},
                    totalPrices : data.hasOwnProperty(`totalPrices`) ? data.totalPrices : {},
                };

                this.setState(
                    {
                        data: {
                            ...this.state.data,
                            day: {
                                ...this.state.data.day,
                                [`${daysData.year}-${daysData.month}-${daysData.day}`]: daysData
                            }
                        }
                    },
                    () => {
                        const dateTimeNow = utils.datetime.create();
                        const dateTimeNext = utils.datetime.create();
                        dateTimeNext.setMilliseconds(dateTimeNext.getMilliseconds() + requestInMS.day);

                        this.setState(
                            {
                                dateTime: {
                                    ...this.state.dateTime,
                                    day: {
                                        ...this.state.dateTime.day,
                                        requesting: false,
                                        lastUpdate: dateTimeNow,
                                        nextUpdate: dateTimeNext,
                                    }
                                }
                            },
                            () => setTimeout(requestDay, requestInMS.day)
                        );
                    }
                );
            }
        );

        requestDay();
        setTimeout(
            requestMonth,
            500
        );
        setTimeout(
            requestYear,
            1000
        );

        let isWeatherAPIFailed = false;
        const fetchWeather = () => {
            if (isWeatherAPIFailed) {
                return
            }

            fetch(`https://api.open-meteo.com/v1/forecast?latitude=51.66&longitude=5.62&hourly=temperature_2m,relativehumidity_2m,apparent_temperature,precipitation,rain,showers,weathercode,cloudcover,uv_index,uv_index_clear_sky,is_day,direct_radiation,shortwave_radiation_instant,direct_radiation_instant,terrestrial_radiation_instant&daily=sunrise,sunset,uv_index_max,uv_index_clear_sky_max&current_weather=true&forecast_days=1&timezone=Europe%2FBerlin`)
                .then(d=>d.json())
                .then(d=>{
                    this.setState({
                        data: {
                            ...this.state.data,
                            weather: {
                                current: {
                                    isDay: d?.current_weather?.is_day || false,
                                    temperature: d?.current_weather?.temperature || -1,
                                    time: utils.datetime.createFromString(d?.current_weather?.time || ``) || new Date(),
                                    weatherCode: d?.current_weather?.weathercode || 0,
                                    windDirection: d?.current_weather?.winddirection || 0,
                                    windSpeed: d?.current_weather?.windspeed || 0,
                                },
                                daily: {
                                    precipitationSum   : d?.daily?.precipitation_sum || [],
                                    rainSum            : d?.daily?.rain_sum || [],
                                    showerSum          : d?.daily?.shower_sum || 0,
                                    snowfallSum        : d?.daily?.snowfall_sum || 0,
                                    sunRise            : d?.daily?.sunrise || [],
                                    sunSet             : d?.daily?.sunset || [],
                                    temperature2mMin   : d?.daily?.temperature_2m_min || [],
                                    temperature2mMax   : d?.daily?.temperature_2m_max || [],
                                    time               : d?.daily?.time || [],
                                    uvIndexClearSkyMax : d?.daily?.uv_index_clear_sky_max || [],
                                    uvIndexMax         : d?.daily?.uv_index_max || [],
                                    weatherCode        : d?.daily?.weathercode || [],
                                },
                                dailyUnits: {
                                    time              : d?.daily_units?.time || `iso8601`,
                                    weatherCode       : d?.daily_units?.weathercode || `wmo code`,
                                    temperature2mMin  : d?.daily_units?.temperature_2m_min || `°C`,
                                    temperature2mMax  : d?.daily_units?.temperature_2m_max || `°C`,
                                    sunRise           : d?.daily_units?.sunrise || `iso8601`,
                                    sunSet            : d?.daily_units?.sunset || `iso8601`,
                                    uvIndexMax        : d?.daily_units?.uv_index_max || ``,
                                    uvIndexClearSkyMax: d?.daily_units?.uv_index_clear_sky_max || ``,
                                    precipitationSum  : d?.daily_units?.precipitation_sum || `mm`,
                                    rainSum           : d?.daily_units?.rain_sum || `mm`,
                                    showersSum        : d?.daily_units?.showers_sum || `mm`,
                                    snowfallSum       : d?.daily_units?.snowfall_sum || `cm`,
                                },
                                position: {
                                    latitude : d?.latitude || 0,
                                    longitude: d?.langitude || 0,
                                    timezone : d?.timezone || ``,
                                },
                            },
                        },
                    },
                );
            })
            .finally(
                () => setTimeout(
                    fetchWeather,
                    60000 * 5 // each 5 minutes
                )
            );
        };
        fetchWeather();

        const wsFusebox = new WebSocket(`wss://websocket.agers.nl/fusebox`);
        setTimeout(
            ()=> wsFusebox.send(JSON.stringify({
                type: `fusebox`,
                action: `actual`,
                data: {
                    subscribe: true
                }
            })),
            1500
        );

        wsFusebox.onmessage = d => {
            if (d.data) {
                const data = JSON.parse(d.data) || {};
                const results = data.results || {};
                    if (
                        results.stats
                        && results.stats.usage
                    ) {
                        let usageElectric;
                        let usageGas;
                        let version: undefined|string;
                        let differenceInSecondsElectric;
                        let differenceInSecondsGas;
                        let phases: phaseType[] = [];
                        if (results.v2) {
                            usageElectric    = results.stats.usage.electric;
                            usageGas         = results.stats.usage;

                            const previousDateTimeTime = utils.datetime.createFromString(results.stats.previous.datetime)?.getTime() || 0;
                            const currentDateTimeTime = utils.datetime.createFromString(results.stats.current.datetime)?.getTime() || 0;
                            const differenceInMS = currentDateTimeTime - previousDateTimeTime;
                            let   difference = differenceInMS / 1000;
                            if (difference < 0) {
                                difference = 0;
                            }

                            differenceInSecondsElectric = difference;
                            differenceInSecondsGas = difference;

                            version          = `v2`;
                        } else if (results.agersVersion === `V3`) {
                            usageElectric               = results.stats.usage.electric;
                            usageGas                    = results.stats.usage.gas;
                            differenceInSecondsElectric = results.stats.usage.durationInSeconds.electric;
                            differenceInSecondsGas      = results.stats.usage.durationInSeconds.gas;
                            version                     = `v3`;

                            const resultPhases = results.phases ?? [];
                            phases = resultPhases.map(
                                (phase: {[key: string]: any}) => {
                                    return {
                                        voltage: phase.voltage || 0,
                                        sags: phase.sags || 0,
                                        swells: phase.swells || 0,
                                        power: {
                                            used: phase?.power?.used !== 0 ? phase.power.used * 1000 : 0,
                                            return: phase?.power?.return !== 0 ? phase.power.return * 1000 : 0,
                                            total: phase?.power?.total !== 0 ? phase.power.total * 1000 : 0,
                                        }
                                    };
                                }
                            );
                        }

                        if (
                            usageElectric === undefined
                            || usageGas === undefined
                        ) {
                            return;
                        }

                        const electricKWH = differenceInSecondsElectric > 0 ? usageElectric.total.total / differenceInSecondsElectric : usageElectric.total.total;
                        const electricWhPerSecond = electricKWH * 1000;
                        const electricWhPerMinute = electricWhPerSecond !== 0 ? electricWhPerSecond * 60 : 0;
                        const electricWhPerHour = electricWhPerMinute !== 0 ? electricWhPerMinute * 60 : 0;
                        const electricWhPerDay = electricWhPerHour !== 0 ? electricWhPerHour * 24 : 0;

                        const wattPerSecond = electricWhPerSecond !== 0 && differenceInSecondsElectric > 0 ? electricWhPerSecond / differenceInSecondsElectric : electricWhPerSecond;
                        const wattPerMinute = wattPerSecond !== 0 ? wattPerSecond*60 : 0;
                        const wattPerHour = wattPerMinute !== 0 ? wattPerMinute*60 : 0;
                        const wattPerDay = wattPerHour !== 0 ? wattPerHour*24 : 0;

                        const gas: number = usageGas.gas || 0;
                        const gasPerSecond = gas > 0 && differenceInSecondsGas > 0 ? gas / differenceInSecondsGas : 0;

                        this.setState({
                            data : {
                                ...this.state.data,
                                actual: {
                                    version: version,
                                    accurateTotal: {
                                        electric: {
                                            power: results.power.total * 1000,
                                            phases: phases,
                                            difference: differenceInSecondsElectric,
                                            raw: usageElectric.total.total,
                                            inSeconds: {
                                                watt: wattPerSecond !== 0 ? wattPerSecond : 0,
                                                kiloWatt: wattPerSecond !== 0 ? wattPerSecond/1000 : 0,
                                                Wh: electricWhPerSecond,
                                                kWh: electricKWH,
                                            },
                                            inMinutes: {
                                                watt: wattPerMinute !== 0 ? wattPerMinute : 0,
                                                kiloWatt: wattPerMinute !== 0 ? wattPerMinute/1000 : 0,
                                                Wh: electricWhPerMinute,
                                                kWh:electricWhPerMinute/1000,
                                            },
                                            inHours: {
                                                watt: wattPerHour !== 0 ? wattPerHour : 0,
                                                kiloWatt: wattPerHour !== 0 ? wattPerHour/1000 : 0,
                                                Wh: electricWhPerHour,
                                                kWh:electricWhPerHour/1000,
                                            },
                                            inDays: {
                                                watt: wattPerDay !== 0 ? wattPerDay : 0,
                                                kiloWatt: wattPerDay !== 0 ? wattPerDay/1000 : 0,
                                                Wh: electricWhPerDay,
                                                kWh:electricWhPerDay/1000,
                                            },
                                        },
                                        gas: {
                                            difference: differenceInSecondsGas,
                                            inSeconds: gas > 0 && differenceInSecondsGas > 0 ? gasPerSecond : 0,
                                            inMinutes: gas > 0 && differenceInSecondsGas > 0 ? gasPerSecond*60 : 0,
                                            inHours: gas > 0 && differenceInSecondsGas > 0 ? gasPerSecond*60*60 : 0,
                                            inDays: gas > 0 && differenceInSecondsGas > 0 ? gasPerSecond*60*60*24 : 0,
                                        },
                                    },
                                    electricTotal: usageElectric.total.total,
                                    gasTotal: usageGas.gas,
                                }
                            },
                        });
                    }

            }
        };
    }

    updateComponentDateTime(
        requesting: boolean,
        lastUpdated: Date | undefined,
        nextUpdate: Date | undefined,
        updateInSeconds: number | undefined
    ) {
        return <StyledComponentColumnDateTime>
            <StyledComponentColumnHeaderContainer>
                <StyledComponentColumnHeaderSmall></StyledComponentColumnHeaderSmall>
                <StyledComponentColumnHeaderSmallRight>
                    {
                        requesting
                            ? `retrieving data...`
                            : ``
                    }
                </StyledComponentColumnHeaderSmallRight>
            </StyledComponentColumnHeaderContainer>

            <StyledComponentColumnHeaderContainer>
                <StyledTableInformation>
                    <tbody>
                        <tr>
                            <td>Last update:</td>
                            <td>Next update:</td>
                            <td>Current:</td>
                            <td>Next in:</td>
                        </tr>
                        <tr>
                            <td>
                                {
                                    lastUpdated
                                        ? utils.datetime.formattedDate(lastUpdated, `H:i:s d-m-Y`)
                                        : `unavailable`
                                }
                            </td>
                            <td>
                                {
                                    nextUpdate
                                        ? utils.datetime.formattedDate(nextUpdate, `H:i:s d-m-Y`)
                                        : `unavailable`
                                }
                            </td>
                            <td>
                                {utils.datetime.formattedDate(this.state.date, `H:i:s d-m-Y`)}
                            </td>
                            <td>
                                {
                                    updateInSeconds
                                        ? `${updateInSeconds || 0} seconds`
                                        : `unavailable`
                                }
                            </td>
                        </tr>
                    </tbody>
                </StyledTableInformation>
            </StyledComponentColumnHeaderContainer>
        </StyledComponentColumnDateTime>;
    }


    numberMultiplyBy(
        value: number,
        decimals: number,
        paddingNotNegative: boolean,
        multiplyBy: number
    ) {
        if (
            multiplyBy === 0
            || value === 0
        ) {
            const valueWithDecimals = utils.number.format(value, decimals);
            return paddingNotNegative && value < 0
                ? ` ${valueWithDecimals}`
                : valueWithDecimals;
        }

        const multipliedValue = value * multiplyBy;
        const multipliedValueDecimals = utils.number.format(multipliedValue, decimals);

        return paddingNotNegative && multipliedValue > 0
            ? ` ${multipliedValueDecimals}`
            : multipliedValueDecimals;
    }

    numberDivideBy(
        value: number,
        decimals: number,
        paddingNotNegative: boolean,
        divideBy: number,
    ) {
        if (
            divideBy === 0
            || value === 0
        ) {
            const valueWithDecimals = utils.number.format(value,decimals);
            return paddingNotNegative && value < 0
                ? ` ${valueWithDecimals}`
                : valueWithDecimals;
        }

        const dividedValue = value / divideBy;
        const dividedValueDecimals = utils.number.format(dividedValue,decimals);

        return paddingNotNegative && dividedValue > 0
            ? ` ${dividedValueDecimals}`
            : dividedValueDecimals;
    }

    render() {
        return <StyledMainBody>
            <StyledComponentColumn>
                <StyledComponentColumnHeaderContainer>
                    <StyledComponentColumnHeaderSub>Day [{utils.datetime.getDayOfWeekName(this.state.date)}]</StyledComponentColumnHeaderSub>
                    <StyledComponentColumnHeaderSubRight>{utils.datetime.formattedDate(this.state.date, `d-m-Y`)}</StyledComponentColumnHeaderSubRight>
                </StyledComponentColumnHeaderContainer>
                <TablePrices
                    subjectWithUnits={true}
                    decimals={this.state.decimals}
                    yearPayment={undefined}
                    data={this.state.data.day[utils.datetime.formattedDate(this.state.date, `Y-M-D`)] ?? {}}
                />
                {
                    this.updateComponentDateTime(
                        this.state.dateTime.day.requesting,
                        this.state.dateTime.day.lastUpdate,
                        this.state.dateTime.day.nextUpdate,
                        this.state.dateTime.day.updateInSeconds,
                    )
                }
            </StyledComponentColumn>

            <StyledComponentColumn>
                <StyledComponentColumnHeaderContainer>
                    <StyledComponentColumnHeaderSub>Month [{utils.datetime.getMonthName(this.state.date)}]</StyledComponentColumnHeaderSub>
                    <StyledComponentColumnHeaderSubRight>{utils.datetime.formattedDate(this.state.date, `m-Y`)}</StyledComponentColumnHeaderSubRight>
                </StyledComponentColumnHeaderContainer>
                <TablePrices
                    subjectWithUnits={true}
                    decimals={this.state.decimals}
                    yearPayment={undefined}
                    data={this.state.data.month[utils.datetime.formattedDate(this.state.date, `Y-M`)] ?? {}}
                />
                {
                    this.updateComponentDateTime(
                        this.state.dateTime.month.requesting,
                        this.state.dateTime.month.lastUpdate,
                        this.state.dateTime.month.nextUpdate,
                        this.state.dateTime.month.updateInSeconds,
                    )
                }
            </StyledComponentColumn>

            <StyledComponentColumn>
                <StyledComponentColumnHeaderContainer>
                    <StyledComponentColumnHeaderSub>Year</StyledComponentColumnHeaderSub>
                    <StyledComponentColumnHeaderSubRight>{utils.datetime.formattedDate(this.state.date, `Y`)}</StyledComponentColumnHeaderSubRight>
                </StyledComponentColumnHeaderContainer>
                <TablePrices
                    subjectWithUnits={true}
                    decimals={this.state.decimals}
                    yearPayment={undefined}
                    data={this.state.data.year[utils.datetime.formattedDate(this.state.date, `Y`)] ?? {}}
                />
                {
                    this.updateComponentDateTime(
                        this.state.dateTime.month.requesting,
                        this.state.dateTime.month.lastUpdate,
                        this.state.dateTime.month.nextUpdate,
                        this.state.dateTime.month.updateInSeconds,
                    )
                }
            </StyledComponentColumn>
            <StyledComponentColumnSmall>
                <StyledComponentColumnHeaderContainer>
                    <StyledComponentColumnHeaderSub>Current usage ({this.state.data.actual.version ?? `v0`})</StyledComponentColumnHeaderSub>
                </StyledComponentColumnHeaderContainer>
                <StyledDonutContainer>
                    <StyledDonut>
                        <Circle
                            prefix={`Total`}
                            maxValue={9200}
                            currentValue={this.state.data.actual.accurateTotal.electric.power}
                        />
                    </StyledDonut>
                    {
                        this.state.data.actual.accurateTotal.electric.phases
                        && this.state.data.actual.accurateTotal.electric.phases.map(
                            (phase,index) => <StyledDonut
                                key={index}
                            >
                                <Circle
                                    prefix={`Phase #1 load`}
                                    maxValue={9200}
                                    currentValue={phase.power.total || 0}
                                />
                            </StyledDonut>
                        )
                    }

                </StyledDonutContainer>
                <StyledTableMax>
                    <tbody>
                    <tr>
                        <StyledTableDataSmall>Gas ({this.state.data.actual.accurateTotal.gas.difference} s)</StyledTableDataSmall>
                        <td>:</td>
                        <StyledTableDataMax
                            className={ValueColor(this.state.data.actual.gasTotal ?? 0)}
                        >
                                {
                                    this.numberMultiplyBy(
                                        this.state.data.actual.gasTotal,
                                        0,
                                        false,
                                        0
                                    )
                                } {M3}
                        </StyledTableDataMax>
                    </tr>
                    <tr>
                        <td colSpan={3}>&nbsp;</td>
                    </tr>
                    <tr>
                        <td>Day or Night</td>
                        <td>:</td>
                        <td>{this.state.data.weather.current.isDay ? `Day` : `Night`} {this.state.data.weather.current.isDay ? <>&#9728;&#65039;</>: <>&#127761;</>}</td>
                    </tr>
                    <tr>
                        <td>
                            Temperature
                        </td>
                        <td>:</td>
                        <td
                            style={
                                {
                                    color: utils.color.calculateTemperatureColor(-20, 50, this.state.data.weather.current.temperature),
                                }
                            }
                        >{this.state.data.weather.current.temperature} {this.state.data.weather.dailyUnits.temperature2mMin}</td>
                    </tr>
                    <tr>
                        <td>Wind Direction</td>
                        <td>:</td>
                        <td>
                            <StyledWindDirection>
                                <div>{this.state.data.weather.current.windDirection}</div>
                                <StyledWindDirectionArrow
                                    style={{
                                        transform: `rotate(${this.state.data.weather.current.windDirection}deg)`,
                                    }}
                                >&#8593;</StyledWindDirectionArrow>
                            </StyledWindDirection>
                        </td>
                    </tr>
                    <tr>
                        <td>Wind Speed</td>
                        <td>:</td>
                        <td>{this.state.data.weather.current.windSpeed} km/h</td>
                    </tr>
                    </tbody>
                </StyledTableMax>
            </StyledComponentColumnSmall>
        </StyledMainBody>;
    }
}

const page: pageType = {
    name  : `home monitor`,
    link  : [`/home/monitor`],
    page  : <Page />,
    props : {
        enabled  : true,
        menu     : false,
        login    : false,
        redirect : redirect,
    },
};

export default page;