import React        from "react";
import styled       from 'styled-components';
import { pageType } from "../pages";
import Body         from "../component/body";
import * as utils   from '../utils';

const StyledContactContainer = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    min-width: 30rem;
    
    & > * {
        margin: .25rem 0;
    }
`;


interface Props {
}

interface State {
}

class PageBody extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
        };
    }

    componentDidMount() {
    }

    render() {
        return <Body
            background={false}
        >
            <h1>Test</h1>
            <StyledContactContainer>
            </StyledContactContainer>
        </Body>;
    }
}

const page: pageType = {
    name  : `test`,
    link  : [`/test`],
    page  : <PageBody />,
    props : {
        menu    : false,
        enabled : utils.storage.get(`test`) === `2957aeb927300d2cf9d5f0bdf39e7448` && false
    }
};

export default page;