import React        from "react";
import styled       from 'styled-components';
import { pageType } from "../../../pages";
import Body         from "../../../component/body";
import * as utils   from '../../../utils';

const StyledDiv = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
`;

const StyledGameContainer = styled(StyledDiv)`
    flex-direction: row;

    min-width: 50rem;
    max-width: 50rem;
    min-height: 40rem;
    max-height: 40rem;
    
    background-color: green;
`;

const StyledStatusContainer = styled(StyledDiv)`
    padding: 1rem 2rem 1rem 1rem;

    background-color: black;
`;

const StyledItemDescription = styled.td`
    text-transform: capitalize;
`;


type amountType = {
    current: number;
    max: number;
};

type amountTypeFactories = {
    current: number;
    max: number;
    incomeRadius: {
        min: number;
        max: number;
    };
};

type maxLevelType = {
    base     : {
        houses : number;
        factory: {
            gold  : number;
            lumber: number;
        };
    };
    residents: {
        villages: number;
        warriors: number;
    };
    resources: {
        gold  : {
            max: number;
            incomeRadius: {
                min: number;
                max: number;
            };
        };
        lumber: {
            max: number;
            incomeRadius: {
                min: number;
                max: number;
            };
        };
    };
};

interface Props {
}

interface State {
    incomeInSecondsBetween: number;
    nextIncome: {
        seconds: number;
        date   : Date
    };
    base: {
        name: string;
        level: number;
        hp: amountType;
        houses: amountType;
        factories: {
            gold: amountTypeFactories,
            lumber: amountTypeFactories,
        };
        restore: Date;
    };
    residents: {
        villagers: amountType;
        warriors: amountType;
        [key: string] : amountType
    }
    resources: {
        [key: string] : amountType
    }
}

class PageBody extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        const nextIncome = new Date();
        nextIncome.setSeconds(nextIncome.getSeconds() + 30);

        this.state = {
            incomeInSecondsBetween: 5,
            nextIncome: {
                seconds : 0,
                date    : nextIncome
            },
            base: {
                name: `Unknown`,
                level: 0,
                hp: {
                    current: 0,
                    max    : 0
                },
                houses : {
                    current : 0,
                    max     : 0,
                },
                factories: {
                    gold: {
                        current : 0,
                        max     : 0,
                        incomeRadius: {
                            min: .01,
                            max: .2,
                        },
                    },
                    lumber: {
                        current : 0,
                        max     : 0,
                        incomeRadius: {
                            min: .01,
                            max: .2,
                        },
                    },
                },
                restore: new Date()
            },
            residents: {
                villagers : {
                    current : 0,
                    max     : 0,
                },
                warriors : {
                    current : 0,
                    max     : 0,
                },
            },
            resources: {
                gold : {
                    current : 0,
                    max     : 0,
                },
                lumber : {
                    current : 0,
                    max     : 0,
                },
            },
        };

        this.income = this.income.bind(this);
        this.setMaxValues = this.setMaxValues.bind(this);
        this.levelUpBase = this.levelUpBase.bind(this);
        this.levelUpFactory = this.levelUpFactory.bind(this);
    }

    maxLevelObject(
        {
            houses,
            gold,
            lumber,
        } : {
            houses?: number;
            gold?: number;
            lumber?: number;
        }
    ): maxLevelType {
        houses = houses || this.state.base.houses.max;
        gold   = gold || this.state.base.factories.gold.max;
        lumber = lumber || this.state.base.factories.lumber.max;

        return {
            base     : {
                houses : houses,
                factory: {
                    gold  : gold,
                    lumber: lumber,
                },
            },
            residents: {
                villages: 0,
                warriors: 0,
            },
            resources: {
                gold  : {
                    max: Math.floor(gold * 1.15),
                    incomeRadius: {
                        min: gold * this.state.base.factories.gold.incomeRadius.min,
                        max: gold * this.state.base.factories.gold.incomeRadius.max,
                    }
                },
                lumber: {
                    max: Math.floor(lumber * 1.04),
                    incomeRadius: {
                        min: lumber * this.state.base.factories.lumber.incomeRadius.min,
                        max: lumber * this.state.base.factories.lumber.incomeRadius.max,
                    }
                },
            },
        };
    }

    levels(): {[level: number]: maxLevelType} {
        return {
            0  : this.maxLevelObject({ houses: 1, gold: 2, lumber: 1}),
            1  : this.maxLevelObject({ houses: 3, gold: 4, lumber: 1}),
            2  : this.maxLevelObject({ houses: 6, gold: 6, lumber: 3}),
            3  : this.maxLevelObject({ houses: 10, gold: 10, lumber: 6}),
            4  : this.maxLevelObject({ houses: 17, gold: 15, lumber: 8}),
            5  : this.maxLevelObject({ houses: 24, gold: 30, lumber: 12}),
            6  : this.maxLevelObject({ houses: 32, gold: 100, lumber: 20}),
            7  : this.maxLevelObject({ houses: 41, gold: 174, lumber: 50}),
            8  : this.maxLevelObject({ houses: 55, gold: 227, lumber: 90}),
            9  : this.maxLevelObject({ houses: 70, gold: 300, lumber: 160}),
            10 : this.maxLevelObject({ houses: 140, gold: 404, lumber: 210}),
            11 : this.maxLevelObject({ houses: 194, gold: 734, lumber: 290}),
        };
    }

    updateFrame(
        callbacks: (() => void)[]
    ) {
        callbacks.length > 0
        && setTimeout(
            () => {
                callbacks.forEach((callback) => callback());
                this.updateFrame(callbacks);
            },
            1000
        );
    }

    income() {
        const newIncomeAvailable = this.state.nextIncome.seconds <= 0;
        const nextIncome = {
            seconds : this.state.nextIncome.seconds,
            date    : newIncomeAvailable ? new Date() : this.state.nextIncome.date,
            dateNow : new Date(),
        };

        if (newIncomeAvailable) {
            nextIncome.date.setSeconds(nextIncome.date.getSeconds() + this.state.incomeInSecondsBetween + 1);
            nextIncome.date.setMilliseconds(0);
        }

        nextIncome.seconds = Math.floor((nextIncome.date.getTime() - nextIncome.dateNow.getTime()) / 1000);

        const factories = {
            gold   : this.state.base.factories.gold.current <= this.state.base.factories.gold.max ? this.state.base.factories.gold.current : this.state.base.factories.gold.max,
            lumber : this.state.base.factories.lumber.current <= this.state.base.factories.lumber.max ? this.state.base.factories.lumber.current : this.state.base.factories.lumber.max,
        };

        const currentResources = {
            gold   : this.state.resources.gold.current,
            lumber : this.state.resources.lumber.current,
        };

        const newResources = {
            gold   : currentResources.gold + (factories.gold * utils.random(this.state.base.factories.gold.incomeRadius.min, this.state.base.factories.gold.incomeRadius.max)),
            lumber : currentResources.lumber + (factories.lumber * utils.random(this.state.base.factories.lumber.incomeRadius.min, this.state.base.factories.lumber.incomeRadius.max)),
        };

        if (newResources.gold > this.state.resources.gold.max) {
            newResources.gold = this.state.resources.gold.max;
        }

        if (newResources.lumber > this.state.resources.lumber.max) {
            newResources.lumber = this.state.resources.lumber.max;
        }

        this.setState(
            {
                nextIncome: {
                    seconds : nextIncome.seconds,
                    date    : nextIncome.date,
                },
                base: {
                    ...this.state.base,
                    factories: {
                        gold: {
                            ...this.state.base.factories.gold,
                            current: factories.gold,
                        },
                        lumber: {
                            ...this.state.base.factories.lumber,
                            current: factories.lumber,
                        },
                    },
                },
                resources: {
                    gold: {
                        ...this.state.resources.gold,
                        current: newIncomeAvailable ? newResources.gold : currentResources.gold,
                    },
                    lumber: {
                        ...this.state.resources.lumber,
                        current: newIncomeAvailable ? newResources.lumber : currentResources.lumber,
                    },
                },
            }
        );
    }

    levelUpBase() {
        const currentLevel = this.state.base.level;
        const levels       = this.levels();
        const maxLevel     = Number(Object.keys(levels).reverse()[0]);
        const newValue     = currentLevel + 1;
        const newLevel     = newValue <= maxLevel ? newValue : currentLevel;

        this.setState(
            {
                incomeInSecondsBetween: 5 + newLevel + Math.ceil(newLevel * .654321),
                base: {
                    ...this.state.base,
                    level: newLevel
                }
            },
            () => this.setMaxValues()
        )
    }

    levelUpFactory(
        factoryName: `gold` | `lumber`
    ) {
        const factory    = this.state.base.factories[factoryName];
        const current    = factory.current;
        const newValue   = current + 1;
        const max        = factory.max;
        const newCurrent = newValue <= max ? newValue : current;

        this.setState(
            {
                base : {
                    ...this.state.base,
                    factories: {
                        ...this.state.base.factories,
                        [factoryName]: {
                            ...factory,
                            current: newCurrent
                        }
                    },
                },
            },
            () => this.setMaxValues()
        );
    }

    setMaxValues() {
        const newValues = this.levels()[this.state.base.level];

        this.setState(
            {
                base : {
                    ...this.state.base,
                    factories: {
                        gold: {
                            ...this.state.base.factories.gold,
                            max: newValues.base.factory.gold,
                            incomeRadius: {
                                min: this.state.base.factories.gold.incomeRadius.min + (this.state.base.factories.gold.current > 1 ? .001 * this.state.base.factories.gold.current : 0),
                                max: this.state.base.factories.gold.incomeRadius.max + (this.state.base.factories.gold.current > 1 ? .024 * this.state.base.factories.gold.current : 0),
                            }
                        },
                        lumber: {
                            ...this.state.base.factories.lumber,
                            max: newValues.base.factory.lumber,
                            incomeRadius: {
                                min: this.state.base.factories.lumber.incomeRadius.min + (this.state.base.factories.lumber.current > 1 ? .001 * this.state.base.factories.lumber.current : 0),
                                max: this.state.base.factories.lumber.incomeRadius.max + (this.state.base.factories.lumber.current > 1 ? .123 * this.state.base.factories.lumber.current : 0),
                            }
                        },
                    },
                },
                residents: {
                    villagers: {
                        ...this.state.residents.villagers,
                        max: Math.ceil(newValues.residents.villages),
                    },
                    warriors: {
                        ...this.state.residents.warriors,
                        max: Math.ceil(newValues.residents.warriors),
                    },
                },
                resources: {
                    gold: {
                        ...this.state.resources.gold,
                        max: Math.ceil(this.state.base.factories.gold.current * 4.45),
                    },
                    lumber: {
                        ...this.state.resources.lumber,
                        max: Math.ceil(this.state.base.factories.lumber.current * 2.54)
                    },
                },
            }
        );
    }

    componentDidMount() {
        this.setMaxValues();
        this.updateFrame(
            [
                this.income
            ]
        );
    }

    render() {
        return <Body
            background={false}
        >
            <h1>The Gatherer</h1>
            <StyledGameContainer>
                <StyledStatusContainer>
                    <table>
                        <tbody>
                            <tr><td colSpan={3}><strong>Base {this.state.base.name}</strong></td></tr>
                            <tr>
                                <StyledItemDescription>level</StyledItemDescription>
                                <td>:</td>
                                <td>{this.state.base.level}</td>
                            </tr>
                            <tr>
                                <StyledItemDescription>health points</StyledItemDescription>
                                <td>:</td>
                                <td>{this.state.base.hp.current} / {this.state.base.hp.max}</td>
                            </tr>
                            <tr>
                                <StyledItemDescription>houses</StyledItemDescription>
                                <td>:</td>
                                <td>{this.state.base.houses.current} / {this.state.base.houses.max}</td>
                            </tr>
                            <tr><td colSpan={3}>&nbsp;</td></tr>
                            <tr><td colSpan={3}><strong>Factories</strong></td></tr>
                            <tr>
                                <StyledItemDescription>gold</StyledItemDescription>
                                <td>:</td>
                                <td>{this.state.base.factories.gold.current} / {this.state.base.factories.gold.max}</td>
                            </tr>
                            <tr>
                                <StyledItemDescription>lumber</StyledItemDescription>
                                <td>:</td>
                                <td>{this.state.base.factories.lumber.current} / {this.state.base.factories.lumber.max}</td>
                            </tr>

                            <tr><td colSpan={3}>&nbsp;</td></tr>
                            <tr><td colSpan={3}><strong>Residents</strong></td></tr>
                            {
                                this.state.residents
                                && Object.keys(this.state.residents).map(
                                    (residentName, index) => {
                                        const residents = typeof this.state.residents[residentName] === `object`
                                            ? this.state.residents[residentName]
                                            : {current : 0, max : 0};

                                        return <tr
                                            key={index}
                                        >
                                            <StyledItemDescription>{residentName}</StyledItemDescription>
                                            <td>:</td>
                                            <td>{Number((residents.current || 0).toFixed(2))} / {residents.max || 0}</td>
                                        </tr>
                                    }
                                )
                            }
                            <tr><td colSpan={3}>&nbsp;</td></tr>
                            <tr><td colSpan={3}><strong>Resources</strong></td></tr>
                            {
                                this.state.resources
                                && Object.keys(this.state.resources).map(
                                    (resourceName, index) => {
                                        const resource = typeof this.state.resources[resourceName] === `object`
                                            ? this.state.resources[resourceName]
                                            : {current : 0, max : 0};

                                        return <tr
                                            key={index}
                                        >
                                            <StyledItemDescription>{resourceName}</StyledItemDescription>
                                            <td>:</td>
                                            <td>{Number((resource.current || 0).toFixed(2))} / {resource.max || 0}</td>
                                        </tr>
                                    }
                                )
                            }
                            <tr><td colSpan={3}>&nbsp;</td></tr>
                            <tr>
                                <td>Next income</td>
                                <td>:</td>
                                <td>{this.state.nextIncome.seconds}</td>
                            </tr>
                        </tbody>
                    </table>
                </StyledStatusContainer>
                <button
                    onClick={()=>this.levelUpBase()}
                >Test Button!</button>
                <button
                    onClick={()=>this.levelUpFactory(`gold`)}
                >buy gold factory Button!</button>
                <button
                    onClick={()=>this.levelUpFactory(`lumber`)}
                >buy lumber factory Button!</button>
                <button
                    onClick={()=>this.setState({base: {...this.state.base, level: 0}}, () => this.setMaxValues())}
                >Reset Button!</button>
            </StyledGameContainer>
        </Body>;
    }
}

const page: pageType = {
    name  : `The Gatherer`,
    link  : [`/games/gatherer`],
    page  : <PageBody />,
    props : {
        menu    : false,
        enabled : utils.storage.get(`test`) === `2957aeb927300d2cf9d5f0bdf39e7448`
    }
};

export default page;