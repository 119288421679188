import React from "react";
import {pageType} from "../../pages";
import Base from "./base";

const url = `/converter/volume`;
const page: pageType = {
    name: `Volume`,
    link: [url, `${url}/*`],
    page: <Base
        title={`Volume`}
        url={url}
        steps={10}
        defaultValues={
            {
                from  : `ml`,
                to    : `ml`,
                value : 1,
            }
        }
        allowableUnits={
            {
                'ml'       : `milliliter / cc`,
                'cl'       : `centiliter`,
                'dl'       : `deciliter`,
                'l'        : `liter`,
                '--empty--': `--empty--`,
                'hl'       : `hectoliter`,
                'kl'       : `kiloliter / kuub / cubic meter`,
            }
        }
    />,
    props: {
        menu: false
    },
};

export default page;