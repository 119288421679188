import React                               from 'react';
import Book, {maxPageChunks} from "../../../../../../../../component/bookshelf/book";
import {pagesType, pageType} from "../../../../../../../../pages";
import * as Images from "../../../../../../../../asset/guide/windows/server/2012";

const link  = `/guides/windows/server/2012/raf/remote-access/second-nic`;
let   pages: pagesType = [
    {
        title: `Youtube video`,
        pages: [
            {
                text: <p>In this roles and features guide we are going to configure the role "Remote Access" with the secondary network interface (NIC).</p>,
                youtubeIdFull: `BFvf5iebuBc`
            },
        ]
    },
    {
        title: `VMware Network editor`,
        pages: [
            {
                text: <p>Click on "Edit" and on "Virtual Network Editor...".</p>,
                imageSrc: Images.RolesAndFeatures.remoteAccess.VMWareVirtualNetworkEditor
            },
            {
                text: <p>Click on "Change Settings".</p>,
                imageSrc: Images.RolesAndFeatures.remoteAccess.ChangeSettings
            },
            {
                text: <p>Click on "Yes" to proceed.</p>,
                imageSrc: Images.RolesAndFeatures.remoteAccess.WindowsUserAccountControl
            },
            {
                text: <p>Click on "Add Network...".</p>,
                imageSrc: Images.RolesAndFeatures.remoteAccess.AddNetwork
            },
            {
                text: <p>Select an available virtual network and click on "Ok".</p>,
                imageSrc: Images.RolesAndFeatures.remoteAccess.AddVirtualNetwork
            },
            {
                text: <div>
                    <p>Select the virtual network you've just added.</p>
                    <p>Change the following:</p>
                    <ul>
                        <li>Select "NAT".</li>
                        <li>Uncheck "Use Local DHCP service to distribute IP address to VMs".</li>
                        <li>Change "Subnet IP:" (to desired IP address scope), in this guide i'll use "192.168.82.0".</li>
                    </ul>
                    <p>Click on "NAT Settings...".</p>
                </div>,
                imageSrc: Images.RolesAndFeatures.remoteAccess.NetworkSettings
            },
            {
                text: <div>
                    <p>Change the Gateway IP to the same IP address scope if you've changed it.</p>
                    <p>Since i've changed it, i change the gateway IP to "192.168.82.2".</p>
                    <p>Click on "Ok".</p>
                </div>,
                imageSrc: Images.RolesAndFeatures.remoteAccess.NATSettings
            },
            {
                text: <p>Click on "Ok" to proceed.</p>,
                imageSrc: Images.RolesAndFeatures.remoteAccess.VirtualNetworkEditor
            },
            {
                text: <p>Click on "Edit virtual machine settings".</p>,
                imageSrc: Images.RolesAndFeatures.remoteAccess.EditVirtualMachineSettings
            },
            {
                text: <p>Click on "Add...".</p>,
                imageSrc: Images.RolesAndFeatures.remoteAccess.VirtualMachineAdd
            },
            {
                text: <p>Select "Network Adapter" and click on "Finish".</p>,
                imageSrc: Images.RolesAndFeatures.remoteAccess.SelectNetworkAdapter
            },
            {
                text: <div>
                    <p>Select the added Network Adapter (2).</p>
                    <p>Select "Custom: Specific virtual network".</p>
                    <p>Select the added virtual network (in this guide it's "VMnet2").</p>
                    <p>Click on "Ok".</p>
                </div>,
                imageSrc: Images.RolesAndFeatures.remoteAccess.CustomSpecificVirtualNetwork
            },
            {
                text: <p>Click on "Power on this virtual machine".</p>,
                imageSrc: Images.RolesAndFeatures.remoteAccess.StartVirtualMachine
            }
        ]
    },
    {
        title: `Second network interface (NIC)`,
        pages: [
            {
                text: <p>Sign in the server with the administrator account.</p>,
                imageSrc: Images.SignInDomain
            },
            {
                text: <p>Open the quick menu by pressing key combination "windows key" + "x" and click on "Control panel".</p>,
                imageSrc: Images.QuickMenu
            },
            {
                text: <p>Click on "Network and Internet".</p>,
                imageSrc: Images.ControlPanel
            },
            {
                text: <p>Click on "Network and Sharing Center".</p>,
                imageSrc: Images.ControlPanelNetwork
            },
            {
                text: <p>Click on "Change adapter settings".</p>,
                imageSrc: Images.ControlPanelNetworkAndSharingCenter
            },
            {
                text: <div>
                    <p>Right click on the new network adapter and click on "Rename".</p>
                    <p>Rename the NIC (Network Interface) to "Internet".</p>
                </div>,
                imageSrc: Images.ControlPanelNetworkNICRename
            },
            {
                text: <p>Right click again on the NIC (Network Interface) and click on "Properties".</p>,
                imageSrc: Images.RolesAndFeatures.remoteAccess.NICInternetProperties
            },
            {
                text: <p>Select "Internet Protocol Version 4 (TCP/IPv4)" and click on "Properties".</p>,
                imageSrc: Images.NICProperties
            },
            {
                text: <div>
                    <p>Select "Use the following IP address:" and fill in the following (if you picked another IP address scope, use that scope!).</p>
                    <ul>
                        <li>IP address: 192.168.81.5 (yes, this is correct. We use the 192.168.81.x cause we want to route it later on that network.)</li>
                        <li>Subnet mask: 255.255.255.0</li>
                        <li>Default Gateway: 192.168.82.2 (yes, this should be the same as from the virtual network)</li>
                    </ul>
                    <p>Select "Use the following DNS server addresses:"</p>
                    <ul>
                        <li>Preferred DNS Server: 192.168.82.2 (yes, this should be the same as the default gateway)</li>
                    </ul>
                    <p>Click on "Ok" to proceed.</p>
                </div>,
                imageSrc: Images.RolesAndFeatures.remoteAccess.NICPropertiesIPV4
            },
            {
                text: <div>
                    <p>Cause the IP address and gateway are not in the same network segment windows will give this message.</p>
                    <p>Because this is a virtual machine it's fine and click on "Yes".</p>
                    <p>If this pops up on a barebone server, this is not a good sign.</p>
                    <p>In case of a barebone server use default gateway empty and use DNS 8.8.8.8 or 8.8.4.4 (DNS IP addresses of google).</p>
                </div>,
                imageSrc: Images.RolesAndFeatures.remoteAccess.NICPropertiesIPV4Properties
            },
            {
                text: <p>Right click on the Internet NIC and click on "Status"</p>,
                imageSrc: Images.RolesAndFeatures.remoteAccess.NICInternetStatus
            },
            {
                text: <p>The NIC status says that there is no internet access, but actually that's not true. Click on "Close".</p>,
                imageSrc: Images.RolesAndFeatures.remoteAccess.NICInternetStatusSummary
            }
        ]
    },
    {
        title: `Command prompt`,
        pages: [
            {
                text: <p>Open the quick menu by pressing key combination "windows key" + "x" and click on "Run".</p>,
                imageSrc: Images.RolesAndFeatures.remoteAccess.QuickMenuRun
            },
            {
                text: <p>Type "cmd" and click on "Ok".</p>,
                imageSrc: Images.RolesAndFeatures.remoteAccess.RunCMD
            },
            {
                text: <div>
                    <p>Type "ping 8.8.8.8" and press enter. If there is a internet connection you've should have a reply.</p>
                    <p>It will ping 4 times (by default) and gives a little summary.</p>
                    <p>If the "loss" is 0% it's good, that means we have a connection to the internet.</p>
                </div>,
                imageSrc: Images.RolesAndFeatures.remoteAccess.PingDNSGoogle
            },
            {
                text: <div>
                    <p>Type "ping www.google.nl" and press enter. It's possible that this doesn't work. But it should work.</p>
                    <p>Same as the previous step "If the "loss" is 0% it's good" and the reverse lookup of the internet works too.</p>
                </div>,
                imageSrc: Images.RolesAndFeatures.remoteAccess.PingGoogle
            }
        ]
    },
    {
        title: `Browser internet`,
        pages: [
            {
                text: <p>Go to the right bottom corner, wait for about 2 seconds and you should see "Start" and click.</p>,
                imageSrc: Images.StartMenu
            },
            {
                text: <p>Click on "Internet explorer".</p>,
                imageSrc: Images.RolesAndFeatures.remoteAccess.InternetExplorer
            },
            {
                text: <p>Close the "Windows Internet explorer 10" message.</p>,
                imageSrc: Images.RolesAndFeatures.remoteAccess.SetupInternetExplorer
            },
            {
                text: <p>Fill in a website, for example "www.google.nl" and press enter.</p>,
                imageSrc: Images.RolesAndFeatures.remoteAccess.EnterUrl
            },
            {
                text: <p>Click on "Ok" on the "Security alert" message.</p>,
                imageSrc: Images.RolesAndFeatures.remoteAccess.SecurityAlert
            },
            {
                text: <div>
                    <p>By default every website is blocked on Windows Server.</p>
                    <p>So we need to add the website to "Trusted Sites". We can do this by just go to the website.</p>
                    <p>A window pops up with 2 buttons "Close" and "Add...". Click on "Add..."</p>
                </div>,
                imageSrc: Images.RolesAndFeatures.remoteAccess.InternetExplorerServer
            },
            {
                text: <p>Click on "Add" and close all the screens.</p>,
                imageSrc: Images.RolesAndFeatures.remoteAccess.TrustedSite
            },
            {
                text: <p>The internet connection seems to work.</p>,
                imageSrc: Images.RolesAndFeatures.remoteAccess.Site
            },
            {
                text: <p>Congratulations, you've just added a second NIC and working Internet connection! &#127881;</p>,
            }
        ]
    },
];

const links: string[] = [];
const lengthPages: number = (pages.map(
    (p) => {
        const modulo = p.pages.length % maxPageChunks;
        return p.pages.length + (modulo !== 0 ? maxPageChunks - (p.pages.length % maxPageChunks) : 0);
    }
).reduce((a,b)=>a+b) / (maxPageChunks * 2)) + 1;
for (let i=0;i<=lengthPages;i++) {
    links.push(`${link}/${i}`);
}

const page: pageType = {
    name: `Windows Server 2012`,
    link: [
        link,
        ...links,
    ],
    page: <Book
        logo={Images.Logo}
        preTitle={`Windows Server 2012`}
        title={`Roles and Features`}
        subTitle={`Remote Access: second NIC`}
        link={link}
        links={links}
        pages={pages}
    />,
    props: {
        menu: true
    }
};

export default page;