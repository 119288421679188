import React           from "react";
import styled          from 'styled-components';
import Body            from "../../component/body";
import { pageType }    from "../../pages";
import { storage, ws } from "../../utils";
import {Form, Input}   from "../../component/form";
import { Navigate }    from "react-router-dom";
import Link from "../../component/link";
import {Register as RegisterPage} from "../account";

const StyledContainer = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    justify-content: center;
    align-self: center;
`;
const StyledContainerContents = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    justify-self: center;
    align-self: center;
    padding: 1rem 4rem;
    border-radius: .3rem;
    width: 40%;
    min-width: 20rem;
    max-width: 30rem;
    background-color: var(--color-background-box-overlay);
`;

interface Props {
}
interface State {
    redirect?: string
    username?: string;
    password?: string;
    submit: {
        error?: string
        success: boolean
    };
    fieldRef: {
        username: React.RefObject<Input>;
        password: React.RefObject<Input>;
        submit  : React.RefObject<Input>;
    }
}

const redirect = `/home`;
class Page extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            fieldRef: {
                username : React.createRef<Input>(),
                password : React.createRef<Input>(),
                submit   : React.createRef<Input>(),
            },
            submit: {
                error  : undefined,
                success: false
            }
        };
    }

    componentDidMount() {
        ws.listener.message(
            [`account.login`],
            data => {
                switch (data.code) {
                    case 200:
                        if (
                            typeof data.results === `object`
                            && data.results.token !== undefined
                        ) {
                            storage.account.set.key(`username`, data.results.username);
                            storage.account.set.key(`avatar`, data.results.avatar);
                            storage.account.set.key(`token`, data.results.token);
                            storage.account.set.key(`validTo`, data.results.validTo);
                            ws.sent(
                                `account`,
                                `verify`,
                                {
                                    token : data.results.token
                                },
                            );
                        }
                        break;
                    case 406:
                        this.setState(
                            {
                                submit: {
                                    error   : data.results.error !== null ? data.results.error : undefined,
                                    success : false
                                }
                            },
                            () => this.state.fieldRef.username.current?.getFocus()
                        );
                        break;
                    default:
                        break;
                }
            }
        );
    }

    change(e: KeyboardEvent, key: `username` | `password`) {
        const keyEnterPressed = () => {
            if (
                e.hasOwnProperty(`key`)
                && e.key.toLowerCase() === `enter`
            ) {
                const mapper = {
                    'username' : this.state.fieldRef.password,
                    'password' : this.state.fieldRef.submit,
                };

                if (mapper.hasOwnProperty(key)) {
                    if (key === `password`) {
                        this.state.username === undefined || this.state.username.length < 4
                            ? this.state.fieldRef.username.current?.getFocus()
                            : this.state.fieldRef.submit.current?.click();
                    } else {
                        mapper[key]?.current?.getFocus();
                    }
                }
            }
        };

        const value  = (e.target as HTMLInputElement).value;
        const update = {
            username: false,
            password: false,
        };

        update[key] = value !== undefined && this.state[key] !== value;
        if (Object.values(update).filter(v=>v).length > 0) {
            this.setState(
                {
                    username: update.username ? value : this.state.username,
                    password: update.password ? value : this.state.password
                },
                () => this.setState(
                    {
                        submit: {
                            success: this.state.username !== undefined
                                && this.state.username.length > 3
                                && this.state.password !== undefined
                                && this.state.password.length > 3
                        }
                    },
                    () => keyEnterPressed()
                )
            );
        }

        keyEnterPressed();
    }

    render() {
        return <Body background={false}>
            {this.state.redirect !== undefined && <Navigate replace to={this.state.redirect}/>}
            <StyledContainer>
                <StyledContainerContents>
                    <h1>Login</h1>
                    <Form>
                        <Input
                            ref={this.state.fieldRef.username}
                            type={`text`}
                            placeholder={`Username`}
                            onBlur={(e: KeyboardEvent) => this.change(e, `username`)}
                            onKeyUp={(e: KeyboardEvent) => this.change(e, `username`)}
                            errorEnabled={false}
                        />
                        <Input
                            ref={this.state.fieldRef.password}
                            type={`password`}
                            placeholder={`Password`}
                            onBlur={(e: KeyboardEvent) => this.change(e, `password`)}
                            onKeyUp={(e: KeyboardEvent) => this.change(e, `password`)}
                            errorEnabled={false}
                        />
                        <Input
                            ref={this.state.fieldRef.submit}
                            type={`submit`}
                            placeholder={`login`}
                            error={this.state.submit.error}
                            success={this.state.submit.success}
                            onClick={
                                () => this.setState(
                                    {
                                        submit: {
                                            success: false
                                        }
                                    },
                                    () => ws.sent(
                                        `account`,
                                        `login`,
                                        {
                                            username: this.state.username,
                                            password: this.state.password,
                                        },
                                    )
                                )
                            }
                        />
                    </Form>
                    {
                        RegisterPage.props?.enabled === false
                            ? <p></p>
                            : <p>Don't have an account yet? <Link onClick={()=>this.setState({redirect: `/register`})}>Sign up</Link> now!</p>
                    }
                </StyledContainerContents>
            </StyledContainer>
        </Body>;
    }
}

const page: pageType = {
    name  : `login`,
    link  : [`/login`],
    page  : <Page />,
    props : {
        enabled  : false,
        menu     : false,
        login    : false,
        redirect : redirect,
    },
};

export default page;