import Adds from './adds';
import DHCP from './dhcp';
import DNS from './dns';
import RemoteAccess from './remote_access';

const RolesAndFeatures = [
    ...Adds,
    ...DHCP,
    ...DNS,
    ...RemoteAccess,
];

export default RolesAndFeatures;