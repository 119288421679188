import React                               from 'react';
import Book, {maxPageChunks} from "../../../../../../../component/bookshelf/book";
import {pagesType, pageType} from "../../../../../../../pages";
import * as Images from "../../../../../../../asset/guide/windows/server/2012";

const link  = `/guides/windows/server/2012/disk/add`;
let   pages: pagesType = [
    {
        title: `Youtube video`,
        pages: [
            {
                text: <p>In this guide we are going to add a virtual disk and make it available for the server.</p>,
                youtubeIdFull: `1gIs817n1BA`
            },
        ]
    },
    {
        title: `Add new virtual hard disk`,
        pages: [
            {
                text: <p>Click on "Edit virtual machine settings".</p>,
                imageSrc: Images.Disk.EditVirtualMachineSettings
            },
            {
                text: <p>Click on "Add...".</p>,
                imageSrc: Images.Disk.VirtualMachineAdd
            },
            {
                text: <p>Select "Hard Disk" and click on "Next &gt;".</p>,
                imageSrc: Images.Disk.HardwareTypeHardDisk
            },
            {
                text: <p>Leave the selection "SCSI" and click on "Next &gt;".</p>,
                imageSrc: Images.Disk.DiskTypeSCSI
            },
            {
                text: <p>Leave the selection on "Create a new virtual disk" and click on "Next &gt;" to proceed.</p>,
                imageSrc: Images.Disk.NewVirtualDisk
            },
            {
                text: <p>Fill in the capacity of the new disk and leave the selection "Split virtual disk into multiple files". In this guide i'll use 50GB</p>,
                imageSrc: Images.Disk.DiskCapacity
            },
            {
                text: <p>Leave the disk file location and click on "Finish".</p>,
                imageSrc: Images.Disk.DiskLocation
            },
            {
                text: <p>Click on "Ok" in the virtual machine settings.</p>,
                imageSrc: Images.Disk.VirtualMachine
            },
            {
                text: <p>Click on "Power on this virtual machine".</p>,
                imageSrc: Images.Disk.StartVirtualMachine
            }
        ]
    },
    {
        title: `Bring disk online`,
        pages: [
            {
                text: <p>Sign in.</p>,
                imageSrc: Images.SignInDomain
            },
            {
                text: <p>Click on "File and Storage services" in the server manager.</p>,
                imageSrc: Images.Disk.FileAndStorageServices
            },
            {
                text: <p>Click on "Disks", right click on the second disk, click on "Bring Online".</p>,
                imageSrc: Images.Disk.DiskBringOnline
            },
            {
                text: <p>Click on "Yes", since this is a new added disk and not in use by any other server.</p>,
                imageSrc: Images.Disk.CausesDataLoss
            },
            {
                text: <p>Congratulations, you've just added a new Hard Disk! &#127881;</p>,
            }
        ]
    },
];

const links: string[] = [];
const lengthPages: number = (pages.map(
    (p) => {
        const modulo = p.pages.length % maxPageChunks;
        return p.pages.length + (modulo !== 0 ? maxPageChunks - (p.pages.length % maxPageChunks) : 0);
    }
).reduce((a,b)=>a+b) / (maxPageChunks * 2)) +1;
for (let i=0;i<=lengthPages;i++) {
    links.push(`${link}/${i}`);
}

const page: pageType = {
    name: `Windows Server 2012`,
    link: [
        link,
        ...links,
    ],
    page: <Book
        logo={Images.Logo}
        preTitle={`Windows Server 2012`}
        title={`Disk`}
        subTitle={`Add new disk`}
        link={link}
        links={links}
        pages={pages}
    />,
    props: {
        menu: true
    }
};

export default page;