import React  from 'react';
import styled from 'styled-components';
import Image  from "./index";

const StyledImage = styled(Image)`
    --image-width: 15rem;

    max-width: var(--image-width);
    height: auto;

    transition: all .2s ease-in-out;
    
    @media only screen and (min-width: 900px) {
        --image-width: 5rem;
    }
    @media only screen and (min-width: 1200px) and (min-height: 700px) {
        --image-width: 10rem;
    }
    @media only screen and (min-width: 1500px) and (min-height: 1100px) {
        --image-width: 15rem;
    }
    @media only screen and (min-width: 1800px) and (min-height: 1400px) {
        --image-width: 20rem;
    }
    @media only screen and (min-width: 1800px) and (min-height: 1700px) {
        --image-width: 25rem;
    }
    @media only screen and (min-width: 3000px) and (min-height: 2000px) {
        --image-width: 40rem;
    }
`;

interface Props {
    src: string;
    flat?: boolean;
    [key: string]: any;
}

interface State {
    show: boolean;
}

class GuideImage extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            show: true
        };
    }

    componentDidMount() {
        window.addEventListener(
            `resize`,
            () => {
                this.setState(
                    {
                        show: this.props.flat || (window.innerHeight > 950 && window.innerWidth > 800)
                    }
                )
                console.log(`resize window`, this.props.flat);
            }
        );
    }

    render() {
        return this.state.show
            ? <StyledImage
                alt={``}
                {...this.props}
            />
            : undefined;
    }
}

export default GuideImage;