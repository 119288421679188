import React from 'react';
import styled from 'styled-components';

const StyledBody = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    padding: 1rem 2rem;
    white-space: pre-line;
    margin: auto;
`;
const StyledBodyBackground = styled(StyledBody)`
    background-color: var(--color-background-box-overlay);
`;

const Body = (
    {
        background = true,
        children
    }: {
        background?: boolean,
        children: React.ReactNode
    }
) => {
    const StyledBodyElement = background
        ? StyledBodyBackground
        : StyledBody;

    return <StyledBodyElement>
        {children}
    </StyledBodyElement>
};

export default Body;