import React        from "react";
import styled       from 'styled-components';
import Body         from "../../../../component/body";
import { pageType } from "../../../../pages";
import {Navigate} from "react-router-dom";
import * as utils from "../../../../utils";
import TablePrices from "./overview/tablePrices";

import BarAndLineChart, {chartBarAndLine} from "../../../../component/chart/bar_and_line";
import {chartType} from "../../../../component/chart";
import Checkbox from "../../../../component/form/checkbox";

const StyledContainer          = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    justify-content: center;
    align-self: center;
    min-width: 50rem;
`;
const StyledContainerContents  = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    justify-self: center;
    align-self: center;
    padding: 1rem 4rem;
    border-radius: .3rem;
    width: 100%;
    background-color: var(--color-background-box-overlay);
`;

type usageElectricType = {
    offPeak: number;
    peak: number;
    total: number;
};

type electricType = {
    used: usageElectricType;
    return: usageElectricType;
    total: usageElectricType;
};
type usageType = {
    electric: electricType,
    gas: number;
};
type statsType = {
    previous: usageType;
    current: usageType;
    usage: usageType;
};

type priceVatType = {
    vatPercentage: number;
    exclusive: number;
    inclusive: number;
    vatExclusive: number;
    vatInclusive: number;
};

type totalPricesType = {
    electricPrices: {
        base: {
            offPeak: priceVatType;
            peak: priceVatType;
            usedTotal: priceVatType;
            returnOffPeak: priceVatType;
            returnPeak: priceVatType;
            returnTotal: priceVatType;
        };
        fixed: {
            delivery: priceVatType;
            gridManagement: priceVatType;
            reduction: priceVatType;
            vat: priceVatType;
        };
        ode: priceVatType;
        price: {
            offPeak: priceVatType;
            peak: priceVatType;
            usedTotal: priceVatType;
            returnOffPeak: priceVatType;
            returnPeak: priceVatType;
            returnTotal: priceVatType;
            fixedTotal: priceVatType;
        };
        taxOnElectricity: priceVatType;
        value: {
            offPeak: number;
            peak: number;
            usedTotal: number;
            returnOffPeak: number;
            returnPeak: number;
            returnTotal: number;
        };
        returnThresholdReached: boolean;
        vatPercentage: number;
    };
    gasPrices: {
        base: {
            gas: priceVatType;
        };
        fixed: {
            delivery: priceVatType;
            gridManagement: priceVatType;
            vat: priceVatType;
        };
        ode: priceVatType;
        price: {
            fixedTotal: priceVatType;
            gas: priceVatType;
        };
        taxOnGas: priceVatType;
        value: {
            gas: number;
        };
        vatPercentage: number;
    };
    fixedPrices: {
        delivery: priceVatType;
        gridManagement: priceVatType;
        reduction: priceVatType;
        vat: priceVatType;
        total: priceVatType;
    };
    totalFixed: priceVatType;
    totalPrice: priceVatType;
};


export type dataType = {
    creation: string;
    datetime: string;
    year: number;
    month: number;
    day: number;
    hour: number;
    minute: number;
    stats: statsType;
    totalPrices: totalPricesType;
};

export type yearType = {
    creation: string;
    datetime: string;
    year: number;
    month?: number;
    day?: number;
    hour?: number;
    minute?: number;
    months: {[yearMonth: string]: dataType};
    stats: statsType;
    totalPrices: totalPricesType;
};
export type monthType = {
    creation: string;
    datetime: string;
    year: number;
    month: number;
    day?: number;
    hour?: number;
    minute?: number;
    days: {[date: string]: dataType};
    stats: statsType;
    totalPrices: totalPricesType;
};
export type dayType = {
    creation: string;
    datetime: string;
    year: number;
    month: number;
    day: number;
    hour?: number;
    minute?: number;
    hours: {[dateHour: string]: dataType};
    stats: statsType;
    totalPrices: totalPricesType;
};
export type hourType = {
    creation: string;
    datetime: string;
    year: number;
    month: number;
    day: number;
    hour: number;
    minute?: number;
    minutes: {[dateHourMinute: string]: dataType};
    stats: statsType;
    totalPrices: totalPricesType;
};
export type minuteType = {
    creation: string;
    datetime: string;
    year: number;
    month: number;
    day: number;
    hour: number;
    minute: number;
    stats: statsType;
    totalPrices: totalPricesType;
};

interface Props {
}
interface State {
    redirect?: string;
    keepTrackDate: boolean;
    view : `year` | `month` | `day` | `hour`;
    unit : `value` | `price`,
    decimals: {
        price: number,
        units: number,
    };
    date : Date;
    dateNextDisabled : boolean;
    payments         : {
        year  : undefined | number,
        month : undefined | number,
        day   : undefined | number,
        hour  : undefined | number,
        minute: undefined | number,
    };
    types :{
        electricty: {
            used: boolean;
            return: boolean;
        };
        gas: boolean;
        fixed: boolean;
        total: boolean;
    };
    isRequestLocked: boolean;
    isLoop: boolean;
    isLoopStopped: boolean;
    year: number;
    month: number;
    day: number;
    hour: number;
    data : {
        year: {
            [year: number]: yearType | dataType
        };
        month: {
            [yearMonth: string]: monthType | dataType
        };
        day: {
            [yearMonthDay: string]: dayType | dataType
        };
        hour: {
            [yearMonthDayHour: string]: hourType | dataType
        };
        minute: {
            [yearMonthDayHourMinute: string]: minuteType | dataType
        };
    };
    chart: {
        value: chartBarAndLine,
        price: chartBarAndLine,
    }
}

const redirect  = `/home`;

class Page extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        const today = new Date();
        const keepTrackDate = utils.storage.account.fusebox.get(`trackDate`) || false;
        let   datetime = keepTrackDate
            ? new Date()
            : utils.datetime.createFromString(utils.storage.account.fusebox.get(`date`));

        if (
            datetime?.toString().toLowerCase() === 'invalid date'
            || !(datetime instanceof Date)
        ) {
            datetime = new Date();
        }

        if (datetime.getTime() >= today.getTime()) {
            datetime = today;
        }

        const types = {
            electricity: {
                used: utils.storage.account.fusebox.get(`types.electricity.used`),
                return: utils.storage.account.fusebox.get(`types.electricity.return`),
            },
            gas: utils.storage.account.fusebox.get(`types.gas`),
            fixed: utils.storage.account.fusebox.get(`types.fixed`),
            total: utils.storage.account.fusebox.get(`types.total`),
        };

        this.state = {
            redirect : undefined,
            keepTrackDate: utils.storage.account.fusebox.get(`trackDate`) || false,
            view     : utils.storage.account.fusebox.get(`view`) || `day`,
            unit     : utils.storage.account.fusebox.get(`unit`) || `value`,
            decimals : {
                price: utils.storage.account.fusebox.get(`decimals.price`) || 2,
                units: utils.storage.account.fusebox.get(`decimals.units`) || 0,
            },
            date             : datetime,
            dateNextDisabled : datetime.getTime() >= today.getTime(),
            payments         : {
                year  : undefined,
                month : undefined,
                day   : undefined,
                hour  : undefined,
                minute: undefined,
            },
            types: {
                electricty: {
                    used  : [true, false].includes(types.electricity.used) ? types.electricity.used : true,
                    return: [true, false].includes(types.electricity.return) ? types.electricity.return : true,
                },
                gas       : [true, false].includes(types.gas) ? types.gas : true,
                fixed     : [true, false].includes(types.fixed) ? types.fixed : true,
                total     : [true, false].includes(types.total) ? types.total : true,
            },
            isRequestLocked  : false,
            isLoop           : false,
            isLoopStopped    : true,
            year             : datetime.getFullYear(),
            month            : datetime.getMonth() + 1,
            day              : datetime.getDate(),
            hour             : datetime.getHours(),
            data             : {
                year   : {},
                month  : {},
                day    : {},
                hour   : {},
                minute : {},
            },
            chart: {
                value: {
                    bar  : [
                        {
                            label: `January`,
                            data: [
                                {
                                    primary: 0,
                                    secondary: 0
                                }
                            ]
                        },
                    ],
                    line : [
                        {
                            label: `Electric used`,
                            data: [
                                {
                                    primary: 0,
                                    secondary: 0
                                }
                            ]
                        },
                    ],
                },
                price: {
                    bar  : [
                        {
                            label: `January`,
                            data: [
                                {
                                    primary: 0,
                                    secondary: 0
                                }
                            ]
                        },
                    ],
                    line : [
                        {
                            label: `Electric used`,
                            data: [
                                {
                                    primary: 0,
                                    secondary: 0
                                }
                            ]
                        },
                    ],
                },
            }
        };
    }

    componentDidMount() {
        utils.ws.listener.message(
            [`account.financial.payments`],
            d => {
                if (d.code === 200) {
                    const prices: {[interval: string]: undefined | number} = d.results.prices || {};

                    this.setState(
                        {
                            payments: {
                                year  : prices.year || undefined,
                                month : prices.month || undefined,
                                day   : prices.day || undefined,
                                hour  : prices.hour || undefined,
                                minute: prices.minute || undefined,
                            }
                        }
                    );
                }
            }
        );
        utils.ws.listener.message(
            [`account.home.usage.overview.year`],
            d => {
                if (d.code === 200) {
                    const data = d.results;
                    const yearsData: yearType = {
                        creation    : data.hasOwnProperty(`creation`) ? data.creation : null,
                        datetime    : data.hasOwnProperty(`datetime`) ? data.datetime : null,
                        year        : data.hasOwnProperty(`year`) ? data.year : null,
                        months      : data.hasOwnProperty(`months`) ? data.months : {},
                        stats       : data.hasOwnProperty(`stats`) ? data.stats : {},
                        totalPrices : data.hasOwnProperty(`totalPrices`) ? data.totalPrices : {},
                    };

                    const today = utils.datetime.getTodayDate();

                    if (
                        this.state.keepTrackDate
                        && this.state.date.getTime() !== today.getTime()
                    ) {
                        this.changeDateData(today);
                    }

                    this.setState(
                        {
                            isRequestLocked : false,
                            data: {
                                ...this.state.data,
                                year: {
                                    ...this.state.data.year,
                                    [yearsData.year]: yearsData
                                },
                                month: {
                                    ...this.state.data.month,
                                    ...yearsData.months,
                                }
                            }
                        },
                        () => this.changeChart(
                            `month`,
                            Object.values(yearsData.months)
                                .map(
                                    (data) => this.objectToDataType(data)
                                )
                        )
                    );
                }
            }
        );
        utils.ws.listener.message(
            [`account.home.usage.overview.month`],
            d => {
                if (d.code === 200) {
                    const data = d.results;
                    const monthsData: monthType = {
                        creation    : data.hasOwnProperty(`creation`) ? data.creation : null,
                        datetime    : data.hasOwnProperty(`datetime`) ? data.datetime : null,
                        year        : data.hasOwnProperty(`year`) ? data.year : null,
                        month       : data.hasOwnProperty(`month`) ? data.month : null,
                        days        : data.hasOwnProperty(`days`) ? data.days : {},
                        stats       : data.hasOwnProperty(`stats`) ? data.stats : {},
                        totalPrices : data.hasOwnProperty(`totalPrices`) ? data.totalPrices : {},
                    };

                    const today = utils.datetime.getTodayDate();

                    if (
                        this.state.keepTrackDate
                        && this.state.date.getTime() !== today.getTime()
                    ) {
                        this.changeDateData(today);
                    }

                    this.setState(
                        {
                            isRequestLocked : false,
                            data: {
                                ...this.state.data,
                                month: {
                                    ...this.state.data.month,
                                    [`${monthsData.year}-${data.month}`]: monthsData
                                },
                                day: {
                                    ...this.state.data.day,
                                    ...monthsData.days,
                                }
                            }
                        },
                        () => this.changeChart(
                            `day`,
                            Object.values(monthsData.days)
                                .map(
                                    (data) => this.objectToDataType(data)
                                )
                        )
                    );
                }
            }
        );
        utils.ws.listener.message(
            [`account.home.usage.overview.day`],
            d => {
                if (d.code === 200) {
                    const data = d.results;
                    const daysData: dayType = {
                        creation    : data.hasOwnProperty(`creation`) ? data.creation : null,
                        datetime    : data.hasOwnProperty(`datetime`) ? data.datetime : null,
                        year        : data.hasOwnProperty(`year`) ? data.year : null,
                        month       : data.hasOwnProperty(`month`) ? data.month : null,
                        day         : data.hasOwnProperty(`day`) ? data.day : null,
                        hours       : data.hasOwnProperty(`hours`) ? data.hours : {},
                        stats       : data.hasOwnProperty(`stats`) ? data.stats : {},
                        totalPrices : data.hasOwnProperty(`totalPrices`) ? data.totalPrices : {},
                    };

                    const today = utils.datetime.getTodayDate();

                    if (
                        this.state.keepTrackDate
                        && this.state.date.getTime() !== today.getTime()
                    ) {
                        this.changeDateData(today);
                    }

                    this.setState(
                        {
                            isRequestLocked : false,
                            data: {
                                ...this.state.data,
                                day: {
                                    ...this.state.data.day,
                                    [`${daysData.year}-${data.month}-${data.day}`]: daysData
                                },
                                hour: {
                                    ...this.state.data.hour,
                                    ...daysData.hours,
                                }
                            }
                        },
                        () => this.changeChart(
                            `hour`,
                            Object.values(daysData.hours)
                                .map(
                                    (data) => this.objectToDataType(data)
                                )
                        )
                    );
                }
            }
        );
        utils.ws.listener.message(
            [`account.home.usage.overview.hour`],
            d => {
                if (d.code === 200) {
                    const data = d.results;
                    const hoursData: hourType = {
                        creation    : data.hasOwnProperty(`creation`) ? data.creation : null,
                        datetime    : data.hasOwnProperty(`datetime`) ? data.datetime : null,
                        year        : data.hasOwnProperty(`year`) ? data.year : null,
                        month       : data.hasOwnProperty(`month`) ? data.month : null,
                        day         : data.hasOwnProperty(`day`) ? data.day : null,
                        hour        : data.hasOwnProperty(`hour`) ? data.hour : null,
                        minutes     : data.hasOwnProperty(`minutes`) ? data.minutes : {},
                        stats       : data.hasOwnProperty(`stats`) ? data.stats : {},
                        totalPrices : data.hasOwnProperty(`totalPrices`) ? data.totalPrices : {},
                    };

                    const today = utils.datetime.getTodayDate();

                    if (
                        this.state.keepTrackDate
                        && this.state.date.getTime() !== today.getTime()
                    ) {
                        this.changeDateData(today);
                    }

                    this.setState(
                        {
                            isRequestLocked : false,
                            data: {
                                ...this.state.data,
                                hour: {
                                    ...this.state.data.hour,
                                    [`${hoursData.year}-${hoursData.month}-${hoursData.day} ${hoursData.hour}`]: hoursData
                                },
                                minute: {
                                    ...this.state.data.minute,
                                    ...hoursData.minutes,
                                }
                            }
                        },
                        () => this.state.hour === hoursData.hour
                            && this.changeChart(
                            `minute`,
                            Object.values(hoursData.minutes)
                                .map(
                                    (data) => this.objectToDataType(data)
                                )
                        )
                    );
                }
            }
        );

        utils.ws.connect();
        setTimeout(
            () => this.update(
                this.state.view === `year`,
                this.state.view === `month`,
                this.state.view === `day`,
                this.state.view === `hour`,
                true,
            ),
            500
        );
    }

    getLoop(
        yearChanged: boolean = false,
        monthChanged: boolean = false,
        dayChanged: boolean = false,
        hourChanged: boolean = false
    ) {
        return {
            year  : yearChanged  && this.state.year === utils.datetime.create().getFullYear(),
            month : monthChanged && this.state.year === utils.datetime.create().getFullYear() && this.state.month === (utils.datetime.create().getMonth() + 1),
            day   : dayChanged   && this.state.year === utils.datetime.create().getFullYear() && this.state.month === (utils.datetime.create().getMonth() + 1) && this.state.day === utils.datetime.create().getDate(),
            hour  : hourChanged  && this.state.year === utils.datetime.create().getFullYear() && this.state.month === (utils.datetime.create().getMonth() + 1) && this.state.day === utils.datetime.create().getDate() && this.state.hour === utils.datetime.create().getHours(),
        };
    }

    update(yearChanged: boolean = false, monthChanged: boolean = false, dayChanged: boolean = false, hourChanged: boolean = false, payments: boolean = false) {
        const loop = this.getLoop(yearChanged, monthChanged, dayChanged, hourChanged);

        (yearChanged
        || monthChanged
        || dayChanged
        || hourChanged)
        && this.setState(
            {
                isRequestLocked: true,
                isLoop: Object.values(loop).includes(true),
                isLoopStopped: !Object.values(loop).includes(true)
            },
            () => {
                !this.state.isLoop && !this.state.isLoopStopped && this.setState({isLoopStopped: true}, () => utils.ws.sent(`loop`, `stop`, {}))

                yearChanged
                && utils.ws.sent(
                    `account`,
                    `home.usage.overview.year`,
                    {
                        year : this.state.year,
                        loop : loop.year
                    },
                );

                monthChanged
                && utils.ws.sent(
                    `account`,
                    `home.usage.overview.month`,
                    {
                        year  : this.state.year,
                        month : this.state.month,
                        loop  : loop.month
                    },
                );

                dayChanged
                && utils.ws.sent(
                    `account`,
                    `home.usage.overview.day`,
                    {
                        year  : this.state.year,
                        month : this.state.month,
                        day   : this.state.day,
                        loop  : loop.day
                    },
                );

                hourChanged
                && utils.ws.sent(
                    `account`,
                    `home.usage.overview.hour`,
                    {
                        year  : this.state.year,
                        month : this.state.month,
                        day   : this.state.day,
                        hour  : this.state.hour,
                        loop  : loop.hour
                    },
                );
            }
        );

        payments = payments
            || yearChanged
            || monthChanged
            || dayChanged
            || hourChanged;

        payments = false; // ToDo: remove this!

        const today     = utils.datetime.create();
        const isChanged = {
            'year'  : today.getFullYear() !== this.state.date.getFullYear(),
            'month' : utils.datetime.formattedDate(today, `Y-m`) !== utils.datetime.formattedDate(this.state.date, `Y-m`),
            'day'   : utils.datetime.formattedDate(today, `Y-m-d`) !== utils.datetime.formattedDate(this.state.date, `Y-m-d`),
            'hour'  : utils.datetime.formattedDate(today, `Y-m-d H`) !== utils.datetime.formattedDate(this.state.date, `Y-m-d H`),
        }[this.state.view];

        const isCurrentYear = payments && today.getFullYear() === this.state.date.getFullYear();

        if (payments) {
            console.log(
                `payments`,
                this.state.year,
                isCurrentYear,
                utils.datetime.formattedDate(
                    new Date(
                        isCurrentYear ? utils.datetime.create().getFullYear() : this.state.year,
                        0,
                        1
                    )
                ),
                utils.datetime.formattedDate(
                    new Date(
                        isCurrentYear ? utils.datetime.create().getFullYear() : this.state.year + 1,
                        isCurrentYear ? utils.datetime.create().getMonth() : 0,
                        isCurrentYear ? utils.datetime.create().getDate() : 1,
                    )
                ),
            ); // ToDo: when changing the date, also change the payments, only when it's applied to!
        }

        payments
        && isChanged
        && this.setState(
            {
                payments : {
                    year  : undefined,
                    month : undefined,
                    day   : undefined,
                    hour  : undefined,
                    minute: undefined,
                }
            },
            () => utils.ws.sent(
                `account`,
                `financial.payments`,
                {
                    provider : `greenchoice`,
                    start    : utils.datetime.formattedDate(
                        new Date(
                            isCurrentYear ? utils.datetime.create().getFullYear() : this.state.year,
                            0,
                            1
                        ),
                        `Y-m-d`
                    ),
                    stop     : utils.datetime.formattedDate(
                        new Date(
                            isCurrentYear ? utils.datetime.create().getFullYear() : this.state.year + 1,
                            isCurrentYear ? utils.datetime.create().getMonth() : 0,
                            isCurrentYear ? utils.datetime.create().getDate() : 1,
                        ),
                        `Y-m-d`
                    ),
                },
            )
        );
    }

    changeChart(
        interval: `month` | `day` | `hour` | `minute`,
        data: dataType[]
    ) {
        const getLabel = (data: dataType) => {
            const labelInterval = Object.keys(data).length > 0
                ? interval
                : ``;

            return {
                'month'  : utils.datetime.getMonthName(new Date(data?.year, data?.month - 1, 1)) || `January`,
                'day'    : [data?.year, data?.month, data?.day].join(`-`),
                'hour'   : `${data?.hour}`,
                'minute' : `${data?.minute}`,
                '' : ``,
            }[labelInterval];
        };

        if (data.length === 0) {
            const electric = {
                offPeak: 0,
                peak   : 0,
                total  : 0,
            };
            const priceVat = {
                vatPercentage: 0,
                exclusive    : 0,
                inclusive    : 0,
                vatExclusive : 0,
                vatInclusive : 0,
            };
            const empty = {
                usage           : {
                    electric: {
                        used  : electric,
                        return: electric,
                        total : electric,
                    },
                    gas     : 0,
                },
                electricPriceVat: {
                    offPeak      : priceVat,
                    peak         : priceVat,
                    usedTotal    : priceVat,
                    returnOffPeak: priceVat,
                    returnPeak   : priceVat,
                    returnTotal  : priceVat,
                },
                electric        : {
                    offPeak      : 0,
                    peak         : 0,
                    usedTotal    : 0,
                    returnOffPeak: 0,
                    returnPeak   : 0,
                    returnTotal  : 0,
                },
            };
            data.push(
                {
                    creation   : ``,
                    datetime   : ``,
                    year       : this.state.year,
                    month      : this.state.month,
                    day        : this.state.day,
                    hour       : this.state.hour,
                    minute     : 0,
                    stats      : {
                        previous: empty.usage,
                        current : empty.usage,
                        usage   : empty.usage,
                    },
                    totalPrices: {
                        electricPrices: {
                            base                  : empty.electricPriceVat,
                            fixed                 : {
                                delivery      : priceVat,
                                gridManagement: priceVat,
                                reduction     : priceVat,
                                vat           : priceVat,
                            },
                            ode                   : priceVat,
                            price                 : {
                                ...empty.electricPriceVat,
                                fixedTotal: priceVat,
                            },
                            taxOnElectricity      : priceVat,
                            value                 : empty.electric,
                            returnThresholdReached: false,
                            vatPercentage         : 0
                        },
                        gasPrices     : {
                            base         : {gas: priceVat},
                            fixed        : {
                                delivery      : priceVat,
                                gridManagement: priceVat,
                                vat           : priceVat,
                            },
                            ode          : priceVat,
                            price        : {
                                fixedTotal: priceVat,
                                gas       : priceVat,
                            },
                            taxOnGas     : priceVat,
                            value        : {gas: 0},
                            vatPercentage: 0
                        },
                        fixedPrices   : {
                            delivery      : priceVat,
                            gridManagement: priceVat,
                            reduction     : priceVat,
                            vat           : priceVat,
                            total         : priceVat,
                        },
                        totalFixed    : priceVat,
                        totalPrice    : priceVat,
                    },
                }
            );
        }

        const chart: {valueLine: chartType[], priceLine: chartType[]} = {
            valueLine: [],
            priceLine: [],
        };

        if (this.state.types.electricty.used || (!this.state.types.electricty.used && !this.state.types.electricty.return && !this.state.types.gas)) {
            chart.valueLine.push({
                label : `Electric used`,
                data  : data.map(
                    (chartData) => {
                        return {
                            primary   : getLabel(chartData),
                            secondary : chartData?.totalPrices?.electricPrices?.value?.usedTotal || 0,
                        };
                    }
                )
            });
            chart.priceLine.push({
                label : `Electric used`,
                data  : data.map(
                    (chartData) => {
                        return {
                            primary   : getLabel(chartData),
                            secondary : chartData?.totalPrices?.electricPrices?.price?.usedTotal?.inclusive || 0,
                        };
                    }
                )
            });
        }
        if (this.state.types.electricty.return || (!this.state.types.electricty.used && !this.state.types.electricty.return && !this.state.types.gas)) {
            chart.valueLine.push({
                label : `Electric return`,
                data  : data.map(
                    (chartData) => {
                        return {
                            primary   : getLabel(chartData),
                            secondary : chartData?.totalPrices?.electricPrices?.value?.returnTotal || 0,
                        };
                    }
                )
            });
            chart.priceLine.push({
                label : `Electric return`,
                data  : data.map(
                    (chartData) => {
                        return {
                            primary   : getLabel(chartData),
                            secondary : chartData?.totalPrices?.electricPrices?.price?.returnTotal?.inclusive || 0,
                        };
                    }
                )
            });
        }
        if (this.state.types.gas || (!this.state.types.electricty.used && !this.state.types.electricty.return && !this.state.types.gas)) {
            chart.valueLine.push({
                label : `Gas used`,
                data  : data.map(
                    (chartData) => {
                        return {
                            primary   : getLabel(chartData),
                            secondary : chartData?.totalPrices?.gasPrices?.value?.gas || 0,
                        };
                    }
                )
            });
            chart.priceLine.push({
                label : `Gas used`,
                data  : data.map(
                    (chartData) => {
                        return {
                            primary   : getLabel(chartData),
                            secondary : chartData?.totalPrices?.gasPrices?.price?.gas?.inclusive || 0,
                        };
                    }
                )
            });
        }
        if (this.state.types.fixed || (!this.state.types.electricty.used && !this.state.types.electricty.return && !this.state.types.gas && !this.state.types.fixed && !this.state.types.total)) {
            chart.priceLine.push({
                label : `Fixed`,
                data  : data.map(
                    (chartData) => {
                        return {
                            primary   : getLabel(chartData),
                            secondary : chartData?.totalPrices?.fixedPrices?.total?.exclusive || 0,
                        };
                    }
                )
            });
        }
        if (this.state.types.total || (!this.state.types.electricty.used && !this.state.types.electricty.return && !this.state.types.gas && !this.state.types.fixed && !this.state.types.total)) {
            chart.priceLine.push({
                label : `Total`,
                data  : data.map(
                    (chartData) => {
                        return {
                            primary   : getLabel(chartData),
                            secondary : chartData?.totalPrices?.totalPrice?.inclusive || 0,
                        };
                    }
                )
            });
        }

        this.setState(
            {
                isLoop: Object.values(this.getLoop(
                    interval === `month`,
                    interval === `day`,
                    interval === `hour`,
                    interval === `minute`
                )).includes(true),
                chart: {
                    value: {
                        bar  : data.map(
                            (chartData) => {
                                const dataChart = [];
                                if (this.state.types.electricty.used || (!this.state.types.electricty.used && !this.state.types.electricty.return && !this.state.types.gas)) {
                                    dataChart.push({ primary: `Electric used`,   secondary: chartData?.totalPrices?.electricPrices?.value?.usedTotal || 0, });
                                }
                                if (this.state.types.electricty.return || (!this.state.types.electricty.used && !this.state.types.electricty.return && !this.state.types.gas)) {
                                    dataChart.push({ primary: `Electric return`, secondary: chartData?.totalPrices?.electricPrices?.value?.returnTotal || 0, });
                                }
                                if (this.state.types.gas || (!this.state.types.electricty.used && !this.state.types.electricty.return && !this.state.types.gas)) {
                                    dataChart.push({ primary: `Gas used`,        secondary: chartData?.totalPrices?.gasPrices?.value?.gas || 0, });
                                }

                                return {
                                    label : getLabel(chartData),
                                    data  : dataChart
                                };
                            },
                        ),
                        line : chart.valueLine,
                    },
                    price: {
                        bar  : data.map(
                            (chartData) => {
                                const dataChart = [];
                                if (this.state.types.electricty.used || (!this.state.types.electricty.used && !this.state.types.electricty.return && !this.state.types.gas && !this.state.types.fixed && !this.state.types.total)) {
                                    dataChart.push({ primary: `Electric used`,   secondary: chartData?.totalPrices?.electricPrices?.price?.usedTotal?.inclusive || 0, });
                                }
                                if (this.state.types.electricty.return || (!this.state.types.electricty.used && !this.state.types.electricty.return && !this.state.types.gas && !this.state.types.fixed && !this.state.types.total)) {
                                    dataChart.push({ primary: `Electric return`, secondary: chartData?.totalPrices?.electricPrices?.price?.returnTotal?.inclusive || 0, });
                                }
                                if (this.state.types.gas || (!this.state.types.electricty.used && !this.state.types.electricty.return && !this.state.types.gas && !this.state.types.fixed && !this.state.types.total)) {
                                    dataChart.push({ primary: `Gas used`,        secondary: chartData?.totalPrices?.gasPrices?.price?.gas?.inclusive || 0, });
                                }
                                if (this.state.types.fixed || (!this.state.types.electricty.used && !this.state.types.electricty.return && !this.state.types.gas && !this.state.types.fixed && !this.state.types.total)) {
                                    dataChart.push({ primary: `Fixed`,           secondary: chartData?.totalPrices?.fixedPrices?.total?.exclusive || 0, });
                                }
                                if (this.state.types.total || (!this.state.types.electricty.used && !this.state.types.electricty.return && !this.state.types.gas && !this.state.types.fixed && !this.state.types.total)) {
                                    dataChart.push({ primary: `Total`,           secondary: chartData?.totalPrices?.totalPrice?.inclusive || 0, });
                                }

                                return {
                                    label : getLabel(chartData),
                                    data  : dataChart,
                                };
                            },
                        ),
                        line : chart.priceLine,
                    }
                }
            },
            () => !this.state.isLoop && !this.state.isLoopStopped && this.setState({isLoopStopped: true}, () => utils.ws.sent(`loop`, `stop`, {}))
        );
    }

    objectToDataType(obj: monthType | dayType | hourType | minuteType): dataType {
        return {
            creation   : obj?.creation || ``,
            datetime   : obj?.datetime || ``,
            year       : obj?.year || 0,
            month      : obj?.month || 0,
            day        : obj?.day || 0,
            hour       : obj?.hour || 0,
            minute     : obj?.minute || 0,
            stats      : obj?.stats || {},
            totalPrices: obj?.totalPrices || {},
        };
    }

    changeView() {
        if (this.state.view === `year`) {
            if (
                !this.state.data.year.hasOwnProperty(this.state.year)
                || this.getLoop(true).year
                || Object.keys(this.state.data.month).filter((datetime) => datetime.includes(`${this.state.year}`)).length === 0
            ) {
                this.update(true);
            } else {
                const months: dataType[] = [];
                for(let i=1; i<=12; i++) {
                    months.push(this.objectToDataType(this.state.data.month[`${this.state.year}-${i}`]));
                }

                this.changeChart(
                    `month`,
                    months
                );
            }
        }

        if (this.state.view === `month`) {
            const date = `${this.state.year}-${this.state.month}`;
            if (
                !this.state.data.month.hasOwnProperty(date)
                || this.getLoop(false, true).month
                || Object.keys(this.state.data.day).filter((datetime) => datetime.includes(date)).length === 0
            ) {
                this.update(false, true);
            } else {
                const days: dataType[] = [];
                const monthLastDay = utils.datetime.getLastDate(new Date(this.state.year, this.state.month, 1)).getDate();
                if (monthLastDay > 1) {
                    for(let i=1; i<=monthLastDay; i++) {
                        days.push(this.objectToDataType(this.state.data.day[`${date}-${i}`]));
                    }
                }

                this.changeChart(
                    `day`,
                    days
                );
            }
        }

        if (this.state.view === `day`) {
            const date = `${this.state.year}-${this.state.month}-${this.state.day}`;

            if (
                !this.state.data.day.hasOwnProperty(date)
                || this.getLoop(false, false, true).day
                || Object.keys(this.state.data.hour).filter((datetime) => datetime.includes(date)).length === 0
            ) {
                this.update(false, false, true);
            } else {
                const hours: dataType[] = [];
                for(let i=1; i<=23; i++) {
                    hours.push(this.objectToDataType(this.state.data.hour[`${date} ${i}`]));
                }

                this.changeChart(
                    `hour`,
                    hours
                );
            }
        }

        if (this.state.view === `hour`) {
            const datetime = `${this.state.year}-${this.state.month}-${this.state.day} ${this.state.hour}`;

            if (
                !this.state.data.hour.hasOwnProperty(datetime)
                || this.getLoop(false, false, false, true).hour
                || Object.keys(this.state.data.minute).filter((dateHourMinute) => dateHourMinute.includes(`${datetime}:`)).length === 0
            ) {
                this.update(false, false, false, true);
            } else {
                const minutes: dataType[] = [];
                for(let i=1; i<=59; i++) {
                    minutes.push(this.objectToDataType(this.state.data.minute[`${datetime}:${i}`]));
                }

                this.changeChart(
                    `minute`,
                    minutes
                );
            }
        }
    }

    changeViewData(
        view?: `year` | `month` | `day` | `hour`
    ) {
        if (view !== undefined && this.state.view !== view) {
            this.setState(
                {
                    view     : view,
                    payments : {
                        year  : undefined,
                        month : undefined,
                        day   : undefined,
                        hour  : undefined,
                        minute: undefined,
                    }
                },
                () => {
                    utils.storage.account.fusebox.set.key(`view`, view);
                    this.changeView();
                }
            );
            return;
        }

        this.changeView();
    }

    changeDateData(
        date?: Date
    ) {
        if (date === undefined) {
            date = this.state.date;
        }

        const today          = utils.datetime.create();
        let nextDateDisabled = false;
        if (date.getTime() > today.getTime()) {
            date = today;
            nextDateDisabled = true;
        }

        const intervals: {
            [key: string] : `month` | `day` | `hour` | `minute`
        } = {
            'year' : `month`,
            'month': `day`,
            'day'  : `hour`,
            'hour' : `minute`,
        };

        this.changeChart(
            intervals[this.state.view],
            []
        );

        this.setState(
            {
                date             : date,
                dateNextDisabled : nextDateDisabled,
                year             : date.getFullYear(),
                month            : date.getMonth() + 1,
                day              : date.getDate(),
                hour             : date.getHours(),
            },
            () => {
                utils.storage.account.fusebox.set.key(`date`, utils.datetime.formattedDate(date));
                this.changeView();
            }
        )
    }

    changeKeepTrackDate(state: boolean = false) {
        this.setState(
            {
                keepTrackDate: state
            },
            () => {
                utils.storage.account.fusebox.set.key(`trackDate`, state);
                this.changeDateData(this.state.keepTrackDate ? new Date() : this.state.date);
            }
        );
    }

    render() {
        return <Body background={false}>
            {this.state.redirect !== undefined && <Navigate replace to={this.state.redirect} />}

            <StyledContainer>
                <StyledContainerContents>
                    <h1>Overview</h1>
                    <div>
                        <table>
                            <tbody>
                                <tr>
                                    <td>
                                        Date
                                    </td>
                                    <td colSpan={3}>
                                        <div>
                                            <button
                                                onClick={
                                                    () => {
                                                        this.state.view === `year`  && this.changeDateData(utils.datetime.addYear(-1, this.state.date));
                                                        this.state.view === `month` && this.changeDateData(utils.datetime.addMonth(-1, this.state.date));
                                                        this.state.view === `day`   && this.changeDateData(utils.datetime.addDay(-1, this.state.date));
                                                        this.state.view === `hour`  && this.changeDateData(utils.datetime.addHour(-1, this.state.date));
                                                        this.changeKeepTrackDate(false);
                                                    }
                                                }
                                                disabled={this.state.isRequestLocked}
                                            >
                                                {`<<`}
                                            </button>
                                            <input
                                                type={`date`}
                                                value={utils.datetime.formattedDate(this.state.date, `Y-m-d`)}
                                                onChange={
                                                    (e) => {
                                                        const currentElement = e.currentTarget as HTMLInputElement;
                                                        const date           = new Date(currentElement.value);
                                                        date !== null && this.changeDateData(date);
                                                    }
                                                }
                                                disabled={this.state.isRequestLocked}
                                            />
                                            {
                                                this.state.view === `hour`
                                                && <input
                                                    type={`number`}
                                                    value={utils.datetime.formattedDate(this.state.date, `H`)}
                                                    step={1}
                                                    min={0}
                                                    max={utils.datetime.formattedDate(utils.datetime.create(), `Y-m-d`) === utils.datetime.formattedDate(this.state.date, `Y-m-d`) ? utils.datetime.create().getHours() : 23}
                                                    onChange={
                                                        (e) => {
                                                            const currentElement = e.currentTarget as HTMLInputElement;
                                                            const date           = new Date(this.state.date);
                                                            if (date !== null) {
                                                                date.setHours(Number(currentElement.value));
                                                                this.changeDateData(date);
                                                            }
                                                        }
                                                    }
                                                    disabled={this.state.isRequestLocked}
                                                />
                                            }
                                            <button
                                                onClick={
                                                    () => {
                                                        this.state.view === `year`  && this.changeDateData(utils.datetime.addYear(1, this.state.date));
                                                        this.state.view === `month` && this.changeDateData(utils.datetime.addMonth(1, this.state.date));
                                                        this.state.view === `day`   && this.changeDateData(utils.datetime.addDay(1, this.state.date));
                                                        this.state.view === `hour`  && this.changeDateData(utils.datetime.addHour(1, this.state.date));
                                                        this.changeKeepTrackDate(false);
                                                    }
                                                }
                                                disabled={this.state.isRequestLocked || this.state.dateNextDisabled || utils.datetime.formattedDate(this.state.date, `Y-m-d`) === utils.datetime.formattedDate(new Date(), `Y-m-d`)}
                                            >
                                                {`>>`}
                                            </button>
                                            <button
                                                onClick={
                                                    () => this.state.date !== utils.datetime.create() && this.changeDateData(utils.datetime.create())
                                                }
                                                disabled={this.state.isRequestLocked || this.state.dateNextDisabled || utils.datetime.formattedDate(this.state.date, `Y-m-d`) === utils.datetime.formattedDate(new Date(), `Y-m-d`)}
                                            >
                                                {
                                                    {
                                                        'year' : `Current year`,
                                                        'month': `Current month`,
                                                        'day'  : `Today`,
                                                        'hour' : `Now`,
                                                    }[this.state.view]
                                                }
                                            </button>
                                        </div>
                                    </td>
                                    <td>
                                        <Checkbox
                                            context={`Keep current day`}
                                            title={`If this page is opened this day and this day is passed, then the next day will be used for retrieving data.`}
                                            checked={this.state.keepTrackDate}
                                            onChange={()=>{}}
                                            onClick={
                                                () => {
                                                    this.setState(
                                                        {
                                                            keepTrackDate: !this.state.keepTrackDate,
                                                        },
                                                        () => this.changeKeepTrackDate(this.state.keepTrackDate)
                                                    );
                                                }
                                            }
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td>Decimals Price</td>
                                    <td colSpan={3}>
                                        <div>
                                            <button
                                                onClick={
                                                    () => this.setState({decimals:{...this.state.decimals, price: this.state.decimals.price - 1}}, () => utils.storage.account.fusebox.set.key(`decimals.price`, this.state.decimals.price))
                                                }
                                                disabled={this.state.decimals.price === 0}
                                            >
                                                {`<<`}
                                            </button>
                                            <input
                                                type={`number`}
                                                value={this.state.decimals.price}
                                                min={0}
                                                max={7}
                                                onChange={
                                                    (e) => this.setState(
                                                        {
                                                            decimals: {
                                                                ...this.state.decimals,
                                                                price: (e.currentTarget as HTMLInputElement).valueAsNumber || 0
                                                            }
                                                        },
                                                        () => utils.storage.account.fusebox.set.key(`decimals.price`, this.state.decimals.price)
                                                    )
                                                }
                                            />
                                            <button
                                                onClick={
                                                    () => this.setState({decimals:{...this.state.decimals, price: this.state.decimals.price + 1}}, () => utils.storage.account.fusebox.set.key(`decimals.price`, this.state.decimals.price))
                                                }
                                                disabled={this.state.decimals.price >= 7}
                                            >
                                                {`>>`}
                                            </button>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Decimals Units</td>
                                    <td colSpan={3}>
                                        <div>
                                            <button
                                                onClick={
                                                    () => this.setState({decimals:{...this.state.decimals, units: this.state.decimals.units - 1}}, () => utils.storage.account.fusebox.set.key(`decimals.units`, this.state.decimals.units))
                                                }
                                                disabled={this.state.decimals.units === 0}
                                            >
                                                {`<<`}
                                            </button>
                                            <input
                                                type={`number`}
                                                value={this.state.decimals.units}
                                                min={0}
                                                max={7}
                                                onChange={
                                                    (e) => this.setState(
                                                        {
                                                            decimals: {
                                                                ...this.state.decimals,
                                                                units: (e.currentTarget as HTMLInputElement).valueAsNumber || 0
                                                            }
                                                        },
                                                        () => utils.storage.account.fusebox.set.key(`decimals.units`, this.state.decimals.units)
                                                    )
                                                }
                                            />
                                            <button
                                                onClick={
                                                    () => this.setState({decimals:{...this.state.decimals, units: this.state.decimals.units + 1}}, () => utils.storage.account.fusebox.set.key(`decimals.units`, this.state.decimals.units))
                                                }
                                                disabled={this.state.decimals.units >= 7}
                                            >
                                                {`>>`}
                                            </button>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>View</td>
                                    <td>
                                        <div>
                                            <button disabled={this.state.isRequestLocked} onClick={() => this.changeViewData(`year`)} style={{backgroundColor: this.state.view === `year` ? `rgba(75,150,75, .4)` : `unset`}} >Year</button>
                                            <button disabled={this.state.isRequestLocked} onClick={() => this.changeViewData(`month`)} style={{backgroundColor: this.state.view === `month` ? `rgba(75,150,75, .4)` : `unset`}} >Month</button>
                                            <button disabled={this.state.isRequestLocked} onClick={() => this.changeViewData(`day`)} style={{backgroundColor: this.state.view === `day` ? `rgba(75,150,75, .4)` : `unset`}} >Day</button>
                                            <button disabled={this.state.isRequestLocked} onClick={() => this.changeViewData(`hour`)} style={{backgroundColor: this.state.view === `hour` ? `rgba(75,150,75, .4)` : `unset`}} >Hour</button>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Unit</td>
                                    <td>
                                        <div>
                                            <button onClick={() => this.state.unit !== `value` && this.setState({unit: `value`}, () => utils.storage.account.fusebox.set.key(`unit`, `value`))} style={{backgroundColor: this.state.unit === `value` ? `rgba(75,150,75, .4)` : `unset`}} >Value</button>
                                            <button onClick={() => this.state.unit !== `price` && this.setState({unit: `price`}, () => utils.storage.account.fusebox.set.key(`unit`, `price`))} style={{backgroundColor: this.state.unit === `price` ? `rgba(75,150,75, .4)` : `unset`}} >Price</button>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Types</td>
                                    <td>
                                        <div>
                                            <button onClick={() => this.setState({types: {...this.state.types, electricty: {...this.state.types.electricty, used: !this.state.types.electricty.used}}}, () => utils.storage.account.fusebox.set.key(`types.electricity.used`, this.state.types.electricty.used) && this.changeView())} style={{backgroundColor: this.state.types.electricty.used ? `rgba(75,150,75, .4)` : `unset`}} >Electricity Used</button>
                                            <button onClick={() => this.setState({types: {...this.state.types, electricty: {...this.state.types.electricty, return: !this.state.types.electricty.return}}}, () => utils.storage.account.fusebox.set.key(`types.electricity.return`, this.state.types.electricty.return) && this.changeView())} style={{backgroundColor: this.state.types.electricty.return ? `rgba(75,150,75, .4)` : `unset`}} >Electricity Return</button>
                                            <button onClick={() => this.setState({types: {...this.state.types, gas: !this.state.types.gas }}, () => utils.storage.account.fusebox.set.key(`types.gas`, this.state.types.gas) && this.changeView())} style={{backgroundColor: this.state.types.gas ? `rgba(75,150,75, .4)` : `unset`}} >Gas</button>
                                            {this.state.unit === `price` && <button onClick={() => this.setState({types: {...this.state.types, fixed: !this.state.types.fixed }}, () => utils.storage.account.fusebox.set.key(`types.fixed`, this.state.types.fixed) && this.changeView())} style={{backgroundColor: this.state.types.fixed ? `rgba(75,150,75, .4)` : `unset`}} >Fixed</button>}
                                            {this.state.unit === `price` && <button onClick={() => this.setState({types: {...this.state.types, total: !this.state.types.total }}, () => utils.storage.account.fusebox.set.key(`types.total`, this.state.types.total) && this.changeView())} style={{backgroundColor: this.state.types.total ? `rgba(75,150,75, .4)` : `unset`}} >Total</button>}
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td colSpan={7}>
                                        <div style={{minHeight:`1.3125rem`}}>
                                            {this.state.isRequestLocked ? `Loading...` : ``}
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <div>
                            <h1>
                                {this.state.view && this.state.view === `year` ? `Year: ${this.state.year}` : ``}
                                {this.state.view && this.state.view === `month` ? `Month: ${this.state.year}-${utils.z(this.state.month)} (${utils.datetime.getMonthName(new Date(this.state.year, this.state.month - 1, 1))})` : ``}
                                {this.state.view && this.state.view === `day` ? `Day: ${this.state.year}-${utils.z(this.state.month)}-${utils.z(this.state.day)} (${utils.datetime.getDayOfWeekName(new Date(this.state.year, this.state.month - 1, this.state.day))})` : ``}
                                {this.state.view && this.state.view === `hour` ? `Hour: ${this.state.year}-${utils.z(this.state.month)}-${utils.z(this.state.day)} ${utils.z(this.state.hour)}:00 <-> ${utils.z(this.state.hour)}:59 (${utils.datetime.getDayOfWeekName(new Date(this.state.year, this.state.month - 1, this.state.day))})` : ``}
                            </h1>
                            <div>
                                <BarAndLineChart
                                    chart={
                                        this.state.unit === `value` ? this.state.chart.value : this.state.chart.price
                                    }
                                />
                            </div>
                            {
                                this.state.view === `year`
                                && <TablePrices
                                    decimals={this.state.decimals}
                                    yearPayment={this.state.payments.year}
                                    data={this.state.data.year[this.state.year] ?? {}}
                                />
                            }
                            {
                                this.state.view === `month`
                                && <TablePrices
                                    decimals={this.state.decimals}
                                    yearPayment={this.state.payments.month}
                                    data={this.state.data.month[`${this.state.year}-${this.state.month}`] ?? {}}
                                />
                            }
                            {
                                this.state.view === `day`
                                && <TablePrices
                                    decimals={this.state.decimals}
                                    yearPayment={this.state.payments.day}
                                    data={this.state.data.day[`${this.state.year}-${this.state.month}-${this.state.day}`] ?? {}}
                                />
                            }
                            {
                                this.state.view === `hour`
                                && <TablePrices
                                    decimals={this.state.decimals}
                                    yearPayment={this.state.payments.hour}
                                    data={this.state.data.hour[`${this.state.year}-${this.state.month}-${this.state.day} ${this.state.hour}`] ?? {}}
                                />
                            }
                        </div>
                    </div>
                </StyledContainerContents>
            </StyledContainer>
        </Body>
    }
}

const page: pageType = {
    name  : `home usage overview`,
    link  : [`/home/usage/overview`],
    page  : <Page />,
    props : {
        enabled  : true,
        menu     : true,
        login    : false,
        redirect : redirect,
    },
};

export default page;