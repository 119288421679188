import React                               from 'react';
import Book, {maxPageChunks} from "../../../../../../../../component/bookshelf/book";
import {pagesType, pageType} from "../../../../../../../../pages";
import * as Images from "../../../../../../../../asset/guide/windows/server/2012";

const link  = `/guides/windows/server/2012/raf/dns/settings`;
let   pages: pagesType = [
    {
        title: `Youtube video`,
        pages: [
            {
                text: <p>In this roles and features guide we are going to configure the role "Domain Name System" (DNS).</p>,
                youtubeIdFull: `eYir-ezwSjg`
            },
        ]
    },
    {
        title: `Configure settings`,
        pages: [
            {
                text: <p>Click on "Tools" and on "DNS".</p>,
                imageSrc: Images.RolesAndFeatures.dns.ServerManagerDNS
            },
            {
                text: <p>Right click on the domain (in this case "agers.nl") and click on "Properties".</p>,
                imageSrc: Images.RolesAndFeatures.dns.DNSProperties
            },
            {
                text: <p>Click on "Change..." near "Replication: All DNS servers in this domain".</p>,
                imageSrc: Images.RolesAndFeatures.dns.DNSPropertiesReplication
            },
            {
                text: <p>Select "To all domain controllers in this domain (for Windows 2000 compatibility): agers.nl" (the domain at the end could be different for your domain). Click on "Ok" to proceed.</p>,
                imageSrc: Images.RolesAndFeatures.dns.DNSReplicationScope
            },
            {
                text: <div>
                    <p>Click on tab "Start of Authority (SOA)".</p>
                    <p>Change the following:</p>
                    <ul>
                        <li>Refresh interval: 15 seconds</li>
                        <li>Minimum (default) TTL: 10 seconds</li>
                        <li>TTL for this record: 0:0:0:10</li>
                    </ul>
                </div>,
                imageSrc: Images.RolesAndFeatures.dns.DNSStartOfAuthority
            },
            {
                text: <p>Click on tab "Name Servers", check if both servers are present and click on "Ok".</p>,
                imageSrc: Images.RolesAndFeatures.dns.DNSNameServers
            },
            {
                text: <p>Congratulations, you've just configured Domain Name System! &#127881;</p>,
            },
        ]
    },
];

const links: string[] = [];
const lengthPages: number = (pages.map(
    (p) => {
        const modulo = p.pages.length % maxPageChunks;
        return p.pages.length + (modulo !== 0 ? maxPageChunks - (p.pages.length % maxPageChunks) : 0);
    }
).reduce((a,b)=>a+b) / (maxPageChunks * 2));
for (let i=0;i<=lengthPages;i++) {
    links.push(`${link}/${i}`);
}

const page: pageType = {
    name: `Windows Server 2012`,
    link: [
        link,
        ...links,
    ],
    page: <Book
        logo={Images.Logo}
        preTitle={`Windows Server 2012`}
        title={`Roles and Features`}
        subTitle={`Domain Name System (DNS)`}
        link={link}
        links={links}
        pages={pages}
    />,
    props: {
        menu: true
    }
};

export default page;