import React                               from 'react';
import Book, {maxPageChunks} from "../../../../../../../component/bookshelf/book";
import {pagesType, pageType} from "../../../../../../../pages";
import * as Images from "../../../../../../../asset/guide/windows/server/2012";

const link  = `/guides/windows/server/2012/disk/previous-versions`;
let   pages: pagesType = [
    {
        title: `Youtube video`,
        pages: [
            {
                text: <p>In this guide we are going to configure "Shadow copy" (a previous version of a directory).</p>,
                youtubeIdFull: `7UkA_8htvRM`
            },
        ]
    },
    {
        title: `Enable Shadow copy`,
        pages: [
            {
                text: <p>Open up the explorer. Press "windows key" + "E".</p>,
                imageSrc: Images.Explorer
            },
            {
                text: <p>Right click on a volume and click on "Properties".</p>,
                imageSrc: Images.Disk.Properties
            },
            {
                text: <p>Click on tab "Shadow Copies".</p>,
                imageSrc: Images.Disk.TabShadowCopies
            },
            {
                text: <p>Select a volume and click on "Enable".</p>,
                imageSrc: Images.Disk.EnableForDiskE
            },
            {
                text: <p>Check "Do not show this message again" and click on "Yes".</p>,
                imageSrc: Images.Disk.EnableShadowCopyMessage
            }
        ]
    },
    {
        title: `Configure Shadow copy`,
        pages: [
            {
                text: <p>Click on "Settings...".</p>,
                imageSrc: Images.Disk.ShadowCopySettings1
            },
            {
                text: <div>
                    <p>Select "Use limit" and fill in a higher amount. This amount will determine how long or many previous versions will be available.</p>
                    <p>The higher the amount the move previous versions will be available, but also uses more disk space.</p>
                    <p>After changing the maximum size limit click on "Schedule...".</p>
                </div>,
                imageSrc: Images.Disk.ShadowCopySettings2
            },
            {
                text: <div>
                    <p>Delete the existing schedules or alter them to you liking.</p>
                    <p>Keep in mind that the more frequently you let create a previous version the more disk space will be used (until the maximum size limit is reached).</p>
                    <p>If the maximum size limit is reached, the older previous versions will not be available cause they will be deleted first.</p>
                    <p>After creating new schedules or alter the existing ones click on "Ok".</p>
                </div>,
                imageSrc: Images.Disk.ShadowCopySettings3
            },
            {
                text: <p>Click on "Ok" again.</p>,
                imageSrc: Images.Disk.ShadowCopySettings4
            },
            {
                text: <p>Apply for only 1 volume or some or all the volumes (only with the drive letter). After your done click on "Ok".</p>,
                imageSrc: Images.Disk.PropertiesOk
            }
        ]
    },
    {
        title: `Previous versions`,
        pages: [
            {
                text: <p>Right click on a volume and click on "Properties".</p>,
                imageSrc: Images.Disk.Properties2
            },
            {
                text: <p>Click on tab "Previous Versions", select the one entry and click on open (or double click on the entry) to open this previous version of the volume.</p>,
                imageSrc: Images.Disk.PreviousVersions
            },
            {
                text: <p>The first previous version is empty, cause we didn't place anything inside this volume. So close this previous version.</p>,
                imageSrc: Images.Disk.PreviousVersion
            },
            {
                text: <p>Open up the volume and create a new text file.</p>,
                imageSrc: Images.Disk.CreateNewFile
            },
            {
                text: <div>
                    <p>Rename the file or leave it as it is.</p>
                    <p>Recommended: put some text inside this text file.</p>
                </div>,
                imageSrc: Images.Disk.FileName
            },
            {
                text: <p>Right click on a volume and click on "Properties".</p>,
                imageSrc: Images.Disk.Properties2
            },
            {
                text: <div>
                    <p>Click on tab "Shadow Copies" and select the volume where you've created the text file.</p>
                    <p>Click on "Create Now", this will create a new previous version.</p>
                </div>,
                imageSrc: Images.Disk.ShadowCopyCreateNew
            },
            {
                text: <p>Click on tab "Previous Versions".</p>,
                imageSrc: Images.Disk.NewVersionCreated
            },
            {
                text: <div>
                    <p>See that there is still 1 previous version, but we create 2 in total.</p>
                    <p>Click on "Cancel" (the previous version tab isn't updated, therefore closing and opening it again will result in 2 previous versions as we suspected)</p>
                </div>,
                imageSrc: Images.Disk.PreviousVersion1
            },
            {
                text: <p>Right click on a volume and click on "Properties".</p>,
                imageSrc: Images.Disk.Properties2
            },
            {
                text: <p>Click on tab "Previous Versions". See that there are 2 previous versions, just like in the shadow copies tab. Open the most recent previous version.</p>,
                imageSrc: Images.Disk.PreviousVersion2
            },
            {
                text: <p>See that the text file is present in this previous version. Close this previous version.</p>,
                imageSrc: Images.Disk.PreviousVersionContents
            },
            {
                text: <p>Open up the current volume again and rename the text file (also the content).</p>,
                imageSrc: Images.Disk.RenameExistingFile
            },
            {
                text: <p>Right click on a volume and click on "Properties".</p>,
                imageSrc: Images.Disk.Properties2
            },
            {
                text: <p>Click on tab "Previous Versions" and open the most recent previous version.</p>,
                imageSrc: Images.Disk.PreviousVersion2
            },
            {
                text: <div>
                    <p>Check that the text file name isn't changed.</p>
                    <p>It's also possible to use the previous version (which is configured on the volume) to use on the files inside this volume (also subdirectories). The previous version can be restored to that state or copy individual files from the previous versions.</p>
                </div>,
                imageSrc: Images.Disk.PreviousVersionContents
            },
            {
                text: <p>Congratulations, you've just configured previous versions on the disk! &#127881;</p>,
            }
        ]
    },
];

const links: string[] = [];
const lengthPages: number = (pages.map(
    (p) => {
        const modulo = p.pages.length % maxPageChunks;
        return p.pages.length + (modulo !== 0 ? maxPageChunks - (p.pages.length % maxPageChunks) : 0);
    }
).reduce((a,b)=>a+b) / (maxPageChunks * 2)) +1;
for (let i=0;i<=lengthPages;i++) {
    links.push(`${link}/${i}`);
}

const page: pageType = {
    name: `Windows Server 2012`,
    link: [
        link,
        ...links,
    ],
    page: <Book
        logo={Images.Logo}
        preTitle={`Windows Server 2012`}
        title={`Disk`}
        subTitle={`Previous versions`}
        link={link}
        links={links}
        pages={pages}
    />,
    props: {
        menu: true
    }
};

export default page;