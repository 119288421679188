import React from 'react';
import Book, {maxPageChunks} from "../../../../../../../component/bookshelf/book";
import {pagesType, pageType} from "../../../../../../../pages";

const link  = `/guides/windows/client/11/installation`;
let   pages: pagesType = [
    {
        title: `Youtube video`,
        pages: [
            {
                text: <p>In this guide we are going to install a windows 11 (client).</p>,
                youtubeIdFull: `shLlUkWkybQ`
            },
        ]
    },
    {
        title: `Installation Windows 11`,
        pages: [
        ]
    },
    {
        title: `VMware tools`,
        pages: [
        ]
    },
    {
        title: `Change computer name`,
        pages: [
        ]
    },
    {
        title: `Verify computer name`,
        pages: [
        ]
    },
];

const links: string[] = [];
const lengthPages: number = (pages.map(
    (p) => {
        const modulo = p.pages.length % maxPageChunks;
        return p.pages.length + (modulo !== 0 ? maxPageChunks - (p.pages.length % maxPageChunks) : 0);
    }
).reduce((a,b)=>a+b) / (maxPageChunks * 2)) +1;
for (let i=0;i<=lengthPages;i++) {
    links.push(`${link}/${i}`);
}

const page: pageType = {
    name: `Windows 11`,
    link: [
        link,
        ...links,
    ],
    page: <Book
        preTitle={`Windows 11`}
        title={`Installation`}
        link={link}
        links={links}
        pages={pages}
    />,
    props: {
        enabled: false,
        menu: true
    }
};

export default page;