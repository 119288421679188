import React from 'react';
import Book, {maxPageChunks} from "../../../../../../../component/bookshelf/book";
import {pagesType, pageType} from "../../../../../../../pages";
import * as Images from "../../../../../../../asset/guide/windows/client/7";

const link  = `/guides/windows/client/7/domain/configure`;
let   pages: pagesType = [
    {
        title: `Youtube video`,
        pages: [
            {
                text: <p>In this guide we are going to add this client to the domain.</p>,
                youtubeIdFull: `ruPb4nqZrUU`
            },
        ]
    },
    {
        title: `Join Domain`,
        pages: [
            {
                text: <p>Click on "Start", right click on "Computer" and click on "Properties".</p>,
                imageSrc: Images.ComputerProperties
            },
            {
                text: <p>Click on "Change settings".</p>,
                imageSrc: Images.SystemChangeSettings
            },
            {
                text: <p>Click on tab "Computer Name" and click on "Change...".</p>,
                imageSrc: Images.PropertiesChange
            },
            {
                text: <p>Enter the domain name and click on "Ok". In this guide i'll use "agers".</p>,
                imageSrc: Images.Domain
            },
            {
                text: <div>
                    <p>Enter the domain credentials. Keep in mind that "agers\" in this example is used cause this is the domain for this guide.</p>
                    <p>If the domain name isn't before the user name, it could use the local account and will fail joining the domain (asking again for credentials).</p>
                </div>,
                imageSrc: Images.DomainCredentials
            },
            {
                text: <p>If the credentials are correct, this message pops up. Click on "Ok".</p>,
                imageSrc: Images.WelcomeToDomain
            },
            {
                text: <p>The client needs to reboot to apply the changes. Click on "Ok".</p>,
                imageSrc: Images.RebootToTakeEffect
            },
            {
                text: <p>Click on "Close".</p>,
                imageSrc: Images.PropertiesClose
            },
            {
                text: <p>Click on "Restart Now", cause we want to apply this change.</p>,
                imageSrc: Images.RestartToTakeEffect
            }
        ]
    },
    {
        title: `Verify joined domain`,
        pages: [
            {
                text: <p>Click on "Switch User".</p>,
                imageSrc: Images.SwitchUser
            },
            {
                text: <p>Click on "Other User".</p>,
                imageSrc: Images.OtherUser
            },
            {
                text: <div>
                    <p>Sign in with a domain account.</p>
                    <p>So in this guide for example "agers\administrator".</p>
                    <p>After filling in the credentials click on the arrow.</p>
                </div>,
                imageSrc: Images.SignInDomain
            },
            {
                text: <p>Click on "Start", right click on "Computer" and click on "Properties".</p>,
                imageSrc: Images.ComputerProperties
            },
            {
                text: <p>Click on "Change settings".</p>,
                imageSrc: Images.SystemChangeSettingDomain
            },
            {
                text: <p>Verify that the domain isn't "WORKGROUP" and actually the entered domain.</p>,
                imageSrc: Images.VerifyDomain
            },
            {
                text: <p>Congratulations, you've just joined a domain in Windows 7! &#127881;</p>,
            }
        ]
    },
];

const links: string[] = [];
const lengthPages: number = (pages.map(
    (p) => {
        const modulo = p.pages.length % maxPageChunks;
        return p.pages.length + (modulo !== 0 ? maxPageChunks - (p.pages.length % maxPageChunks) : 0);
    }
).reduce((a,b)=>a+b) / (maxPageChunks * 2)) +1;
for (let i=0;i<=lengthPages;i++) {
    links.push(`${link}/${i}`);
}

const page: pageType = {
    name: `Windows 7`,
    link: [
        link,
        ...links,
    ],
    page: <Book
        logo={Images.Logo}
        preTitle={`Windows 7`}
        title={`Domain`}
        subTitle={`Configure`}
        link={link}
        links={links}
        pages={pages}
    />,
    props: {
        menu: true
    }
};

export default page;