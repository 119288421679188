
const Color = {
    stringToColor: (value: string): string => {
        let hash = 0;
        value.split(``).forEach(
            (v: string, index: number) => hash = value.charCodeAt(index) + ((hash << 5) - hash)
        );

        return [
            `#`,
            ``,
            ``,
            ``,
        ].map(
            (h, index: number) => index === 0 ? h : `00${((hash >> (index - 1) * 8) & 0xFF).toString(16)}`.substr(-2)
        ).join(``);
    },
    calculateTemperatureColor: (
        minValue: number,
        maxValue: number,
        currentValue: number,
    ) => {
        const percentage = (currentValue - minValue) / (maxValue - minValue);
        const colorNumberByPercentage = Math.round(255 * (1 - percentage));

        const colors = {
            red  : 255,
            green: colorNumberByPercentage,
            blue : 0,
        };

        const thresholdNeutral = 217;
        if (currentValue > 8 && currentValue < 21) {
            if (currentValue < 21) {
                colors.red = Math.round(255 * (percentage/4));
                colors.blue = Math.round(255 * (percentage/4));
                colors.green = Math.round(255 * percentage);
            }

            if (currentValue < 16) {
                colors.red = thresholdNeutral;
                colors.green = thresholdNeutral;
                colors.blue = thresholdNeutral;
            }

        } else if (currentValue <= 8) {
            const colorNumberByPercentageCold = Math.round(thresholdNeutral * (1 - percentage));
            const colorNumberCold = 38+colorNumberByPercentageCold;
            const colorNumberColdRed = 255 - colorNumberByPercentageCold;

            colors.red = colorNumberColdRed > thresholdNeutral ? thresholdNeutral : colorNumberColdRed;
            colors.green = colorNumberCold < thresholdNeutral ? thresholdNeutral : colorNumberCold;
            colors.blue = colorNumberCold < thresholdNeutral ? thresholdNeutral : colorNumberCold;
        }

        if (colors.red < 0) {
            colors.red = 0;
        }
        if (colors.green < 0) {
            colors.green = 0;
        }
        if (colors.blue < 0) {
            colors.blue = 0;
        }

        return `rgba(${colors.red},${colors.green},${colors.blue},.8)`;
    },
};

export default Color;