import React from 'react';
import styled from 'styled-components';

const StyledLink = styled.span`
    cursor: pointer;
    color: var(--color-link);
    
    &.toc {
        color: rgba(0, 0, 0, 1);
    }
    &.activeFlat {
        color: var(--color-text);
    }
    &.toc:hover {
        color: rgba(0, 0, 0, .6);
    }
    &.activeFlat:hover {
        color: var(--color-text);
    }
`;

interface Props {
    children: JSX.Element | string;
    [key: string]: any;
}

class Link extends React.Component<Props> {
    render() {
        return <StyledLink
            {...this.props}
        >
            {this.props.children}
        </StyledLink>;
    }
}

export default Link;