import ws from './websocket';
import client from './client';
import storage from './storage';
import color from './color';
import crypto from './crypto';
import calculation from './calculation';
import validation from './validation';
import datetime from './datetime';
import number from './number';

const isDevelopment = (): boolean => !window.location.host.includes(`agers.nl`) || window.location.host.includes(`dev.`);
const uid    = (): string => (performance.now().toString(36)+Math.random().toString(36)).replace(/\./g,``);
const setUrl = (pathName: string): void => window.history.pushState(null, ``, `${window.location.origin}${pathName}`);
const cssProperty = (propertyName: string): string | number => window
    .getComputedStyle(document.documentElement)
    .getPropertyValue(propertyName)
    .replace(` `, ``)
    .replace(`%`, ``);

const random = (min?: number, max?: number): number => {
    min = min === undefined ? 0 : min;
    max = max === undefined ? 0 : max;

    return Math.random() * (max - min + 1) + min;
};
const isObjectEmpty = (object: any): boolean => object !== undefined && typeof object === `object` && (object === null || Object.keys(object).length === 0);
const range = (from: number, to: number, step: number = 1) => [...new Array(Math.floor((to-from+1)/step))].map((_, i) => (i * step) + from);
const z = (v: number): string => v < 10 ? `0${v}` : `${v}`;
const formattedDate = (date?: Date, format?: string) => {
    date   = date instanceof Date ? date : new Date();
    format = format === undefined ? storage.account.preferences.get(`dateTimeFormat`) : format;
    format = format === undefined || format === null ? `Y-m-d H:i:s` : format;

    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();
    const hour = date.getHours();
    const minute = date.getMinutes();
    const second = date.getSeconds();
    const milliseconds = date.getMilliseconds();

    format = format.replace(`Y`, year.toString());
    format = format.replace(`y`, year.toString().substring(-2));
    format = format.replace(`M`, month.toString());
    format = format.replace(`m`, z(month));
    format = format.replace(`D`, day.toString());
    format = format.replace(`d`, z(day));
    format = format.replace(`H`, z(hour));
    format = format.replace(`h`, hour.toString());
    format = format.replace(`i`, z(minute));
    format = format.replace(`S`, second.toString());
    format = format.replace(`s`, z(second));
    format = format.replace(`U`, z(milliseconds));
    format = format.replace(`u`, milliseconds.toString());

    return format;
};

type callbackType = (
    {
        startPositionX,
        startPositionY,
        newPositionX,
        newPositionY
    } : {
        startPositionX: number;
        startPositionY: number;
        newPositionX: number;
        newPositionY: number;
    }
) => void;
const swipe = (
    element: HTMLElement,
    extraOptions: {
        touch?: boolean;
        mouse?: boolean;
        showGrab?: boolean;
        axis?: (`x` | `y`)[];
        boundaries?: {
            top?: number;
            left?: number;
            right?: number;
            bottom?: number;
        };
        callbackOnSwiping?: callbackType;
        callbackOnStop?: callbackType;
    }
) => {
    let swipeState      = 0; // 0 not swiping, 1 swiping.
    let startPosX       = 0;
    let startPosY       = 0;
    let startPosContentX = 0;
    let startPosContentY = 0;
    let setNewPositionX = startPosContentX;
    let setNewPositionY = startPosContentY;
    const options       = {
        touch : true,
        mouse : false,
        axis  : [`x`, `y`],
        ...extraOptions,
        threshold : {
            x : 0,
            y : 0
        },
    };

    if (options.touch) {
        element.addEventListener(`touchstart`, swipeStart);
        document.documentElement.addEventListener(`touchmove`, swiping, {passive : false});
        document.documentElement.addEventListener(`touchend`, swipeStop);
        document.documentElement.addEventListener(`touchcancel`, swipeStop);
    }

    if (options.mouse) {
        if (options.showGrab) {
            element.style.cursor = `grab`;
        }
        element.addEventListener(`mousedown`, swipeStart);
        document.documentElement.addEventListener(`mousemove`, swiping);
        document.documentElement.addEventListener(`mouseup`, swipeStop);
        document.documentElement.addEventListener(`mouseleave`, swipeStop);
    }

    function swipeStart(event: MouseEvent | TouchEvent) {
        if (swipeState === 0) {
            swipeState      = 1;
            const previousStartPosX = startPosX;
            const previousStartPosY = startPosY;
            startPosX       = event instanceof MouseEvent ? event.clientX : event.changedTouches[0].clientX;
            startPosY       = event instanceof MouseEvent ? event.clientY : event.changedTouches[0].clientY;
            if (options.boundaries !== undefined) {
                if (
                    (options.boundaries.top !== undefined && startPosY < options.boundaries.top)
                    || (options.boundaries.bottom !== undefined && startPosY > options.boundaries.bottom)
                ) {
                    startPosY  = previousStartPosY;
                    swipeState = 0;
                    return;
                }

                if (
                    (options.boundaries.left !== undefined && startPosX < options.boundaries.left)
                    || (options.boundaries.right !== undefined && startPosX > options.boundaries.right)
                ) {
                    startPosX  = previousStartPosX;
                    swipeState = 0;
                    return;
                }
            }

            startPosContentX = element.scrollLeft;
            startPosContentY = element.scrollTop;
        }
    }

    function swiping(event: MouseEvent | TouchEvent) {
        if (swipeState !== 1) {
            return; // nope, not swiping!
        }

        options.mouse
        && event.preventDefault();

        if (options.axis.includes(`x`)) {
            const currentPos = event instanceof MouseEvent ? event.clientX : event.changedTouches[0].clientX;
            setNewPositionX  = startPosContentX;

            if (startPosX > currentPos) {
                setNewPositionX = startPosContentX + (startPosX - currentPos);
            } else if (startPosX < currentPos) {
                setNewPositionX = startPosContentX + (currentPos - startPosX);
            }

            options.callbackOnSwiping
            && options.callbackOnSwiping(
                {
                    startPositionX: startPosX,
                    startPositionY: startPosY,
                    newPositionX: setNewPositionX,
                    newPositionY: setNewPositionY
                }
            );
        }

        if (options.axis.includes(`y`)) {
            const currentPos = event instanceof MouseEvent ? event.clientY : event.changedTouches[0].clientY;
            setNewPositionY  = startPosContentY;

            if (startPosY > currentPos) {
                setNewPositionY = startPosContentY + (startPosY - currentPos);
            } else if (startPosY < currentPos) {
                setNewPositionY = startPosContentY + (currentPos - startPosY);
            }

            options.callbackOnSwiping
            && options.callbackOnSwiping(
                {
                    startPositionX: startPosX,
                    startPositionY: startPosY,
                    newPositionX: setNewPositionX,
                    newPositionY: setNewPositionY
                }
            );
        }
    }

    function swipeStop() {
        if (swipeState === 1) {
            swipeState = 0;

            options.callbackOnStop
            && options.callbackOnStop(
                {
                    startPositionX: startPosX,
                    startPositionY: startPosY,
                    newPositionX: setNewPositionX,
                    newPositionY: setNewPositionY
                }
            );

            if (
                options.mouse
                && options.showGrab
                && (
                    options.axis.includes(`x`)
                    || options.axis.includes(`y`)
                )
            ) {
                element.style.cursor = `grab`;
            }
        }
    }
};

const sleep = async (ms: number) => await new Promise((r)=>setTimeout(r, ms));

export {
    isDevelopment,
    number,
    ws,
    client,
    storage,
    color,
    calculation,
    validation,
    crypto,
    uid,
    setUrl,
    cssProperty,
    random,
    isObjectEmpty,
    range,
    z,
    formattedDate, // ToDo: replace all usages with, "datetime.formattedDate" !
    datetime,
    swipe,
    sleep
};