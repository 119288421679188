import storage from "../storage";
import {z} from "../index";

const DateTime = {
    create: (date?: Date): Date => date ? new Date(date) : new Date(),
    createFromString: (datetimeString: string): Date|undefined => {
        try {
            return new Date(Date.parse(datetimeString));
        } catch (e) {
        }

        return undefined;
    },
    addYear: (years: number, date?: Date): Date => {
        date = date instanceof Date ? date : new Date();
        date.setFullYear(date.getFullYear() + years);

        return date;
    },
    addMonth: (months: number, date?: Date): Date => {
        date = date instanceof Date ? date : new Date();
        date.setMonth(date.getMonth() + months);

        return date;
    },
    addDay: (days: number, date?: Date): Date => {
        date = date instanceof Date ? date : new Date();
        date.setDate(date.getDate() + days);

        return date;
    },
    addHour: (hours: number, date?: Date): Date => {
        date = date instanceof Date ? date : new Date();
        date.setHours(date.getHours() + hours);

        return date;
    },
    addMinute: (minutes: number, date?: Date): Date => {
        date = date instanceof Date ? date : new Date();
        date.setMinutes(date.getMinutes() + minutes);

        return date;
    },
    addSecond: (seconds: number, date?: Date): Date => {
        date = date instanceof Date ? date : new Date();
        date.setSeconds(date.getSeconds() + seconds);

        return date;
    },
    formattedDate: (date?: Date, format?: string) => {
        date   = date instanceof Date ? date : new Date();
        format = format === undefined ? storage.account.preferences.get(`dateTimeFormat`) : format;
        format = format === undefined || format === null ? `Y-m-d H:i:s` : format;

        const year = date.getFullYear();
        const month = date.getMonth() + 1;
        const day = date.getDate();
        const hour = date.getHours();
        const minute = date.getMinutes();
        const second = date.getSeconds();
        const milliseconds = date.getMilliseconds();

        format = format.replace(`Y`, year.toString());
        format = format.replace(`y`, year.toString().substring(-2));
        format = format.replace(`M`, month.toString());
        format = format.replace(`m`, z(month));
        format = format.replace(`D`, day.toString());
        format = format.replace(`d`, z(day));
        format = format.replace(`H`, z(hour));
        format = format.replace(`h`, hour.toString());
        format = format.replace(`i`, z(minute));
        format = format.replace(`S`, second.toString());
        format = format.replace(`s`, z(second));
        format = format.replace(`U`, z(milliseconds));
        format = format.replace(`u`, milliseconds.toString());

        return format;
    },
    getFirstDate: (date?: Date) => {
        date = date === undefined ? new Date() : date;

        return new Date(date.getFullYear(), date.getMonth(), 1);
    },
    getLastDate: (date?: Date) => {
        date = date === undefined ? new Date() : date;

        return new Date(date.getFullYear(), date.getMonth() + 1, 0);
    },
    getDays: function (date?: Date): number[] {
        return [...new Array(this.getLastDate(date).getDate())].map((_, i) => i + 1);
    },
    getMonthName: (date?: Date): string|undefined => {
        const monthNames = [`January`, `February`, `March`, `April`, `May`, `June`, `July`, `August`, `September`, `October`, `November`, `December`];
        date   = date instanceof Date ? date : new Date();

        return monthNames[date.getMonth()] || undefined;
    },
    getDayOfWeekName: (date?: Date): string|undefined => {
        const dayOfWeek = [`Sunday`, `Monday`, `Tuesday`, `Wednesday`, `Thursday`, `Friday`, `Saturday`];
        date   = date instanceof Date ? date : new Date();

        return dayOfWeek[date.getDay()] || undefined;
    },
    getTodayDate: (): Date => {
        const today = new Date();
        today.setHours(0);
        today.setMinutes(0);
        today.setSeconds(0);
        today.setMilliseconds(0);

        return today;
    },
};

export default DateTime;