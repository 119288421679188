import React from "react";
import {pageType} from "../../pages";
import Base from "./base";

const url = `/converter/weight-mass`;
const page: pageType = {
    name: `Weight / Mass`,
    link: [url, `${url}/*`],
    page: <Base
        title={`Weight / Mass`}
        url={url}
        steps={1000}
        defaultValues={
            {
                from  : `mg`,
                to    : `mg`,
                value : 1,
            }
        }
        allowableUnits={
            {
                'mg': `milligram`,
                'g' : `gram`,
                'kg': `kilogram`,
            }
        }
    />,
    props: {
        menu: false
    },
};

export default page;