import { hasState, listenerFunction, sessionDataType, setListenerFunctions, wsInfo, wsType, wsUrl } from "./helper";

const ws: wsType  = {
    status   : {
        isConnected  : (): boolean => hasState(`OPEN`),
        isClosed     : (): boolean => hasState(`CLOSED`),
        isConnecting : (): boolean => hasState(`CONNECTING`),
        isClosing    : (): boolean => hasState(`CLOSING`),
    },
    listener : {
        open    : (actions: string[], callback: (data: sessionDataType) => void): void => listenerFunction(`onopen`, actions, callback),
        message : (actions: string[], callback: (data: sessionDataType) => void): void => listenerFunction(`onmessage`, actions, callback),
        close   : (actions: string[], callback: (data: sessionDataType) => void): void => listenerFunction(`onclose`, actions, callback),
        error   : (actions: string[], callback: (data: sessionDataType) => void): void => listenerFunction(`onerror`, actions, callback),
        set     : (): void => setListenerFunctions(`onopen`, `onmessage`, `onclose`, `onerror`),
    },
    connect  : (url?: string): boolean => {
        url !== undefined && wsUrl(url);

        if (
            wsInfo.url.length > 8
            && (
                ws.status.isClosed()
                || wsInfo.socket === undefined
            )
        ) {
            wsInfo.socket = new WebSocket(wsInfo.url);
            ws.listener.set();
            return true;
        }

        return false;
    },
    sent     : (
        type   : string,
        action : string,
        data   : object
    ): boolean => {
        if (
            type.length < 2
            || action.length < 2
        ) {
            return false;
        }

        ws.listener.set();
        const sentData = (): boolean => {
            if (
                wsInfo.socket instanceof WebSocket
                && ws.status.isConnected()
            ) {
                const dataObject = {
                    type   : type,
                    action : action,
                    data   : data,
                };

                wsInfo.socket.send(wsInfo.encrypt(JSON.stringify(dataObject)));

                return true;
            }

            return false;
        };

        wsInfo.socket === undefined && ws.connect();

        if (
            !ws.status.isConnected()
            && wsInfo.socket !== undefined
        ) {
            wsInfo.socket.onopen = sentData;
        }

        return ws.status.isConnected() && sentData();
    },
};

export default ws;