import storage from "./index";
import preferences from './account/preferences';
import fusebox from './account/fusebox';

const accountKey = `account`;
const account = {
    set: {
        storage: (newStorage: object): boolean => storage.set.key(accountKey, newStorage),
        key: function(key: string, value?: any): boolean {
            if (`${key}`.length === 0) {
                return false;
            }

            const storageAgers = account.get() || {};
            if (
                typeof storageAgers === `object`
                && storageAgers !== null
            ) {
                storageAgers[key] = value;

                return this.storage(storageAgers);
            }

            return false;
        },
        keys: function(object: object): void {
            const storageAgers = account.get() || {};
            if (
                typeof storageAgers !== `object`
                || storageAgers === null
            ) {
                return;
            }

            this.storage(
                {
                    ...storageAgers,
                    ...object
                }
            );
        },
    },
    remove: {
        key : (key: string): void => {
            if (`${key}`.length === 0) {
                return;
            }

            const storageAgers = account.get();
            if (
                typeof storageAgers === `object`
                && storageAgers !== null
                && storageAgers.hasOwnProperty(key)
            ) {
                storageAgers[key] = undefined;
                delete storageAgers[key];
                account.set.storage(storageAgers);
            }
        },
        keys: (...keys: string[]): void => {
            const storageAgers = account.get();
            if (
                typeof storageAgers !== `object`
                || storageAgers === null
            ) {
                return;
            }

            keys.forEach(
                key => {
                    if (storageAgers.hasOwnProperty(key)) {
                        storageAgers[key] = undefined;
                        delete storageAgers[key];
                    }
                }
            );

            account.set.storage(storageAgers);
        }
    },
    clear : (): void => storage.remove.key(accountKey),
    get: (key?: string) => {
        const storageAgers = storage.get(accountKey);
        if (!key) {
            return storageAgers;
        }

        return typeof storageAgers === `object` && storageAgers !== null && storageAgers.hasOwnProperty(key)
            ? storageAgers[key]
            : undefined;
    },

    preferences: preferences,
    fusebox: fusebox,
};

export default account;