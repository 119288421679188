import React                               from 'react';
import styled                              from 'styled-components';
import { Group, GroupColumn, GroupNoGrow } from "../group";
import Youtube                             from "../iframe/youtube";
import GuideImage                          from "../image/guide";
import Ads                                 from "../ads";

const StyledBookPagePiece = styled.div`
    display: flex;
    flex-grow: 1;
    min-width: 28.4795rem;
`;
const StyledBookPagePieceColumn = styled.div`
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    width: 4rem;
`;

const StyledHeader1 = styled.h1`
    min-height: 2.75rem;
    max-height: 2.75rem;
`;
const StyledHeader2 = styled.h2`
    min-height: 2.0625rem;
    max-height: 2.0625rem;
`;
const StyledText = styled.div`
    min-height: 1.4375rem;
    min-width: 17.5rem;
`;
const StyledImage = styled.div`
    min-height: 15.625rem;
`;

const StyledGroup = styled.div`
    display: flex;
    flex-direction: row;
    height: 100%;

    margin: 0 0 1rem 0;
    
    @media only screen and (max-width: 1200px) {
        flex-direction: column;
    }
    
    &.activeFlat {
        flex-direction: column;
    }
`;
const StyledReferences = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    height: 100%;
`;
const StyledReferencesBig = styled(StyledReferences)`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
`;
const StyledContentYoutube = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    align-items: center;
    justify-content: center;
    
    &.activeFlat {
        min-height: 40rem;
        align-items: start;
    }
`;
const StyledReferencesContent = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: end;
    height: 100%;
    
    &.activeFlat {
        align-items: start;
    }
`;

const StyledBox = styled.div`
    min-height: 5.625rem;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    
    &:not(:first-child) {
        margin: .4rem 0 0 0;
    }
`;

interface Props {
    flat?: boolean;
    isAd?: boolean;
    index: number;
    preTitle?: string;
    title?: string;
    subTitle?: string | React.ReactNode;
    text?: string | React.ReactNode;
    youtubeId?: string;
    youtubeIdFull?: string;
    imageSrc?: string;
}

class BookPage extends React.Component<Props> {
    render() {
        return <StyledBookPagePiece>
            <StyledBookPagePieceColumn>
                {(this.props.index === 0 || this.props.title || this.props.subTitle) && <StyledHeader1>{this.props.title}</StyledHeader1>}
                {(this.props.index === 0 || this.props.title || this.props.subTitle) && <StyledHeader2>{this.props.subTitle}</StyledHeader2>}
                {
                    this.props.isAd === true
                        ? (<Ads adType={`guide`}/>)
                        : (
                            this.props.text === undefined
                                ? (<StyledBox/>)
                                : (
                                    <StyledBox>
                                        {
                                            this.props.youtubeIdFull
                                                ? (
                                                    <GroupColumn>
                                                        <GroupNoGrow><StyledText>{this.props.text}</StyledText></GroupNoGrow>
                                                        <StyledReferencesBig>
                                                            <Group>
                                                                <StyledContentYoutube
                                                                    className={this.props.flat ? `activeFlat` : undefined}
                                                                >
                                                                    {this.props.youtubeIdFull && <Youtube id={this.props.youtubeIdFull}/>}
                                                                </StyledContentYoutube>
                                                            </Group>
                                                        </StyledReferencesBig>
                                                    </GroupColumn>
                                                )
                                                : (
                                                    <StyledGroup
                                                        className={this.props.flat ? `activeFlat` : undefined}
                                                    >
                                                        <Group><StyledText>{this.props.text}</StyledText></Group>
                                                        <StyledReferences>
                                                            {
                                                                (
                                                                    this.props.youtubeId
                                                                    || this.props.imageSrc
                                                                )
                                                                && (
                                                                    <StyledReferencesContent
                                                                        className={this.props.flat ? `activeFlat` : undefined}
                                                                    >
                                                                        {this.props.youtubeId && <Youtube id={this.props.youtubeId}/>}
                                                                        {this.props.imageSrc && <GuideImage src={this.props.imageSrc} flat={this.props.flat}/>}
                                                                    </StyledReferencesContent>
                                                                )
                                                            }
                                                            {
                                                                this.props.youtubeId === undefined
                                                                && this.props.imageSrc === undefined
                                                                && <StyledImage/>
                                                            }
                                                        </StyledReferences>
                                                    </StyledGroup>
                                                )
                                        }
                                    </StyledBox>
                                )
                        )
                }
            </StyledBookPagePieceColumn>
        </StyledBookPagePiece>;
    }
}

export default BookPage;