import styled from 'styled-components';

const StyledGroupNoGrow = styled.div`
    display: flex;
`;
const StyledGroup = styled(StyledGroupNoGrow)`
    flex-grow: 1;
`;

const StyledGroupRow = styled(StyledGroup)`
    flex-direction: row;
`;
const StyledGroupColumn = styled(StyledGroup)`
    flex-direction: column;
`;

const StyledGroupCenter = styled(StyledGroupColumn)`
    align-items: center;
    justify-content: center;
`;

const StyledGroupCenterHorizontal = styled(StyledGroupColumn)`
    align-items: center;
`;

const StyledGroupCenterVertical = styled(StyledGroupColumn)`
    justify-content: center;
`;

export {
    StyledGroupNoGrow as GroupNoGrow,
    StyledGroup as Group,
    StyledGroupRow as GroupRow,
    StyledGroupColumn as GroupColumn,
    StyledGroupCenter as GroupCenter,
    StyledGroupCenterHorizontal as GroupCenterHorizontal,
    StyledGroupCenterVertical as GroupCenterVertical,
};