import React      from "react";
import styled     from 'styled-components';
import {pageType} from "../../pages";
import * as utils from "../../utils";

const StyledBookshelf = styled.div`
    position: relative;
    display: grid;
    margin: -1rem -2rem;
    width: 100%;
    min-width: 37.5rem;
    padding: 0 0 2rem 0;
    border: 2rem solid rgba(42, 24, 18, 1);
    background-color: rgba(42, 24, 18, 1);
`;
const StyledBookshelfHeader = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    text-align: center;
    font-weight: bolder;
    font-size: 2.5rem;
    
    color: rgba(189, 193, 199, 1);
`;
const StyledShelf = styled.div`
    position: relative;
    display: flex;
    flex-direction: row;
    margin: 1rem auto;
    width: 100%;
    height: 25rem;
    background-color: rgba(84, 51, 39, 1);
`;

const StyleShelfNumber = styled.div`
    cursor: default;
    position: absolute;
    top: -1.5rem;
    right: -1.5rem;
    color: rgba(189, 193, 199, 1);

    &::before {
        content: '#'
    }
`;

const StyledBook = styled.div`
    position: relative;
    display: flex;
    flex-direction: row;
    height: 100%;
`;

interface Props {
    title?: string
    allGuides: pageType[],
}
interface State {
    maxBooksPerShelf: number;
    shelf: React.ReactNode[][],
}

class Bookshelf extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            ...this.getShelf(),
        };

        this.fillShelf = this.fillShelf.bind(this);
    }

    getShelf() {
        const bodyWidth                   = document.documentElement.offsetWidth;
        const outerSpace                  = bodyWidth - ((bodyWidth / 100) * (2 * Number(utils.cssProperty(`--site-size`))));
        const innerWidth                  = outerSpace > 100 ? outerSpace : 110;
        const maxGuidesEachShelf          = Math.floor((innerWidth-15-(6*15))/85);
        const allGuidesPages              = this.props.allGuides.map((guide)=>guide.page);
        const totalShelf                  = Math.ceil(this.props.allGuides.length/maxGuidesEachShelf);
        const guides: React.ReactNode[][] = [];

        if (totalShelf > 0) {
            for (let i=0; i<totalShelf; i++) {
                const index = i * maxGuidesEachShelf;
                guides.push(allGuidesPages.slice(index, index + maxGuidesEachShelf));
            }
        }

        return {
            shelf            : guides,
            maxBooksPerShelf : maxGuidesEachShelf
        };
    }

    fillShelf() {
        this.setState(this.getShelf());
    }

    componentDidMount() {
        setTimeout(
            () => this.fillShelf(),
            200
        );

        window.addEventListener(
            `resize`,
            () => this.fillShelf()
        );
    }

    render() {
        return <StyledBookshelf>
            {this.props.title && <StyledBookshelfHeader>{this.props.title}</StyledBookshelfHeader>}
            {
                this.state.shelf.map(
                    (bookSet, index: number) => bookSet !== undefined && bookSet.length > 0
                        ? <StyledShelf
                            key={index}
                        >
                            <StyleShelfNumber>{index + 1}</StyleShelfNumber>
                            {
                                bookSet.map(
                                    (book, bookIndex: number) => bookIndex < this.state.maxBooksPerShelf ? <StyledBook key={bookIndex}>{book}</StyledBook> : undefined
                                )
                            }
                        </StyledShelf>
                        : undefined
                )
            }

        </StyledBookshelf>;
    }
}

export default Bookshelf;