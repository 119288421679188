import React from "react";
import {pageType} from "../../pages";
import {Input} from "../../component/form";
import styled from "styled-components";
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';
import AddIcon from '@mui/icons-material/Add';
import hljs from "highlight.js";
import 'highlight.js/styles/github.css';
import * as Utils from '../../utils';
import {CircularProgress} from "@mui/material";

const StyledContainer = styled.div`
    // height: 100%;
    display: flex;
    flex-direction: column;
    min-height: 40rem;
    max-height: 60rem;
    min-width: 30rem;
    padding: 0 2rem;
`;
const StyledContainerPrompts = styled.div`
    display: flex;
    flex-direction: row;
    min-height: 30rem;
    max-height: 60rem;
`;

const StyledImage = styled.img`
    max-width: 30rem;
    max-height: 30rem;
`;

const StyledAIMenuContainer = styled.div`
    background-color: rgba(0, 0, 0, .2);
    display: flex;
    flex-direction: column;
    flex-grow: 1;

    min-width: 20rem;
    max-width: 20rem;
`;
const StyledAIMenu = styled.div`
    max-height: 60rem;
    flex-direction: column;
    flex-grow: 1;
    padding: .5rem;

    overflow: auto;
`;

const StyledAIMenuItem = styled.div`
    // border: 1px solid rgba(255, 255, 255, 1);
    margin: .25rem 0;
    padding: .5rem;
    border-radius: .2rem;
    display: flex;

    &:hover {
        cursor: pointer;
        background-color: rgba(255, 255, 255, .1);
    }
    & > div {
        margin: 0 0 0 1rem;
        align-self: center;
    }
    &.selected {
        background-color: rgba(255, 255, 255, .05);
    }
`;
const StyledAIMenuItemAdd = styled(StyledAIMenuItem)`
    border: .09375rem solid rgba(255, 255, 255, .1);
`;

const StyledAIMenuDivider = styled.div`
    border-top: .09375rem solid rgba(255, 255, 255, .1);
    margin: 0 .5rem;
`;
const StyledAIMenuOptions = styled.div`
    padding: .5rem;
    display: flex;
    flex-direction: column;

    min-height: 3rem;
    max-height: 3rem;
    overflow: hidden;
`;

const StyledPromptContainer = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    min-width: 30rem;
`;
const StyledPromptOutputContainer = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    flex-grow: 1;

    overflow-x: hidden;
    overflow-y: scroll;

    & > div:nth-child(even) {
        opacity: .8;
    }
    & > div:nth-child(odd) {
        opacity: .9;
    }
`;
const StyledPromptOutputItem = styled.div`
    padding: 2rem;
    text-align: left;
    
    & > div, & > pre, & > pre > code {
        white-space: pre-wrap;
        word-wrap: break-word;
        line-height: 1.3rem;
    }
    & > div > code {
        background-color: rgba(0, 0, 0, .3);
        padding: .1rem .3rem;
        margin: .1rem -.2rem;
        border-radius: .2rem;
    }
`;
const StyledPromptOutputItemUser = styled(StyledPromptOutputItem)`
    background-color: rgba(200, 200, 200, .2);
`;
const StyledPromptOutputItemAI = styled(StyledPromptOutputItem)`
    background-color: rgba(225, 225, 225, .1);
`;

const StyledPromptOutputItemPre = styled.pre`
`;
const StyledPromptOutputItemCode = styled.code`
    display: block;
    background-color: rgba(0, 0, 0, .2);
    // background-color: #f4f4f4;
    padding: 0 1rem 1rem 1rem;
    border-radius: .4rem;
`;
const StyledPromptOutputLoading = styled.div`
    position: absolute;
    display: flex;
    flex-direction: column;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, .3);
    
    z-index: 10;
`;

const StyledPromptInputContainer = styled.div`
    margin: 0 0 -.33rem 0;
`;

interface Props {}
interface State {
    currentHashId?: string;
    chats: {[hashId: string]: {hashId: string; summary: string}};
    messages: {
        [hashId: string]: { role: `user` | `assistant`; content: string; isImage?: boolean; }[]
    };
    output: {
        element: React.RefObject<HTMLDivElement>;
        autoScroll: boolean;
    };
    input: {
        isImage: boolean;
        disabled: boolean;
        loading: boolean;
        minCharacters: number;
        error?: string;
    };
    queueQuestions: {[question: string]: boolean};
}

class AI extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        const artificialIntelligenceStorage = Utils.storage.ai.get() || {};

        this.state = {
            currentHashId : artificialIntelligenceStorage.id || undefined,
            chats         : artificialIntelligenceStorage.chats || {},
            messages      : artificialIntelligenceStorage.messages || {},
            output        : {
                element   : React.createRef<HTMLDivElement>(),
                autoScroll: true,
            },
            input         : {
                isImage      : false,
                disabled     : false,
                loading      : false,
                minCharacters: 5,
                error        : undefined,
            },
            queueQuestions: {},
        };
    }

    componentDidMount() {
        this.outputScrollDown();
        Utils.ws.listener.message(
            [`ai.question`],
            data => {
                if (data.code === 200) {
                    const hashId = data.results.questionId;
                    this.addMessage(
                        {
                            hashId,
                            type: `assistant`,
                            text: data.results.result,
                            isImage: data.results.isImage || false
                        },
                    );

                    if (
                        this.state.chats.hasOwnProperty(hashId)
                        && this.state.chats[hashId] !== undefined
                        && (
                            this.state.chats[hashId].summary.length === 0
                            || [`Unknown`, `A new chat`].includes(this.state.chats[hashId].summary)
                        )
                    ) {
                        this.setState(
                            {
                                chats: {
                                    ...this.state.chats,
                                    [hashId]: {
                                        hashId: hashId,
                                        summary: data.results.summary || `Unknown`,
                                    },
                                },
                            },
                            () => {
                                Utils.storage.ai.set.key(`chats`, this.state.chats);
                            }
                        );
                    }
                }

                this.setState(
                    {
                        input: {
                            ...this.state.input,
                            loading: false,
                            disabled: false,
                        },
                    },
                    () => Object.keys(this.state.queueQuestions).forEach(
                        question => {
                            delete this.state.queueQuestions[question]; // remove questions from queue.
                        }
                    )
                );
            }
        );
    }

    addChat(callback?: () => void) {
        Utils.crypto.createHash().then(
            hash => this.setState(
                {
                    currentHashId: hash,
                    chats        : {
                        ...this.state.chats,
                        [hash]: {
                            hashId : hash,
                            summary: `A new chat`,
                        }
                    },
                },
                () => {
                    Utils.storage.ai.set.keys({
                        'id'    : this.state.currentHashId,
                        'chats' : this.state.chats,
                    });

                    callback && callback();
                }
            )
        );
    }

    addMessage(
        {
            hashId,
            type,
            text,
            isImage,
            callback
        } : {
            hashId: string|undefined;
            type: `user` | `assistant`;
            text: string;
            isImage?: boolean;
            callback?: () => void;
        }
    ) {
        this.outputScrollDown();
        if (hashId === undefined) {
            return;
        }

        this.setState(
            {
                messages: {
                    ...this.state.messages,
                    [hashId]: [
                        ...(this.state.messages[hashId] || []),
                        {
                            role: type,
                            content: text,
                            isImage: isImage,
                        }
                    ],
                },
            },
            () => {
                Utils.storage.ai.set.key(`messages`, this.state.messages);
                callback && callback();
                this.outputScrollDown();
            }
        );
    }

    outputScrollDown() {
        const outputElement = this.state.output.element.current;
        if (
            outputElement !== null
            && outputElement.scrollHeight > outputElement.clientHeight
        ) {
            outputElement.scrollTop = outputElement.scrollHeight - outputElement.clientHeight;
        }
    }

    askQuestion(question: string, isImage: boolean) {
        const setQuestion = () => this.setState(
            {
                queueQuestions: {
                    ...this.state.queueQuestions,
                    [question]: true
                },
                input: {
                    ...this.state.input,
                    disabled: true,
                    loading: true,
                },
            },
            () => {
                const hashId = this.state.currentHashId;
                this.addMessage(
                    {
                        hashId,
                        type: `user`,
                        text: question,
                        callback: () => {
                            Utils.ws.sent(
                                `ai`,
                                `question`,
                                {
                                    id        : hashId,
                                    input     : question,
                                    sensitive : .1,
                                    image     : isImage,
                                    previous  : hashId !== undefined && this.state.messages.hasOwnProperty(hashId)
                                        ? this.state.messages[hashId]
                                        : []
                                }
                            );
                            setTimeout(
                                () => {
                                    if (
                                        this.state.queueQuestions[question]
                                        && this.state.input.loading
                                    ) {
                                        this.setState({
                                            queueQuestions: {
                                                ...this.state.queueQuestions,
                                                [question]: false,
                                            },
                                            input: {
                                                ...this.state.input,
                                                loading: false,
                                                disabled: false,
                                                error: `Unable to obtain answer, please try again.`,
                                            },
                                        })
                                    }
                                },
                                20000
                            );
                        }
                    },
                )
            }
        );

        if (this.state.currentHashId === undefined) {
            this.addChat(setQuestion);
        } else {
            setQuestion();
        }
    }

    outputPromptItem(
        {
            text,
            type = `user`,
            codingType,
            isImage,
        } : {
            text?: string;
            type: `user` | `assistant`;
            codingType?: string;
            isImage?: boolean;
        }
    ) {
        if (text === undefined) {
            return undefined;
        }

        let newText: (JSX.Element|undefined)[] = [];
        if (isImage) {
            newText = [<StyledImage key={Utils.uid()} alt={``} loading={`lazy`} src={text}/>];
        } else {
            function getTextBetweenChars(text: string, startChar: string, endChar: string): string[] {
                const regex = new RegExp(`${startChar}(.+?)${endChar}`, 'g');
                const matches = text.match(regex);

                return matches ? matches.map((match) => match.slice(startChar.length, -1)) : [];
            }

            const interpretedType = typeof text === `string` ? getTextBetweenChars(text, '```', '\n') : text;
            if (interpretedType.length > 0) {
                const interpretedTypeString = interpretedType.join(``);
                if (
                    interpretedTypeString.length > 0
                    && codingType !== interpretedTypeString
                ) {
                    codingType = interpretedTypeString;
                }
            }

            const textValue = text;
            const amountBackticks = (textValue.match(new RegExp('```', `g`)) || []).length;
            if (amountBackticks > 1 && amountBackticks % 2 === 0) {
                newText = textValue.split('```').map(
                    (v, i) => {
                        if (i === amountBackticks / 2) {
                            const typeTests = {
                                'php'        : /<\?php[\s\S]*?\?>/i.test(v),
                                'python'     : /```python[\s\S]*?```/i.test(v),
                                'javascript' : /<script\b[^<]*(?:(?!<\/script>)<[^<]*)*<\/script>/i.test(v) || /<\s*script[^>]*>(.*?)<\s*\/\s*script\s*>/si.test(v),
                                'typescript' : /<script\b[^<]*(?:(?!<\/script>)<[^<]*)*lang=['"]?ts['"]?(?:(?!<\/script>)<[^<]*)*<\/script>/i.test(v),
                                'css'        : /<style\b[^<]*(?:(?!<\/style>)<[^<]*)*type=['"]?text\/css['"]?(?:(?!<\/style>)<[^<]*)*<\/style>/i.test(v),
                            };
                            const typeTestsKeys        = Object.keys(typeTests);
                            const interpretedTypeArray = Object.values(typeTests).map((v, i)=>v ? i : -1).filter(v=>v!==-1).map(v=>typeTestsKeys.length > v ? typeTestsKeys[v] : undefined);
                            let interpretedTypeString  = interpretedTypeArray.length > 0 && interpretedTypeArray[0] !== undefined
                                ? interpretedTypeArray[0]
                                : codingType;

                            const highlighted = hljs.highlight(v, {language: interpretedTypeString || `javascript`});

                            return v
                                ? <StyledPromptOutputItemPre
                                    key={i}
                                >
                                    <StyledPromptOutputItemCode
                                        className={highlighted.language}
                                        dangerouslySetInnerHTML={{__html: highlighted.value}}
                                    />
                                </StyledPromptOutputItemPre>
                                : undefined;
                        }

                        getTextBetweenChars(v, `<`, `>`).forEach(
                            replace => v = v.replace(`<${replace}>`, ``)
                        );
                        getTextBetweenChars(v, '`', '`').forEach(
                            replace => v = v.replace(`\`${replace}\``,`<code>${replace}</code>`)
                        );

                        return <div
                            key={i}
                            dangerouslySetInnerHTML={{__html: v || ``}}
                        />;
                    }
                );
            } else {
                newText = [<div key={textValue}>{textValue}</div>];
            }
        }

        const StyledPromptOutputItemType = type === `user`
            ? StyledPromptOutputItemUser
            : StyledPromptOutputItemAI;

        return text === undefined
            ? undefined
            : <StyledPromptOutputItemType
                key={Utils.uid()}
            >
                {newText}
            </StyledPromptOutputItemType>;
    }

    render() {
        return (
                <StyledContainer>
                    <h1>AI (Artificial Intelligence)</h1>
                    <StyledContainerPrompts>
                        <StyledAIMenuContainer>
                            <StyledAIMenu>
                                <StyledAIMenuItemAdd
                                    title={`Start a new chat.`}
                                    onClick={() => this.addChat()}
                                >
                                    <AddIcon/>
                                    <div>New chat</div>
                                </StyledAIMenuItemAdd>
                                {
                                    this.state.chats
                                    && Object.values(this.state.chats).map(
                                        chat => <StyledAIMenuItem
                                            key={chat.hashId}
                                            title={chat.summary}
                                            className={this.state.currentHashId === chat.hashId ? `selected` : ``}
                                            onClick={() => this.setState({currentHashId: chat.hashId})}
                                        >
                                            <ChatBubbleOutlineIcon/>
                                            <div>{chat.summary}</div>
                                        </StyledAIMenuItem>
                                    )
                                }
                            </StyledAIMenu>
                            <StyledAIMenuDivider/>
                            <StyledAIMenuOptions>
                                <StyledAIMenuItem
                                    title={`Start a new chat.`}
                                    onClick={
                                        () => window.confirm(`Are you sure you want to remove all chats?`)
                                            && this.setState(
                                                {
                                                    currentHashId: undefined,
                                                    chats: {},
                                                    messages: {},
                                                    queueQuestions: {},
                                                    input: {
                                                        ...this.state.input,
                                                        disabled: false,
                                                        loading: false,
                                                    },
                                                },
                                                () => {
                                                    Utils.storage.ai.clear();
                                                }
                                            )
                                    }
                                >
                                    <ChatBubbleOutlineIcon/>
                                    <div>Clear conversations</div>
                                </StyledAIMenuItem>
                            </StyledAIMenuOptions>
                        </StyledAIMenuContainer>
                        <StyledPromptContainer>
                            {
                                this.state.input.loading
                                && <StyledPromptOutputLoading>
                                    <CircularProgress disableShrink/>
                                </StyledPromptOutputLoading>
                            }
                            <StyledPromptOutputContainer
                                ref={this.state.output.element}
                            >
                                {
                                    this.state.currentHashId !== undefined
                                    && this.state.messages.hasOwnProperty(this.state.currentHashId)
                                    && this.state.messages[this.state.currentHashId] !== undefined
                                    && Array.isArray(this.state.messages[this.state.currentHashId])
                                    && this.state.messages[this.state.currentHashId].map(
                                        output => output
                                            ? this.outputPromptItem({text: output.content, type: output.role, isImage: output.isImage})
                                            : undefined
                                    )
                                }
                            </StyledPromptOutputContainer>
                            <StyledPromptInputContainer>
                                <Input
                                    type={`text`}
                                    placeholder={`Send a message...`}
                                    noIcon={true}
                                    disabled={this.state.input.disabled}
                                    error={this.state.input.error}
                                    onKeyUp={
                                        (e: KeyboardEvent) => {
                                            if (
                                                e.currentTarget !== null
                                                && (e.currentTarget as HTMLInputElement).value.length >= this.state.input.minCharacters
                                            ) {
                                                this.setState({
                                                    input: {
                                                        ...this.state.input,
                                                        error: undefined
                                                    }
                                                });
                                            }

                                            if (
                                                e.key.toLowerCase() === `enter`
                                                && e.currentTarget !== null
                                            ) {
                                                const errorMsg = (e.currentTarget as HTMLInputElement).value.length < this.state.input.minCharacters
                                                    ? `Minimum of ${this.state.input.minCharacters} are required.`
                                                    : undefined;

                                                this.setState({
                                                    input: {
                                                        ...this.state.input,
                                                        error: errorMsg
                                                    }
                                                });

                                                if ((e.currentTarget as HTMLInputElement).value.length >= this.state.input.minCharacters) {
                                                    const question = (e.currentTarget as HTMLInputElement).value;
                                                    (e.currentTarget as HTMLInputElement).value = ``;
                                                    this.askQuestion(question, this.state.input.isImage);
                                                }
                                            }
                                        }
                                    }
                                />
                            </StyledPromptInputContainer>
                        </StyledPromptContainer>
                    </StyledContainerPrompts>
                </StyledContainer>
        );
    }
}

const page: pageType = {
    name: `AI`,
    link: [`/ai`],
    page: <AI/>
};

export default page;