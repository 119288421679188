import React from 'react';

interface Props {
    src: string;
    [key: string]: any;
}

class Image extends React.Component<Props> {
    render() {
        return <img alt={``} {...this.props}/>;
    }
}

export default Image;