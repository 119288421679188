import React                               from 'react';
import Book, {maxPageChunks} from "../../../../../../../../component/bookshelf/book";
import {pagesType, pageType} from "../../../../../../../../pages";
import * as Images from "../../../../../../../../asset/guide/windows/server/2012";

const link  = `/guides/windows/server/2012/raf/dhcp/reservations`;
let   pages: pagesType = [
    {
        title: `Youtube video`,
        pages: [
            {
                text: <p>In this roles and features guide we are going to configure the role "DHCP" reservations.</p>,
                youtubeIdFull: `2ojiN9HxR8g`
            },
        ]
    },
    {
        title: `Revise Internet NIC IP address`,
        pages: [
            {
                text: <p>Click on "Tools" and on "DHCP".</p>,
                imageSrc: Images.RolesAndFeatures.dhcp.DHCPManager
            },
            {
                text: <p>If there is no green circle with a check within, it's probably that the NIC IP addresses are not configured correctly.</p>,
                imageSrc: Images.RolesAndFeatures.dhcp.DHCPRedArrowDown
            },
            {
                text: <p>Open the quick menu by pressing key combination "windows key" + "x" and click on "Control panel".</p>,
                imageSrc: Images.QuickMenu
            },
            {
                text: <p>Click on "Network and Internet".</p>,
                imageSrc: Images.ControlPanel
            },
            {
                text: <p>Click on "Network and Sharing Center".</p>,
                imageSrc: Images.ControlPanelNetwork
            },
            {
                text: <p>Click on "Change adapter settings".</p>,
                imageSrc: Images.ControlPanelNetworkAndSharingCenter
            },
            {
                text: <p>Right click again on the NIC (Network Interface) and click on "Properties".</p>,
                imageSrc: Images.ControlPanelNetworkNICRename
            },
            {
                text: <p>Select "Internet Protocol Version 4 (TCP/IPv4)" and click on "Properties".</p>,
                imageSrc: Images.NICPropertiesIPV4
            },
            {
                text: <p>Change the IP address to "192.168.82.5". This cause as you can see the LAN network is an undefined network. Click on "Ok"</p>,
                imageSrc: Images.RolesAndFeatures.dhcp.NICPropertiesIPV4Properties
            },
            {
                text: <p>See that the LAN NIC displays the domain, if not right click on the white space below the NIC's and click on refresh.</p>,
                imageSrc: Images.RolesAndFeatures.dhcp.VerifyDomainNetwork
            },
            {
                text: <p>Right click on the server in the DHCP manager and click on "Refresh".</p>,
                imageSrc: Images.RolesAndFeatures.dhcp.RefreshDHCP
            }
        ]
    },
    {
        title: `Reserve IP addresses`,
        pages: [
            {
                text: <p>Expand Server --&gt; IPv4 --&gt; Scope [...]. Right click on "Reservations" and click on "New Reservation...".</p>,
                imageSrc: Images.RolesAndFeatures.dhcp.NewReservations
            },
            {
                text: <div>
                    <p>Go to the server or client which you want to reserve an IP address for. In this guide i'll use TutorialClient3 (Windows 8).</p>
                    <p><strong>Don't use this for the primary server!</strong></p>
                    <p>Open a command prompt and execute the command "ipconfig /all".</p>
                    <p>This will show some information about the NIC, also the MAC. The MAC is an unique id for the network adapter.</p>
                    <p>This is what we need to remember for the next steps.</p>
                </div>,
                imageSrc: Images.RolesAndFeatures.dhcp.CommandPromptMac1
            },
            {
                text: <p>Same applies as the previous step. In this guide i'll use TutorialClient4 (Windows 10).</p>,
                imageSrc: Images.RolesAndFeatures.dhcp.CommandPromptMac2
            },
            {
                text: <div>
                    <p>Fill in the information.</p>
                    <table>
                        <tbody>
                            <tr>
                                <td style={{minWidth: `8rem`}}>Reservation name</td>
                                <td>This could be anything you like.</td>
                            </tr>
                            <tr>
                                <td>IP address</td>
                                <td>This is the IP address the server/client of the NIC MAC will get.</td>
                            </tr>
                            <tr>
                                <td>MAC address</td>
                                <td>This is the MAC address of the server or client.</td>
                            </tr>
                            <tr>
                                <td>Description</td>
                                <td>Any description you want (optional).</td>
                            </tr>
                        </tbody>
                    </table>
                    <p>Leave the Support types "Both" selected and click on "Add".</p>
                </div>,
                imageSrc: Images.RolesAndFeatures.dhcp.AddReservations
            },
            {
                text: <p>Do the same as the previous step but for the second client.</p>,
                imageSrc: Images.RolesAndFeatures.dhcp.AddReservations2
            }
        ]
    },
    {
        title: `Reservations and Leases`,
        pages: [
            {
                text: <p>Click on "Reservations", verify that the added reservations are there.</p>,
                imageSrc: Images.RolesAndFeatures.dhcp.Reservations
            },
            {
                text: <div>
                    <p>The IP address on the server or client that you've used, will not automatically update the IP address.</p>
                    <p>There are a 2 options to obtain the reserved IP address.</p>
                    <ol>
                        <li>Reboot the server/client, after the reboot it should have the reserved IP address.</li>
                        <li>Execute the following commands in the command prompt:
                            <table>
                                <tbody>
                                    <tr>
                                        <td style={{minWidth:`10rem`}}>ipconfig /release</td>
                                        <td>this will release the current IP address</td>
                                    </tr>
                                    <tr>
                                        <td>ipconfig /renew</td>
                                        <td>this will obtain the reserved IP address, if the DHCP server can be reached.</td>
                                    </tr>
                                </tbody>
                            </table>
                        </li>
                    </ol>
                </div>,
                imageSrc: Images.RolesAndFeatures.dhcp.CommandPromptNewReserved1
            },
            {
                text: <p>For the second client do the same as the previous step.</p>,
                imageSrc: Images.RolesAndFeatures.dhcp.CommandPromptNewReserved2
            },
            {
                text: <p>Open DHCP manager again and click on "Address leases", this will give the overview of the leased IP addresses and to which server/client.</p>,
                imageSrc: Images.RolesAndFeatures.dhcp.IPLeases
            },
            {
                text: <p>Congratulations, you've just configured a DHCP Reservation! &#127881;</p>,
            }
        ]
    },
];

const links: string[] = [];
const lengthPages: number = (pages.map(
    (p) => {
        const modulo = p.pages.length % maxPageChunks;
        return p.pages.length + (modulo !== 0 ? maxPageChunks - (p.pages.length % maxPageChunks) : 0);
    }
).reduce((a,b)=>a+b) / (maxPageChunks * 2)) +1;
for (let i=0;i<=lengthPages;i++) {
    links.push(`${link}/${i}`);
}

const page: pageType = {
    name: `Windows Server 2012`,
    link: [
        link,
        ...links,
    ],
    page: <Book
        logo={Images.Logo}
        preTitle={`Windows Server 2012`}
        title={`Roles and Features`}
        subTitle={`DHCP: reservations`}
        link={link}
        links={links}
        pages={pages}
    />,
    props: {
        menu: true
    }
};

export default page;