import React from "react";
import Body from "../../component/body";
import {pageType} from "../../pages";

const Page = () => <Body>
    <h1>Account</h1>
    <p>
        <img alt={``}
             src={`https://cdnb.artstation.com/p/assets/images/images/009/836/467/large/maria-bo-schatzis-stream-profilpicture.jpg?1521139318`}/>
    </p>
</Body>;

const page: pageType = {
    name : `account`,
    link : [`/account`],
    page : <Page/>,
    props: {
        menu    : false,
        login   : true,
        redirect: `/login`
    },
};

export default page;