import React                from "react";
import styled               from 'styled-components';
import Body                 from "../../component/body";
import { pageType }         from "../../pages";
import { ws }               from "../../utils";
import {Form, Input}        from "../../component/form";
import {Navigate}           from "react-router-dom";
import Link                 from "../../component/link";
import {Login as LoginPage} from "../account";
import * as utils           from "../../utils";

const StyledContainer = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    justify-content: center;
    align-self: center;
`;
const StyledContainerContents = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    justify-self: center;
    align-self: center;
    padding: 1rem 4rem;
    border-radius: .3rem;
    width: 40%;
    min-width: 20rem;
    max-width: 30rem;
    background-color: var(--color-background-box-overlay);
`;

type inputType = {
    value?: string;
    error?: string;
    success?: boolean;
};

interface Props {
}
interface State {
    redirect?: string;
    email?: inputType;
    username?: inputType;
    password?: inputType;
    confirmPassword?: inputType;
    submit?: inputType;
    feedback?: string;
    fieldRef?: {
        email: React.RefObject<Input>;
        username: React.RefObject<Input>;
        password: React.RefObject<Input>;
        confirmPassword: React.RefObject<Input>;
        submit: React.RefObject<Input>;
    };
}

const redirect = `/login`;
class Page extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            fieldRef: {
                email          : React.createRef<Input>(),
                username       : React.createRef<Input>(),
                password       : React.createRef<Input>(),
                confirmPassword: React.createRef<Input>(),
                submit         : React.createRef<Input>(),
            },
            email: undefined,
            username: undefined,
            password: undefined,
            confirmPassword: undefined,
            submit: {
                success: false
            },
        };
    }

    componentDidMount() {
        ws.listener.message(
            [`account.register`],
            data => {
                switch (data.code) {
                    case 200:
                        const redirectSeconds = 10;
                        this.setState(
                            {
                                feedback: `Successful registered. Page will automatically redirect in ${redirectSeconds} seconds.`,
                            },
                            () => {
                                for (let i=0;i<=redirectSeconds;i++) {
                                    setTimeout(
                                        () => this.setState({feedback: `Successful registered. Page will automatically redirect in ${redirectSeconds-i} seconds.`}),
                                        i * 1000
                                    );
                                }
                                setTimeout(
                                    () => this.setState({redirect: redirect}, () => this.setState({redirect: undefined})),
                                    (redirectSeconds * 1000) + 1000
                                )
                            }
                        );
                        break;
                    case 406:
                        const email: string | boolean | undefined = data.results.hasOwnProperty(`email`) ? data.results.email : undefined;
                        let username: string | boolean | undefined = data.results.hasOwnProperty(`username`) ? data.results.username : undefined;
                        let password: string | boolean | undefined = data.results.hasOwnProperty(`password`) ? data.results.password : undefined;
                        let confirmPassword: string | boolean | undefined = data.results.hasOwnProperty(`confirmPassword`) ? data.results.confirmPassword : undefined;

                        const stateEmail = this.getError(`email`, this.state.email?.value, typeof email !== `boolean` ? email : undefined);
                        const stateUsername = this.getError(`username`, this.state.username?.value, typeof username !== `boolean` ? username : undefined);
                        const statePassword = this.getError(`password`, this.state.password?.value, typeof password !== `boolean` ? password : undefined);
                        const stateConfirmPassword = this.getError(`confirmPassword`, this.state.confirmPassword?.value, typeof confirmPassword !== `boolean` ? confirmPassword : undefined);

                        this.setState(
                            {
                                email: {
                                    ...this.state.email,
                                    ...stateEmail,
                                },
                                username: {
                                    ...this.state.username,
                                    ...stateUsername,
                                },
                                password: {
                                    ...this.state.password,
                                    ...statePassword,
                                },
                                confirmPassword: {
                                    ...this.state.confirmPassword,
                                    ...stateConfirmPassword,
                                },
                            },
                            () => {
                                if (stateEmail.success !== true) {
                                    this.state.fieldRef?.email?.current?.getFocus();
                                } else if (stateUsername.success !== true) {
                                    this.state.fieldRef?.username?.current?.getFocus();
                                } else if (statePassword.success !== true) {
                                    this.state.fieldRef?.password?.current?.getFocus();
                                } else if (stateConfirmPassword.success !== true) {
                                    this.state.fieldRef?.confirmPassword?.current?.getFocus();
                                }

                                this.setSubmitState(
                                    stateEmail,
                                    stateUsername,
                                    statePassword,
                                    stateConfirmPassword,
                                )
                            }
                        )
                        break;
                }
            }
        );
    }

    getError(
        key: `email` | `username` | `password` | `confirmPassword`,
        value?: string,
        error?: string
    ): {error?: string, success?: boolean} {
        const state: {error?: string, success?: boolean} = {
            error   : this.state[key]?.error,
            success : this.state[key]?.success
        };

        if (error !== undefined) {
            state.error   = error;
            state.success = false;
            return state;
        }

        if (
            value === undefined
            || `${value}`.length <= 0
        ) {
            state.error   = undefined;
            state.success = undefined;
            return state;
        }

        switch (key) {
            case `email`:
                if (`${value}`.length < 10) {
                    state.error = `Email address must be at least 10 characters long!`;
                    state.success = false;
                } else if (!utils.validation.isValidEmail(value)) {
                    state.error = `Email address is invalid!`;
                    state.success = false;
                } else {
                    state.error   = undefined;
                    state.success = true;
                }
                break;
            case `username`:
                if (`${value}`.length < 4) {
                    state.error = `Username must be at least 4 characters long!`;
                    state.success = false;
                } else {
                    state.error   = undefined;
                    state.success = true;
                }
                break;
            case `password`:
                if (`${value}`.length < 5) {
                    state.error = `Password must be at least 5 characters long!`;
                    state.success = false;
                } else if (!/[A-Z]/.test(`${value}`)) {
                    state.error = `Password must contain at least 1 uppercase!`;
                    state.success = false;
                } else if (!/[a-z]/.test(`${value}`)) {
                    state.error = `Password must contain at least 1 lowercase!`;
                    state.success = false;
                } else if (!/[0-9]/.test(`${value}`)) {
                    state.error = `Password must contain at least 1 number!`;
                    state.success = false;
                } else if (!/[!@#$%&*()_+={}]/.test(`${value}`)) {
                    state.error = `Password must contain at least 1 special character (!@#$%&*()_+-={})!`;
                    state.success = false;
                } else {
                    state.error   = undefined;
                    state.success = true;
                }
                break;
            case `confirmPassword`:
                if (this.state.password?.value !== value) {
                    state.error = `password doesn't match!`;
                } else {
                    state.error   = undefined;
                    state.success = true;
                }
                break;
            default:
                break;
        }

        return state;
    }

    setSubmitState(...state: {error?: string, success?: boolean}[]) {
        const isSuccess = state.length > 0
            ? state.length === state.filter(s=>s.success === true).length
            : this.state.email !== undefined
                && this.state.email?.error === undefined
                && this.state.email?.value !== undefined
                && this.getError(`email`, this.state.email?.value).success
                && this.getError(`email`, this.state.email?.value).error === undefined
                && this.state.username !== undefined
                && this.state.username?.error === undefined
                && this.state.username?.value !== undefined
                && this.getError(`username`, this.state.username?.value).success
                && this.getError(`username`, this.state.username?.value).error === undefined
                && this.state.password !== undefined
                && this.state.password?.error === undefined
                && this.state.password?.value !== undefined
                && this.getError(`password`, this.state.password?.value).success
                && this.getError(`password`, this.state.password?.value).error === undefined
                && this.state.confirmPassword !== undefined
                && this.state.confirmPassword?.error === undefined
                && this.state.confirmPassword?.value !== undefined
                && this.getError(`confirmPassword`, this.state.confirmPassword?.value).success
                && this.getError(`confirmPassword`, this.state.confirmPassword?.value).error === undefined;

        this.setState(
            {
                submit: {
                    ...this.state.submit,
                    success: isSuccess
                        && this.state.submit?.error === undefined
                }
            }
        );
    }

    change(e: KeyboardEvent, key: `email` | `username` | `password` | `confirmPassword`) {
        const value = (e.target as HTMLInputElement).value;

        if (
            e.hasOwnProperty(`key`)
            && e.key.toLowerCase() === `enter`
        ) {
            const mapper = {
                'email'          : this.state.fieldRef?.username,
                'username'       : this.state.fieldRef?.password,
                'password'       : this.state.fieldRef?.confirmPassword,
                'confirmPassword': this.state.fieldRef?.submit,
                'submit'         : this.state.fieldRef?.email,
            };

            if (mapper.hasOwnProperty(key)) {
                if (key === `confirmPassword`) {
                    this.state.submit?.success
                        ? this.state.fieldRef?.submit?.current?.click()
                        : this.state.fieldRef?.email?.current?.getFocus();
                } else {
                    mapper[key]?.current?.getFocus();
                }
            }
        }

        if (
            this.state[key] === undefined
            || this.state[key]?.value !== value
        ) {
            this.setState(
                {
                    [key]: {
                        ...this.state[key],
                        ...this.getError(key, value),
                        value: `${value}`.length > 0 ? value : undefined,
                    }
                },
                () => {
                    if ([`password`, `confirmPassword`].includes(key)) {
                        this.setState(
                            {
                                confirmPassword: {
                                    ...this.state.confirmPassword,
                                    ...this.getError(`confirmPassword`, this.state.confirmPassword?.value),
                                },
                            },
                            () => this.setSubmitState()
                        );
                    } else {
                        this.setSubmitState();
                    }
                }
            );
        }
    }

    render() {
        return <Body background={false}>
            {this.state.redirect !== undefined && <Navigate replace to={this.state.redirect}/>}
            <StyledContainer>
                <StyledContainerContents>
                    <h1>Register</h1>
                    <Form>
                        <Input
                            ref={this.state.fieldRef?.email}
                            type={`email`}
                            placeholder={`Email`}
                            error={this.state.email?.error}
                            success={this.state.email?.success}
                            onBlur={(e: KeyboardEvent) => this.change(e, `email`)}
                            onKeyUp={(e: KeyboardEvent) => this.change(e, `email`)}
                        />
                        <Input
                            ref={this.state.fieldRef?.username}
                            type={`text`}
                            placeholder={`Username`}
                            error={this.state.username?.error}
                            success={this.state.username?.success}
                            onBlur={(e: KeyboardEvent) => this.change(e, `username`)}
                            onKeyUp={(e: KeyboardEvent) => this.change(e, `username`)}
                        />
                        <Input
                            ref={this.state.fieldRef?.password}
                            type={`password`}
                            placeholder={`Password`}
                            error={this.state.password?.error}
                            success={this.state.password?.success}
                            onBlur={(e: KeyboardEvent) => this.change(e, `password`)}
                            onKeyUp={(e: KeyboardEvent) => this.change(e, `password`)}
                        />
                        <Input
                            ref={this.state.fieldRef?.confirmPassword}
                            type={`password`}
                            placeholder={`Confirm Password`}
                            iconName={`password`}
                            error={this.state.confirmPassword?.error}
                            success={this.state.confirmPassword?.success}
                            onBlur={(e: KeyboardEvent) => this.change(e, `confirmPassword`)}
                            onKeyUp={(e: KeyboardEvent) => this.change(e, `confirmPassword`)}
                        />
                        <Input
                            ref={this.state.fieldRef?.submit}
                            type={`submit`}
                            placeholder={`register`}
                            error={this.state.submit?.error}
                            success={this.state.submit?.success}
                            onClick={
                                () => this.setState(
                                    {
                                        submit: {
                                            ...this.state.submit,
                                            success: false
                                        }
                                    },
                                    () => ws.sent(
                                        `account`,
                                        `register`,
                                        {
                                            email          : this.state.email?.value,
                                            username       : this.state.username?.value,
                                            password       : this.state.password?.value,
                                            confirmPassword: this.state.confirmPassword?.value,
                                        },
                                    )
                                )
                            }
                        />
                    </Form>
                    {
                        this.state.feedback
                        && <p>{this.state.feedback}</p>
                    }
                    {
                        LoginPage.props?.enabled === false
                            ? <p></p>
                            : <p>Have already an account? <Link onClick={()=>this.setState({redirect: redirect})}>Sign in</Link> now!</p>
                    }
                </StyledContainerContents>
            </StyledContainer>
        </Body>;
    }
}

const page: pageType = {
    name  : `register`,
    link  : [`/register`],
    page  : <Page />,
    props : {
        enabled  : false,
        menu     : false,
        login    : false,
        redirect : redirect,
    },
};

export default page;