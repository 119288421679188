import React from 'react';
import styled from 'styled-components';
import MuiImageList from '@mui/icons-material/List';

const StyledContainer = styled.div`
    position: relative;
    display: flex;
    flex-grow: 1;
`;
const StyledContainerColumn = styled(StyledContainer)`
    flex-direction: column;
`;
const StyledContainerRow = styled(StyledContainer)`
    flex-direction: row;
`;
const StyledContainerColMax = styled(StyledContainerColumn)`
    max-height: 3.5rem;
`;

const StyledLabel = styled.label`
    display: flex;
    padding: 1rem 1.25rem;
    border-radius: .25rem;
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    background-color: var(--color-background-label);

    &.success {
        color: var(--color-text-success-darker);
    }
`;
const StyledImageList = styled(MuiImageList)`
    max-width: 1.5rem;
    max-height: 1.5rem;
    color: var(--color-text-invert);
`;

const StyledSelect = styled.select`
    display: flex;
    flex-grow: 1;
    border-radius: .25rem;
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
    background-image: none;
    border: 0;
    color: inherit;
    font: inherit;
    margin: 0;
    outline: 0;
    padding: 1rem 4rem 1rem 1.25rem;
    transition: background-color .3s;
    color: var(--color-text);
    background-color: var(--color-background-input);
    cursor: pointer;
    
    &.success {
        color: var(--color-text-success);
        background-color: var(--color-background-input-success);
    }
    &.error {
        color: var(--color-text-error);
        background-color: var(--color-background-input-danger);
    }
    &:is(:hover, :focus) {
        background-color: var(--color-background-input-hover);
    }
    &.success:is(:hover, :focus) {
        background-color: var(--color-background-input-success-hover);
    }
    &.error:is(:hover, :focus) {
        background-color: var(--color-background-input-danger-hover);
    }
    &:disabled {
        cursor: not-allowed;
        color: var(--color-text-error);
        background-color: var(--color-background-input-danger);
    }
`;

const StyledOption = styled.option`
    cursor: pointer;
`;

interface Props {
    defaultOption?: string;
    placeholder: string;
    iconName?: string;
    errorEnabled?: boolean;
    error?: string;
    success?: boolean;
    options: { value: string; name: string; disabled?: boolean}[];
    [key: string]: any;
}

interface State {
    errorEnabled: boolean;
    inputRef: React.RefObject<HTMLSelectElement>;
}

class Select extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            errorEnabled: this.props.errorEnabled !== undefined ? this.props.errorEnabled : true,
            inputRef: React.createRef<HTMLSelectElement>(),
        };
    }

    getFocus() {
        this.state.inputRef.current?.focus();
    }

    render() {
        return <StyledContainerColMax>
            <StyledContainerColumn>
                <StyledContainerRow>
                    <StyledLabel
                        title={this.props.placeholder}
                        className={this.props.success ? `success` : ``}
                    >
                        <StyledImageList/>
                    </StyledLabel>

                    <StyledSelect
                        ref={this.state.inputRef}
                        className={`${this.props.error && this.props.error.length > 0 ? `error` : ``} ${this.props.success ? `success` : ``}`}
                        defaultValue={this.props.options.filter((o)=>o.value===this.props.defaultOption).length > 0 ? this.props.defaultOption : undefined}
                        {...this.props}
                    >
                        {
                            this.props.options.map(
                                (option, index) => <StyledOption
                                    key={index}
                                    value={option.value}
                                    disabled={option.disabled || false}
                                >
                                    {option.name}
                                </StyledOption>
                            )
                        }
                    </StyledSelect>
                </StyledContainerRow>
            </StyledContainerColumn>
        </StyledContainerColMax>;
    }
}

export default Select;