const client = {
    isMobile: (): boolean => [
        /Android/i,
        /webOS/i,
        /iPhone/i,
        /iPad/i,
        /iPod/i,
        /BlackBerry/i,
        /Windows Phone/i
    ].some((item => window.navigator.userAgent.match((item)))),
    isTablet: (): boolean => !client.isMobile()
        && (
        [
            /ipad/i,
            /tablet/i,
            /kindle/i,
            /playbook/i,
            /silk/i,
        ].some((item => window.navigator.userAgent.toLowerCase().match((item))))
        || navigator.maxTouchPoints > 0
    ),
    isDesktop: (): boolean => !client.isMobile() && !client.isTablet(),
    isSmallScreen: (element: HTMLElement = document.documentElement): boolean => element.offsetWidth < 1100
        || element.offsetHeight < 1200,
};
export default client;