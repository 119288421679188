import React from 'react';
import styled from 'styled-components';
import MuiImageMenu from '@mui/icons-material/Menu';
import Features from "./component/menu/feature";
import * as utils from "./utils";
import {pageType} from "./pages";

const StyledMenuContainer = styled.div`
    display: flex;
    flex-direction: column;
    position: fixed;
    top: 2rem;
    left: 0rem;
    bottom: 7rem;
    width: 20rem;
    min-width: 20rem;
    max-width: 20rem;
    padding: 1rem .5rem 1rem 1rem;
    border-top-right-radius: .2rem;
    border-bottom-right-radius: .2rem;
    background-color: var(--color-background-menu);
    transition: all .2s ease-in-out;

    z-index: 2;

    &.collapsed {
        left: -21.5rem;
    }
`;
const StyleMenuExpander = styled.div`
    position: absolute;
    display: flex;
    flex-direction: column;
    top: 1rem;
    right: -2.2rem;
    border-top-right-radius: .4rem;
    border-bottom-right-radius: .4rem;
    cursor: pointer;
    overflow: hidden;
    background-color: var(--color-background-menu);
    z-index: 1;
    
    &.bigIcon {
        right: -5.2rem;
    }
`;
const StyledMenuExpanderIconContainer = styled.div`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    color: var(--color-menu);
    padding: .5rem 0;
    min-width: 2.1875rem;
    max-width: 2.1875rem;
    min-height: 1.3125rem;
    max-height: 1.3125rem;
    align-items: center;
    justify-content: center;

    &:hover {
        color: var(--color-menu-hover);
        background-color: var(--color-background-menu-hover);
    }

    &.bigIcon {
        min-width: 3.1875rem;
        max-width: 3.1875rem;
        min-height: 3.3125rem;
        max-height: 3.3125rem;
        padding: 1rem;
        color: var(--color-text);
    }
`;

const StyledMenuOptionContainer = styled.div`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    padding: 0 .5rem 0 0;
    overflow: auto;
`;

const StyledMenuOption = styled.a`
    padding: .7rem 1rem;
    font-size: 1.2rem;
    text-transform: capitalize;
    color: var(--color-menu);
    background-color: var(--color-background-submenu);
    cursor: pointer;
    margin: 0 0 .5rem 0;
    border-radius: .2rem;

    &:hover {
        color: var(--color-menu-hover);
        background-color: var(--color-background-submenu-hover);
    }
    &:active {
        color: var(--color-menu-active);
        background-color: var(--color-background-submenu-hover);
    }
`;

const StyledMenuDivider = styled.div`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    margin: .5rem -.5rem .5rem -1rem;
    min-height: .1rem;
    max-height: .1rem;
    background-color: rgba(255, 255, 255, .2);
`;

interface Props {
    pages: pageType[];
    login: boolean;
}

interface State {
    bigIcon: boolean;
    menuCollapsed: boolean;
}

class Menu extends React.Component <Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            bigIcon       : utils.client.isMobile(),
            menuCollapsed : utils.storage.menu.isCollapsed(),
        };
    }

    componentDidMount() {
        utils.swipe(
            document.documentElement,
            {
                axis: [`x`],
                boundaries: {
                    left   : 0,
                    right  : 22*16,
                },
                callbackOnStop: ({startPositionX, newPositionX}) => {
                    newPositionX > 15
                    && this.setState(
                        {
                            menuCollapsed: startPositionX > newPositionX
                        },
                        () => utils.storage.menu.setCollapsed(this.state.menuCollapsed)
                    );
                }
            }
        );
    }

    render() {
        return <StyledMenuContainer
            className={
                [
                    this.state.menuCollapsed ? `collapsed` : ``,
                ].join(` `)
            }
        >
            <StyleMenuExpander
                className={this.state.bigIcon ? `bigIcon` : ``}
                onClick={
                    () => {
                        this.setState(
                            {
                                menuCollapsed: !this.state.menuCollapsed
                            },
                            () => utils.storage.menu.setCollapsed(this.state.menuCollapsed)
                        )
                    }
                }
            >
                <StyledMenuExpanderIconContainer
                    className={this.state.bigIcon ? `bigIcon` : ``}
                >
                    <MuiImageMenu
                        style={
                            {
                                width: this.state.bigIcon ? `5rem` : undefined,
                                height: this.state.bigIcon ? `5rem` : undefined,
                            }
                        }
                    />
                </StyledMenuExpanderIconContainer>
            </StyleMenuExpander>
            <StyledMenuOptionContainer>
                {
                    this.props.pages.map(
                        (pageInfo, index: number) => {
                            if (
                                pageInfo.hasOwnProperty(`props`)
                                && pageInfo.props !== undefined
                            ) {
                                if (
                                    pageInfo.props.enabled === false
                                    || pageInfo.props.menu === false
                                    || (pageInfo.props.login !== undefined && pageInfo.props.login !== this.props.login)
                                ) {
                                    return undefined;
                                }
                            }

                            return <StyledMenuOption
                                key={index}
                                href={pageInfo.link[0]}
                            >
                                {pageInfo.name}
                            </StyledMenuOption>;
                        }
                    )
                }
            </StyledMenuOptionContainer>

            <StyledMenuDivider/>
            <Features.Menu/>
        </StyledMenuContainer>;
    }
}

export default Menu;