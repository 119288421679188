import storage from "./index";
import * as utils from '../../utils';

const adsKey = `ads`;
const ads = {
    set: {
        storage: (newStorage: object): boolean => storage.set.key(adsKey, newStorage),
        key: function(key: string, value?: any): boolean {
            if (`${key}`.length === 0) {
                return false;
            }

            const storageAgers = ads.get() || {};
            if (
                typeof storageAgers === `object`
                && storageAgers !== null
            ) {
                storageAgers[key] = value;

                return this.storage(storageAgers);
            }

            return false;
        },
        keys: function(object: object): void {
            const storageAgers = ads.get() || {};
            if (
                typeof storageAgers !== `object`
                || storageAgers === null
            ) {
                return;
            }

            this.storage(
                {
                    ...storageAgers,
                    ...object
                }
            );
        },
    },
    remove: {
        key : (key: string): void => {
            if (`${key}`.length === 0) {
                return;
            }

            const storageAgers = ads.get();
            if (
                typeof storageAgers === `object`
                && storageAgers !== null
                && storageAgers.hasOwnProperty(key)
            ) {
                storageAgers[key] = undefined;
                delete storageAgers[key];
                ads.set.storage(storageAgers);
            }
        },
        keys: (...keys: string[]): void => {
            const storageAgers = ads.get();
            if (
                typeof storageAgers !== `object`
                || storageAgers === null
            ) {
                return;
            }

            keys.forEach(
                key => {
                    if (storageAgers.hasOwnProperty(key)) {
                        storageAgers[key] = undefined;
                        delete storageAgers[key];
                    }
                }
            );

            ads.set.storage(storageAgers);
        }
    },
    clear : (): void => storage.remove.key(adsKey),
    get: (key?: string) => {
        const storageAgers = storage.get(adsKey);
        if (!key) {
            return storageAgers;
        }

        return typeof storageAgers === `object` && storageAgers !== null && storageAgers.hasOwnProperty(key)
            ? storageAgers[key]
            : undefined;
    },

    setClickBlock : (reset: boolean = true): void => {
        const blockedDateTime = new Date();
        blockedDateTime.setDate(blockedDateTime.getDate() + 1);
        blockedDateTime.setHours(0, 0, 0, 0);

        reset
        && ads.removeClickBlock();

        ads.set.key(`clickBlockedUntil`, utils.formattedDate(blockedDateTime));
    },
    removeClickBlock: (): void => ads.remove.keys(`clicked`, `clickBlockedUntil`),
    isClickBlocked: (): boolean => {
        const clickBlockedUntilDateTime = ads.get(`clickBlockedUntil`);

        return clickBlockedUntilDateTime !== null
            && clickBlockedUntilDateTime !== undefined
            && utils.formattedDate(new Date(clickBlockedUntilDateTime)) > utils.formattedDate();
    },
};

export default ads;