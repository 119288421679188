import React                               from 'react';
import Book, {maxPageChunks} from "../../../../../../../../component/bookshelf/book";
import {pagesType, pageType} from "../../../../../../../../pages";
import Link from "../../../../../../../../component/link";
import * as Images from "../../../../../../../../asset/guide/windows/server/2012";

const link  = `/guides/windows/server/2012/raf/adds/redundant`;
let   pages: pagesType = [
    {
        title: `Youtube video`,
        pages: [
            {
                text: <p>In this roles and features guide we are going to install the role "Active Directory Domain Services" (ADDS) on a secondary server.</p>,
                youtubeIdFull: `zZ55g5JLN-U`
            },
        ]
    },
    {
        title: `Install Windows Server 2012`,
        pages: [
            {
                text: <div>
                    <p>Follow this guide to install windows server 2012.</p>
                    <p>Keep in mind that the second service needs an other computer name and ip address.</p>
                    <Link>Windows server 2012 installation</Link>
                </div>,
            },
            {
                text: <div>
                    <p>Since this is the second server we want to use an IP address close to the first server and in the same range.</p>
                    <p>For the first server we used 192.168.81.1 so we will use 192.168.81.2 for the second server.</p>
                    <p>Change the ipv4 address to 192.168.81.2.</p>
                </div>,
            },
        ]
    },
    {
        title: `Promote to domain controller / configure`,
        pages: [
            {
                text: <p>Click on "Manage" and on "Add Roles and Features".</p>,
                imageSrc: Images.RolesAndFeatures.AddRolesAndFeatures
            },
            {
                text: <p>Leave the selected option "Role-based or feature-based installation" and click on "Next &gt;".</p>,
                imageSrc: Images.RolesAndFeatures.adds.secondary.AddRolesAndFeaturesWizard1
            },
            {
                text: <p>Leave the selected option "Select a server from the server pool" and the selected server, in this case "TutorialServer2". Click on "Next &gt;".</p>,
                imageSrc: Images.RolesAndFeatures.adds.secondary.AddRolesAndFeaturesWizard2
            },
            {
                text: <p>Check "Active Directory Domain Services".</p>,
                imageSrc: Images.RolesAndFeatures.adds.secondary.AddRolesAndFeaturesWizard3
            },
            {
                text: <p>Leave the checkbox "Include management tools (if applicable)" checked and click on "Add Features".</p>,
                imageSrc: Images.RolesAndFeatures.adds.secondary.AddRolesAndFeaturesWizard4
            },
            {
                text: <p>Click on "Next &gt;".</p>,
                imageSrc: Images.RolesAndFeatures.adds.secondary.AddRolesAndFeaturesWizard5
            },
            {
                text: <p>Click on "Next &gt;" again.</p>,
                imageSrc: Images.RolesAndFeatures.adds.secondary.AddRolesAndFeaturesWizard6
            },
            {
                text: <p>Click on "Next &gt;" again.</p>,
                imageSrc: Images.RolesAndFeatures.adds.secondary.AddRolesAndFeaturesWizard7
            },
            {
                text: <p>Click on "Install".</p>,
                imageSrc: Images.RolesAndFeatures.adds.secondary.AddRolesAndFeaturesWizard8
            },
            {
                text: <p>Click on "Close".</p>,
                imageSrc: Images.RolesAndFeatures.adds.secondary.AddRolesAndFeaturesWizard9
            },
            {
                text: <p>Click on the flag (there should be a yellow warning sign) in the Server Manager. Click on "Promote this server to a domain controller".</p>,
                imageSrc: Images.RolesAndFeatures.adds.secondary.PromoteToDomainController
            },
            {
                text: <div>
                    <p>Select "Add a domain controller to an existing domain" and fill in the domain name or click on "Select...".</p>
                    <p>In this case i'll use my own domain which is also used in the guide to the first domain controller configuration.</p>
                </div>,
                imageSrc: Images.RolesAndFeatures.adds.secondary.AddToExistingDomain
            },
            {
                text: <p>Fill in "domainName\administrator" (in this guide i'll use my own domain "agers"), also fill in the administrator password.</p>,
                imageSrc: Images.RolesAndFeatures.adds.secondary.DomainCredentials
            },
            {
                text: <p>Click on "Next &gt;" to proceed.</p>,
                imageSrc: Images.RolesAndFeatures.adds.secondary.AddToExistingDomainSummary
            },
            {
                text: <div>
                    <p>Select the domain and click on "Ok".</p>
                    <p>In this case it's my own domain "agers.nl".</p>
                </div>,
                imageSrc: Images.RolesAndFeatures.adds.secondary.SelectDomain
            },
            {
                text: <div>
                    <p>Fill in the password which also is used on the first domain controller.</p>
                    <p>Click on "Next &gt;".</p>
                </div>,
                imageSrc: Images.RolesAndFeatures.adds.secondary.DomainControllerOptionsPassword
            },
            {
                text: <p>We didn't install the DNS role so basically we skip this one by clicking on "Next &gt;".</p>,
                imageSrc: Images.RolesAndFeatures.adds.secondary.DNSOptions
            },
            {
                text: <p>Select in the dropdown box of "Replicate from" the first domain controller we've made, in this case it's "TutorialServer1.agers.nl" and click on "Next &gt;".</p>,
                imageSrc: Images.RolesAndFeatures.adds.secondary.ReplicateFrom
            },
            {
                text: <div>
                    <p>This could be changed, but for this guide i'll leave it default like this.</p>
                    <p>Click on "Next &gt;".</p>
                </div>,
                imageSrc: Images.RolesAndFeatures.adds.secondary.Paths
            },
            {
                text: <p>This is the summary of what will be installed. Click on "Next &gt;".</p>,
                imageSrc: Images.RolesAndFeatures.adds.secondary.ReviewOptions
            },
            {
                text: <p>Click on "Install" to proceed.</p>,
                imageSrc: Images.RolesAndFeatures.adds.secondary.PreRequisitesCheck
            },
            {
                text: <p>The installation is done. Click on "Close".</p>,
                imageSrc: Images.RolesAndFeatures.adds.secondary.DomainConfiguration
            },
            {
                text: <p>After the domain controller is configured, the server will log you out and restarting the server.</p>,
                imageSrc: Images.RolesAndFeatures.adds.SignedOff
            },
            {
                text: <p>Login the server with the administrator account.</p>,
                imageSrc: Images.SignInDomain
            },
            {
                text: <p>Click on "Tools" and on "Active Directory Users and Computers" in the Server manager.</p>,
                imageSrc: Images.RolesAndFeatures.adds.ActiveDirectoryUsersAndComputers
            },
            {
                text: <p>Click on "Users" and verify that the first user you've created in the first ADDS guide is in the map "Users".</p>,
                imageSrc: Images.RolesAndFeatures.adds.secondary.AccountAdmin
            },
            {
                text: <div>
                    <p>Click on "Domain Controllers" and verify that the second server is in the map "Domain Controllers".</p>
                    <p>Close the "Active Directory Users and Computers".</p>
                </div>,
                imageSrc: Images.RolesAndFeatures.adds.secondary.Server2IsAddedToDomainController
            },
            {
                text: <p>Click on "Tools" and on "Active directory Sites and Services" in the Server manager.</p>,
                imageSrc: Images.RolesAndFeatures.adds.ActiveDirectorySitesAndServices
            },
            {
                text: <div>
                    <p>Click on "Sites", "AgersNL" (Site name, could be different in your case), Servers.</p>
                    <p>Verify that the second server is also visible here.</p>
                </div>,
                imageSrc: Images.RolesAndFeatures.adds.secondary.Server2AddedToSite
            },
            {
                text: <p>Congratulations, you've just installed a second domain controller! &#127881;</p>,
            },
        ]
    },
];

const links: string[] = [];
const lengthPages: number = (pages.map(
    (p) => {
        const modulo = p.pages.length % maxPageChunks;
        return p.pages.length + (modulo !== 0 ? maxPageChunks - (p.pages.length % maxPageChunks) : 0);
    }
).reduce((a,b)=>a+b) / (maxPageChunks * 2)) + 3;
for (let i=0;i<lengthPages;i++) {
    links.push(`${link}/${i}`);
}

const page: pageType = {
    name: `Windows Server 2012`,
    link: [
        link,
        ...links,
    ],
    page: <Book
        logo={Images.Logo}
        preTitle={`Windows Server 2012`}
        title={`Roles and Features`}
        subTitle={`Secondary: ADDS`}
        link={link}
        links={links}
        pages={pages}
    />,
    props: {
        menu: true
    }
};

export default page;