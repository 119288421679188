import React                  from 'react';
import styled                 from 'styled-components';
import * as utils             from "../../utils";
import MuiImageThemeLight     from '@mui/icons-material/LightMode';
import MuiImageThemeDark      from '@mui/icons-material/DarkMode';
import MuiImageScreenSmallest from '@mui/icons-material/RemoveFromQueue';
import MuiImageScreenSmall    from '@mui/icons-material/AspectRatio';
import MuiImageScreenNormal   from '@mui/icons-material/Tv';
import MuiImageScreenBig      from '@mui/icons-material/FitScreen';
import MuiImageScreenFull     from '@mui/icons-material/Fullscreen';
import { SideIcon }           from "../../page/feedback";

const StyledMenuFeatureContainer = styled.div`
    display: flex;
    flex-direction: row;
`;

const StyledFrames = styled.div`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    align-items: center;
    justify-items: center;
`;
const StyledTheme = styled(StyledFrames)`
    padding: 0 .5rem;
    min-width: 50%;
    min-height: 1.3125rem;
    max-height: 1.3125rem;

    text-align: center;
    color: var(--color-text-theme);
    cursor: pointer;

    &:hover {
        color: var(--color-text-theme-hover);
    }
`;

const StyledSize = styled(StyledFrames)`
    cursor: pointer;
    color: var(--color-text);

    &:hover {
        color: var(--color-text-hover);
    }
`;
const StyledLabel = styled.div`
    font-size: .5rem;
`;

interface Props {
}

interface State {
    theme: {
        themes: { [themeName: string]: React.ReactNode };
        current: string;
    };
    size: {
        sizes: { [sizeName: string]: React.ReactNode };
        current: string;
    };
}

export const themes: { [themeName: string]: React.ReactNode } = {
    'dark' : <MuiImageThemeDark/>,
    'light': <MuiImageThemeLight/>,
};

export const sizes: { [themeName: string]: React.ReactNode } = {
    'smallest'  : <MuiImageScreenSmallest/>,
    'small'     : <MuiImageScreenSmall/>,
    'normal'    : <MuiImageScreenNormal/>,
    'big'       : <MuiImageScreenBig/>,
    'fullscreen': <MuiImageScreenFull/>,
};

class Feature extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        let storageTheme = utils.storage.features.get(`theme`);
        let storageSize = utils.storage.features.get(`size`);

        if (!themes.hasOwnProperty(storageTheme)) {
            storageTheme = `light`;
        }
        if (!sizes.hasOwnProperty(storageSize)) {
            storageSize = !utils.client.isDesktop() ? `fullscreen` : `normal`;
        }

        document.documentElement.setAttribute(`data-theme`, storageTheme);
        document.documentElement.setAttribute(`data-size`, storageSize);
        this.state = {
            theme: {
                themes : themes,
                current: storageTheme
            },
            size : {
                sizes  : sizes,
                current: storageSize
            },
        };

        this.switch = this.switch.bind(this);
    }

    switch(
        name: `theme` | `size`,
        current: string,
        mapperOptions: { [name: string]: React.ReactNode },
    ) {
        const options: string[] = Object.keys(mapperOptions);
        const mapper: { [name: string]: string } = {};
        options.forEach(
            (option: string, index: number) => {
                index = index === options.length - 1
                    ? 0
                    : index + 1;

                mapper[option] = options[index];
            }
        );

        if (
            !this.state.hasOwnProperty(name)
            || !mapper.hasOwnProperty(current)
        ) {
            return;
        }

        this.setState(
            // @ts-ignore
            {
                [name]: {
                    ...this.state[name],
                    current: mapper[current]
                },
            },
            () => {
                const newCurrent = this.state[name].current;
                utils.storage.features.set.key(name, newCurrent);
                document.documentElement.setAttribute(`data-${name}`, newCurrent);
            }
        )
    }

    render() {
        return <StyledMenuFeatureContainer>
            <StyledTheme
                className={this.state.theme.current}
                title={`Theme: ${this.state.theme.current}`}
                onClick={
                    () => this.switch(
                        `theme`,
                        this.state.theme.current,
                        this.state.theme.themes
                    )
                }
            >
                {this.state.theme.themes[this.state.theme.current]}
                <StyledLabel>
                    {this.state.theme.current}
                </StyledLabel>
            </StyledTheme>
            {
                utils.client.isDesktop()
                && <StyledSize
                    className={this.state.size.current}
                    title={`Size: ${this.state.size.current}`}
                    onClick={
                        () => this.switch(
                            `size`,
                            this.state.size.current,
                            this.state.size.sizes
                        )
                    }
                >
                    {this.state.size.sizes[this.state.size.current]}
                    <StyledLabel>
                        {this.state.size.current}
                    </StyledLabel>
                </StyledSize>
            }
        </StyledMenuFeatureContainer>;
    }
}

const Features = {
    Menu     : Feature,
    Feedback : SideIcon
};

export default Features;