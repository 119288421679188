import React from 'react';
import Chart, {chartType} from "./index";

export type chartBarAndLine = {
    bar: chartType[];
    line: chartType[];
};

const BarAndLineChart = (
    {
        chart
    } : {
        chart: chartBarAndLine
    }
) => <>
    <Chart
        type={`bar`}
        data={chart.bar}
    />
    <Chart
        type={`line`}
        data={chart.line}
    />
</>;

export default BarAndLineChart;