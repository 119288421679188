import React from "react";
import styled from 'styled-components';
import Body from "../component/body";
import {pageType} from "../pages";
import Distance from "./converters/distance";
import Volume from "./converters/volume";
import Weight from "./converters/weight";
import LengthToContent from "./converters/length_to_content";

const StyledConverterLink = styled.a`
    color: var(--color-text);
    
    &:hover {
        color: var(--color-text-hover);
    }
`;

const converters = [
    Distance,
    Volume,
    Weight,
    LengthToContent,
];

const page: pageType = {
    name: `converters/formulas`,
    link: [`/converter`],
    page: <Body
        background={false}
    >
        <h1>Converters / Formulas</h1>
        {
            converters.length > 0
            && <ul>
                {
                    converters.map(
                        (converter, index) => converter.props?.enabled !== false
                            ? <li
                                key={index}
                            >
                                <StyledConverterLink href={converter.link[0]}>{converter.name}</StyledConverterLink>
                            </li>
                            : undefined
                    )
                }
            </ul>
        }
    </Body>,
    props: {},
};

converters.push(page);
export default page;
export {
    converters as ConverterFormulas,
};