import React                               from 'react';
import Book, {maxPageChunks} from "../../../../../../../../component/bookshelf/book";
import {pagesType, pageType} from "../../../../../../../../pages";
import * as Images from "../../../../../../../../asset/guide/windows/server/2012";

const link  = `/guides/windows/server/2012/raf/remote-access/configure`;
let   pages: pagesType = [
    {
        title: `Youtube video`,
        pages: [
            {
                text: <p>In this roles and features guide we are going to configure the role "Remote Access".</p>,
                youtubeIdFull: `fJB96kp0y_s`
            },
        ]
    },
    {
        title: `Add/install role`,
        pages: [
            {
                text: <p>Click on "Manage" and on "Add Roles and Features".</p>,
                imageSrc: Images.RolesAndFeatures.AddRolesAndFeatures
            },
            {
                text: <p>Click on "Next &gt;" to proceed.</p>,
                imageSrc: Images.RolesAndFeatures.remoteAccess.AddRolesAndFeaturesWizard1
            },
            {
                text: <p>Click on "Next &gt;" to proceed.</p>,
                imageSrc: Images.RolesAndFeatures.remoteAccess.AddRolesAndFeaturesWizard2
            },
            {
                text: <p>Check "Remote Access".</p>,
                imageSrc: Images.RolesAndFeatures.remoteAccess.AddRolesAndFeaturesWizard3
            },
            {
                text: <p>Leave the checkbox "Include management tools (if applicable)" checked and click on "Add Features".</p>,
                imageSrc: Images.RolesAndFeatures.remoteAccess.AddRolesAndFeaturesWizard4
            },
            {
                text: <p>Click on "Next &gt;".</p>,
                imageSrc: Images.RolesAndFeatures.remoteAccess.AddRolesAndFeaturesWizard5
            },
            {
                text: <p>Click on "Next &gt;" again.</p>,
                imageSrc: Images.RolesAndFeatures.remoteAccess.AddRolesAndFeaturesWizard6
            },
            {
                text: <p>Click on "Next &gt;".</p>,
                imageSrc: Images.RolesAndFeatures.remoteAccess.AddRolesAndFeaturesWizard7
            },
            {
                text: <p>Check "Routing" and click on "Next &gt;".</p>,
                imageSrc: Images.RolesAndFeatures.remoteAccess.AddRolesAndFeaturesWizard8
            },
            {
                text: <p>Click on "Next &gt;".</p>,
                imageSrc: Images.RolesAndFeatures.remoteAccess.AddRolesAndFeaturesWizard9
            },
            {
                text: <p>Leave the checkboxes as it default is and click on "Next &gt;".</p>,
                imageSrc: Images.RolesAndFeatures.remoteAccess.AddRolesAndFeaturesWizard10
            },
            {
                text: <p>Click on "Install".</p>,
                imageSrc: Images.RolesAndFeatures.remoteAccess.AddRolesAndFeaturesWizard11
            },
            {
                text: <p>Click on "Close".</p>,
                imageSrc: Images.RolesAndFeatures.remoteAccess.AddRolesAndFeaturesWizard2
            }
        ]
    },
    {
        title: `Configure Routing and Remote Access`,
        pages: [
            {
                text: <p>Click on "Tools" and on "Routing and Remote Access".</p>,
                imageSrc: Images.RolesAndFeatures.remoteAccess.RaRa
            },
            {
                text: <p>Right click on "TutorialServer1" and click on "Configure and Enable Routing and Remote Access".</p>,
                imageSrc: Images.RolesAndFeatures.remoteAccess.RaRaConfigure
            },
            {
                text: <p>Click on "Next &gt;".</p>,
                imageSrc: Images.RolesAndFeatures.remoteAccess.RaRaConfigure1
            },
            {
                text: <p>Click on "Network address translation (NAT)" and click on "Next &gt;".</p>,
                imageSrc: Images.RolesAndFeatures.remoteAccess.RaRaConfigure2
            },
            {
                text: <p>Select the NIC (Network Interface) which has a connection to the internet and click on "Next &gt;" to proceed.</p>,
                imageSrc: Images.RolesAndFeatures.remoteAccess.RaRaConfigure3
            },
            {
                text: <p>Click on "Finish".</p>,
                imageSrc: Images.RolesAndFeatures.remoteAccess.RaRaConfigure4
            },
            {
                text: <p>Wait until the services is done, the configuration will close automatically.</p>,
                imageSrc: Images.RolesAndFeatures.remoteAccess.RaRaConfigure5
            },
            {
                text: <p>Expand TutorialServer1 (in this guide) and IPv4.</p>,
                imageSrc: Images.RolesAndFeatures.remoteAccess.RaRaConfigured
            }
        ]
    },
    {
        title: `Internet connection check`,
        pages: [
            {
                text: <p>Go to the right bottom corner, wait for about 2 seconds and you should see "Start" and click.</p>,
                imageSrc: Images.StartMenu
            },
            {
                text: <p>Click on "Internet explorer".</p>,
                imageSrc: Images.Explorer
            },
            {
                text: <p>Fill in a website, for example "www.google.nl" and press enter.</p>,
                imageSrc: Images.RolesAndFeatures.remoteAccess.EnterUrl
            },
            {
                text: <p>The internet connection seems to work.</p>,
                imageSrc: Images.RolesAndFeatures.remoteAccess.Site
            },
            {
                text: <p>Go to the second server.</p>,
                imageSrc: Images.RolesAndFeatures.remoteAccess.Server2
            },
            {
                text: <p>Go to the right bottom corner, wait for about 2 seconds and you should see "Start" and click.</p>,
                imageSrc: Images.StartMenu
            },
            {
                text: <p>Click on "Internet explorer".</p>,
                imageSrc: Images.RolesAndFeatures.remoteAccess.InternetExplorer
            },
            {
                text: <p>Fill in a website, for example "www.google.nl" and press enter.</p>,
                imageSrc: Images.RolesAndFeatures.remoteAccess.EnterUrl
            },
            {
                text: <div>
                    <p>By default every website is blocked on Windows Server.</p>
                    <p>So we need to add the website to "Trusted Sites". We can do this by just go to the website.</p>
                    <p>A window pops up with 2 buttons "Close" and "Add...". Click on "Add..."</p>
                </div>,
                imageSrc: Images.RolesAndFeatures.remoteAccess.SiteBlockAdd
            },
            {
                text: <p>Click on "Add" and close all the screens.</p>,
                imageSrc: Images.RolesAndFeatures.remoteAccess.TrustedSites
            },
            {
                text: <p>The internet connection seems to work.</p>,
                imageSrc: Images.RolesAndFeatures.remoteAccess.Site
            },
            {
                text: <p>Congratulations, you've just configured Routing and Remote access! &#127881;</p>,
            }
        ]
    },
];

const links: string[] = [];
const lengthPages: number = (pages.map(
    (p) => {
        const modulo = p.pages.length % maxPageChunks;
        return p.pages.length + (modulo !== 0 ? maxPageChunks - (p.pages.length % maxPageChunks) : 0);
    }
).reduce((a,b)=>a+b) / (maxPageChunks * 2)) + 1;
for (let i=0;i<=lengthPages;i++) {
    links.push(`${link}/${i}`);
}

const page: pageType = {
    name: `Windows Server 2012`,
    link: [
        link,
        ...links,
    ],
    page: <Book
        logo={Images.Logo}
        preTitle={`Windows Server 2012`}
        title={`Roles and Features`}
        subTitle={`Remote Access: configure`}
        link={link}
        links={links}
        pages={pages}
    />,
    props: {
        menu: true
    }
};

export default page;