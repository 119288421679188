import React from "react";
import {pageType} from "../pages";
import Body from "../component/body";

const page: pageType = {
    name: `home`,
    link: [`/`],
    page: <Body
        background={false}
    >
        <h1>Welcome!</h1>
        <div>
            <p>Hello there, welcome to my website!</p>
            <p>
                What is this website all about?
                <br/>
                Well i'm an enthusiastic programmer who likes to try new pieces of code or learning new features, but also like a system administrator, installing windows server or server features and creating a local network.
                <br/>
                I'll try to make guides for installing windows server, server features or local networks and also trying new pieces of code or features to improve the website.
                <br/>
                <br/>
                If you want to help me to improve this website or add more guides, please disable adblocker (for this website) or allow this site to adblocker, this will help me a lot.
                <br/>
                <br/>
                <a href={`/contact`}>Contact</a> and <a href={`/feedback`}>feedback</a> page are available.
            </p>
        </div>
    </Body>
};

export default page;