import React                               from 'react';
import Book, {maxPageChunks} from "../../../../../../../component/bookshelf/book";
import {pagesType, pageType} from "../../../../../../../pages";
import * as Images from "../../../../../../../asset/guide/windows/server/2012";

const link  = `/guides/windows/server/2012/disk/volumes`;
let   pages: pagesType = [
    {
        title: `Youtube video`,
        pages: [
            {
                text: <p>In this guide we are going to create new volumes.</p>,
                youtubeIdFull: `vfTOvMW6ldQ`
            },
        ]
    },
    {
        title: `Create new volumes`,
        pages: [
            {
                text: <p>Select the second disk and click on "To create a volume, start the New Volume Wizard".</p>,
                imageSrc: Images.Disk.CreateVolume
            },
            {
                text: <p>Check "Don't show this page again" and click on "Next &gt;".</p>,
                imageSrc: Images.Disk.NewVolume1
            },
            {
                text: <p>Select "Disk 1" and click on "Next &gt;".</p>,
                imageSrc: Images.Disk.NewVolume2
            },
            {
                text: <p>If this is the first volume on the disk, it's probably not initialized. This message will popup. Click on "Ok" to proceed.</p>,
                imageSrc: Images.Disk.NewVolume2Message
            },
            {
                text: <p>Fill in a desired disk size.</p>,
                imageSrc: Images.Disk.NewVolume3
            },
            {
                text: <p>Leave the selection to "Drive letter" (the drive letter may vary)</p>,
                imageSrc: Images.Disk.NewVolume4
            },
            {
                text: <p>Leave the File system at "NTFS", allocation unit size "Default", give the volume a name and click on "Next &gt;".</p>,
                imageSrc: Images.Disk.NewVolume5
            },
            {
                text: <p>Click on "Create".</p>,
                imageSrc: Images.Disk.NewVolume6
            },
            {
                text: <p>Click on "Close".</p>,
                imageSrc: Images.Disk.NewVolume7
            },
            {
                text: <p>Right click below the freshly added volume and click on "New Volume...".</p>,
                imageSrc: Images.Disk.NewVolume
            },
            {
                text: <p>Select "Disk 1" and click on "Next &gt;".</p>,
                imageSrc: Images.Disk.NewVolume1Wizard1
            },
            {
                text: <p>Fill in a desired disk size.</p>,
                imageSrc: Images.Disk.NewVolume2Wizard2
            },
            {
                text: <p>Leave the selection to "Drive letter" (the drive letter may vary)</p>,
                imageSrc: Images.Disk.NewVolume3Wizard3
            },
            {
                text: <p>Leave the File system at "NTFS", allocation unit size "Default", give the volume a name and click on "Next &gt;".</p>,
                imageSrc: Images.Disk.NewVolume4Wizard4
            },
            {
                text: <p>Click on "Create".</p>,
                imageSrc: Images.Disk.NewVolume5Wizard5
            },
            {
                text: <p>Click on "Close".</p>,
                imageSrc: Images.Disk.NewVolume6Wizard6
            }
        ]
    },
    {
        title: `Verify volumes`,
        pages: [
            {
                text: <p>Verify 2 new created volumes are present.</p>,
                imageSrc: Images.Disk.Volumes
            },
            {
                text: <p>Open up the explorer. Press "windows key" + "E".</p>,
                imageSrc: Images.Explorer
            },
            {
                text: <p>Verify that the 2 created volumes are visible here..</p>,
                imageSrc: Images.Disk.AvailableHardDrives
            },
            {
                text: <p>Congratulations, you've just configured disk volumes! &#127881;</p>,
            }
        ]
    },
];

const links: string[] = [];
const lengthPages: number = (pages.map(
    (p) => {
        const modulo = p.pages.length % maxPageChunks;
        return p.pages.length + (modulo !== 0 ? maxPageChunks - (p.pages.length % maxPageChunks) : 0);
    }
).reduce((a,b)=>a+b) / (maxPageChunks * 2)) +1;
for (let i=0;i<=lengthPages;i++) {
    links.push(`${link}/${i}`);
}

const page: pageType = {
    name: `Windows Server 2012`,
    link: [
        link,
        ...links,
    ],
    page: <Book
        logo={Images.Logo}
        preTitle={`Windows Server 2012`}
        title={`Disk`}
        subTitle={`Volumes`}
        link={link}
        links={links}
        pages={pages}
    />,
    props: {
        menu: true
    }
};

export default page;