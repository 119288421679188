import React from "react";
import styled from 'styled-components';
import Link from "../link";

import MuiImageArrowClose from '@mui/icons-material/Close';
import MuiImageArrowLeft  from '@mui/icons-material/KeyboardArrowLeft';
import MuiImageArrowRight from '@mui/icons-material/KeyboardArrowRight';
import BookPage           from "./bookPage";
import {pagesType}        from "../../pages";
import * as utils         from "../../utils";
import Ads                from "../ads";

const StyledBookContainer = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    margin: 1rem .125rem .125rem .125rem;
    border-radius: .25rem;
    background: grey;
    box-shadow: 0 0.5rem 1rem 0rem rgba(0, 0, 0, 0.4);
    transition: margin 0.3s ease-in-out, box-shadow 0.3s ease-in-out,
    transform 0.3s ease-in-out;
    filter: grayscale(25%);
    border-top-style: solid;
    border-top-width: .0625rem;
    border-image: linear-gradient(to right, #333, #333 15%, antiquewhite 30%, antiquewhite 70%, #333 85%, #333);
    border-image-slice: 1;
    overflow: hidden;
    min-width: 5rem;

    &.active {
        box-shadow: 0 0.5rem 3rem -0.5rem rgba(0, 0, 0, 0.4);
        position: fixed;
        top: 1.5rem;
        left: 1rem;
        right: 1rem;
        bottom: 1.5rem;
        min-width: 60.625rem;
        min-height: 36.25rem;
        z-index: 1;
        border-top-width: 0;
    }
    
    &.activeFlat {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 1;
        margin: 0;
        padding: 0;
    }
`;

const StyledBook = styled.div`
    display: flex;
    height: 100%;
    width: 100%;
    box-shadow: inset -0.35rem 0 0.5rem rgba(0, 0, 0, 0.4), inset 0.35rem 0 0.5rem rgba(0, 0, 0, 0.4);
    justify-content: center;
    align-items: center;
    writing-mode: vertical-rl;
    
    align-items: center;
    background: radial-gradient(ellipse at top, rgba(0, 0, 0, 0.35), rgba(0, 0, 0, 0.75)),
    radial-gradient(ellipse at bottom, rgba(70, 70, 70, 0.5), transparent);
    font-family: "Arial", cursive;
    color: darkorange;
    
    & h1,
    & h2,
    & h3,
    & h4,
    & h5,
    & h6 {
        margin: 0;
        padding: 0;
        text-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.8);
    }
`;

const StyledBookShading = styled.div`
    &.active {
        position: absolute;
        width: 100%;
        height: 100%;
        background: linear-gradient(to right, transparent, transparent 43%, rgba(30, 30, 30, 0.3) 44%, rgba(30, 30, 30, 0.5) 45%, rgba(30, 30, 30, 0.4) 50%, rgba(30, 30, 30, 0.65) 55%, rgba(30, 30, 30, 0.3) 56%, transparent 57%, transparent);
        z-index: 0;
    }
`;

const StyledBookBackCover = styled.div`
    cursor: pointer;
    position: relative;
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    justify-items: center;
    align-items: center;
    z-index: 1;
    padding: .5rem;
    max-width: 5rem;
    
    &.active {
        z-index: 0;
    }
`;

const StyledLogo = styled.img`
    display: flex;
    flex-direction: column;
    min-width: 2rem;
    max-width: 2rem;
    min-height: 2rem;
    max-height: 2rem;
    transform: rotate(90deg);
    margin: 1rem 0;
    outline: none;
    border: none;
`;
const StyledNoLogoFiller = styled.div`
    display: flex;
    flex-direction: column;
    min-width: 2rem;
    max-width: 2rem;
    min-height: 2rem;
    max-height: 2rem;
    margin: 1rem 0;
`;
const StyledLogoPage = styled(StyledLogo)`
    position: relative;
    max-width: 10rem;
    max-height: 10rem;
    transform: rotate(0deg);
`;

const StyledBookBackCoverHeaders = styled.div`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
`;

const StyledBookContents = styled.div`
    display: flex;
    position: absolute;
    opacity: 0;

    &.active {
        background-color: antiquewhite;
        position: fixed;
        opacity: 100;
        width: calc(100% - 3.125rem);
        height: calc(100% - 3.125rem);
        border-left-style: double;
        border-right-style: double;
        border-left-width: .25rem;
        border-right-width: .25rem;
        border-color: rgb(92, 92, 92);
        border-top-style: solid;
        border-top-width: .0625rem;
        border-top-color: antiquewhite;
        border-bottom-style: groove;
        border-bottom-color: rgb(155, 153, 153);
        border-bottom-width: .125rem;
        color: #333;
        writing-mode: horizontal-tb;
        font-family: "Arial", cursive;
    }
`;

const StyledClose = styled.div`
    cursor: pointer;
    position: absolute;
    border: none;
    width: 2rem;
    top: 0.125rem;
    right: 0.125rem;
    z-index: 1;
    opacity: 85%;
    filter: drop-shadow(0px 0px .125rem black);

    &:hover {
        opacity: 100%;
        filter: drop-shadow(0px 0px .125rem whitesmoke);
    }
    &.activeFlat {
        top: 1.125rem;
        right: 1.525rem;
        color: var(--color-text);
    }
    &.bigIcon {
        top: 3.125rem;
        right: 6.125rem;
    }
`;
const StyledFlat = styled.div`
    cursor: pointer;
    position: absolute;
    top: 0.125rem;
    right: 4.125rem;
    z-index: 1;
    opacity: 85%;
    filter: drop-shadow(0px 0px .125rem black);
    
    &:hover {
        opacity: 100%;
        filter: drop-shadow(0px 0px .125rem whitesmoke);
    }

    &.activeFlat {
        top: 1.325rem;
        right: 4.525rem;
        color: var(--color-text);
    }
    &.bigIcon {
        top: 3.125rem;
        right: 12.125rem;
        font-size: 3rem;
    }
`;

const StyledPage = styled.div`
    position: absolute;
    top: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    width: calc(50% - 7rem);
    height: calc(100% - 4rem);
    min-width: 19.34375rem;
    min-height: 28.0625rem;
    margin: 2rem;
    overflow: hidden;
    z-index: 1;

    & h1,
    & h3 {
        text-shadow: none;
    }
    & p {
        margin: 1rem;
    }
`;
const StyledPageLeft = styled(StyledPage)`
    padding: 0 1rem 0 2rem;
    left: 0;
    right: 50%;
`;
const StyledPageRight = styled(StyledPage)`
    padding: 0 2rem 0 1rem;
    left: 50%;
    right: 0;
`;

const StyledPageShading = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    background: linear-gradient(to right, rgba(30, 30, 30, 0.1), transparent 25%, transparent 30%, rgba(30, 30, 30, 0.3) 48%, rgba(30, 30, 30, 0.4) 50%, transparent 50%, rgba(30, 30, 30, 0.3) 70%, transparent);
`;

const StyledPageAlign = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    z-index: 1;
`;

const StyledPageNavigation = styled.div`
    position: absolute;
    display: flex;
    top: calc(50% - 2.5rem);
    bottom: calc(50% - 2.5rem);
    width: 5rem;
    justify-content: center;
    align-items: center;
    z-index: 1;
    cursor: pointer;
    
    &.disabled {
        display: none;
        background-color: red;
    }
`;
const StyledPageNavigationLeft = styled(StyledPageNavigation)`
    left: -1.5rem;
`;
const StyledPageNavigationRight = styled(StyledPageNavigation)`
    right: -1.5rem;
`;

const StyledPageNumber = styled.div`
    position: absolute;
    bottom: .5rem;
`;
const StyledPageNumberLeft = styled(StyledPageNumber)`
    left: 1rem;
`;
const StyledPageNumberRight = styled(StyledPageNumber)`
    right: 1rem;
`;

const StyledOnePageContainer = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    writing-mode: horizontal-tb;
    background-color: var(--color-background);
    opacity: 0;
    z-index: 0;
    
    font-family: "Arial", cursive;
    
    &.active {
        opacity: 100;
        z-index: 1;
    }
`;
const StyledOnePage = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    overflow: auto;
    color: var(--color-text);
`;
const StyledContentContainer = styled.div`
    padding: 5rem;
`;

const StyledTableOfContentLink = styled(Link)`
    &.activeFlat {
        cursor: text;
    }
`;
const StyledClosedArrow = styled(MuiImageArrowClose)`
    width: 100%;
    height: 100%;
`;

export type pageType = {
    preTitle?: string;
    title?: string;
    subTitle?: string | React.ReactNode;
    text?: string | React.ReactNode;
    youtubeId?: string;
    youtubeIdFull?: string;
    imageSrc?: string;
};

interface Props {
    logo?: string;
    preTitle: string;
    title?: string;
    subTitle?: string | React.ReactNode;
    link: string;
    links: string[];
    pages: pagesType
}

interface State {
    enableFlatBookToggle: boolean;
    bigIcon: boolean;
    flat: boolean;
    active: boolean;
    alreadyLoaded: boolean;
    activePages: number;
    tableOfContents: { [key: string]: number };
    allPages: pageType[][],
    leftPages: pageType[][],
    rightPages: pageType[][],
    color          : {
        cover: string;
        text: string;
    };
}

const maxPageChunks = 3;

class Book extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        const isSmallScreen = utils.client.isSmallScreen();
        const isTablet = utils.client.isTablet();
        const isMobile = utils.client.isMobile();

        const flat: boolean = isMobile || (isTablet && isSmallScreen) || utils.storage.account.preferences.get(`flat`) || false;
        let tableOfContents = {};

        let pages: pagesType = [
            ...this.props.pages
        ];

        const ads = {
            isAd: true
        };

        pages = pages.map(
            (p, index: number) => {
                p.pageInfo = {
                    pageNumber : 0,
                    pages      : Math.ceil(p.pages.length / maxPageChunks),
                    difference : (Math.ceil(p.pages.length / maxPageChunks) * maxPageChunks) - p.pages.length,
                };

                if (p.pageInfo.difference > 0) {
                    for (let i=0; i<p.pageInfo.difference; i++) {
                        p.pages.push(ads);
                    }

                }

                if (index === 0) {
                    p.pageInfo.pageNumber = 3;
                    p.pageInfo.index = 1;
                }

                const previousPage = pages[index - 1];
                if (
                    previousPage !== undefined
                    && previousPage.pageInfo !== undefined
                ) {
                    p.pageInfo.pageNumber = previousPage.pageInfo.pageNumber + previousPage.pageInfo.pages;
                    p.pageInfo.index      = Math.ceil((p.pageInfo.pageNumber/2)-1);
                }

                if (p.pageInfo.index !== undefined) {
                    p.link = this.props.links[p.pageInfo.index];
                }

                return p;
            }
        );

        pages = [
            {
                title: [
                    this.props.preTitle,
                    this.props.title,
                    this.props.subTitle
                ].join(` `),
                pages: [
                    {
                        text: this.props.logo
                            ? <StyledLogoPage src={this.props.logo} />
                            : <StyledNoLogoFiller/>
                    }
                ]
            },
            {
                title: `Table of Contents`,
                subTitle: <hr/>,
                pages: [{}]
            },
            ...pages,
        ];

        pages[1] = {
            ...pages[1],
            pages: [
                {
                    text: <StyledPageAlign>
                        {
                            pages.map(
                                (page, index: number) => <StyledTableOfContentLink
                                    key={index}
                                    className={
                                        [
                                            `toc`
                                        ].join(` `)
                                    }
                                    onClick={
                                        () => page.link !== undefined ? this.setPage(Number(page.link.split(`/`).reverse()[0])) : undefined
                                    }
                                >
                                    {`${index + 1}. ${page.title}`}
                                </StyledTableOfContentLink>
                            )
                        }
                    </StyledPageAlign>
                }
            ]
        };

        const allPages: pageType[][] = [];
        const leftPages: pageType[][] = [];
        const rightPages: pageType[][] = [];
        pages.forEach(
            (page) => {
                if (page.pages.length > 0) {
                    const pagePages: pageType[] = page.pages.filter(p=>Object.keys(p).length > 0);

                    const difference = maxPageChunks - (pagePages.length % maxPageChunks);
                    if (difference < maxPageChunks) {
                        for (let i=0; i<difference; i++) {
                            pagePages.push({});
                        }
                    }

                    if (pagePages.length <= maxPageChunks) {
                        pagePages[0] = {
                            preTitle : page.preTitle,
                            title    : page.title,
                            subTitle : page.subTitle,
                            ...pagePages[0]
                        };

                        if (pagePages.length > 0) {
                            allPages.push(pagePages);
                            leftPages.length > rightPages.length
                                ? rightPages.push(pagePages)
                                : leftPages.push(pagePages);
                        }
                    } else {
                        const loopLength = Math.floor(pagePages.length / maxPageChunks);
                        if (loopLength > 0) {
                            for (let i=0;i<loopLength;i++) {
                                let index = i*maxPageChunks;
                                const pagePagesChunks = pagePages.slice(index, index+maxPageChunks);
                                if (i === 0) {
                                    pagePagesChunks[0] = {
                                        preTitle : page.preTitle,
                                        title    : page.title,
                                        subTitle : page.subTitle,
                                        ...pagePagesChunks[0]
                                    };
                                }

                                if (pagePagesChunks.length > 0) {
                                    allPages.push(pagePagesChunks);
                                    leftPages.length > rightPages.length
                                        ? rightPages.push(pagePagesChunks)
                                        : leftPages.push(pagePagesChunks);
                                }
                            }
                        }
                    }

                }
            }
        );

        if (leftPages.length > rightPages.length) {
            rightPages.push([]);
        }

        let activePages: number = 0;
        if (window.location.href.includes(this.props.link)) {
            activePages = Number(window.location.href.split(`${this.props.link}/`).reverse()[0]);
            if (Object.keys(leftPages)[activePages] === undefined) {
                activePages = 0;
            }
        }
        const active: boolean = window.location.pathname.includes(this.props.link);

        this.state = {
            enableFlatBookToggle: !isMobile,
            bigIcon             : isMobile,
            flat                : flat,
            active              : active,
            alreadyLoaded       : active,
            activePages         : activePages,
            tableOfContents     : tableOfContents,
            allPages            : allPages,
            leftPages           : leftPages,
            rightPages          : rightPages,
            color               : {
                cover: utils.color.stringToColor(this.props.preTitle),
                text : utils.color.stringToColor(Array.from(Array(20)).map(() => this.props.preTitle).join(` `)),
            },
        };

        active && this.noScroll();
        document.documentElement.addEventListener(
            `keyup`,
            (e: KeyboardEvent) => {
                if (this.state.active) {
                    e.key.toLowerCase() === `escape` && this.setState({active: false}, () => this.scrollable() && this.setUrl(`/guides`));

                    e.key.toLowerCase() === `arrowleft` && this.setPage(this.state.activePages - 1);
                    e.key.toLowerCase() === `arrowright` && this.setPage(this.state.activePages + 1);
                }
            }
        );
    }

    setPage(number: number) {
        number >= 0 && number <= (this.state.leftPages.length - 1) && this.state.activePages !== number && this.setState(
            {activePages: number},
            () => utils.setUrl(`${this.props.link}${this.state.activePages > 0 ? `/${this.state.activePages}` : ``}`)
        );
    }
    setUrl(url: string) {
        url.length > 4 && url !== `${this.props.link}/${this.state.activePages}` && this.setState(
            {activePages: 0},
            () => utils.setUrl(url)
        );
    }

    scrollable(): boolean {
        (document.documentElement as HTMLElement).style.overflow = ``;
        return true;
    }
    noScroll(): boolean {
        (document.documentElement as HTMLElement).style.overflow = `hidden`;
        return true;
    }

    render() {
        return <StyledBookContainer
            className={this.state.active ? (this.state.flat ? `activeFlat` : `active`) : ``}
            style={
                {
                    backgroundColor: this.state.color.cover
                }
            }
        >
            <StyledBook>
                <StyledBookShading
                    className={this.state.active ? `active` : ``}
                />
                <StyledBookBackCover
                    className={this.state.active ? `active` : ``}
                    onClick={
                        () => this.setState(
                            {
                                active: true,
                                alreadyLoaded: true,
                                flat: utils.storage.account.preferences.get(`flat`) || this.state.flat
                            },
                            () => this.noScroll() && this.setUrl(this.props.link)
                        )
                    }
                >
                    {
                        this.props.logo
                            ? <StyledLogo src={this.props.logo} />
                            : <StyledNoLogoFiller/>
                    }
                    <StyledBookBackCoverHeaders>
                        <span
                            style={
                                {
                                    color: this.state.color.text
                                }
                            }
                        >
                            {this.props.preTitle}
                        </span>
                        <span
                            style={
                                {
                                    color: this.state.color.text
                                }
                            }
                        >
                            {
                                this.props.subTitle === undefined
                                    ? <strong>{this.props.title}</strong>
                                    : this.props.title
                            }
                        </span>
                        {
                            this.props.subTitle
                            && <span
                                style={
                                    {
                                        color: this.state.color.text
                                    }
                                }
                            >
                                <strong>{this.props.subTitle}</strong>
                            </span>
                        }
                    </StyledBookBackCoverHeaders>
                </StyledBookBackCover>
                {
                    this.state.flat
                        ? (
                            <StyledOnePageContainer
                                className={this.state.active ? `active` : ``}
                            >
                                {
                                    this.state.enableFlatBookToggle
                                    && <StyledFlat
                                        className={
                                            [
                                                this.state.bigIcon ? `bigIcon` : ``,
                                                this.state.flat ? `activeFlat` : ``
                                            ].join(` `)
                                        }
                                        onClick={
                                            () => this.setState(
                                                {
                                                    flat: !this.state.flat
                                                },
                                                () => utils.storage.account.preferences.set.key(`flat`, this.state.flat)
                                            )
                                        }
                                    >
                                        {this.state.flat ? `Book` : `Flat`}
                                    </StyledFlat>
                                }
                                <StyledClose
                                    title={`Close Tutorial/Guide`}
                                    className={
                                        [
                                            this.state.bigIcon ? `bigIcon` : ``,
                                            this.state.flat ? `activeFlat` : ``
                                        ].join(` `)
                                    }
                                    onClick={
                                        () => this.setState({active: false}, () => this.scrollable() && this.setUrl(`/guides`))
                                    }
                                >
                                    <StyledClosedArrow
                                        style={
                                            {
                                                width: this.state.bigIcon ? `5rem` : undefined,
                                                height: this.state.bigIcon ? `5rem` : undefined,
                                            }
                                        }
                                    />
                                </StyledClose>
                                <StyledOnePage>
                                    {
                                        this.state.alreadyLoaded
                                        && this.state.allPages.map(
                                            (pageItems, index) => <StyledContentContainer
                                                key={index}
                                            >
                                                {
                                                    pageItems.map(
                                                        (page, index) => <BookPage key={index} index={index} flat={this.state.flat || false} {...page}/>
                                                    )
                                                }
                                                <Ads adType={`guide`}/>
                                            </StyledContentContainer>
                                        )
                                    }
                                </StyledOnePage>
                            </StyledOnePageContainer>
                        )
                        : (
                            <StyledBookContents
                                className={this.state.active ? `active` : ``}
                            >
                                {
                                    this.state.enableFlatBookToggle
                                    && <StyledFlat
                                        className={
                                            [
                                                this.state.bigIcon ? `bigIcon` : ``,
                                                this.state.flat ? `activeFlat` : ``
                                            ].join(` `)
                                        }
                                        onClick={
                                            () => this.setState(
                                                {
                                                    flat: !this.state.flat
                                                },
                                                () => utils.storage.account.preferences.set.key(`flat`, this.state.flat)
                                            )
                                        }
                                    >
                                        {this.state.flat ? `Book` : `Flat`}
                                    </StyledFlat>
                                }
                                <StyledClose
                                    title={`Close Tutorial/Guide`}
                                    className={
                                        [
                                            this.state.bigIcon ? `bigIcon` : ``,
                                            this.state.flat ? `activeFlat` : ``
                                        ].join(` `)
                                    }
                                    onClick={
                                        () => this.setState({active: false}, () => this.scrollable() && this.setUrl(`/guides`))
                                    }
                                >
                                    <StyledClosedArrow/>
                                </StyledClose>
                                {
                                    this.state.alreadyLoaded
                                    && this.state.leftPages.map(
                                        (leftPage, index: number) => {
                                            let pageNumber = index === 0 ? 1 : (index + index + 1);

                                            return <StyledPageLeft
                                                key={`left_${index}`}
                                                style={
                                                    {
                                                        opacity: this.state.activePages === index ? 1 : 0,
                                                        zIndex: this.state.activePages === index ? 1 : 0
                                                    }
                                                }
                                            >
                                                <StyledPageNavigationLeft
                                                    className={this.state.activePages === 0 ? `disabled` : ``}
                                                    onClick={
                                                        () => this.setPage(this.state.activePages - 1)
                                                    }
                                                >
                                                    <MuiImageArrowLeft style={{fontSize : `5rem`}} />
                                                </StyledPageNavigationLeft>
                                                {
                                                    leftPage.map(
                                                        (page, index: number) => <BookPage key={index} index={index} {...page}/>
                                                    )
                                                }
                                                <StyledPageNumberLeft>{pageNumber}</StyledPageNumberLeft>
                                            </StyledPageLeft>;
                                        }
                                    )
                                }
                                {
                                    this.state.alreadyLoaded
                                    && this.state.rightPages.map(
                                        (rightPage, index: number) => {
                                            let pageNumber = index === 0 ? 2 : (index + index + 2);

                                            return <StyledPageRight
                                                key={`right_${index}`}
                                                style={
                                                    {
                                                        opacity: this.state.activePages === index ? 1 : 0,
                                                        zIndex: this.state.activePages === index ? 1 : 0
                                                    }
                                                }
                                            >
                                                <StyledPageNavigationRight
                                                    className={this.state.activePages === this.state.rightPages.length - 1 ? `disabled`: ``}
                                                    onClick={
                                                        () => this.setPage(this.state.activePages + 1)
                                                    }
                                                >
                                                    <MuiImageArrowRight style={{fontSize: `5rem`}}/>
                                                </StyledPageNavigationRight>
                                                {
                                                    rightPage.map(
                                                        (page, index: number) =>  <BookPage key={index} index={index} {...page}/>
                                                    )
                                                }
                                                <StyledPageNumberRight>{pageNumber}</StyledPageNumberRight>
                                            </StyledPageRight>;
                                        }
                                    )
                                }
                                <StyledPageShading/>
                            </StyledBookContents>
                        )
                }
            </StyledBook>
        </StyledBookContainer>;
    }
}

export default Book;
export {
    maxPageChunks
};