import React from 'react';
import styled from 'styled-components';
import * as utils from "../utils";

const StyledDonutContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: 100%;
  height: 100%;
  //width: 100px;
  //height: 100px;

  align-items: center;
  justify-items: center;

  position: relative;
`;
const StyledDonut = styled.svg`
  position: relative;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: 100%;
  height: 100%;
  margin: auto;
  transform: rotate(-90deg);

  & > circle {
    cx: 50%;
    cy: 50%;
    r: calc(50% - 1rem);

    fill: none;
    stroke-width: 10px;
  }
  & > circle:first-child {
    stroke: #e6e6e6;
  }
  & > circle:last-child {
    transition: stroke-dashoffset .25s ease-in-out;
  }
`;

const StyledText = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  text-align: center;
  
  & > div {
    text-align: center;
    font-size: .8rem;
  }
`;

interface Props {
    prefix: string|undefined;
    maxValue: number;
    currentValue: number;
}
interface State {
    ref: {
        svg: React.RefObject<SVGSVGElement>;
    };
    width: number;
    configuration: {
        min: number;
        max: number;
    };
}

class Circle extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            ref: {
                svg: React.createRef<SVGSVGElement>(),
            },
            width: 0,
            configuration: {
                min: 0,
                max: 1000,
            },
        };
    }

    componentDidMount() {
        this.setState({
            width: this.state.ref.svg.current !== null ? this.state.ref.svg.current.getBBox().width / 2 : 0,
        });
    }

    render() {
        const percentage = (this.props.currentValue / this.props.maxValue) * 100;
        const circumference = this.state.width !== 0 ? this.state.width * 2 * Math.PI : 0;
        const offset = circumference !== 0 && percentage !== 0 ? circumference - (percentage / 100) * circumference : circumference;

        return <StyledDonutContainer>
            <StyledDonut
                ref={this.state.ref.svg}
            >
                <circle className="donut-chart__background" />
                <circle className="donut-chart__progress"
                    stroke={percentage > 0 ? `#cc1e01` : `#5d9604`}
                    style={{
                        strokeDasharray: circumference,
                        strokeDashoffset: offset,
                    }}
                />
            </StyledDonut>
            <StyledText>
                <div>{this.props.prefix}</div>
                <div>{utils.number.format(this.props.currentValue,0)} / {this.props.maxValue}</div>
                <div>watt ({percentage.toFixed(2)} %)</div>
            </StyledText>
        </StyledDonutContainer>;
    }
}


export default Circle;