import React from "react";
import Body from "../../component/body";
import {pageType} from "../../pages";
import Bookshelf from "../../component/bookshelf";
import Windows from "./books/windows";

const allGuides = [
    ...Windows
].filter((guide) => guide.props?.enabled !== false);

const page: pageType = {
    name : `tutorials/guide`,
    link : [
        `/guides`,
        ...allGuides.map((guide)=>guide.link).reduce((a,b)=>[...a,...b]),
    ],
    page : <Body
        background={false}
    >
        <Bookshelf
            title={`Tutorials / Guides`}
            allGuides={allGuides}
        />
    </Body>,
    props: {
        menu    : true,
        login   : false,
        redirect: `/home`
    },
};

export default page;