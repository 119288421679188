import Login from './account/login';
import Register from './account/register';
import Profile from './account/profile';

import Home from './account/home';
import Monitoring from './account/monitoring';

const Account = [
    Login,
    Register,
    Profile,

    ...Home,
    Monitoring
];

export default Account;
export {
    Login,
    Register,
    Profile,

    Home,
};