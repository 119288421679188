import React      from 'react';
import ReactDOM   from 'react-dom/client';
import LOGO_AGERS from './asset/agers.png';
import Body       from './body';
import Ads        from "./component/ads";
import Links      from "./component/links";
import * as utils from './utils';

const elements: { [name: string]: React.ReactElement } = {
    'agers-header': <img alt={`No logo`} src={LOGO_AGERS}/>,
    'agers-body'  : <Body/>,
    'agers-links' : <Links/>,
};

const renderElement = (element: HTMLElement, reactNode: React.ReactElement) => ReactDOM.createRoot(element).render(reactNode);
Object.keys(elements).forEach(
    element => renderElement(document.getElementById(element) as HTMLElement, elements[element])
);

utils.isDevelopment()
&& Array.from([
    ...document.getElementsByTagName(`script`),
    ...document.getElementsByTagName(`iframe`),
]).forEach((e) => e.src.includes(`google`) && e.remove());

document.addEventListener(
    `DOMContentLoaded`,
    () => Array.from(document.getElementsByClassName(`agers-box`)).forEach((element) => renderElement(element as HTMLElement, <Ads adType={`page`} />))
);