import React                               from 'react';
import Book, {maxPageChunks} from "../../../../../../../../component/bookshelf/book";
import {pagesType, pageType} from "../../../../../../../../pages";
import * as Images from '../../../../../../../../asset/guide/windows/server/2012';

const link  = `/guides/windows/server/2012/raf/adds/configure`;
let   pages: pagesType = [
    {
        title: `Youtube video`,
        pages: [
            {
                text: <p>In this roles and features guide we are going to install the role "Active Directory Domain Services" (ADDS).</p>,
                youtubeIdFull: `IX1QzFxxf5U`
            },
        ]
    },
    {
        title: `Add/install role`,
        pages: [
            {
                text: <p>Click on "Manage" and on "Add Roles and Features".</p>,
                imageSrc: Images.RolesAndFeatures.AddRolesAndFeatures
            },
            {
                text: <p>Check "Skip this page by default" and click on "Next".</p>,
                imageSrc: Images.RolesAndFeatures.adds.AddRolesAndFeaturesWizard1
            },
            {
                text: <p>Leave the selected option "Role-based or feature-based installation" and click on "Next".</p>,
                imageSrc: Images.RolesAndFeatures.adds.AddRolesAndFeaturesWizard2
            },
            {
                text: <p>Leave the selected option "Select a server from the server pool" and the selected server, in this case "TutorialServer1". Click on "Next".</p>,
                imageSrc: Images.RolesAndFeatures.adds.AddRolesAndFeaturesWizard3
            },
            {
                text: <p>Check "Active Directory Domain Services".</p>,
                imageSrc: Images.RolesAndFeatures.adds.AddRolesAndFeaturesWizard4
            },
            {
                text: <p>Leave the checkbox "Include management tools (if applicable)" checked and click on "Add Features".</p>,
                imageSrc: Images.RolesAndFeatures.adds.AddRolesAndFeaturesWizard5
            },
            {
                text: <p>Click on "Next".</p>,
                imageSrc: Images.RolesAndFeatures.adds.AddRolesAndFeaturesWizard6
            },
            {
                text: <p>Click on "Next" again.</p>,
                imageSrc: Images.RolesAndFeatures.adds.AddRolesAndFeaturesWizard7
            },
            {
                text: <p>Click on "Next" again.</p>,
                imageSrc: Images.RolesAndFeatures.adds.AddRolesAndFeaturesWizard8
            },
            {
                text: <p>Click on "Install".</p>,
                imageSrc: Images.RolesAndFeatures.adds.AddRolesAndFeaturesWizard9
            },
            {
                text: <p>Click on "Close".</p>,
                imageSrc: Images.RolesAndFeatures.adds.AddRolesAndFeaturesWizard10
            },
        ]
    },
    {
        title: `Promote to domain controller / configure`,
        pages: [
            {
                text: <p>Click on the flag (there should be a yellow warning sign) in the Server Manager. Click on "Promote this server to a domain controller".</p>,
                imageSrc: Images.RolesAndFeatures.adds.PromoteToDomainController
            },
            {
                text: <div>
                    <p>Select "Add a new forest" and fill in a Root domain name.</p>
                    <p>In this case i'll use my own domain. But technically it could be any name but it needs to contain at least 1 dot in it. For example; agers.local, tutorial.local, tutorial.nl or example.local.</p>
                    <p>Keep in mind that this name will be used on any computer which is connected to this domain or will join this domain.</p>
                </div>,
                imageSrc: Images.RolesAndFeatures.adds.DomainName
            },
            {
                text: <div>
                    <p>Fill in a password, keep in mind if you want to add a secondary domain controller server you'll need this password.</p>
                    <p>I recommend to keep this equal to the administrator password.</p>
                </div>,
                imageSrc: Images.RolesAndFeatures.adds.DomainControllerOptions
            },
            {
                text: <p>{`We didn't install the DNS role so basically we skip this one by clicking on "Next >".`}</p>,
                imageSrc: Images.RolesAndFeatures.adds.DNSOptions
            },
            {
                text: <p>{`This is the NetBIOS name which will be used by logging in the server or joined clients. Click on "Next >" if this name seems fine.`}</p>,
                imageSrc: Images.RolesAndFeatures.adds.NetBIOSDomainName
            },
            {
                text: <p>{`This could be changed, but for this guide i'll leave it default like this. Click on "Next >".`}</p>,
                imageSrc: Images.RolesAndFeatures.adds.Paths
            },
            {
                text: <p>{`This is the summary of what will be installed. Click on "Next >".`}</p>,
                imageSrc: Images.RolesAndFeatures.adds.ReviewOptions
            },
            {
                text: <p>Click on "Install" to proceed.</p>,
                imageSrc: Images.RolesAndFeatures.adds.PreRequisites
            },
            {
                text: <p>After the domain controller is configured, the server will log you out and restarting the server.</p>,
                imageSrc: Images.RolesAndFeatures.adds.DomainConfigurationDone
            },
        ]
    },
    {
        title: `Active directory sites and services`,
        pages: [
            {
                text: <p>Login the server with the administrator account.</p>,
                imageSrc: Images.SignInDomain
            },
            {
                text: <p>Click on "Tools" and on "Active directory Sites and Services" in the Server manager.</p>,
                imageSrc: Images.RolesAndFeatures.adds.ActiveDirectorySitesAndServices
            },
            {
                text: <div>
                    <p>Select "Default-First-Site-Name", right click and click on "Rename".</p>
                    <p>Change the name to like a location. This could be a country, state or land. Anything really.</p>
                    <p>For this guide i'll use my domain name "Agers" with NL since i live in the Netherlands.</p>
                </div>,
                imageSrc: Images.RolesAndFeatures.adds.RenameDefaultSite
            },
            {
                text: <p>Right click on the site again and click on "Properties".</p>,
                imageSrc: Images.RolesAndFeatures.adds.SiteProperties
            },
            {
                text: <div>
                    <p>Fill in a description, also this could be anything you want. This is optional.</p>
                    <p>For this guide i'll use "Country/Location".</p>
                    <p>Click on "Location" tab.</p>
                </div>,
                imageSrc: Images.RolesAndFeatures.adds.SitePropertiesGeneral
            },
            {
                text: <div>
                    <p>Fill in a location, again this could be anything you want. This is optional.</p>
                    <p>For this guide i'll use "Netherlands".</p>
                    <p>Click on "Apply" and on "Ok".</p>
                </div>,
                imageSrc: Images.RolesAndFeatures.adds.SitePropertiesLocation
            },
        ]
    },
    {
        title: `Create first domain account`,
        pages: [
            {
                text: <p>Click on "Tools" and on "Active Directory Users and Computers" in the Server manager.</p>,
                imageSrc: Images.RolesAndFeatures.adds.ActiveDirectoryUsersAndComputers
            },
            {
                text: <div>
                    <p>Expand "agers.nl" (your domain name) and click on "Users".</p>
                    <p>Right click on a empty area near the user descriptions.</p>
                    <p>{`Hover on "New >" and click on "User".`}</p>
                </div>,
                imageSrc: Images.RolesAndFeatures.adds.NewUser
            },
            {
                text: <div>
                    <p>Fill in the user information, for this guide i'll use "admin".</p>
                    <ul>
                        <li>First name</li>
                        <li>Initials</li>
                        <li>Last name</li>
                        <li>Full name</li>
                        <li>User logon name</li>
                    </ul>
                    <p>{`Click on "Next >".`}</p>
                </div>,
                imageSrc: Images.RolesAndFeatures.adds.UserInformation
            },
            {
                text: <div>
                    <p>Pick a very strong password, with at lease:</p>
                    <ul>
                        <li>1 uppercase</li>
                        <li>1 lowercase</li>
                        <li>1 number</li>
                        <li>1 special character</li>
                        <li>at least 8 characters long</li>
                    </ul>

                    <p>{`Check "Password never expires" and click on "Next >".`}</p>
                </div>,
                imageSrc: Images.RolesAndFeatures.adds.UserPassword
            },
            {
                text: <p>Click on "Finish" to proceed.</p>,
                imageSrc: Images.RolesAndFeatures.adds.UserSummary
            },
        ]
    },
    {
        title: `Domain controller properties`,
        pages: [
            {
                text: <p>Click on "Domain controllers", right click on the server in this case "TutorialServer1" and click on "Properties".</p>,
                imageSrc: Images.RolesAndFeatures.adds.DomainControllerProperties
            },
            {
                text: <div>
                    <p>See that the domain controller has the site "AgersNL" (or in you case it can be different, it depends on the name you've chosen).</p>
                    <p>Click on "Cancel".</p>
                </div>,
                imageSrc: Images.RolesAndFeatures.adds.DomainControllerPropertiesCancel
            },
            {
                text: <p>Congratulations, you've just installed a Active Directory Domain Services! &#127881;</p>,
            },
        ]
    },
];

const links: string[] = [];
const lengthPages: number = (pages.map(
    (p) => {
        const modulo = p.pages.length % maxPageChunks;
        return p.pages.length + (modulo !== 0 ? maxPageChunks - (p.pages.length % maxPageChunks) : 0);
    }
).reduce((a,b)=>a+b) / (maxPageChunks * 2)) + 1;
for (let i=0;i<lengthPages;i++) {
    links.push(`${link}/${i}`);
}

const page: pageType = {
    name: `Windows Server 2012`,
    link: [
        link,
        ...links,
    ],
    page: <Book
        logo={Images.Logo}
        preTitle={`Windows Server 2012`}
        title={`Roles and Features`}
        subTitle={`Active Directory Domain Services (ADDS)`}
        link={link}
        links={links}
        pages={pages}
    />,
    props: {
        menu: true
    }
};

export default page;