import React from 'react';
import Book, {maxPageChunks} from "../../../../../../../component/bookshelf/book";
import {pagesType, pageType} from "../../../../../../../pages";
import * as Images from '../../../../../../../asset/guide/windows/client/7';

const link  = `/guides/windows/client/7/installation`;
let   pages: pagesType = [
    {
        title: `Youtube video`,
        pages: [
            {
                text: <p>In this guide we are going to install a windows 7 (client).</p>,
                youtubeIdFull: `Ih4w0iij8qM`
            },
        ]
    },
    {
        title: `Installation Windows 7`,
        pages: [
            {
                text: <div>
                    <p>Select the desired language, time and currency format and Keyboard or input method.</p>
                    <p>For this guide i'll use the default English and US international keyboard.</p>
                </div>,
                imageSrc: Images.Language
            },
            {
                text: <p>Click on "Install now".</p>,
                imageSrc: Images.InstallNow
            },
            {
                text: <p>Read the license terms, check the "I accept the license terms" and click "Next" to proceed.</p>,
                imageSrc: Images.License
            },
            {
                text: <p>Choose the "Custom (advanced)" option.</p>,
                imageSrc: Images.CustomAdvanced
            },
            {
                text: <p>Now we need to allocate space to install the server on. Click on "Drive options (advanced)".</p>,
                imageSrc: Images.DriverOptions
            },
            {
                text: <p>Click on "New".</p>,
                imageSrc: Images.NewVolume
            },
            {
                text: <div>
                    <p>Leave the size as it is and click "Apply".</p>
                    <p>The disk size may vary on your computer/virtual machine.</p>
                </div>,
                imageSrc: Images.Apply
            },
            {
                text: <p>Click "Ok" to proceed.</p>,
                imageSrc: Images.WindowAdditionalPartitions
            },
            {
                text: <p>Click "Next".</p>,
                imageSrc: Images.DiskPartitions
            },
            {
                text: <p>Fill in "Admin" as username and "TutorialClient2" as the computer name and click on "Next".</p>,
                imageSrc: Images.SetupComputerName
            },
            {
                text: <p>Fill in a strong password and a use full hint and click "Next".</p>,
                imageSrc: Images.Password
            },
            {
                text: <p>Fill in the product key and click on "Next".</p>,
                imageSrc: Images.ProductKey
            },
            {
                text: <p>Click on "Use recommended settings".</p>,
                imageSrc: Images.UpdateSettings
            },
            {
                text: <p>Select your TimeZone and click on "Next".</p>,
                imageSrc: Images.TimeZone
            },
            {
                text: <p>Click on "Work network".</p>,
                imageSrc: Images.NetworkConnectionType
            }
        ]
    },
    {
        title: `VMware tools`,
        pages: [
            {
                text: <p>Right click on the virtual machine and click on "Install VMware Tools...".</p>,
                imageSrc: Images.VMWareToolsInstall
            },
            {
                text: <div>
                    <p>Open a explorer.</p>
                    <p>Tip: use the shortcut "windows key" + "E".</p>
                </div>,
                imageSrc: Images.Explorer
            },
            {
                text: <p>Click on "VMware Tools" on the DVD/CD drive.</p>,
                imageSrc: Images.DVDDrive
            },
            {
                text: <p>Click on "Yes".</p>,
                imageSrc: Images.WindowsUserAccountControl
            },
            {
                text: <p>Click on "Next &gt;".</p>,
                imageSrc: Images.VMWareTools1
            },
            {
                text: <p>Leave the setup type on "Typical" and click on "Next &gt;" to proceed.</p>,
                imageSrc: Images.VMWareTools2
            },
            {
                text: <p>Click on "Install" and wait until the setup is done.</p>,
                imageSrc: Images.VMWareTools3
            },
            {
                text: <p>Click on "Finish" to close the setup.</p>,
                imageSrc: Images.VMWareTools4
            },
            {
                text: <p>Click on "Yes" if the screen didn't adjust to the VMware client.</p>,
                imageSrc: Images.VMWareTools5
            }
        ]
    },
    {
        title: `Verify computer name`,
        pages: [
            {
                text: <p>Log in.</p>,
                imageSrc: Images.SignIn
            },
            {
                text: <p>Click on the start menu, right click on "Computer" and click on "Properties".</p>,
                imageSrc: Images.ComputerProperties
            },
            {
                text: <p>Click on "Change settings".</p>,
                imageSrc: Images.SystemChangeSettings
            },
            {
                text: <p>See that this computer has the computer name which we give in the setup/install earlier.</p>,
                imageSrc: Images.ComputerNameVerify
            },
            {
                text: <p>Congratulations, you've just installed Windows 7! &#127881;</p>,
            }
        ]
    },
];

const links: string[] = [];
const lengthPages: number = (pages.map(
    (p) => {
        const modulo = p.pages.length % maxPageChunks;
        return p.pages.length + (modulo !== 0 ? maxPageChunks - (p.pages.length % maxPageChunks) : 0);
    }
).reduce((a,b)=>a+b) / (maxPageChunks * 2)) +1;
for (let i=0;i<=lengthPages;i++) {
    links.push(`${link}/${i}`);
}

const page: pageType = {
    name: `Windows 7`,
    link: [
        link,
        ...links,
    ],
    page: <Book
        logo={Images.Logo}
        preTitle={`Windows 7`}
        title={`Installation`}
        link={link}
        links={links}
        pages={pages}
    />,
    props: {
        menu: true
    }
};

export default page;