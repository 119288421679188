import React        from "react";
import styled       from 'styled-components';
import { pageType } from "../pages";
import Body         from "../component/body";
import { Input }    from "../component/form";
import Textarea     from "../component/form/textarea";
import * as utils   from '../utils';
import Select       from "../component/form/select";
import { ws }       from "../utils";
import ReCAPTCHA    from "../component/recaptcha";
import MuiIconFeedback from '@mui/icons-material/Textsms';
import MuiImageClose from '@mui/icons-material/Close';

const StyledContactContainer = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    min-width: 30rem;
    opacity: 1;
    transition: opacity .3s ease-in-out;
    
    & > * {
        margin: .25rem 0;
    }
    
    &.sent {
        opacity: .3;
        pointer-events: none;
        cursor: progress;
    }
`;

const StyledMessageFormSent = styled.div`
    opacity: 0;
    transition: opacity .2s ease-in-out;

    &.sent {
        opacity: 1;
    }
`;

interface Props {
    isOverlay?: boolean
}

interface State {
    isOverlay: boolean;
    showOther: boolean;
    defaultType: string;
    dateTimeFormat: string;
    name: React.RefObject<Input>;
    feedbackType: React.RefObject<Select>;
    feedbackTypeOther: React.RefObject<Input>;
    email: React.RefObject<Input>;
    description: React.RefObject<Textarea>;
    nameCharactersCurrent: number;
    feedbackTypeOtherCharactersCurrent: number;
    emailCharactersCurrent: number;
    descriptionCharactersCurrent: number;
    maxCharacters: {
        name: number;
        feedbackTypeOtherCurrent: number;
        email: number;
        description: number;
    };
    valid: {
        name: boolean;
        feedbackType: boolean;
        feedbackTypeOther: boolean;
        email: boolean;
        description: boolean;
    };
    currentDateTime: () => Date;
    blockedUntil?: Date;
    error?: string;
    success?: string;
    sentEnabled: { [name: string]: boolean };
    recaptcha: boolean;
    submitted: boolean;
}

class PageBody extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        const dateTimeFormat  = utils.storage.account.preferences.get(`dateTimeFormat`);
        const currentDateTime = (): Date => new Date();
        const blockedUntil    = new Date(utils.storage.contact.get(`feedback`));
        this.state            = {
            isOverlay                          : !!this.props.isOverlay,
            showOther                          : false,
            defaultType                        : `FEEDBACK`,
            dateTimeFormat                     : dateTimeFormat || `Y-m-d H:i:s`,
            name                               : React.createRef<Input>(),
            feedbackType                       : React.createRef<Select>(),
            feedbackTypeOther                  : React.createRef<Input>(),
            email                              : React.createRef<Input>(),
            description                        : React.createRef<Textarea>(),
            nameCharactersCurrent              : 0,
            feedbackTypeOtherCharactersCurrent : 0,
            emailCharactersCurrent             : 0,
            descriptionCharactersCurrent       : 0,
            maxCharacters                      : {
                name                     : 64,
                feedbackTypeOtherCurrent : 128,
                email                    : 100,
                description              : 512
            },
            valid                              : {
                name              : false,
                feedbackType      : true,
                feedbackTypeOther : true,
                email             : false,
                description       : false,
            },
            currentDateTime                    : currentDateTime,
            blockedUntil                       : blockedUntil,
            error                              : currentDateTime() <= blockedUntil ? `Sorry, this form is locked until ${utils.formattedDate(blockedUntil, dateTimeFormat)} is passed.` : undefined,
            success                            : undefined,
            sentEnabled                        : {
                name              : false,
                feedbackType      : true,
                feedbackTypeOther : true,
                email             : false,
                description       : false,
                threshold         : this.getThreshold(blockedUntil, currentDateTime),
            },
            recaptcha: false,
            submitted: false,
        };
    }

    componentDidMount() {
        ws.listener.message(
            [`verify.recaptcha`],
            data => this.setState({recaptcha: data.code === 200})
        );

        setInterval(
            () => {
                if (
                    this.state.blockedUntil
                    && this.state.error !== undefined
                    && this.state.currentDateTime() > this.state.blockedUntil
                ) {
                    const inputData = this.inputData();
                    this.setState(
                        {
                            error       : undefined,
                            sentEnabled : {
                                name         : inputData.name.valid,
                                feedbackType : inputData.feedbackType.valid,
                                email        : inputData.email.valid,
                                description  : inputData.description.valid,
                                threshold    : this.getThreshold(),
                            }
                        },
                    );
                }
            },
            1000
        );
    }

    inputData() {
        const nameCurrent              = this.state.name.current?.state.inputRef.current?.value || ``;
        const feedbackTypeCurrent      = this.state.feedbackType.current?.state.inputRef.current?.value || ``;
        const feedbackTypeOtherCurrent = this.state.feedbackTypeOther.current?.state.inputRef.current?.value || ``;
        const emailCurrent             = this.state.email.current?.state.inputRef.current?.value || ``;
        const descriptionCurrent       = this.state.description.current?.state.inputRef.current?.value || ``;

        return {
            name              : {
                value          : nameCurrent,
                length         : nameCurrent.length,
                valid          : nameCurrent.length > 3,
                greaterThanMax : nameCurrent.length > this.state.maxCharacters.name,
            },
            feedbackType      : {
                value          : feedbackTypeCurrent,
                length         : feedbackTypeCurrent.length,
                valid          : feedbackTypeCurrent.length !== 0,
                greaterThanMax : false,
            },
            feedbackTypeOther : {
                value          : feedbackTypeOtherCurrent,
                length         : feedbackTypeOtherCurrent.length,
                valid          : feedbackTypeCurrent !== `OTHER` || feedbackTypeOtherCurrent.length > 3,
                greaterThanMax : feedbackTypeCurrent !== `OTHER` || feedbackTypeOtherCurrent.length > this.state.maxCharacters.feedbackTypeOtherCurrent,
            },
            email             : {
                value          : emailCurrent,
                length         : emailCurrent.length,
                valid          : utils.validation.isValidEmail(emailCurrent),
                greaterThanMax : emailCurrent.length > this.state.maxCharacters.email,
            },
            description       : {
                value          : descriptionCurrent,
                length         : descriptionCurrent.length,
                valid          : descriptionCurrent.length > 20,
                greaterThanMax : descriptionCurrent.length > this.state.maxCharacters.description,
            },
        };
    }

    getThreshold(blockedUntil?: Date, currentDateTime?: () => Date): boolean {
        if (blockedUntil === undefined) {
            blockedUntil = this.state.blockedUntil;
        }

        if (currentDateTime === undefined) {
            currentDateTime = this.state.currentDateTime;
        }

        return blockedUntil === undefined
            || blockedUntil.toString() === `Invalid Date`
            || currentDateTime() > blockedUntil;
    }

    inputChangedCheckMax(e: KeyboardEvent) {
        const inputData = this.inputData();
        if (
            (
                inputData.name.greaterThanMax
                || inputData.email.greaterThanMax
                || inputData.description.greaterThanMax
            )
            && ![
                `shift`,
                `backspace`,
                `home`,
                `end`,
                `delete`,
            ].includes(e.key.toLowerCase())
        ) {
            e.preventDefault();
        }

        this.setState(
            {
                nameCharactersCurrent        : inputData.name.length,
                emailCharactersCurrent       : inputData.email.length,
                descriptionCharactersCurrent : inputData.description.length,
            }
        );
    }

    inputChanged() {
        const inputData = this.inputData();
        if (
            inputData.name.length > this.state.maxCharacters.name
            || inputData.feedbackTypeOther.length > this.state.maxCharacters.feedbackTypeOtherCurrent
            || inputData.email.length > this.state.maxCharacters.email
            || inputData.description.length > this.state.maxCharacters.description
        ) {
            return;
        }

        const currentState = {
            name              : inputData.name.valid,
            feedbackType      : inputData.feedbackType.valid,
            feedbackTypeOther : inputData.feedbackTypeOther.valid,
            email             : inputData.email.valid,
            description       : inputData.description.valid,
        };

        this.setState(
            {
                sentEnabled                        : {
                    ...currentState,
                    threshold : this.getThreshold(),
                },
                nameCharactersCurrent              : inputData.name.length,
                feedbackTypeOtherCharactersCurrent : inputData.feedbackTypeOther.length,
                emailCharactersCurrent             : inputData.email.length,
                descriptionCharactersCurrent       : inputData.description.length,
                valid                              : currentState,
            }
        );
    }

    sentContactForm() {
        if (
            !this.state.recaptcha
            || Object.values(this.state.sentEnabled).includes(false)
        ) {
            return;
        }

        const inputData     = this.inputData();
        const contactObject = {
            name              : inputData.name.value,
            feedbackType      : inputData.feedbackType.value,
            feedbackTypeOther : inputData.feedbackTypeOther.value,
            email             : inputData.email.value,
            description       : inputData.description.value,
        };

        const messageSeconds  = 7;
        const blockedSeconds  = 1;
        const blockedDateTime = new Date();
        blockedDateTime.setTime((this.state.currentDateTime().getTime() || 0) + (blockedSeconds * 1000));

        utils.storage.contact.set.key(
            `feedback`,
            utils.formattedDate(blockedDateTime, `Y-m-d H:i:s.u`)
        );

        (this.state.name.current?.state.inputRef.current as HTMLInputElement).value              = ``;
        (this.state.feedbackType.current?.state.inputRef.current as HTMLSelectElement).value     = this.state.defaultType;
        const feedbackOther = this.state.feedbackTypeOther.current?.state.inputRef.current as HTMLInputElement;
        if (feedbackOther !== undefined && feedbackOther !== null) {
            feedbackOther.value = ``;
        }
        (this.state.email.current?.state.inputRef.current as HTMLInputElement).value             = ``;
        (this.state.description.current?.state.inputRef.current as HTMLTextAreaElement).value    = ``;

        this.setState(
            {
                showOther                          : false,
                success                            : `Form sent successful!`,
                blockedUntil                       : blockedDateTime,
                sentEnabled                        : {
                    ...this.state.sentEnabled,
                    threshold : this.getThreshold(blockedDateTime),
                },
                valid                              : {
                    ...this.state.valid,
                    name        : false,
                    email       : false,
                    description : false,
                },
                nameCharactersCurrent              : 0,
                feedbackTypeOtherCharactersCurrent : 0,
                emailCharactersCurrent             : 0,
                descriptionCharactersCurrent       : 0,
                recaptcha: false,
                submitted: true
            },
            () => {
                // @ts-ignore
                window.grecaptcha.reset();

                utils.ws.sent(
                    `contact`,
                    `feedback`,
                    {
                        ...contactObject,
                        accountId       : utils.storage.account.get(`accountId`) || `anonymous`,
                        type            : contactObject.feedbackType,
                        typeDescription : contactObject.feedbackTypeOther
                    },
                );

                !this.state.isOverlay
                && setTimeout(
                    () => this.setState(
                        {
                            success : undefined,
                            error   : this.state.currentDateTime && this.state.blockedUntil && this.state.currentDateTime() <= this.state.blockedUntil
                                ? `This form is locked until ${utils.formattedDate(this.state.blockedUntil, this.state.dateTimeFormat)} is passed.`
                                : undefined,
                            submitted: false,
                        },
                    ),
                    messageSeconds * 1000
                );
            }
        );
    }

    selectChange() {
        const isOther = (this.state.feedbackType.current?.state.inputRef.current as HTMLSelectElement).value === `OTHER`;
        if (!isOther) {
            const feedbackOther = this.state.feedbackTypeOther.current?.state.inputRef.current as HTMLInputElement;
            if (feedbackOther !== undefined && feedbackOther !== null) {
                feedbackOther.value = ``;
            }
        }

        const inputData = this.inputData();

        this.setState(
            {
                showOther                          : isOther,
                feedbackTypeOtherCharactersCurrent : isOther ? inputData.feedbackTypeOther.length : 0,
                sentEnabled                        : {
                    ...this.state.sentEnabled,
                    feedbackTypeOther : isOther ? inputData.feedbackTypeOther.valid : true,
                },
                valid                              : {
                    ...this.state.valid,
                    feedbackTypeOther : isOther ? inputData.feedbackTypeOther.valid : true,
                }
            }
        );
    }

    render() {
        return <Body
            background={false}
        >
            <h1>Feedback</h1>
            <StyledMessageFormSent
                className={this.state.submitted ? `sent` : ``}
            >
                The feedback information has been sent successful!
            </StyledMessageFormSent>
            {
                (this.state.isOverlay && !this.state.submitted) || !this.state.isOverlay
                    ? <StyledContactContainer
                        className={this.state.submitted ? `sent` : ``}
                    >
                        <Input
                            ref={this.state.name}
                            type={`text`}
                            placeholder={`Name`}
                            iconName={`username`}
                            onBlur={() => this.inputChanged()}
                            onKeyUp={() => this.inputChanged()}
                            onKeyPress={(e: KeyboardEvent) => this.inputChangedCheckMax(e)}
                            errorEnabled={false}
                            success={this.state.valid.name}
                            currentLength={this.state.nameCharactersCurrent}
                            maxLength={this.state.maxCharacters.name}
                            showLength={true}
                        />
                        <Select
                            ref={this.state.feedbackType}
                            placeholder={`email`}
                            defaultOption={this.state.defaultType}
                            options={
                                [
                                    {
                                        value : `IMPROVEMENT`,
                                        name  : `Improvement`,
                                    },
                                    {
                                        value : `BUG`,
                                        name  : `Bug`,
                                    },
                                    {
                                        value : `FEEDBACK`,
                                        name  : `Feedback`,
                                    },
                                    {
                                        value : `OTHER`,
                                        name  : `Other`,
                                    },
                                ]
                            }
                            onChange={() => this.selectChange()}
                            success={this.state.valid.feedbackType}
                        />
                        {
                            this.state.showOther
                            && <Input
                                ref={this.state.feedbackTypeOther}
                                type={`text`}
                                placeholder={`What is the other type?`}
                                iconName={`description`}
                                onBlur={() => this.inputChanged()}
                                onKeyUp={() => this.inputChanged()}
                                onKeyPress={(e: KeyboardEvent) => this.inputChangedCheckMax(e)}
                                errorEnabled={false}
                                success={this.state.valid.feedbackTypeOther}
                                currentLength={this.state.feedbackTypeOtherCharactersCurrent}
                                maxLength={this.state.maxCharacters.feedbackTypeOtherCurrent}
                                showLength={true}
                            />
                        }
                        <Input
                            ref={this.state.email}
                            type={`text`}
                            placeholder={`Email`}
                            onBlur={() => this.inputChanged()}
                            onKeyUp={() => this.inputChanged()}
                            onKeyPress={(e: KeyboardEvent) => this.inputChangedCheckMax(e)}
                            errorEnabled={false}
                            success={this.state.valid.email}
                            currentLength={this.state.emailCharactersCurrent}
                            maxLength={this.state.maxCharacters.email}
                            showLength={true}
                        />
                        <Textarea
                            ref={this.state.description}
                            placeholder={
                                [
                                    `What is your feedback?`,
                                    ``,
                                    `What do you want to let the website holders to know?`,
                                    ``,
                                    `Found a bug, know an improvement, suggestion or incorrect data/information?`,
                                ].join(`\r\n`)
                            }
                            onBlur={() => this.inputChanged()}
                            onKeyUp={() => this.inputChanged()}
                            onKeyPress={(e: KeyboardEvent) => this.inputChangedCheckMax(e)}
                            minHeight={`15rem`}
                            resizable={false}
                            success={this.state.valid.description}
                            currentLength={this.state.descriptionCharactersCurrent}
                            maxLength={this.state.maxCharacters.description}
                            showLength={true}
                        />
                        <ReCAPTCHA/>
                        <Input
                            type={`submit`}
                            placeholder={`Sent`}
                            title={this.state.error}
                            error={this.state.error}
                            success={!Object.values(this.state.sentEnabled).includes(false) && this.state.recaptcha}
                            onClick={() => this.sentContactForm()}
                        />
                    </StyledContactContainer>
                    : undefined
            }
        </Body>;
    }
}

const page: pageType = {
    name  : `feedback`,
    link  : [`/feedback`],
    page  : <PageBody />,
    props : {
        menu : false,
        // enabled: false
    }
};

const StyledSideIconContainer = styled.div`
    cursor: pointer;
    position: fixed;
    top: 7.5rem;
    right: 0;
    padding: 1rem 1rem .60rem 1rem;
    border-top-left-radius: .3rem;
    border-bottom-left-radius: .3rem;
    color: var(--color-text-invert);
    background-color: var(--color-background-menu);
    z-index: 3;

    &:hover {
        color: var(--color-menu-hover);
        background-color: var(--color-background-menu-hover);
    }
`;
const StyledSideIcon = styled(MuiIconFeedback)``;

const StyledOverlayFeedbackContainer = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0,0,0,.6);
    display: flex;
    flex-direction: column;
    z-index: 2;
`;
const StyledOverlayFeedback = styled.div`
    position: relative;
    margin: auto;
    padding: 1rem 2rem;
    border-radius: .3rem;
    background-color: var(--color-background);
`;
const StyledCloseButtonContainer = styled.div`
    cursor: pointer;
    position: absolute;
    top: 1.5rem;
    right: 1.5rem;
    z-index: 1;
`;
const StyledCloseButton = styled(MuiImageClose)`
`;

interface SideIconProps {}
interface SideIconState {
    show: boolean;
}

class SideIcon extends React.Component<SideIconProps, SideIconState> {
    constructor(props: SideIconProps) {
        super(props);

        this.state = {
            show: false,
        };

        document.documentElement.addEventListener(
            `keyup`,
            (e: KeyboardEvent) => {
                if (this.state.show) {
                    e.key.toLowerCase() === `escape` && this.setState({show: false});
                }
            }
        );
    }

    render() {
        return !page.link.map((pathName) => window.location.pathname.includes(pathName)).includes(true) && page.props?.enabled !== false
            ? <div>
                <StyledSideIconContainer
                    title={`Feedback`}
                    onClick={() => this.setState({show: !this.state.show})}
                >
                    <StyledSideIcon/>
                </StyledSideIconContainer>
                {
                    this.state.show
                    && <StyledOverlayFeedbackContainer>
                        <StyledOverlayFeedback>
                            <StyledCloseButtonContainer
                                title={`Close`}
                                onClick={() => this.setState({show: false})}
                            >
                                <StyledCloseButton/>
                            </StyledCloseButtonContainer>
                            <PageBody isOverlay={true}/>
                        </StyledOverlayFeedback>
                    </StyledOverlayFeedbackContainer>
                }
            </div>
            : undefined;
    }
}

export default page;
export {
    SideIcon
};