import ads from "./ads";
import account from "./account";
import contact from "./contact";
import features from "./features";
import menu from "./menu";
import artificial_intelligence from "./artificial_intelligence";

const eulaAgreement = true;
const storageKey: string = `agers`;
const storageType: Storage = localStorage;
const storage = {
    set   : {
        storage: (storageObject: object): void => eulaAgreement
            ? storageType.setItem(storageKey, JSON.stringify(storageObject))
            : undefined,
        key    : function (key: string, value?: any): boolean {
            if (`${key}`.length === 0) {
                return false;
            }

            const storageAgers = storage.get();
            if (
                typeof storageAgers === `object`
                && storageAgers !== null
            ) {
                storageAgers[key] = value;
                this.storage(storageAgers);

                return true;
            }

            return false;
        },
        keys   : function (object: object): void {
            const storageAgers = storage.get();
            if (
                typeof storageAgers !== `object`
                || storageAgers === null
            ) {
                return;
            }

            this.storage(
                {
                    ...storageAgers,
                    ...object
                }
            );
        },
    },
    remove: {
        key : (key: string): void => {
            if (`${key}`.length === 0) {
                return;
            }

            const storageAgers = storage.get();
            if (
                typeof storageAgers === `object`
                && storageAgers !== null
                && storageAgers.hasOwnProperty(key)
            ) {
                storageAgers[key] = undefined;
                delete storageAgers[key];
                storage.set.storage(storageAgers);
            }
        },
        keys: (...keys: string[]): void => {
            const storageAgers = storage.get();
            if (
                typeof storageAgers !== `object`
                || storageAgers === null
            ) {
                return;
            }

            keys.forEach(
                key => {
                    if (storageAgers.hasOwnProperty(key)) {
                        storageAgers[key] = undefined;
                        delete storageAgers[key];
                    }
                }
            );

            storage.set.storage(storageAgers);
        }
    },
    clear : (): void => storageType.removeItem(storageKey),
    get   : (key?: string) => {
        const storageAgers = JSON.parse(storageType.getItem(storageKey) || `{}`);
        if (!key) {
            return storageAgers;
        }

        return storageAgers.hasOwnProperty(key)
            ? storageAgers[key]
            : undefined;
    },

    account  : account,
    menu     : menu,
    features : features,
    contact  : contact,
    ads      : ads,
    ai       : artificial_intelligence,
};

export default storage;